import React from "react";
import NormalTextComponent from "../styles/NormalTextComponent";
import "./PriceCard.css";
import {
  BASAR_PARTNER_ANDROID_APP_URL,
  BASAR_PARTNER_IOS_APP_URL,
} from "./links/AppStoreLinks";
import { COLORS } from "../designSystem/colors";
import dS from "../designSystem/designSystem";

interface PriceCardProps {
  offer: string;
  price: string;
  pricedetails: string;
  benefitOne: string;
  benefitTwo: string;
  benefitThree: string;
  showBadge: boolean;
}

const useDeviceDetect = () => {
  const [isAndroid, setIsAndroid] = React.useState(false);

  React.useEffect(() => {
    const userAgent =
      navigator.userAgent || navigator.vendor || (window as any).opera;
    setIsAndroid(/android/i.test(userAgent));
  }, []);

  return { isAndroid };
};

const PriceCard: React.FC<PriceCardProps> = ({
  offer,
  price,
  benefitOne,
  benefitTwo,
  benefitThree,
  pricedetails,
  showBadge,
}: PriceCardProps) => {
  const { isAndroid } = useDeviceDetect();

  const getAppStoreLink = (): string => {
    return isAndroid
      ? BASAR_PARTNER_ANDROID_APP_URL
      : BASAR_PARTNER_IOS_APP_URL;
  };

  const redirectToAppStore = () => {
    window.open(getAppStoreLink(), "_blank");
  };

  return (
    <div className="price-card-container">
      {!showBadge && <div className="special-badge">Spare 20%</div>}
      <div className="price-card">
        <div className="price-card-info-area">
          <div className="first-row">
            <NormalTextComponent size={"L"}>{offer}</NormalTextComponent>
            <div className="price-details-container">
              <NormalTextComponent size={"L"}>{price}</NormalTextComponent>
              <NormalTextComponent
                style={{
                  fontWeight: "lighter",
                  color: dS.colors.basarColorGrey,
                  lineHeight: "1",
                }}
                size={"XS"}
              >
                {pricedetails}
              </NormalTextComponent>
            </div>
          </div>
          <div className="second-row">
            <ul className="benefit-list">
              <li>
                <span role="img" aria-label="checkmark">
                  ✅
                </span>{" "}
                {benefitOne}
              </li>
              <li>
                <span role="img" aria-label="checkmark">
                  ✅
                </span>{" "}
                {benefitTwo}
              </li>
              <li>
                <span role="img" aria-label="checkmark">
                  ✅
                </span>{" "}
                {benefitThree}
              </li>
            </ul>
          </div>
        </div>
        <div className="price-card-action-area">
          <button
            className="call-to-action-button"
            onClick={redirectToAppStore}
          >
            <NormalTextComponent
              className="action-text"
              size={"L"}
              title={""}
              seoDescription={""}
            >
              Jetzt buchen
            </NormalTextComponent>
          </button>
        </div>
      </div>
    </div>
  );
};

export default PriceCard;
