export const translations = {
  "de": {
    "!add_row_below": "VVV Zeile hierdrunter hinzufügen VVV",
    "so_easy_for_partners": "So einfach geht’s für Partner",
    "so_easy_for_partners.register_store": "Melde dein Geschäft schnell und unkompliziert an, um mehr Kunden zu erreichen.",
    "so_easy_for_partners.create_coupon": "Erstelle individuelle Gutscheine für deine Kunden, steigere den Umsatz und gewinne neue Kunden – alles nach deinen Wünschen.",
    "so_easy_for_partners.manage_store": "Verwalte dein Geschäft ganz einfach – passe Öffnungszeiten, Bilder und Gutscheine jederzeit nach Bedarf an.",
    "change_language": "Sprache ändern",
    "english_USA": "🇬🇧 Englisch",
    "german_Germany": "🇩🇪 Deutsch",
    "Imprint": "Impressum",
    "Datenschutz": "Datenschutzerklärung",
    "AGB": "Allgemeine Geschäftsbedingungen",
    "Cookies": "Cookies",
    "about": "ÜBER UNS",
    "our_magazine": "Unser Magazin",
    "for_companies": "Für Firmen",
    "contact": "Kontakt",
    "no_answer_found": "Frage nicht beantwortet?",
    "jobs": "Jobs",
    "become_partner": "Werde Partner",
    "shop": "Shop",
    "experiences": "Erfahrungen",
    "legal": "RECHTLICHES",
    "social_media": "SOZIALE MEDIEN",
    "frequently_asked_questions": "FAQ",
    "vendor_faq.how_work_question": "Wie funktioniert BASAR für Partner?",
    "vendor_faq.how_work_answer": "Wir haben Partnerschaften mit verschiedenen lokalen Unternehmen geschlossen und einzigartige Angebote wie 2-für-1-Dienstleistungen oder Rabatte auf Produkte ausgehandelt. Mit der Basar-App können Sie diese Angebote in zahlreichen Branchen wie Restaurants, Friseuren, Werkstätten, Blumenläden und vielen anderen ansehen, buchen und einlösen.",
    "vendor_faq.participating_businesses_question": "Welche Unternehmen nehmen teil?",
    "vendor_faq.participating_businesses_answer": "Derzeit nehmen tausende lokale Unternehmen mit einer Vielzahl exklusiver Angebote teil. Von Gastronomie über Dienstleistungen bis hin zu Produkten finden Sie tolle Angebote in vielen Kategorien.",
    "vendor_faq.app_free_question": "Ist die App derzeit kostenlos?",
    "vendor_faq.app_free_answer": "Ja, die BASAR-App ist derzeit vollständig kostenlos. Partner können teilnehmen und Angebote ohne Gebühren bereitstellen.",
    "vendor_faq.create_store_question": "Wie erstelle ich ein Geschäft auf BASAR?",
    "vendor_faq.create_store_answer": "Ihr Geschäft bei BASAR zu erstellen, ist einfach und schnell. Sie können Ihr Geschäftsprofil in wenigen Klicks direkt in der App einrichten.",
    "vendor_faq.manage_coupons_question": "Kann ich meine eigenen Coupons erstellen und verwalten?",
    "vendor_faq.manage_coupons_answer": "Ja, Sie haben die vollständige Kontrolle über das Erstellen und Löschen Ihrer eigenen Coupons. Legen Sie Rabatte, Angebote und Bedingungen fest, die am besten zu Ihrem Geschäft passen. Keine Sales Manager stören – Ihr Geschäft, Ihr Marketing.",
    "faq.general.category": "ALLGEMEINES",
    "faq.general.questions[0].question": "Was ist das Konzept von Basar?",
    "faq.general.questions[0].answer": "Wir haben Partnerschaften mit verschiedenen lokalen Unternehmen geschlossen und exklusive Einführungsgutscheine ausgehandelt, wie z. B. 2-für-1-Angebote oder Rabatte auf Produkte. Mit der Basar-App kannst du diese Angebote ganz einfach durchstöbern, buchen und einlösen und das in einer Vielzahl von Branchen wie Restaurants, Friseursalons, Werkstätten, Blumenläden und mehr.",
    "faq.general.questions[1].question": "Welche Unternehmen nehmen teil?",
    "faq.general.questions[1].answer": "Derzeit nehmen Tausende von lokalen Unternehmen mit einer Vielzahl exklusiver Angebote teil. Von Gastronomie bis zu Dienstleistungen und Produkten findest du in vielen Kategorien großartige Deals.",
    "start": "Start",
    "price": "Preis",
    "redeem_error_notToday_title": "Coupon heute nicht einlösbar.",
    "redeem_error_notToday_message": "Der Coupon ist nur zu den angegebenen Tagen einlösbar.",
    "redeemDays.monday": "Mo",
    "redeemDays.tuesday": "Di",
    "redeemDays.wednesday": "Mi",
    "redeemDays.thursday": "Do",
    "redeemDays.friday": "Fr",
    "redeemDays.saturday": "Sa",
    "redeemDays.sunday": "So",
    "coupon_factory.your_coupon_active": "Dein Coupon ist nun aktiv und in Kürze sichtbar für deine Kunden!",
    "coupon_factory.valid_days_header": "Gültige Tage",
    "coupon_factory.valid_days_subHeader": "Tage, an denen die Kunden den Coupons einlösen dürfen.",
    "error_messages.something_wrong": "Es ist etwas schiefgelaufen - Probiere es später nocheinmal oder kontaktiere uns, wenn das Problem weiterhin besteht.",
    "store_factory.store_not_found_input_manual": "Geschäft nicht gefunden? Manuelle Adresseingabe.",
    "sent_review": "Bewertung abschicken.",
    "review_your_visit_at": "Bewerte dein Besuch @",
    "search": "Suche",
    "faq_title": "FAQ (Häufig gestellte Fragen)",
    "faq_title_reverse": "Häufig Gestellte Fragen (FAQ)",
    "vendor_faq_question_concept": "Wie funktioniert BASAR?",
    "vendor_faq_answer_concept": "Das Konzept von BASAR ist simpel und effektiv. Über die BASAR Business App kannst du als Selbstständiger mit stationärem Geschäft deinen Laden präsentieren und Coupons erstellen. Nutzer finden diese Coupons über die BASAR Nutzer App und können sie direkt vor Ort in deinem Geschäft einlösen. So stärken wir lokale Geschäfte im Wettbewerb gegen große Handelsketten.",
    "vendor_faq_question_costs": "Fallen für mich Kosten an?",
    "vendor_faq_answer_costs": "Nein, BASAR ist für Händler komplett kostenlos. Nutzer schließen ein Abo ab, um Zugang zu allen Coupons zu erhalten. Damit decken wir die Plattformkosten und ermöglichen es, lokale Geschäfte konkurrenzfähig zu machen – auch gegenüber großen Ketten, die in teures Marketing und Kundenbindungsprogramme investieren.",
    "vendor_faq_question_minium_requirements": "Welche Voraussetzungen muss ich erfüllen?",
    "vendor_faq_answer_minium_requirements": "Du benötigst ein physisches Ladengeschäft, in dem du Kunden vor Ort bedienst – z. B. ein Restaurant, ein Friseur, eine Werkstatt oder Ähnliches. Zudem solltest du oder deine Mitarbeiter die versprochenen Rabatte an der Kasse unkompliziert gewähren können.",
    "vendor_faq_question_interface_cashier": "Muss ich meine Kasse integrieren?",
    "vendor_faq_answer_interface_cashier": "Nein, BASAR funktioniert ganz unkompliziert mit einem klassischen 4-Augen-Prinzip. Der Kunde zeigt dir auf seinem Smartphone den eingelösten Coupon, und du gewährst den Rabatt. Keine teuren oder komplizierten Kassensystem-Integrationen sind notwendig. Wichtig ist nur, dass du oder deine Mitarbeiter die Rabatte korrekt umsetzen können.",
    "vendor_faq_question_shop_creation": "Wie erstelle ich meinen Shop?",
    "vendor_faq_answer_shop_creation": "Im Reiter „Geschäft“ kannst du deinen Shop erstellen. Suche dein Geschäft in unserer Datenbank oder lege es manuell an. Ergänze Kontaktinformationen, Öffnungszeiten, ein Foto und die passende Kategorie. Wir prüfen die Angaben und melden uns bei Rückfragen. In der Regel dauert die Überprüfung einen Werktag – danach kannst du sofort Coupons erstellen.",
    "vendor_faq_question_coupons": "Wie kann ich Coupons erstellen?",
    "vendor_faq_answer_coupons": "Nach der Erstellung deines Shops kannst du im Reiter „Coupons“ die Coupon-Fabrik starten. Dort findest du vorgefertigte Vorlagen, die du schnell und einfach mit deinen Angaben ergänzen kannst. Nach der Vorschau kannst du den Coupon erstellen, und er wird innerhalb kurzer Zeit live. Voraussetzung: Dein Shop muss verifiziert sein.",
    "vendor_faq_question_storeinfos_update": "Wie aktualisiere ich meine Geschäftsinformationen?",
    "vendor_faq_answer_storeinfos_update": "Gehe zu deinem Shop und klicke auf den Reiter „Infos“. Dort kannst du Kontaktinformationen, Öffnungszeiten und Bilder über die entsprechenden Bearbeiten-Buttons aktualisieren.",
    "vendor_faq_question_abuse": "Wie verhindert BASAR mehrfaches Einlösen eines Coupons?",
    "vendor_faq_answer_abuse": "Jeder Nutzer registriert sich mit seiner Telefonnummer und ist damit eindeutig identifizierbar. Bei der Coupon-Erstellung kannst du festlegen, wie oft ein Coupon eingelöst werden darf, z. B. nur alle 90 Tage. So bleibt die Nutzung fair und kontrolliert.",
    "vendor_faq_question_help_creation": "Unterstützt ihr uns bei der Erstellung des Geschäfts oder der Coupons?",
    "vendor_faq_answer_help_creation": "Natürlich! Wir helfen euch gerne, wenn ihr Unterstützung bei der Erstellung eures Geschäfts oder eurer Coupons benötigt. Ihr könnt uns jederzeit über WhatsApp kontaktieren. Die Nummer findet ihr in den Einstellungen unter \"Feedback & Support\".",
    "vendor_faq_question_how_to_contact": "Wie kann man euch erreichen?",
    "vendor_faq_answer_how_to_contact": "Ihr könnt uns per E-Mail unter basar@basar-digital.com oder direkt über WhatsApp erreichen. Die WhatsApp-Nummer findet ihr in den Einstellungen unter \"Feedback & Support\".",
    "create_store_and_coupons": "Erstelle dein Store & Coupons",
    "click_here_for_help": "Klicke hier, wenn du mehr Info brauchst.",
    "vendorOnboardingTutorial_title_step1": "Suche & erstelle deinen eigenen Shop.",
    "vendorOnboardingTutorial_description_step1": "Finde dein Geschäft über die Suchfunktion oder erstelle es ganz einfach selbst. Ergänze alle wichtigen Informationen wie Kontakt, Öffnungszeiten, Bilder und Kategorien.",
    "vendorOnboardingTutorial_imageCaption_step1": "Dein Shop, dein Start: Mit wenigen Klicks bist du für deine Kunden sichtbar – egal ob du einen bestehenden Shop hinzufügst oder ihn neu erstellst.",
    "vendorOnboardingTutorial_title_step2": "Erstelle unwiderstehliche Coupons.",
    "vendorOnboardingTutorial_description_step2": "Nutze unsere benutzerfreundliche Coupon-Fabrik, um maßgeschneiderte Angebote zu erstellen. Begeistere Kunden mit exklusiven Rabatten und bringe frischen Schwung in dein Geschäft.",
    "vendorOnboardingTutorial_imageCaption_step2": "Mit der Coupon-Fabrik bringst du Kunden ins Staunen: Erstelle in wenigen Sekunden individuelle Angebote und stärke deine Kundenbindung.",
    "vendorOnboardingTutorial_title_step3": "Freu dich auf begeisterte Kunden.",
    "vendorOnboardingTutorial_description_step3": "Mit deinen Angeboten ziehst du neue Kunden an und überraschst deine Stammkunden. Kunden können dein Geschäft und die Coupons direkt in der BASAR Nutzer-App finden. Die Coupons lösen Sie vor Ort bei dir ein & du kannst den Rabatt direkt an der Kasse gewähren.",
    "vendorOnboardingTutorial_imageCaption_step3": "Dein Geschäft, ihre Freude: Mehr Kunden, mehr Umsatz – mit der BASAR Nutzer-App wird dein Shop zur ersten Wahl.",
    "category": "Kategorie",
    "loadings_favorites": "Favoriten sind am Laden",
    "feature_so_great": "Dieses Feature ist so großartig, dass es nicht umsonst ist! Melde dich an und lege los.",
    "login_and_start": "Einloggen & Loslegen",
    "oops_something_missing": "Ups, da fehlt noch was!",
    "coupon_factory": "Coupon Fabrik",
    "coupon_factory.infoHeader_name_of_product": "Name des Produkts",
    "coupon_factory.infoBody_name_of_product": "Trage den Namen des Produkts oder der Dienstleistung ein, die der Kunde kaufen soll.",
    "coupon_factory.infoHeader_value_of_product": "Wert des Produkts",
    "coupon_factory. infoBody_value_of_product": "Wie viel kostet das Produkt bei dir im Geschäft?",
    "coupon_factory.infoHeader_count_of_product": "Anzahl Produkte",
    "coupon_factory.infoBody_count_of_product": "Wie viele er von diesem Produkt kaufen soll.",
    "coupon_factory.infoHeader_minimum_order_volume": "Mindestbestellwert / Mindesteinkaufswert",
    "coupon_factory.infoBody_minimum_order_volume": "Wie viel soll der Kunde mindestens ausgeben, um zu profitieren?",
    "coupon_factory.infoHeader_name_of_benefit_product": "Name des Vorteilprodukts",
    "coupon_factory.infoBody_name_of_benefit_product": "Trage hier den Namen des Produkts oder der Dienstleistung ein, die der Kunde erhält.",
    "coupon_factory.infoHeader_value_of_benefit_product": "Wert des Vorteilprodukts",
    "coupon_factory.infoBody_value_of_benefit_product": "Wie teuer ist das Produkt regulär ohne einen Angebotspreise?",
    "coupon_factory.infoHeader_discount_amount": "Rabatthöhe angeben",
    "coupon_factory.infoBody_discount_amount": "Wie hoch ist der Rabatt pro Stück - in € oder %.",
    "coupon_factory.infoHeader_order_discount_amount": "Rabatthöhe angeben",
    "coupon_factory.infoBody_order_discount_amount": "Wie hoch ist der Rabatt auf dem Einkauf oder der Bestellung - in € oder %.",
    "delete_coupon": "Coupon Löschen",
    "generate": "Generieren",
    "delete_coupon_text": "Möchtest du diesen Coupon wirklich löschen? Diese Aktion kann nicht rückgängig gemacht werden.",
    "delete_coupon_info": "Achtung: Dein Coupon ist bis zum Ende des Tages noch aktiv und ist ab dem nächsten Tag nicht mehr sichtbar für Nutzer.",
    "validation_all_inputs_required": "Alle Felder müssen korrekt ausgefüllt werden.",
    "store_factory.contact_and_opentimes": "Kontakt & Öffnungszeiten",
    "store_factory.always_editable_later": "Du kannst sie später jederzeit bearbeiten.",
    "choose_category_and_image": "Bild & Kategorie auswählen.",
    "store_factory.main_categories_limit": "Wähle eine Kategorie aus.",
    "searchdata_openstreetmap": "Suchdaten © OpenStreetMap",
    "success.opentimes_updated": "Öffnungszeiten erfolgreich aktualisiert!",
    "success.contactdetails_updated": "Kontaktdaten erfolgreich aktualisiert!",
    "editcontact": "Kontaktdetails bearbeiten",
    "from": "Von",
    "until": "Bis",
    "weekday": "Tag",
    "open_questionmark": "Geöffnet?",
    "coupon_factory.contact_if_issue": "Bitte überprüfe alle Angaben. Falls du dennoch Hilfe benötigst, kannst du uns gerne hier kontakieren",
    "respawn_time": "Wartezeit bis zur Erneuerung",
    "coupon_factory.coupon_in_three_steps": "Coupon erstellen in 3 Schritten.",
    "store_factory.your_store_on_map": "Dein Geschäft auf der Karte",
    "coupon_factory.infoHeader_need_help": "Brauchst du Unterstützung?",
    "coupon_factory.infoBody_need_help_whatsapp": "Kontaktiere uns direkt hier über Whatsapp.",
    "coupon_factory.check_preview": "Vorschau von deinem Coupon.",
    "coupon_factory.preview": "Vorschau",
    "coupon_factory.check_details": "Sind alle Angaben richtig? Klicke auf Generieren, um deinen Coupon live zu schalten!",
    "coupon_factory.respawn_time_description": "Wann ein Kunde den Coupon wieder aktivieren darf nach dem Einlösen. Wenn du ein Angebot nicht mehr anbieten möchtest, kannst du es selbstständig löschen.",
    "coupon_factory.respawn_time_short_description": "Wie lange der Kunde warten muss, bis er den Coupon erneut aktivieren darf.",
    "coupon_factory.thirty_days_recommendation": "Der Kunde kann den Coupon nach 30 Tagen erneut einlösen. Ideal für Angebote, die regelmäßig verfügbar sind und für wiederkehrende Kunden attraktiv sind.",
    "coupon_factory.ninety_days_recommendation": "Der Coupon kann nach 90 Tagen erneut genutzt werden. Diese Option eignet sich gut für längere Angebote, die sich an Neukunden richten.",
    "coupon_factory.weekly_recommendation": "Der Coupon kann 1x Woche erneut eingelöst werden. Perfekt für kurzfristige Promo-Aktionen, die regelmäßig erneuert werden müssen.",
    "coupon_factory.thirty_days_recommendation_short": "Ideal für regelmässig verfügbare Angebote.",
    "coupon_factory.ninety_days_recommendation_short": "Ideal für seltene Neukunden-Angebote.",
    "coupon_factory.weekly_recommendation_short": "Ideal für kurze Promo Angebote.",
    "coupon_factory.mainly_for_restaurants": "Trifft meist nur auf Gastronomie zu.",
    "coupon_factory.default_set": "Standardmässig gesetzt.",
    "coupon_factory.redeem_location_onsite_info": "Gültig, wenn der Kunde im Geschäft einkauft.",
    "coupon_factory.redeem_location_togo_info": "Gültig, wenn der Kunde etwas zum Mitnehmen kauft z.B. ein Döner zum Mitnehmen.",
    "coupon_factory.choose_coupon_type": "Coupon Typen auswählen",
    "coupon_factory.choose_coupon_type_details": "Wähle den Coupontypen aus, den du für deinen Geschäft erstellen möchtest.",
    "coupon_factory.example_header_one": "10€ Cashback",
    "coupon_factory.example_body_one": "Erhalte 10€ Rabatt ab einem Mindesbetrag von 50€",
    "coupon_factory.example_headertitle_one": "Cashback",
    "coupon_factory.example_subheader_one": "Deine Neukunden erhält einen Direktrabatt auf ihren Einkauf/Bestellung.",
    "coupon_factory.example_header_two": "1x Getränk GESCHENK",
    "coupon_factory.example_body_two": "Du erhälst 1x Schokolade gratis ohne Mindestbestellwert",
    "coupon_factory.example_headertitle_two": "GESCHENK",
    "coupon_factory.example_subheader_two": "Deine Neukunden erhalten ein Geschenk bei ihrem Einkauf.",
    "coupon_factory.example_header_three": "2 für 1 - Pommes",
    "coupon_factory.example_body_three": "Beim Kauf von 1 x Pommes erhältst du 1x Pommes kostenlos dazu. Gilt für das preisgleiche oder günstigere Produkt.",
    "coupon_factory.example_headertitle_three": "2 für 1",
    "coupon_factory.example_subheader_three": "Deine Neukunden erhalten 2 Produkte für den Preis von einem.",
    "coupon_factory.example_header_four": "50% Direktrabatt auf Jacken",
    "coupon_factory.example_body_four": "Du erhälst 1 x Blumenstrauss mit je 50% Rabatt ohne Mindestbestellwert.",
    "coupon_factory.example_headertitle_four": "SALE",
    "coupon_factory.example_subheader_four": "Deine Kunden erhalten einen Direktrabatt auf ein bestimmtes Produkt.",
    "coupon_factory.example_header_individual": "Individuell",
    "coupon_factory.example_body_individual": "Individueller Deal",
    "coupon_factory.example_headertitle_individual": "Individuell",
    "coupon_factory.example_subheader_individual": "Erstelle einen komplett individuellen Coupon für deine Kunden.",
    "welcome": "Willkommen",
    "languages.de": "🇩🇪 Deutsch",
    "languages.en": "🇺🇸 Englisch",
    "languages.tr": "🇹🇷 Türkisch",
    "languages.ar": "🇸🇦 Arabisch",
    "languages.fr": "🇫🇷 Französisch",
    "languages.es": "🇪🇸 Spanisch",
    "languages.it": "🇮🇹 Italienisch",
    "languages.pl": "🇵🇱 Polnisch",
    "languages.ru": "🇷🇺 Russisch",
    "popular_categories": "Beliebte Kategorien",
    "no_matching_categories_found": "Keine passenden Kategorien gefunden",
    "hello": "Hallo aus Deutschland",
    "with_coupons": "Mit Coupons",
    "cities_with_digital_basar": "Städte mit einem digitalen Basar",
    "use_your_location": "Deinen Standort verwenden",
    "no_matching_cities_found": "Keine passenden Städte gefunden",
    "discover": "Entdecken",
    "favorites": "Favoriten",
    "profile": "Profil",
    "delete": "Löschen",
    "openingTimes": "Öffnungszeiten",
    "editOpeningTimes": "Öffnungszeiten bearbeiten",
    "days.monday": "Montag",
    "days.tuesday": "Dienstag",
    "days.wednesday": "Mittwoch",
    "days.thursday": "Donnerstag",
    "days.friday": "Freitag",
    "days.saturday": "Samstag",
    "days.sunday": "Sonntag",
    "command_searchForYourBusiness": "Suche nach deinem Geschäft",
    "placeholder_find_store_google": "Finde dein Geschäft auf Google",
    "permission_to_camera_roll": "Zugriff auf die Fotomediathek ist erforderlich!",
    "businessMustBeOnGoogleMaps": "Dein Geschäft muss auf Google Maps auffindbar sein. Bei Problemen kannst du uns gerne hier kontaktieren.",
    "closed": "Geschlossen",
    "location": "Standort",
    "coupons": "Coupons",
    "activated_coupons": "Aktivierte Coupons",
    "redeemed_coupons": "Eingelöste Coupons",
    "activate": "Aktivieren",
    "redeem_now": "Jetzt einlösen",
    "coupon_redeemed_message": "Coupon eingelöst - Weiter so! So unterstützen wir den lokalen Handel.",
    "coupon_redeemed_message_title": "Coupon eingelöst!",
    "coupon_redeemed_message_bodxy": "Weiter so! So unterstützen wir den lokalen Handel.",
    "share_message": "Hey, ich bin auf BASAR. Lade dir über diesen Link die App runter.",
    "share_success": "Teilen erfolgreich!",
    "share_dismissed": "Teilen abgebrochen",
    "share_failed": "Teilen fehlgeschlagen:",
    "report_problem": "Problem melden",
    "deactivate_coupon": "Coupon Deaktivieren",
    "info": "Info",
    "more": "Mehr",
    "Information about this Coupons": "Informationen zu diesem Coupon",
    "command_mention_basar_for_redeem": "Erwähne vor dem Bezahlen, dass du einen BASAR-Gutschein hast, und löse ihn vor den Augen eines Mitarbeiters ein. Pro Besuch kann nur ein Coupon eingelöst werden.",
    "Redeem Location": "Einlösungsort",
    "redeem_location_info": "Ob das Angebot vor Ort oder zum Mitnehmen ist",
    "Reactivation time": "Reaktivierungszeit",
    "reactivation_info": "Wann das Angebot wieder aktiv ist für dich, nachdem du es eingelöst hast.",
    "reviews": "Bewertungen",
    "review": "Bewertungen",
    "show_all_reviews": "Alle Bewertungen anzeigen",
    "show_all": "Alle anzeigen",
    "REVIEW_SENT": "Bewertung abgeschickt.",
    "REVIEW_THANKS": "Vielen Dank für deine Bewertung!",
    "REVIEW_WRITE": "Schreibe deine Bewertung...",
    "loading": "Lädt",
    "noReviewsAvailable": "Noch keine Bewertungen",
    "noStoreInformationAvailable": "Noch keine Angaben",
    "advetorials.vendor.become_vendor_title": "Hast du ein lokales Geschäft vor Ort?",
    "advetorials.vendor.become_vendor_body": "Dann werde jetzt Händler auf unserer App. Wir haben viele Nutzer, die dein lokales Geschäft gerne unterstützen möchten.",
    "advetorials.vendor.download_vendor_app": "Lade dir die App für Händler im App Store herunter.",
    "become_Member": "Mitglied werden",
    "enter_discount": "Rabattcode eingeben",
    "translated_by_ai": "Übersetzungen können mit KI erstellt worden sein und sind deswegen nicht immer perfekt.",
    "closeButton": "Schliessen",
    "edit_profile": "Profil bearbeiten",
    "validation_invalid_input": "Ungültige Eingabe",
    "validation_required_input": "Feld muss ausgefüllt werden",
    "success": "Bestätigt",
    "error": "Fehler",
    "profile_update_success": "Profil erfolgreich aktualisiert!",
    "profile_image_update_success": "Profilbild erfolgreich aktualisiert",
    "profile_image_update_error": "Profilbild aktualisieren fehlgeschlagen",
    "tutorial": "Tutorial",
    "settings": "Einstellungen",
    "feedbackAndSupport": "Feedback & Support",
    "changeToVendorMode": "In den Händler-Modus wechseln",
    "changeToUserMode": "In den Kunden Modus wechseln",
    "registerAsPartner": "Als Partner registrieren",
    "logout": "Abmelden",
    "first_name": "Vorname",
    "last_name": "Nachname",
    "email": "E-Mail",
    "zipCode": "PLZ",
    "edit": "Bearbeiten",
    "delete_account": "Profil löschen",
    "delete_account_message": "Möchtest du wirklich dein Profil löschen? Alle deine Vorteile und Einstellungen gehen unwiederruflich verloren.",
    "delete_account_success": "Profil erfolgreich gelöscht",
    "delete_account_success_message": "Dein Profil wurde erfolgreich gelöscht. 👋 Wir hoffen, dass wir uns bald wieder sehen",
    "save": "Speichern",
    "back": "Zurück",
    "continue": "Weiter",
    "later": "Später",
    "terms_and_conditions": "Allgemeine Geschäftsbedingungen",
    "agree_to_terms": "Ich stimme den <terms>Allgemeinen Geschäftsbedingungen</terms> zu.",
    "phonenumber": "Telefonnummer",
    "enter_phonenumber": "Telefonnummer eingeben",
    "login_no_password_info": "Einfaches Login über SMS Code. Du brauchst dir also kein Passwort merken.",
    "enter_code": "Code eingeben",
    "sent_to": "Gesendet an",
    "search_country_code": "Suche nach dem Land oder der Vorwahl",
    "command_agree": "Bitte zustimmen",
    "command_agree_to_terms": "Sie müssen den Bedingungen zustimmen, um fortzufahren.",
    "otp": "Einmalpasswort",
    "enter_otp": "Einmalpasswort eingeben",
    "verify_otp": "Einmalpasswort bestätigen",
    "otp_expired": "Einmalpasswort abgelaufen",
    "otp_incorrect": "Einmalpasswort fehlerhaft",
    "otp_verification_error": "Falsches oder abgelaufenes Einmalpasswort eingegeben.",
    "command_create_profile_to_start": "Erstelle dein Profil, um loszulegen!",
    "command_create_company_to_start": "Registriere deine Firma, um loszulegen.",
    "info_create_shop_later": "Angaben zu deinem Shop kannst du im nächsten Schritt machen.",
    "your_concern": "Dein Anliegen",
    "describe_your_concern": "Schildere uns dein Anliegen.",
    "contact_you_asap": "Wir setzen uns schnellstmöglich mit dir in Kontakt.",
    "contact_via_whatsapp": "Kontaktiere uns via Whatsapp",
    "change_image": "Bild ändern",
    "edit_store_profile": "Geschäftsprofil Bearbeiten",
    "error_loading_store": "Fehler beim Laden der Geschäftsdaten",
    "error_messages.no_stores.no_stores_nearby_found_title": "Keine Geschäfte in deiner Nähe gefunden",
    "error_messages.no_stores.no_stores_nearby_found_body": "Anscheinend sind wir noch nicht schnell. Aber wir kommen dahin - keine Sorge. Ändere die Stadt über die Suche oder scroll dahin über die Karte.",
    "error_messages.conflict.store_exists_title": "Das Geschäft unter dieser Adresse wurde schon registriert.",
    "error_messages.conflict.store_exists_body": "Du kannst kein Geschäft erstellen, das schon registriert ist. Wenn du denkst, dass das ein Fehler ist, kontaktiere uns bitte hier.",
    "funny_error_message": "Ups - Da ist etwas schief gelaufen.",
    "page_not_found": "Seite konnte nicht geladen werden.",
    "page_not_found_connect_to_wifi": "Bitte stelle sicher, dass du mit dem Internet verbunden bist.",
    "page_not_found_help_message": "Falls das Problem weiterhin besteht, kontaktiere uns gerne",
    "cancel": "Abbrechen",
    "partner_login": "Partner Login",
    "partner_register": "Partner Registrierung",
    "command_no_account_register": "Keinen Account? Hier registrieren.",
    "command_have_account_login": "Hast du einen Account? Hier einloggen.",
    "login": "Login",
    "register": "Registrierung",
    "please_enter_email": "Bitte Email angeben",
    "email_sent": "Email versendet",
    "password_reset_email_sent": "Email zum Zurücksetzen des Passworts versendet.",
    "password_reset_failed": "Passwort zurücksetzen fehlgeschlagen.",
    "forgot_password": "Passwort vergessen",
    "password_reset_rate_limit": "Aus Sicherheitsgründen können Sie diese Anfrage nur einmal alle 60 Sekunden stellen. Bitte warten Sie und versuchen Sie es erneut.",
    "back_to_login": "Zurück zum Login",
    "company_profile": "Unternehmensprofil",
    "company_details": "Unternehmensdetails",
    "company_email": "Firmen Email",
    "company_name": "Firmenname",
    "company_address": "Firmenadresse",
    "company_zipCode": "Postleitzahl",
    "company_city": "Stadt",
    "company_country": "Land",
    "company_phone": "Telefonnummer der Firma",
    "company_pocName": "Vorname, Nachname (Ansprechsperson)",
    "company_pocRole": "Rolle auswählen (Ansprechsperson)",
    "store_image": "Bild vom Geschäft",
    "vendor_delete_account": "Möchtest du wirklich dein Profil löschen?",
    "vendor_delete_account_body": "Aktuell kann dein Profil nur gelöscht werden, indem du uns über Whatsapp kontaktierst oder über Email an basar@basar-digital.com",
    "select_a_category": "Wähle eine Kategorie aus",
    "categories.grocery": "Lebensmittel",
    "categories.restaurants": "Restaurant",
    "categories.hairSalon": "Friseur",
    "categories.electronics": "Elektronik",
    "categories.beauty_wellness": "Beauty & Wellness",
    "categories.other": "Andere",
    "categories.non_food_retail": "Einzelhandel",
    "categories.home_and_garden": "Haus und Garten",
    "categories.automotive": "Mobilität",
    "categories.flowers_and_gifts": "Blumen und Geschenke",
    "categories.activities": "Aktivitäten",
    "categories.cafe_and_dessert": "Café & Dessert",
    "categories.farm_shop": "Hofladen",
    "subcategories.convenienceStore": "Mini-Markt",
    "subcategories.eastEuropean": "Ost-Europäisch",
    "subcategories.beverageMarket": "Getränkemarkt",
    "subcategories.orientalGrocery": "Orientalisch",
    "subcategories.indianGrocery": "Indische Lebensmittel",
    "subcategories.importedGrocery": "Import",
    "subcategories.bioOrganic": "Bio & Organisch",
    "subcategories.africanGrocery": "Afrikanisch",
    "subcategories.turkishSupermarket": "Türkisch",
    "subcategories.latinAmericanSupermarket": "Lateinamerikanisch",
    "subcategories.middleEasternSupermarket": "Mittlerer Osten",
    "subcategories.russianSupermarket": "Russisch",
    "subcategories.polishSupermarket": "Polnisch",
    "subcategories.mediterraneanSupermarket": "Mediterran",
    "subcategories.veganVegetarianSupermarket": "Vegan / Veggie",
    "subcategories.halalMarket": "Halal",
    "subcategories.kosherSupermarket": "Kosher",
    "subcategories.southAsianSupermarket": "Süd-Asiatisch",
    "subcategories.germanCuisine": "Deutsch",
    "subcategories.frenchRestaurant": "Französisch",
    "subcategories.spanishTapasBar": "Spanisch",
    "subcategories.greekTavern": "Griechisch",
    "subcategories.mexicanRestaurant": "Mexikanisch",
    "subcategories.indianRestaurant": "Indisch",
    "subcategories.sushiBar": "Sushi Bar",
    "subcategories.steakhouse": "Steak House",
    "subcategories.vegetarianVeganRestaurant": "Vegan / Veggie",
    "subcategories.foodTruck": "Food Truck",
    "subcategories.fusionCuisine": "International",
    "subcategories.medievalTavern": "Mittelalterlich",
    "subcategories.patisserie": "Patisserie",
    "subcategories.lowCarb": "Low Carb",
    "subcategories.brideAndEvent": "Braut & Event",
    "subcategories.afroBarber": "Afro Barber",
    "subcategories.hairSpecialist": "Haar-Spezialist",
    "subcategories.permanentHairRemoval": "Dauerhafte Haarentfernung",
    "subcategories.hairReplacement": "Haarentfernung",
    "subcategories.barberServices": "Haar-Services",
    "subcategories.veganCafe": "Vegan",
    "subcategories.outdoorActivities": "Outdoor Aktivitäten",
    "subcategories.eventDecorators": "Event-Deco",
    "subcategories.indoorPlants": "Indoor Pflanzen",
    "subcategories.outdoorPlants": "Outdoor Pflanzen",
    "subcategories.ballonAndParty": "Ballons & Partyartikel",
    "subcategories.handmade": "Handgemachtes",
    "subcategories.candleAndFragrances": "Kerzen & Düfte",
    "subcategories.autoDetailing": "Fahrzeugaufbereitung",
    "subcategories.autoBodyAndPaint": "Karroserie & Lackierung",
    "subcategories.carRental": "Autoverleih",
    "subcategories.autoGlass": "Autoglass",
    "subcategories.inspection": "Inspektion",
    "subcategories.oilChange": "Ölwechsel",
    "subcategories.motorcycle": "Motorrad",
    "subcategories.autoAccessories": "Autozubehör",
    "subcategories.autoTuning": "Tuningwerkstatt",
    "subcategories.homeDecor": "Hausdekoration",
    "subcategories.homeImprovement": "Home Improvement",
    "subcategories.lightingStore": "Lampen & Lichter",
    "subcategories.applianceStore": "Haushaltsgeräte",
    "subcategories.kitchenAndBath": "Küche & Sanitär",
    "subcategories.securityAndSmartHome": "Sicherheit & Smart Home",
    "subcategories.diy": "DIY",
    "subcategories.pharmacy": "Apotheke",
    "subcategories.medicalSupply": "Sanitätshaus",
    "subcategories.jewlery": "Juwelier",
    "subcategories.toyStore": "Spielwarengeschäft",
    "subcategories.musicStore": "Musik & Instrumente",
    "subcategories.secondhandStores": "Second Hand",
    "subcategories.travelAgency": "Reisebüro",
    "subcategories.photoStudio": "Foto-Studio",
    "subcategories.tailorShop": "Schneiderei",
    "subcategories.cleaningService": "Reinigungsdienst",
    "subcategories.shoeRepair": "Schuhreparatur",
    "subcategories.keyCopyService": "Schlüsseldienst",
    "subcategories.laundryService": "Wäscheservice",
    "subcategories.locksmith": "Schlosser",
    "subcategories.mobileRepair": "Handyreparatur",
    "subcategories.computerRepair": "Computerreparatur",
    "subcategories.packagingService": "Verpackungsservice",
    "subcategories.printShop": "Druckerei",
    "subcategories.eventPlanning": "Eventplanung",
    "subcategories.regionalProducts": "Regionale Produkte",
    "subcategories.butcherGrocery": "Metzger",
    "subcategories.bakeryGrocery": "Backwaren",
    "subcategories.asianGrocery": "Asiatisch",
    "subcategories.eastEuropaen": "Ost-Europäisch",
    "subcategories.farmGrocery": "Hofladen",
    "subcategories.halalRestaurant": "Halal",
    "subcategories.asianRestaurants": "Asiatisches",
    "subcategories.seafoodRestaurant": "Seafood",
    "subcategories.italianRestaurant": "Italienisch",
    "subcategories.burgerRestaurant": "Burgers",
    "subcategories.menHairSalon": "Herren",
    "subcategories.kidsHairSalon": "Kinder",
    "subcategories.womenHairSalon": "Frauen",
    "subcategories.gadgets": "Gadgets",
    "subcategories.vegetarianRestaurant": "Vegetarisch",
    "subcategories.mediterraneanRestaurant": "Mediterran",
    "subcategories.fishMarket": "Fisch Markt",
    "subcategories.imker": "Imker",
    "subcategories.makeup": "Makeup",
    "subcategories.barbeque": "BBQ",
    "subcategories.bubble_tea": "Bubble Tea",
    "subcategories.african": "Afrikanisch",
    "subcategories.hair_treatments": "Haarbehandlungen",
    "subcategories.hair_removal": "Haarentfernung",
    "subcategories.teeth_whitening": "Zahnaufhellung",
    "subcategories.acupuncture": "Akkupunktur",
    "subcategories.nail_salon": "Nagelsalon",
    "subcategories.therapies": "Therapien",
    "subcategories.meat": "Fleisch",
    "subcategories.buffet": "Buffets",
    "subcategories.fried": "Frittiertes",
    "subcategories.fast_food": "Fast Food",
    "subcategories.breakfast_brunch": "Frühstück | Brunch",
    "subcategories.bistro_imbiss": "Bistro | Imbiss",
    "subcategories.manicure_pedicure": "Maniküre & Pediküre",
    "subcategories.facial_treatments": "Gesichtsbehandlungen",
    "subcategories.cosmetic_supply": "Kosmetikprodukte",
    "subcategories.beverage_market": "Getränkemarkt",
    "subcategories.dairyGrocery": "Molkerei",
    "subcategories.vegetableGrocery": "Gemüse",
    "subcategories.dessertBar": "Desserts",
    "subcategories.crepes": "Crepes",
    "subcategories.waffles": "Waffeln",
    "subcategories.juices": "Säfte",
    "subcategories.tea_house": "Tee Haus",
    "subcategories.sweet_pastry": "Süssgebäck",
    "subcategories.candy_shop": "Candy Shop",
    "subcategories.dairyFarmShop": "Molkerei",
    "subcategories.fruit_vegetable": "Obst- und Gemüse",
    "subcategories.eggs": "Eier",
    "subcategories.bio_organic": "Bio & Organisch",
    "subcategories.scooping": "Schröpfen",
    "subcategories.indian_grocery": "Indische",
    "subcategories.imported_grocery": "Import",
    "subcategories.spaWellness": "Spa & Wellness",
    "subcategories.massageCenter": "Massagen",
    "subcategories.gifts": "Geschenke",
    "subcategories.cafe": "Cafe",
    "subcategories.iceCreamParlor": "Eisdiele",
    "subcategories.bakeryCafe": "Backwaren",
    "subcategories.sportsCenter": "Sport",
    "subcategories.kidsPlayArea": "Kinder Spielpark",
    "subcategories.art": "Kunst",
    "subcategories.cinema": "Kino",
    "subcategories.theater": "Theater",
    "subcategories.escape_room": "Escape Room",
    "subcategories.climbing": "Klettern",
    "subcategories.musuem": "Museum",
    "subcategories.flowerShop": "Blumen",
    "subcategories.giftShop": "Geschenke",
    "subcategories.carRepair": "Reparatur",
    "subcategories.carWash": "Wäsche",
    "subcategories.tireShop": "Reifen",
    "subcategories.furnitureStore": "Möbel",
    "subcategories.gardenCenter": "Garten",
    "subcategories.clothingStore": "Kleidungsgeschäft",
    "subcategories.electronicsStore": "Elektronik",
    "subcategories.petStore": "Haustierbedarf",
    "subcategories.zooStore": "Zoobedarf",
    "subcategories.bookstore": "Bücher & Magazine",
    "subcategories.cosmetic": "Kosmetik",
    "keywords.oriental": "Orientalisch",
    "keywords.middleEastern": "Nahöstlich",
    "keywords.arabic": "Arabisch",
    "keywords.turkish": "Türkisch",
    "keywords.moroccan": "Marokkanisch",
    "keywords.persian": "Persisch",
    "keywords.butcher": "Metzger",
    "keywords.meat": "Fleisch",
    "keywords.sausage": "Wurst",
    "keywords.meatShop": "Fleischerei",
    "keywords.slaughterhouse": "Schlachter",
    "keywords.bakery": "Bäckerei",
    "keywords.bread": "Brot",
    "keywords.pastry": "Gebäck",
    "keywords.roll": "Brötchen",
    "keywords.cake": "Kuchen",
    "keywords.patisserie": "Konditorei",
    "keywords.asian": "Asiatisch",
    "keywords.chinese": "Chinesisch",
    "keywords.japanese": "Japanisch",
    "keywords.thai": "Thailändisch",
    "keywords.vietnamese": "Vietnamesisch",
    "keywords.korean": "Koreanisch",
    "keywords.indian": "Indisch",
    "keywords.farmShop": "Hofladen",
    "keywords.farmersMarket": "Bauernmarkt",
    "keywords.regional": "Regional",
    "keywords.organic": "Bio",
    "keywords.fresh": "Frisch",
    "keywords.agricultural": "Landwirtschaftlich",
    "keywords.halal": "Halal",
    "keywords.halalCertified": "Halal-zertifiziert",
    "keywords.islamic": "Islamisch",
    "keywords.muslim": "Muslimisch",
    "keywords.doner": "Döner",
    "keywords.sushi": "Sushi",
    "keywords.fish": "Fisch",
    "keywords.seafood": "Meeresfrüchte",
    "keywords.salmon": "Lachs",
    "keywords.shrimp": "Garnelen",
    "keywords.italian": "Italienisch",
    "keywords.pizza": "Pizza",
    "keywords.pasta": "Pasta",
    "keywords.risotto": "Risotto",
    "keywords.tiramisu": "Tiramisu",
    "keywords.antipasti": "Antipasti",
    "keywords.burger": "Burger",
    "keywords.hamburger": "Hamburger",
    "keywords.cheeseburger": "Cheeseburger",
    "keywords.fries": "Pommes",
    "keywords.fastFood": "Fastfood",
    "keywords.menHairdresser": "Männerfriseur",
    "keywords.menSalon": "Herrensalon",
    "keywords.barber": "Barber",
    "keywords.shaving": "Rasur",
    "keywords.haircutForMen": "Haarschnitt für Männer",
    "keywords.kidsHairdresser": "Kinderfriseur",
    "keywords.kidCut": "Kinderschnitt",
    "keywords.kidHaircut": "Kinderhaarschnitt",
    "keywords.haircutForKids": "Haarschnitt für Kinder",
    "keywords.kidStyling": "Kinderstyling",
    "keywords.womenHairdresser": "Frauenfriseur",
    "keywords.womenSalon": "Damensalon",
    "keywords.haircutForWomen": "Haarschnitt für Frauen",
    "keywords.dyeing": "Färben",
    "keywords.stylingForWomen": "Styling für Frauen",
    "keywords.technology": "Technik",
    "keywords.electronics": "Elektronik",
    "keywords.devices": "Geräte",
    "keywords.accessories": "Zubehör",
    "units.km": "km",
    "open_24_hours": "24 Stunden geöffnet",
    "favorites_and_bookings": "Favoriten und Buchungen",
    "no_favorites": "Keine Favoriten vorhanden!",
    "no_favorites_text": "Du hast noch keine Favoriten hinzugefügt. Durchsuche deine lokalen Geschäfte und speicher sie als Favoriten, um später leichter darauf zugreifen zu können!",
    "no_coupons": "Keine Coupons verfügbar!",
    "no_coupons_text": "Du hast noch keine Coupons aktiviert. Entdecke und aktiviere Coupons, um Rabatte zu genießen!",
    "onboardingTutorial_title_step1": "Entdecken",
    "onboardingTutorial_description_step1": "Entdecke die Welt des lokalen Handels, ob Restaurants, Supermärkte, Friseur, Kosmetikstudios, Werkstätten und vieles mehr!",
    "onboardingTutorial_imageCaption_step1": "Alles da, alles nah.",
    "onboardingTutorial_title_step2": "Einlösen",
    "onboardingTutorial_description_step2": "Unsere Partner im lokalen Handel laden dich ein, über Direktrabatte & Geschenke für Produkte oder Dienstleistungen zu profitieren.",
    "onboardingTutorial_imageCaption_step2": "Lokales zu unterstützen & dabei zu sparen geht nicht? Doch!",
    "onboardingTutorial_title_step3": "Sparen",
    "onboardingTutorial_description_step3": "Alle Deals griffbereit in deiner Hosentasche. Deine Buchung zeigst du ganz einfach dem Mitarbeiter im Geschäft. So unterstützen wir den lokalen Handel!",
    "onboardingTutorial_imageCaption_step3": "Schnell und unkompliziert für alle.",
    "confirm": "Bestätigen",
    "start_now": "Jetzt Starten",
    "next": "Weiter",
    "submit": "Absenden",
    "company_name_required": "Firmenname ist erforderlich",
    "company_email_required": "Firmen-E-Mail ist erforderlich",
    "invalid_email": "Ungültige E-Mail-Adresse",
    "invalid_zipCode": "Ungültige Postleitzahl",
    "company_address_required": "Firmenadresse ist erforderlich",
    "company_zipCode_required": "Postleitzahl ist erforderlich",
    "company_city_required": "Stadt ist erforderlich",
    "company_country_required": "Land ist erforderlich",
    "company_phone_required": "Firmentelefon ist erforderlich",
    "invalid_phone": "Ungültige Telefonnummer",
    "placeholder_company_name": "Firmenname eingeben",
    "placeholder_company_email": "Firmen-E-Mail eingeben",
    "placeholder_company_address": "Straße und Hausnummer eingeben",
    "placeholder_company_zipCode": "Postleitzahl eingeben",
    "placeholder_company_city": "Stadt eingeben",
    "placeholder_company_country": "Land eingeben",
    "placeholder_company_phone": "Telefonnummer eingeben",
    "placeholder_company_pocName": "Vorname Nachname des Ansprechspartner eingeben",
    "placeholder_company_pocRole": "Rolle des Ansprechspartners auswählen",
    "no_stores": "Kein Geschäft erstellt",
    "no_stores_text": "Du hast noch kein Geschäft hinzugefügt. Klicke hier & erstellt jetzt blitzschnell dein Laden, um Coupons anbieten zu können.",
    "more_stores": "Hast du eine weitere Filiale?",
    "more_stores_text": "Klicke hier, um eine weitere Filiale hinzuzufügen, bei der du auch Coupons erstellen kannst.",
    "stores_of_company": "Filialen deiner Firma",
    "vendor_poc_roles.owner": "Inhaber",
    "vendor_poc_roles.employee": "Angestellt",
    "vendor_poc_roles.else": "Sonstige",
    "coupon.ON_SITE": "Vor Ort",
    "coupon.TO_GO": "To-Go",
    "coupon.NINETY_DAYS": "90 Tage",
    "coupon.THIRTY_DAYS": "30 Tage",
    "coupon.WEEKLY": "Wöchentlich",
    "coupon.ONE_YEAR": "Jährlich",
    "coupon.DAILY": "Täglich",
    "coupon.THREE_DAYS": "3 Tage",
    "coupon.HUNDRED_EIGHTY_DAYS": "180 Tage",
    "paywall.price_description_yearly": "Jährliche Zahlung",
    "paywall.price_description_monthly": "Monatliche Zahlung",
    "monetization.paywall_title": "Alle Deals. Sofort sparen.",
    "monetization.maybe_later": "Vielleicht später",
    "monetization.monthly": "Monatlich",
    "monetization.yearly": "Jährlich",
    "monetization.monthly_marketing.first_benefit": "Lohnt sich in der Regel schon bei 1-2 Einkäufen!",
    "monetization.monthly_marketing.second_benefit": "Monatlich kündbar!",
    "monetization.monthly_marketing.third_benefit": "Alle Coupons sofort einlösen.",
    "monetization.yearly_marketing.first_benefit": "Lohnt sich schon bei 3-4 Einkäufen pro Jahr!",
    "monetization.yearly_marketing.second_benefit": "Einmal zahlen, ein Jahr genießen!",
    "monetization.yearly_marketing.third_benefit": "Alle Coupons sofort einlösen.",
    "monetization.price_description_effective_per_month": "effektiv pro Monat",
    "monetization.price_description_per_month": "pro Monat",
    "monetization.confirmation_title": "Kauf erfolgreich!",
    "monetization.confirmation_message": "Vielen Dank für dein Kauf. Du hast nun Zugang zu allen Premium-Features.",
    "validation.setProductName": "Produktname muss gesetzt sein.",
    "for": "für",
    "coupon_factory.2for1_title": "Kaufe 2, erhalte 1 gratis",
    "coupon_factory.2for1_description": "Oder auch mehr. Erstelle einen Mengenangebot",
    "coupon_factory.win_customers_and_money": "Gewinne Kunden und Umsatz.",
    "coupon_factory.example": "Beispielhafter Coupon",
    "coupon_factory.create_now": "Jetzt erstellen",
    "coupon_factory.create_coupon_marketing_header": "Jetzt gratis Neukunden reinholen",
    "coupon_factory.create_coupon_marketing_body": "Erstelle in wenigen Schritten deinen Coupon.",
    "coupon_factory.create_coupon_benefit_header_1": "🎯 Neue Kunden",
    "coupon_factory.create_coupon_benefit_body_1": "Erreiche neue Kundschaft direkt über das Smartphone",
    "coupon_factory.create_coupon_benefit_header_2": "🚀 Leicht & Schnell",
    "coupon_factory.create_coupon_benefit_body_2": "Erstelle Deals so einfach wie noch nie zuvor.",
    "coupon_factory.create_coupon_benefit_header_3": "💸 Mehr Umsatz",
    "coupon_factory.create_coupon_benefit_body_3": "Steigere deinen Umsatz über Coupons für deine Kunden.",
    "coupon_factory.create_coupon_card": "Erstelle einen Coupon!",
    "coupon_factory.create_coupon_card_info": "Klicke hier, um in 3 Schritten einen Coupon zu erstellen.",
    "coupon_factory.create_coupon": "Erstelle einen Coupon!",
    "coupon_factory.create_coupon_body": "In drei Schritten zur perfekten Werbung für Neukunden!",
    "coupon_factory.condition_for_customer": "Bedingung für Kunden setzen",
    "coupon_factory.choose_condition": "Bedingung auswählen",
    "coupon_factory.choose_condition_info": "Was der Kunde tun soll, um zu profitieren",
    "coupon_factory.minimum_order_value": "Mindestbestellwert",
    "coupon_factory.minimum_order_value_info": "Kunde soll einen bestimmten Betrag ausgeben",
    "coupon_factory.product_purchase": "Produktkauf",
    "coupon_factory.product_purchase_info": "Kunde soll bestimmte Produkte kaufen",
    "coupon_factory.direct_rebate": "Direktrabatt auf Produkt",
    "coupon_factory.no_conditions": "Keine Bedingungen",
    "coupon_factory.no_conditions_set": "Keine Bedingungen gesetzt",
    "coupon_factory.go_to_next_step": "Gehe zum nächsten Schritt",
    "coupon_factory.input_mov": "Mindesteinkaufswert angeben",
    "coupon_factory.count_of_products": "Anzahl der Produkte",
    "coupon_factory.product_name": "Name des Produkts",
    "coupon_factory.value_of_product_per_piece": "Wert des Produkts (pro Stück)",
    "coupon_factory.order_discount": "Rabatt auf den Einkauf",
    "coupon_factory.product_discount": "Rabatt auf ein Produkt",
    "coupon_factory.free_product": "Gratis Produkt",
    "coupon_factory.discount": "Rabatt",
    "coupon_factory.enter_discount_amount": "Rabatthöhe eingeben",
    "coupon_factory.redeem_location_conditons": "Rabattbedingungen",
    "coupon_factory.redeem_location_info": "Wo können deine Kunden den Rabatt nutzen?",
    "coupon_factory.choose_discount": "Rabatt",
    "coupon_factory.choose_discount_info": "Was erhält dein Kunde für einen Rabatt?",
    "coupon_factory.carousel_header_one": "Coupon auswählen",
    "coupon_factory.carousel_body_one": "Wähle den Coupon aus, den du erstellen möchtest.",
    "coupon_factory.carousel_header_two": "Bedingung festlegen",
    "coupon_factory.carousel_body_two": "Lege fest, was der Kunde tun muss.",
    "coupon_factory.carousel_header_three": "Benefit festlegen",
    "coupon_factory.carousel_body_three": "Lege fest, was der Kunde erhält.",
    "coupon_factory.product_quantitiy": "Stückzahl pro Kunde",
    "coupon_factory.product_quantitiy_info": "Der Rabatt gilt pro Stück!",
    "coupon_factory.product_quantitiy_free_product_info": "Wie viel Stück erhält er GRATIS?",
    "coupon_factory.same_product": "Gleiches Produkt",
    "coupon_factory.same_product_info": "Kunde erhält das gleiche Produkt.",
    "coupon_factory.other_product": "Anderes Produkt",
    "coupon_factory.other_product_info": "Bitte Produktnamen angeben.",
    "coupon_factory.price_per_unit": "Preis pro Stück",
    "coupon_factory.error_invalid_input": "Ungültige Eingabe",
    "coupon_factory.error_rebate_higher_as_order_value": "Rabatt darf nicht höher als der Betrag des Einkaufs/Produkts",
    "coupon_factory.error_rebate_higher_as_product_value": "Rabatt darf nicht höher als der Betrag des Einkaufs/Produkts",
    "coupon_factory.max_created_coupon_card": "Maximum an Coupons erreicht",
    "coupon_factory.max_created_coupon_card_info": "Du hast das aktuelle Maximum erreicht für deine Coupons.",
    "coupon_factory.max_created_stores": "Maximum Geschäften erreicht",
    "coupon_factory.max_created_stores_info": "Du hast das aktuelle Maximum an erstellbaren Geschäften erreicht. Wenn du einen Store weiteren Store erstellen möchtest, dann wende dich bitte über das Kontaktformular in den Einstellungen an uns.",
    "details": "Details",
    "enter_details": "Details angeben",
    "store_factory.store_conflict": "Problem aufgetreten mit dem Geschäft",
    "store_factory.store_conflict_info": "Das ausgewählte Geschäft auf Google wurde schon auf Basar registriert. Bitte einmal überprüfen. Wenn du immernoch Probleme hast, kannst du gerne kontaktieren.",
    "store_factory.wrong_store": "Falsches Geschäft ausgewählt?",
    "store_factory.wrong_store_search_again": "Möchtest du nochmal neustarten? Bitte beachte dass deine Angaben auf Google korrekt sein müssen.",
    "store_factory.restart": "Neustart",
    "store_factory.what_customers_see": "Das sehen deine Kunden",
    "store_factory.check_details": "Überprüfe, ob deine Angaben stimmen.",
    "store_factory.store_name": "Name der Filiale",
    "store_factory.store_name_example": "Name deines Geschäfts",
    "store_factory.store_phone": "Telefonnummer für deine Kunden",
    "store_factory.store_email": "Email für deine Kunden",
    "store_factory.choose_category": "Kategorie auswählen",
    "store_factory.choose_category_info": "Wählen Sie eine Hauptkategorie, bis zu 3 Subkategorien und laden Sie ein Bild Ihres Geschäfts hoch.",
    "store_factory.main_category": "Hauptkategorie",
    "store_factory.sub_categories": "Subkategorien",
    "store_factory.upload_store_img": "Bild vom Geschäft hochladen",
    "store_factory.store_img": "Foto vom Geschäft",
    "store_factory.max_achieved": "Maximum erreicht",
    "store_factory.sub_categories_limit": "Sie können maximal 3 Unterkategorien auswählen.",
    "store_factory.categories_and_image_required": "Kategorien und Bild sind notwendig!",
    "store_factory.zip_code": "Postleitzahl",
    "store_factory.zip_code_placeholder": "z.B. 44801",
    "store_factory.claim_store": "Geschäft übernehmen",
    "store_factory.house_number": "Hausnummer",
    "store_factory.house_number_placeholder": "z.B. 33",
    "store_factory.street": "Strasse",
    "store_factory.street_placeholder": "z.B. Basar Strasse",
    "store_factory.city": "Stadt",
    "store_factory.city_placeholder": "z.B. Bochum",
    "store_factory.success": "Shop erfolgreich erstellt",
    "store_factory.success_info": "Dein Shop wurde erfolgreich erstellt und wird nun überprüft.",
    "store_factory.success_verification": "Die Verifizierung dauert in der Regel 1-2 Werktage. Sobald die Überprüfung abgeschlossen ist, wird dein Store live geschaltet und für Kunden sichtbar.",
    "store_factory.verification_pending": "Verifizierung ausstehend",
    "store_factory.verification_pending_info": "Aktuell läuft noch ein Verifzierungsprozess. Wir melden uns, wenn wir mehr Infos von dir brauchen. Falls du Fragen hast, kannst du dich gerne bei uns melden.",
    "store_factory.no_coupons": "Noch keine Coupons",
    "store_factory.no_coupons_info": "Du hast noch keine Coupons erstellt. Klicke auf den Store, um ein Coupon zu erstellen.",
    "to_dashboard": "Zum Dashboard",
    "error_unexpected": "Ein unerwarteter Fehler ist aufgetreten. Probiere es nocheinmal. Wenn das Problem weiterhin besteht, kannst du uns gerne kontaktieren.",
    "check_inputs": "Angaben überprüfen",
    "warning": "Achtung",
    "progress_deleted_info": "Dein Zwischenstand wird gelöscht, wenn du hier zurück gehst. Bist du dir sicher?",
    "notification": "Benarichtigung",
    "notifications": "Benarichtigungen",
    "request_notification_permission": "Pushbenarichtigungen aktivieren?",
    "denied_notification_permission": "Pushbenarichtigungen abgelehnt",
    "denied": "denied",
    "activate_notification_in_settings": "Sie können Push-Benarichtigungen in den Einstellungen aktivieren",
    "notifcationsTitle.message1": "🥳 Es ist Zeit für lokale Rabatte!",
    "notifcationsBody.message1": "Spare jetzt mit exklusiven Coupons für lokale Shops! Egal ob Essen, Mode oder Freizeit – entdecke tolle Rabatte und unterstütze deine Gemeinde!"
  },
  "en": {
    "!add_row_below": "VVV Add line below VVV",
    "so_easy_for_partners": "It’s that easy for partners",
    "so_easy_for_partners.register_store": "Register your business quickly and easily to reach more customers.",
    "so_easy_for_partners.create_coupon": "Create individual vouchers for your customers, increase sales and win new customers – everything according to your wishes.",
    "so_easy_for_partners.manage_store": "Manage your store easily - adjust opening times, images and vouchers as needed at any time.",
    "change_language": "Change language",
    "english_USA": "🇬🇧 English",
    "german_Germany": "🇩🇪 German",
    "Imprint": "Imprint",
    "Datenschutz": "Privacy Policy",
    "AGB": "General terms and conditions of business",
    "Cookies": "Cookies",
    "about": "ABOUT US",
    "our_magazine": "Our magazine",
    "for_companies": "For companies",
    "contact": "Contact",
    "no_answer_found": "Question not answered?",
    "jobs": "Jobs",
    "become_partner": "Become a partner",
    "shop": "Shop",
    "experiences": "Experiences",
    "legal": "LEGAL",
    "social_media": "SOCIAL MEDIA",
    "frequently_asked_questions": "FAQ",
    "vendor_faq.how_work_question": "How does BASAR work for partners?",
    "vendor_faq.how_work_answer": "We have partnered with various local businesses and negotiated unique offers such as 2-for-1 services or discounts on products. The Bazaar app allows you to view, book and redeem these offers across numerous industries such as restaurants, hairdressers, workshops, flower shops and many others.",
    "vendor_faq.participating_businesses_question": "Which companies are taking part?",
    "vendor_faq.participating_businesses_answer": "Thousands of local businesses are currently participating with a variety of exclusive offers. From gastronomy to services to products, you will find great offers in many categories.",
    "vendor_faq.app_free_question": "Is the app currently free?",
    "vendor_faq.app_free_answer": "Yes, the BASAR app is currently completely free. Partners can participate and provide offers without any fees.",
    "vendor_faq.create_store_question": "How to create a store on BASAR?",
    "vendor_faq.create_store_answer": "Creating your store on BASAR is easy and quick. You can set up your business profile in just a few clicks directly in the app.",
    "vendor_faq.manage_coupons_question": "Can I create and manage my own coupons?",
    "vendor_faq.manage_coupons_answer": "Yes, you have complete control over creating and deleting your own coupons. Set discounts, offers and conditions that best suit your business. No sales managers disturbing your business, your marketing.",
    "faq.general.category": "GENERAL",
    "faq.general.questions[0].question": "What is the concept of Bazaar?",
    "faq.general.questions[0].answer": "We have partnered with various local businesses and negotiated exclusive introductory vouchers such as: B. 2-for-1 offers or discounts on products. With the Bazaar app you can easily browse, book and redeem these offers across a variety of industries such as restaurants, hair salons, workshops, flower shops and more.",
    "faq.general.questions[1].question": "Which companies are taking part?",
    "faq.general.questions[1].answer": "Thousands of local businesses are currently participating with a variety of exclusive offers. From dining to services and products, you'll find great deals in many categories.",
    "start": "Start",
    "price": "Price",
    "redeem_error_notToday_title": "Coupon not redeemable today.",
    "redeem_error_notToday_message": "The coupon can only be redeemed on the specified days.",
    "redeemDays.monday": "Mo",
    "redeemDays.tuesday": "Tu",
    "redeemDays.wednesday": "We",
    "redeemDays.thursday": "Th",
    "redeemDays.friday": "Fr",
    "redeemDays.saturday": "Sa",
    "redeemDays.sunday": "Su",
    "coupon_factory.your_coupon_active": "Your coupon is now active and will soon be visible to your customers!",
    "coupon_factory.valid_days_header": "Valid days",
    "coupon_factory.valid_days_subHeader": "Days on which customers are allowed to redeem the coupons.",
    "error_messages.something_wrong": "Something went wrong - Please try again later or contact us if the problem persists.",
    "store_factory.store_not_found_input_manual": "Store not found? Manual address entry.",
    "sent_review": "Submit review.",
    "review_your_visit_at": "Rate your visit @",
    "search": "Search",
    "faq_title": "FAQ (Frequently Asked Questions)",
    "faq_title_reverse": "Frequently Asked Questions (FAQ)",
    "vendor_faq_question_concept": "How does BASAR work?",
    "vendor_faq_answer_concept": "The concept of BASAR is simple and effective. As a self-employed person with a brick-and-mortar business, you can use the BASAR Business app to present your store and create coupons. Users can find these coupons via the BASAR user app and redeem them directly in your store. In this way, we strengthen local businesses in competition against large retail chains.",
    "vendor_faq_question_costs": "Are there any costs for me?",
    "vendor_faq_answer_costs": "No, BASAR is completely free for retailers. Users subscribe to get access to all coupons. This allows us to cover the platform costs and make it possible for local businesses to be competitive - even against large chains that invest in expensive marketing and customer loyalty programs.",
    "vendor_faq_question_minium_requirements": "What requirements do I have to meet?",
    "vendor_faq_answer_minium_requirements": "You need a physical store where you serve customers on site - e.g. B. a restaurant, a hairdresser, a workshop or similar. In addition, you or your employees should be able to easily grant the promised discounts at the checkout.",
    "vendor_faq_question_interface_cashier": "Do I have to integrate my cash register?",
    "vendor_faq_answer_interface_cashier": "No, BASAR works very easily with a classic four-eye principle. The customer shows you the redeemed coupon on their smartphone and you grant the discount. No expensive or complicated POS system integrations are necessary. The only important thing is that you or your employees can implement the discounts correctly.",
    "vendor_faq_question_shop_creation": "How do I create my shop?",
    "vendor_faq_answer_shop_creation": "You can create your shop in the “Shop” tab. Search for your business in our database or create it manually. Add contact information, opening times, a photo and the appropriate category. We will check the information and contact you if you have any questions. The verification usually takes one working day - after that you can create coupons immediately.",
    "vendor_faq_question_coupons": "How can I create coupons?",
    "vendor_faq_answer_coupons": "After creating your shop, you can start the coupon factory in the “Coupons” tab. There you will find ready-made templates that you can quickly and easily add your information to. After the preview, you can create the coupon and it will go live within a short time. Requirement: Your shop must be verified.",
    "vendor_faq_question_storeinfos_update": "How do I update my business information?",
    "vendor_faq_answer_storeinfos_update": "Go to your store and click on the “Info” tab. There you can update contact information, opening times and images using the corresponding edit buttons.",
    "vendor_faq_question_abuse": "How does BASAR prevent multiple redemption of a coupon?",
    "vendor_faq_answer_abuse": "Every user registers with their telephone number and is therefore clearly identifiable. When creating a coupon, you can specify how often a coupon can be redeemed, e.g. B. only every 90 days. This way usage remains fair and controlled.",
    "vendor_faq_question_help_creation": "Will you help us create the store or the coupons?",
    "vendor_faq_answer_help_creation": "Naturally! We're happy to help you if you need assistance setting up your store or coupons. You can contact us at any time via WhatsApp. You can find the number in the settings under “Feedback & Support”.",
    "vendor_faq_question_how_to_contact": "How can I reach you?",
    "vendor_faq_answer_how_to_contact": "You can reach us by email at basar@basar-digital.com or directly via WhatsApp. You can find the WhatsApp number in the settings under “Feedback & Support”.",
    "create_store_and_coupons": "Create your store & coupons",
    "click_here_for_help": "Click here if you need more information.",
    "vendorOnboardingTutorial_title_step1": "Search & create your own shop.",
    "vendorOnboardingTutorial_description_step1": "Find your business using the search function or simply create it yourself. Add all important information such as contact, opening times, images and categories.",
    "vendorOnboardingTutorial_imageCaption_step1": "Your shop, your start: With just a few clicks you are visible to your customers - regardless of whether you are adding an existing shop or creating a new one.",
    "vendorOnboardingTutorial_title_step2": "Create irresistible coupons.",
    "vendorOnboardingTutorial_description_step2": "Use our easy-to-use coupon factory to create customized offers. Delight customers with exclusive discounts and bring fresh impetus to your business.",
    "vendorOnboardingTutorial_imageCaption_step2": "With the coupon factory you can amaze customers: create individual offers in just a few seconds and strengthen your customer loyalty.",
    "vendorOnboardingTutorial_title_step3": "Look forward to enthusiastic customers.",
    "vendorOnboardingTutorial_description_step3": "With your offers you attract new customers and surprise your regular customers. Customers can find your store and coupons directly in the BASAR user app. You redeem the coupons on site and you can apply the discount directly at the checkout.",
    "vendorOnboardingTutorial_imageCaption_step3": "Your business, their joy: More customers, more sales – with the BASAR user app your shop will be the first choice.",
    "category": "Category",
    "loadings_favorites": "Favorites Loading",
    "feature_so_great": "This feature is so great that it's not free! Sign up and get started.",
    "login_and_start": "Log in & get started",
    "oops_something_missing": "Oops, something is still missing!",
    "coupon_factory": "Coupon factory",
    "coupon_factory.infoHeader_name_of_product": "Name of the product",
    "coupon_factory.infoBody_name_of_product": "Enter the name of the product or service you want the customer to purchase.",
    "coupon_factory.infoHeader_value_of_product": "Value of the product",
    "coupon_factory. infoBody_value_of_product": "How much does the product cost in your store?",
    "coupon_factory.infoHeader_count_of_product": "Number of products",
    "coupon_factory.infoBody_count_of_product": "How many of this product he should buy.",
    "coupon_factory.infoHeader_minimum_order_volume": "Minimum order value / minimum purchase value",
    "coupon_factory.infoBody_minimum_order_volume": "How much should the customer spend at least to benefit?",
    "coupon_factory.infoHeader_name_of_benefit_product": "Name of the advantage product",
    "coupon_factory.infoBody_name_of_benefit_product": "Enter the name of the product or service the customer will receive here.",
    "coupon_factory.infoHeader_value_of_benefit_product": "Value of the advantage product",
    "coupon_factory.infoBody_value_of_benefit_product": "How much does the product normally cost without an offer price?",
    "coupon_factory.infoHeader_discount_amount": "Specify discount amount",
    "coupon_factory.infoBody_discount_amount": "How much is the discount per piece - in € or %.",
    "coupon_factory.infoHeader_order_discount_amount": "Specify discount amount",
    "coupon_factory.infoBody_order_discount_amount": "How much is the discount on the purchase or order - in € or %.",
    "delete_coupon": "Delete coupon",
    "generate": "Generate",
    "delete_coupon_text": "Are you sure you want to delete this coupon? This action cannot be undone.",
    "delete_coupon_info": "Attention: Your coupon is still active until the end of the day and will no longer be visible to users from the next day.",
    "validation_all_inputs_required": "All fields must be filled out correctly.",
    "store_factory.contact_and_opentimes": "Contact & opening hours",
    "store_factory.always_editable_later": "You can always edit it later.",
    "choose_category_and_image": "Select image & category.",
    "store_factory.main_categories_limit": "Select a category.",
    "searchdata_openstreetmap": "Search data © OpenStreetMap",
    "success.opentimes_updated": "Opening times successfully updated!",
    "success.contactdetails_updated": "Contact details updated successfully!",
    "editcontact": "Edit contact details",
    "from": "From",
    "until": "Until",
    "weekday": "Day",
    "open_questionmark": "Open?",
    "coupon_factory.contact_if_issue": "Please check all information. If you still need help, please feel free to contact us here",
    "respawn_time": "Respawn Time",
    "coupon_factory.coupon_in_three_steps": "Create a coupon in 3 steps.",
    "store_factory.your_store_on_map": "Your business on the map",
    "coupon_factory.infoHeader_need_help": "Do you need support?",
    "coupon_factory.infoBody_need_help_whatsapp": "Contact us directly here via Whatsapp.",
    "coupon_factory.check_preview": "Preview your coupon.",
    "coupon_factory.preview": "Preview",
    "coupon_factory.check_details": "Is all the information correct? Click Generate to make your coupon live!",
    "coupon_factory.respawn_time_description": "When a customer can reactivate the coupon after redeeming it. If you no longer want to offer an offer, you can delete it yourself.",
    "coupon_factory.respawn_time_short_description": "How long the customer has to wait before they can activate the coupon again.",
    "coupon_factory.thirty_days_recommendation": "The customer can redeem the coupon again after 30 days. Ideal for offers that are regularly available and attractive to returning customers.",
    "coupon_factory.ninety_days_recommendation": "The coupon can be used again after 90 days. This option works well for longer offers aimed at new customers.",
    "coupon_factory.weekly_recommendation": "The coupon can be redeemed again once a week. Perfect for short-term promotions that need to be renewed regularly.",
    "coupon_factory.thirty_days_recommendation_short": "Ideal for regularly available offers.",
    "coupon_factory.ninety_days_recommendation_short": "Ideal for rare new customer offers.",
    "coupon_factory.weekly_recommendation_short": "Ideal for short promo offers.",
    "coupon_factory.mainly_for_restaurants": "Usually only applies to gastronomy.",
    "coupon_factory.default_set": "Set by default.",
    "coupon_factory.redeem_location_onsite_info": "Valid if customer purchases in store.",
    "coupon_factory.redeem_location_togo_info": "Valid if the customer buys something to take away, e.g. a kebab to take away.",
    "coupon_factory.choose_coupon_type": "Select coupon types",
    "coupon_factory.choose_coupon_type_details": "Select the coupon type you want to create for your store.",
    "coupon_factory.example_header_one": "10€ cashback",
    "coupon_factory.example_body_one": "Get a €10 discount with a minimum spend of €50",
    "coupon_factory.example_headertitle_one": "Cashback",
    "coupon_factory.example_subheader_one": "Your new customers will receive a direct discount on their purchase/order.",
    "coupon_factory.example_header_two": "1x drink GIFT",
    "coupon_factory.example_body_two": "You get 1x chocolate for free with no minimum order value",
    "coupon_factory.example_headertitle_two": "GIFT",
    "coupon_factory.example_subheader_two": "Your new customers will receive a gift with their purchase.",
    "coupon_factory.example_header_three": "2 for 1 - fries",
    "coupon_factory.example_body_three": "When you buy 1 x fries you get 1x fries for free. Applies to the product of the same price or cheaper.",
    "coupon_factory.example_headertitle_three": "2 for 1",
    "coupon_factory.example_subheader_three": "Your new customers receive 2 products for the price of one.",
    "coupon_factory.example_header_four": "50% direct discount on jackets",
    "coupon_factory.example_body_four": "You will receive 1 x bouquet of flowers with a 50% discount each with no minimum order value.",
    "coupon_factory.example_headertitle_four": "SALE",
    "coupon_factory.example_subheader_four": "Your customers receive a direct discount on a specific product.",
    "coupon_factory.example_header_individual": "Individually",
    "coupon_factory.example_body_individual": "Individual deal",
    "coupon_factory.example_headertitle_individual": "Individually",
    "coupon_factory.example_subheader_individual": "Create a completely individual coupon for your customers.",
    "welcome": "Welcome",
    "languages.de": "🇩🇪 German",
    "languages.en": "🇺🇸 English",
    "languages.tr": "🇹🇷 Turkish",
    "languages.ar": "🇸🇦 Arabic",
    "languages.fr": "🇫🇷 French",
    "languages.es": "🇪🇸 Spanish",
    "languages.it": "🇮🇹 Italian",
    "languages.pl": "🇵🇱 Polish",
    "languages.ru": "🇷🇺 Russian",
    "popular_categories": "Popular Categories",
    "no_matching_categories_found": "No matching categories found",
    "hello": "Hello from Germany",
    "with_coupons": "With coupons",
    "cities_with_digital_basar": "Cities with a digital bazaar",
    "use_your_location": "Use your location",
    "no_matching_cities_found": "No matching cities found",
    "discover": "Discover",
    "favorites": "Favorites",
    "profile": "Profile",
    "delete": "Delete",
    "openingTimes": "Opening hours",
    "editOpeningTimes": "Edit opening hours",
    "days.monday": "Monday",
    "days.tuesday": "Tuesday",
    "days.wednesday": "Wednesday",
    "days.thursday": "Thursday",
    "days.friday": "Friday",
    "days.saturday": "Saturday",
    "days.sunday": "Sunday",
    "command_searchForYourBusiness": "Search for your business",
    "placeholder_find_store_google": "Find your business on Google",
    "permission_to_camera_roll": "Access to the photo library is required!",
    "businessMustBeOnGoogleMaps": "Your business must be discoverable on Google Maps. If you have any problems, please feel free to contact us here.",
    "closed": "Closed",
    "location": "Location",
    "coupons": "Coupons",
    "activated_coupons": "Activated coupons",
    "redeemed_coupons": "Redeemed coupons",
    "activate": "Activate",
    "redeem_now": "Redeem now",
    "coupon_redeemed_message": "Coupon redeemed - keep it up! This is how we support local trade.",
    "coupon_redeemed_message_title": "Coupon redeemed!",
    "coupon_redeemed_message_bodxy": "Keep it up! This is how we support local trade.",
    "share_message": "Hey, I'm at BASAR. Download the app using this link.",
    "share_success": "Share successfully!",
    "share_dismissed": "Sharing canceled",
    "share_failed": "Share failed:",
    "report_problem": "Report a problem",
    "deactivate_coupon": "Deactivate coupon",
    "info": "Info",
    "more": "More",
    "Information about this Coupons": "Information about this coupon",
    "command_mention_basar_for_redeem": "Before paying, mention that you have a BASAR voucher and redeem it in front of an employee. Only one coupon can be redeemed per visit.",
    "Redeem Location": "Redemption location",
    "redeem_location_info": "Whether the offer is on site or to take away",
    "Reactivation time": "Reactivation time",
    "reactivation_info": "When the offer will be active again for you after you have redeemed it.",
    "reviews": "Reviews",
    "review": "Reviews",
    "show_all_reviews": "View all reviews",
    "show_all": "View all",
    "REVIEW_SENT": "Review submitted.",
    "REVIEW_THANKS": "Thank you for your review!",
    "REVIEW_WRITE": "Write your review...",
    "loading": "Loading",
    "noReviewsAvailable": "No reviews yet",
    "noStoreInformationAvailable": "No information yet",
    "advetorials.vendor.become_vendor_title": "Do you have a local shop on site?",
    "advetorials.vendor.become_vendor_body": "Then become a dealer on our app now. We have many users who would like to support your local business.",
    "advetorials.vendor.download_vendor_app": "Download the retailer app from the App Store.",
    "become_Member": "Become a member",
    "enter_discount": "Enter discount code",
    "translated_by_ai": "Translations may have been created using AI and are therefore not always perfect.",
    "closeButton": "Close",
    "edit_profile": "Edit profile",
    "validation_invalid_input": "Invalid input",
    "validation_required_input": "Field must be filled out",
    "success": "Confirmed",
    "error": "Error",
    "profile_update_success": "Profile updated successfully!",
    "profile_image_update_success": "Profile picture updated successfully",
    "profile_image_update_error": "Update profile picture failed",
    "tutorial": "Tutorial",
    "settings": "Settings",
    "feedbackAndSupport": "Feedback & Support",
    "changeToVendorMode": "Switch to dealer mode",
    "changeToUserMode": "Switch to customer mode",
    "registerAsPartner": "Register as a partner",
    "logout": "Log out",
    "first_name": "First name",
    "last_name": "Last name",
    "email": "E-mail",
    "zipCode": "ZIP code",
    "edit": "Edit",
    "delete_account": "Delete profile",
    "delete_account_message": "Are you sure you want to delete your profile? All your advantages and settings will be irretrievably lost.",
    "delete_account_success": "Profile successfully deleted",
    "delete_account_success_message": "Your profile has been successfully deleted. 👋 We hope to see each other again soon",
    "save": "Save",
    "back": "Back",
    "continue": "Continue",
    "later": "Later",
    "terms_and_conditions": "General terms and conditions of business",
    "agree_to_terms": "I agree to the <terms>General Terms and Conditions</terms>.",
    "phonenumber": "Telephone number",
    "enter_phonenumber": "Enter phone number",
    "login_no_password_info": "Easy login via SMS code. So you don't need to remember a password.",
    "enter_code": "Enter the code",
    "sent_to": "Sent to",
    "search_country_code": "Search by country or area code",
    "command_agree": "Please agree",
    "command_agree_to_terms": "You must agree to the terms to continue.",
    "otp": "One-time password",
    "enter_otp": "Enter one-time password",
    "verify_otp": "Confirm one-time password",
    "otp_expired": "One-time password expired",
    "otp_incorrect": "One-time password incorrect",
    "otp_verification_error": "Incorrect or expired one-time password entered.",
    "command_create_profile_to_start": "Create your profile to get started!",
    "command_create_company_to_start": "Register your company to get started.",
    "info_create_shop_later": "You can provide information about your shop in the next step.",
    "your_concern": "Your request",
    "describe_your_concern": "Tell us your request.",
    "contact_you_asap": "We will get in touch with you as quickly as possible.",
    "contact_via_whatsapp": "Contact us via Whatsapp",
    "change_image": "Change image",
    "edit_store_profile": "Business Profile Edit",
    "error_loading_store": "Error loading business data",
    "error_messages.no_stores.no_stores_nearby_found_title": "No stores found near you",
    "error_messages.no_stores.no_stores_nearby_found_body": "Apparently we're not fast yet. But we'll get there - don't worry. Change the city using the search or scroll to it using the map.",
    "error_messages.conflict.store_exists_title": "The business at this address has already been registered.",
    "error_messages.conflict.store_exists_body": "You cannot create a store that is already registered. If you think this is an error, please contact us here.",
    "funny_error_message": "Oops - something went wrong.",
    "page_not_found": "Page could not be loaded.",
    "page_not_found_connect_to_wifi": "Please make sure you are connected to the internet.",
    "page_not_found_help_message": "If the problem persists, please contact us",
    "cancel": "Cancel",
    "partner_login": "Partner login",
    "partner_register": "Partner registration",
    "command_no_account_register": "No account? Register here.",
    "command_have_account_login": "Do you have an account? Log in here.",
    "login": "Login",
    "register": "Registration",
    "please_enter_email": "Please provide email",
    "email_sent": "Email sent",
    "password_reset_email_sent": "Email sent to reset password.",
    "password_reset_failed": "Reset password failed.",
    "forgot_password": "Forgot password",
    "password_reset_rate_limit": "For security reasons, you can only make this request once every 60 seconds. Please wait and try again.",
    "back_to_login": "Back to login",
    "company_profile": "Company profile",
    "company_details": "Company details",
    "company_email": "Company email",
    "company_name": "Company name",
    "company_address": "Company address",
    "company_zipCode": "Postal code",
    "company_city": "City",
    "company_country": "Country",
    "company_phone": "Company phone number",
    "company_pocName": "First name, last name (contact person)",
    "company_pocRole": "Select role (contact person)",
    "store_image": "Picture of the shop",
    "vendor_delete_account": "Are you sure you want to delete your profile?",
    "vendor_delete_account_body": "Currently, your profile can only be deleted by contacting us via WhatsApp or by email to basar@basar-digital.com",
    "select_a_category": "Select a category",
    "categories.grocery": "Groceries",
    "categories.restaurants": "Restaurant",
    "categories.hairSalon": "Barber",
    "categories.electronics": "Electronics",
    "categories.beauty_wellness": "Beauty & Wellness",
    "categories.other": "Other",
    "categories.non_food_retail": "Retail",
    "categories.home_and_garden": "House and garden",
    "categories.automotive": "Mobility",
    "categories.flowers_and_gifts": "Flowers and gifts",
    "categories.activities": "Activities",
    "categories.cafe_and_dessert": "Café & dessert",
    "categories.farm_shop": "Farm shop",
    "subcategories.convenienceStore": "Mini market",
    "subcategories.eastEuropean": "East European",
    "subcategories.beverageMarket": "Beverage market",
    "subcategories.orientalGrocery": "Oriental",
    "subcategories.indianGrocery": "Indian foods",
    "subcategories.importedGrocery": "Import",
    "subcategories.bioOrganic": "Organic & organic",
    "subcategories.africanGrocery": "African",
    "subcategories.turkishSupermarket": "Turkish",
    "subcategories.latinAmericanSupermarket": "Latin American",
    "subcategories.middleEasternSupermarket": "Middle East",
    "subcategories.russianSupermarket": "Russian",
    "subcategories.polishSupermarket": "Polish",
    "subcategories.mediterraneanSupermarket": "Mediterranean",
    "subcategories.veganVegetarianSupermarket": "Vegan/Veggie",
    "subcategories.halalMarket": "Halal",
    "subcategories.kosherSupermarket": "Kosher",
    "subcategories.southAsianSupermarket": "South Asian",
    "subcategories.germanCuisine": "German",
    "subcategories.frenchRestaurant": "French",
    "subcategories.spanishTapasBar": "Spanish",
    "subcategories.greekTavern": "Greek",
    "subcategories.mexicanRestaurant": "Mexican",
    "subcategories.indianRestaurant": "Indian",
    "subcategories.sushiBar": "Sushi bar",
    "subcategories.steakhouse": "Steakhouse",
    "subcategories.vegetarianVeganRestaurant": "Vegan/Veggie",
    "subcategories.foodTruck": "Food truck",
    "subcategories.fusionCuisine": "International",
    "subcategories.medievalTavern": "Medieval",
    "subcategories.patisserie": "Patisserie",
    "subcategories.lowCarb": "Low carb",
    "subcategories.brideAndEvent": "Bride & Event",
    "subcategories.afroBarber": "Afro Barber",
    "subcategories.hairSpecialist": "Hair Specialist",
    "subcategories.permanentHairRemoval": "Permanent hair removal",
    "subcategories.hairReplacement": "Hair removal",
    "subcategories.barberServices": "Hair Services",
    "subcategories.veganCafe": "Vegan",
    "subcategories.outdoorActivities": "Outdoor activities",
    "subcategories.eventDecorators": "Event Deco",
    "subcategories.indoorPlants": "Indoor plants",
    "subcategories.outdoorPlants": "Outdoor plants",
    "subcategories.ballonAndParty": "Balloons & Party Supplies",
    "subcategories.handmade": "Handmade",
    "subcategories.candleAndFragrances": "Candles & Fragrances",
    "subcategories.autoDetailing": "Vehicle preparation",
    "subcategories.autoBodyAndPaint": "Body & paint",
    "subcategories.carRental": "Car rental",
    "subcategories.autoGlass": "Autoglass",
    "subcategories.inspection": "Inspection",
    "subcategories.oilChange": "Oil change",
    "subcategories.motorcycle": "Motorcycle",
    "subcategories.autoAccessories": "Car accessories",
    "subcategories.autoTuning": "Tuning workshop",
    "subcategories.homeDecor": "House decoration",
    "subcategories.homeImprovement": "Home improvement",
    "subcategories.lightingStore": "Lamps & Lights",
    "subcategories.applianceStore": "Household appliances",
    "subcategories.kitchenAndBath": "Kitchen & Sanitary",
    "subcategories.securityAndSmartHome": "Security & Smart Home",
    "subcategories.diy": "DIY",
    "subcategories.pharmacy": "Pharmacy",
    "subcategories.medicalSupply": "Medical store",
    "subcategories.jewlery": "Jeweler",
    "subcategories.toyStore": "Toy store",
    "subcategories.musicStore": "Music & Instruments",
    "subcategories.secondhandStores": "Second hand",
    "subcategories.travelAgency": "Travel agency",
    "subcategories.photoStudio": "Photo studio",
    "subcategories.tailorShop": "Tailoring",
    "subcategories.cleaningService": "Cleaning service",
    "subcategories.shoeRepair": "Shoe repair",
    "subcategories.keyCopyService": "Locksmith service",
    "subcategories.laundryService": "Laundry service",
    "subcategories.locksmith": "Locksmith",
    "subcategories.mobileRepair": "Cell phone repair",
    "subcategories.computerRepair": "Computer repair",
    "subcategories.packagingService": "Packaging service",
    "subcategories.printShop": "Printing shop",
    "subcategories.eventPlanning": "Event planning",
    "subcategories.regionalProducts": "Regional products",
    "subcategories.butcherGrocery": "Butcher",
    "subcategories.bakeryGrocery": "Baked goods",
    "subcategories.asianGrocery": "Asian",
    "subcategories.eastEuropaen": "East European",
    "subcategories.farmGrocery": "Farm shop",
    "subcategories.halalRestaurant": "Halal",
    "subcategories.asianRestaurants": "Asian",
    "subcategories.seafoodRestaurant": "Seafood",
    "subcategories.italianRestaurant": "Italian",
    "subcategories.burgerRestaurant": "Burgers",
    "subcategories.menHairSalon": "Gentlemen",
    "subcategories.kidsHairSalon": "Children",
    "subcategories.womenHairSalon": "Women",
    "subcategories.gadgets": "Gadgets",
    "subcategories.vegetarianRestaurant": "Vegetarian",
    "subcategories.mediterraneanRestaurant": "Mediterranean",
    "subcategories.fishMarket": "Fish market",
    "subcategories.imker": "Beekeeper",
    "subcategories.makeup": "Make up",
    "subcategories.barbeque": "BBQ",
    "subcategories.bubble_tea": "Bubble tea",
    "subcategories.african": "African",
    "subcategories.hair_treatments": "Hair treatments",
    "subcategories.hair_removal": "Hair removal",
    "subcategories.teeth_whitening": "Teeth whitening",
    "subcategories.acupuncture": "Acupuncture",
    "subcategories.nail_salon": "Nail salon",
    "subcategories.therapies": "Therapies",
    "subcategories.meat": "Meat",
    "subcategories.buffet": "Buffets",
    "subcategories.fried": "Fried food",
    "subcategories.fast_food": "Fast food",
    "subcategories.breakfast_brunch": "Breakfast | brunch",
    "subcategories.bistro_imbiss": "Bistro | snack",
    "subcategories.manicure_pedicure": "Manicure & Pedicure",
    "subcategories.facial_treatments": "Facials",
    "subcategories.cosmetic_supply": "Cosmetic products",
    "subcategories.beverage_market": "Beverage market",
    "subcategories.dairyGrocery": "Dairy",
    "subcategories.vegetableGrocery": "Vegetables",
    "subcategories.dessertBar": "Desserts",
    "subcategories.crepes": "Crepes",
    "subcategories.waffles": "Waffles",
    "subcategories.juices": "Juices",
    "subcategories.tea_house": "Tea house",
    "subcategories.sweet_pastry": "Sweet pastries",
    "subcategories.candy_shop": "Candy shop",
    "subcategories.dairyFarmShop": "Dairy",
    "subcategories.fruit_vegetable": "Fruit and vegetables",
    "subcategories.eggs": "Eggs",
    "subcategories.bio_organic": "Organic & organic",
    "subcategories.scooping": "Cupping",
    "subcategories.indian_grocery": "Indian",
    "subcategories.imported_grocery": "Import",
    "subcategories.spaWellness": "Spa & wellness",
    "subcategories.massageCenter": "Massages",
    "subcategories.gifts": "Gifts",
    "subcategories.cafe": "Cafe",
    "subcategories.iceCreamParlor": "Ice cream parlor",
    "subcategories.bakeryCafe": "Baked goods",
    "subcategories.sportsCenter": "Sport",
    "subcategories.kidsPlayArea": "Children's play park",
    "subcategories.art": "Art",
    "subcategories.cinema": "Cinema",
    "subcategories.theater": "Theater",
    "subcategories.escape_room": "Escape rooms",
    "subcategories.climbing": "Climb",
    "subcategories.musuem": "Museum",
    "subcategories.flowerShop": "Flowers",
    "subcategories.giftShop": "Gifts",
    "subcategories.carRepair": "Repair",
    "subcategories.carWash": "Wash",
    "subcategories.tireShop": "Tires",
    "subcategories.furnitureStore": "Furniture",
    "subcategories.gardenCenter": "Garden",
    "subcategories.clothingStore": "Clothing store",
    "subcategories.electronicsStore": "Electronics",
    "subcategories.petStore": "Pet supplies",
    "subcategories.zooStore": "Zoo supplies",
    "subcategories.bookstore": "Books & Magazines",
    "subcategories.cosmetic": "Cosmetics",
    "keywords.oriental": "Oriental",
    "keywords.middleEastern": "Middle Eastern",
    "keywords.arabic": "Arabic",
    "keywords.turkish": "Turkish",
    "keywords.moroccan": "Moroccan",
    "keywords.persian": "Persian",
    "keywords.butcher": "Butcher",
    "keywords.meat": "Meat",
    "keywords.sausage": "Sausage",
    "keywords.meatShop": "Butcher shop",
    "keywords.slaughterhouse": "Butcher",
    "keywords.bakery": "Bakery",
    "keywords.bread": "Bread",
    "keywords.pastry": "Pastries",
    "keywords.roll": "Bread rolls",
    "keywords.cake": "Cake",
    "keywords.patisserie": "Pastry shop",
    "keywords.asian": "Asian",
    "keywords.chinese": "Chinese",
    "keywords.japanese": "Japanese",
    "keywords.thai": "Thai",
    "keywords.vietnamese": "Vietnamese",
    "keywords.korean": "Korean",
    "keywords.indian": "Indian",
    "keywords.farmShop": "Farm shop",
    "keywords.farmersMarket": "Farmers market",
    "keywords.regional": "Regional",
    "keywords.organic": "Organic",
    "keywords.fresh": "Fresh",
    "keywords.agricultural": "Agricultural",
    "keywords.halal": "Halal",
    "keywords.halalCertified": "Halal certified",
    "keywords.islamic": "Islamic",
    "keywords.muslim": "Muslim",
    "keywords.doner": "Doner kebab",
    "keywords.sushi": "Sushi",
    "keywords.fish": "Fish",
    "keywords.seafood": "Seafood",
    "keywords.salmon": "Salmon",
    "keywords.shrimp": "Shrimp",
    "keywords.italian": "Italian",
    "keywords.pizza": "Pizza",
    "keywords.pasta": "Pasta",
    "keywords.risotto": "Risotto",
    "keywords.tiramisu": "Tiramisu",
    "keywords.antipasti": "Antipasti",
    "keywords.burger": "Citizens",
    "keywords.hamburger": "Hamburger",
    "keywords.cheeseburger": "Cheeseburgers",
    "keywords.fries": "Fries",
    "keywords.fastFood": "Fast food",
    "keywords.menHairdresser": "Men's hairdresser",
    "keywords.menSalon": "Men's salon",
    "keywords.barber": "Barber",
    "keywords.shaving": "Shave",
    "keywords.haircutForMen": "Haircut for men",
    "keywords.kidsHairdresser": "Children's hairdresser",
    "keywords.kidCut": "Children's cut",
    "keywords.kidHaircut": "Children's haircut",
    "keywords.haircutForKids": "Haircut for children",
    "keywords.kidStyling": "Children's styling",
    "keywords.womenHairdresser": "Women's hairdresser",
    "keywords.womenSalon": "Ladies salon",
    "keywords.haircutForWomen": "Haircut for women",
    "keywords.dyeing": "Dyeing",
    "keywords.stylingForWomen": "Styling for women",
    "keywords.technology": "Technology",
    "keywords.electronics": "Electronics",
    "keywords.devices": "Devices",
    "keywords.accessories": "Accesories",
    "units.km": "Km",
    "open_24_hours": "Open 24 hours",
    "favorites_and_bookings": "Favorites and bookings",
    "no_favorites": "No favorites available!",
    "no_favorites_text": "You haven't added any favorites yet. Browse your local stores and save them as favorites for easier access later!",
    "no_coupons": "No coupons available!",
    "no_coupons_text": "You haven't activated any coupons yet. Discover and activate coupons to enjoy discounts!",
    "onboardingTutorial_title_step1": "Discover",
    "onboardingTutorial_description_step1": "Discover the world of local commerce, whether restaurants, supermarkets, hairdressers, beauty salons, workshops and much more!",
    "onboardingTutorial_imageCaption_step1": "Everything is there, everything is close.",
    "onboardingTutorial_title_step2": "Redeem",
    "onboardingTutorial_description_step2": "Our local retail partners invite you to benefit from direct discounts & gifts for products or services.",
    "onboardingTutorial_imageCaption_step2": "Supporting local and saving money isn't possible? But!",
    "onboardingTutorial_title_step3": "Save",
    "onboardingTutorial_description_step3": "All deals ready to hand in your pocket. You simply show your booking to the employee in the store. This is how we support local trade!",
    "onboardingTutorial_imageCaption_step3": "Quick and easy for everyone.",
    "confirm": "Confirm",
    "start_now": "Start now",
    "next": "Further",
    "submit": "Submit",
    "company_name_required": "Company name is required",
    "company_email_required": "Company email is required",
    "invalid_email": "Invalid email address",
    "invalid_zipCode": "Invalid zip code",
    "company_address_required": "Company address is required",
    "company_zipCode_required": "Postal code is required",
    "company_city_required": "City is required",
    "company_country_required": "Land is required",
    "company_phone_required": "Company phone is required",
    "invalid_phone": "Invalid phone number",
    "placeholder_company_name": "Enter company name",
    "placeholder_company_email": "Enter company email",
    "placeholder_company_address": "Enter street and house number",
    "placeholder_company_zipCode": "Enter postal code",
    "placeholder_company_city": "Enter city",
    "placeholder_company_country": "Enter country",
    "placeholder_company_phone": "Enter phone number",
    "placeholder_company_pocName": "Enter the first name and last name of the contact person",
    "placeholder_company_pocRole": "Select role of contact person",
    "no_stores": "No business created",
    "no_stores_text": "You haven't added a store yet. Click here & create your store now in a flash to be able to offer coupons.",
    "more_stores": "Do you have another branch?",
    "more_stores_text": "Click here to add another store where you can also create coupons.",
    "stores_of_company": "Branches of your company",
    "vendor_poc_roles.owner": "Owner",
    "vendor_poc_roles.employee": "Employed",
    "vendor_poc_roles.else": "Other",
    "coupon.ON_SITE": "On site",
    "coupon.TO_GO": "To Go",
    "coupon.NINETY_DAYS": "90 days",
    "coupon.THIRTY_DAYS": "30 days",
    "coupon.WEEKLY": "Weekly",
    "coupon.ONE_YEAR": "Yearly",
    "coupon.DAILY": "Daily",
    "coupon.THREE_DAYS": "3 days",
    "coupon.HUNDRED_EIGHTY_DAYS": "180 days",
    "paywall.price_description_yearly": "Annual payment",
    "paywall.price_description_monthly": "Monthly payment",
    "monetization.paywall_title": "All deals. Save immediately.",
    "monetization.maybe_later": "Maybe later",
    "monetization.monthly": "Monthly",
    "monetization.yearly": "Yearly",
    "monetization.monthly_marketing.first_benefit": "It's usually worth it for just 1-2 purchases!",
    "monetization.monthly_marketing.second_benefit": "Can be canceled monthly!",
    "monetization.monthly_marketing.third_benefit": "Redeem all coupons immediately.",
    "monetization.yearly_marketing.first_benefit": "It's worth it for just 3-4 purchases per year!",
    "monetization.yearly_marketing.second_benefit": "Pay once, enjoy for a year!",
    "monetization.yearly_marketing.third_benefit": "Redeem all coupons immediately.",
    "monetization.price_description_effective_per_month": "Effective per month",
    "monetization.price_description_per_month": "Per month",
    "monetization.confirmation_title": "Purchase successful!",
    "monetization.confirmation_message": "Thank you for your purchase. You now have access to all premium features.",
    "validation.setProductName": "Product name must be set.",
    "for": "For",
    "coupon_factory.2for1_title": "Buy 2, get 1 free",
    "coupon_factory.2for1_description": "Or even more. Create a quantity offer",
    "coupon_factory.win_customers_and_money": "Gain customers and sales.",
    "coupon_factory.example": "Example coupon",
    "coupon_factory.create_now": "Create now",
    "coupon_factory.create_coupon_marketing_header": "Get new customers for free now",
    "coupon_factory.create_coupon_marketing_body": "Create your coupon in just a few steps.",
    "coupon_factory.create_coupon_benefit_header_1": "🎯 New customers",
    "coupon_factory.create_coupon_benefit_body_1": "Reach new customers directly via your smartphone",
    "coupon_factory.create_coupon_benefit_header_2": "🚀 Easy & Fast",
    "coupon_factory.create_coupon_benefit_body_2": "Create deals easier than ever before.",
    "coupon_factory.create_coupon_benefit_header_3": "💸 More sales",
    "coupon_factory.create_coupon_benefit_body_3": "Increase your sales with coupons for your customers.",
    "coupon_factory.create_coupon_card": "Create a coupon!",
    "coupon_factory.create_coupon_card_info": "Click here to create a coupon in 3 steps.",
    "coupon_factory.create_coupon": "Create a coupon!",
    "coupon_factory.create_coupon_body": "Three steps to perfect advertising for new customers!",
    "coupon_factory.condition_for_customer": "Set condition for customers",
    "coupon_factory.choose_condition": "Select condition",
    "coupon_factory.choose_condition_info": "What the customer should do to benefit",
    "coupon_factory.minimum_order_value": "Minimum order value",
    "coupon_factory.minimum_order_value_info": "Customer should spend a certain amount",
    "coupon_factory.product_purchase": "Product purchase",
    "coupon_factory.product_purchase_info": "Customer should buy certain products",
    "coupon_factory.direct_rebate": "Direct discount on product",
    "coupon_factory.no_conditions": "No conditions",
    "coupon_factory.no_conditions_set": "No conditions set",
    "coupon_factory.go_to_next_step": "Go to the next step",
    "coupon_factory.input_mov": "Specify minimum purchase value",
    "coupon_factory.count_of_products": "Number of products",
    "coupon_factory.product_name": "Name of the product",
    "coupon_factory.value_of_product_per_piece": "Value of the product (per piece)",
    "coupon_factory.order_discount": "Discount on purchase",
    "coupon_factory.product_discount": "Discount on a product",
    "coupon_factory.free_product": "Free product",
    "coupon_factory.discount": "Discount",
    "coupon_factory.enter_discount_amount": "Enter discount amount",
    "coupon_factory.redeem_location_conditons": "Discount conditions",
    "coupon_factory.redeem_location_info": "Where can your customers use the discount?",
    "coupon_factory.choose_discount": "Discount",
    "coupon_factory.choose_discount_info": "What kind of discount does your customer receive?",
    "coupon_factory.carousel_header_one": "Select coupon",
    "coupon_factory.carousel_body_one": "Select the coupon you want to create.",
    "coupon_factory.carousel_header_two": "Set condition",
    "coupon_factory.carousel_body_two": "Determine what the customer needs to do.",
    "coupon_factory.carousel_header_three": "Set benefits",
    "coupon_factory.carousel_body_three": "Determine what the customer will receive.",
    "coupon_factory.product_quantitiy": "Quantity per customer",
    "coupon_factory.product_quantitiy_info": "The discount applies per piece!",
    "coupon_factory.product_quantitiy_free_product_info": "How many pieces does he get FREE?",
    "coupon_factory.same_product": "Same product",
    "coupon_factory.same_product_info": "Customer receives the same product.",
    "coupon_factory.other_product": "Different product",
    "coupon_factory.other_product_info": "Please provide product name.",
    "coupon_factory.price_per_unit": "Price per piece",
    "coupon_factory.error_invalid_input": "Invalid input",
    "coupon_factory.error_rebate_higher_as_order_value": "Discount cannot be higher than the amount of the purchase/product",
    "coupon_factory.error_rebate_higher_as_product_value": "Discount cannot be higher than the amount of the purchase/product",
    "coupon_factory.max_created_coupon_card": "Maximum number of coupons reached",
    "coupon_factory.max_created_coupon_card_info": "You have reached the current maximum for your coupons.",
    "coupon_factory.max_created_stores": "Maximum shops reached",
    "coupon_factory.max_created_stores_info": "You have reached the current maximum of deals that can be created. If you would like to create another store, please contact us using the contact form in the settings.",
    "details": "Details",
    "enter_details": "Provide details",
    "store_factory.store_conflict": "Problem occurred with the store",
    "store_factory.store_conflict_info": "The selected store on Google has already been registered on Bazaar. Please check once. If you still have problems, please feel free to contact us.",
    "store_factory.wrong_store": "Wrong business selected?",
    "store_factory.wrong_store_search_again": "Do you want to restart again? Please note that your information on Google must be correct.",
    "store_factory.restart": "Restart",
    "store_factory.what_customers_see": "This is what your customers see",
    "store_factory.check_details": "Check whether your information is correct.",
    "store_factory.store_name": "Name of the branch",
    "store_factory.store_name_example": "Name of your business",
    "store_factory.store_phone": "Phone number for your customers",
    "store_factory.store_email": "Email for your customers",
    "store_factory.choose_category": "Select category",
    "store_factory.choose_category_info": "Choose a main category, up to 3 subcategories and upload an image of your store.",
    "store_factory.main_category": "Main category",
    "store_factory.sub_categories": "Subcategories",
    "store_factory.upload_store_img": "Upload image of store",
    "store_factory.store_img": "Photo from the shop",
    "store_factory.max_achieved": "Maximum reached",
    "store_factory.sub_categories_limit": "You can select a maximum of 3 subcategories.",
    "store_factory.categories_and_image_required": "Categories and image are necessary!",
    "store_factory.zip_code": "Postal code",
    "store_factory.zip_code_placeholder": "E.g. 44801",
    "store_factory.claim_store": "Take over business",
    "store_factory.house_number": "House number",
    "store_factory.house_number_placeholder": "E.g. 33",
    "store_factory.street": "Street",
    "store_factory.street_placeholder": "E.g. Bazaar Street",
    "store_factory.city": "City",
    "store_factory.city_placeholder": "E.g. Bochum",
    "store_factory.success": "Shop created successfully",
    "store_factory.success_info": "Your shop has been successfully created and is now being verified.",
    "store_factory.success_verification": "Verification usually takes 1-2 business days. Once verification is complete, your store will go live and be visible to customers.",
    "store_factory.verification_pending": "Verification pending",
    "store_factory.verification_pending_info": "A verification process is currently underway. We'll get in touch if we need more information from you. If you have any questions, please feel free to contact us.",
    "store_factory.no_coupons": "No coupons yet",
    "store_factory.no_coupons_info": "You haven't created any coupons yet. Click on the store to create a coupon.",
    "to_dashboard": "To the dashboard",
    "error_unexpected": "An unexpected error occurred. Try again. If the problem persists, please feel free to contact us.",
    "check_inputs": "Check information",
    "warning": "Danger",
    "progress_deleted_info": "Your interim status will be deleted if you go back here. Are you sure?",
    "notification": "Notification",
    "notifications": "Notifications",
    "request_notification_permission": "Enable push notifications?",
    "denied_notification_permission": "Push notifications rejected",
    "denied": "Denied",
    "activate_notification_in_settings": "You can enable push notifications in Settings",
    "notifcationsTitle.message1": "🥳 It's time for local discounts!",
    "notifcationsBody.message1": "Save now with exclusive coupons for local shops! Whether it's food, fashion or leisure - discover great discounts and support your community!"
  },
  "tr": {
    "!add_row_below": "VVV VVV'nin altına satır ekle",
    "so_easy_for_partners": "Ortaklar için bu kadar kolay",
    "so_easy_for_partners.register_store": "Daha fazla müşteriye ulaşmak için işletmenizi hızlı ve kolay bir şekilde kaydedin.",
    "so_easy_for_partners.create_coupon": "Müşterileriniz için bireysel kuponlar oluşturun, satışları artırın ve yeni müşteriler kazanın; her şey isteklerinize göre.",
    "so_easy_for_partners.manage_store": "Mağazanızı kolayca yönetin; açılış saatlerini, görselleri ve kuponları istediğiniz zaman gerektiği gibi ayarlayın.",
    "change_language": "Dili değiştir",
    "english_USA": "🇬🇧 İngilizce",
    "german_Germany": "🇩🇪 Almanca",
    "Imprint": "Baskı",
    "Datenschutz": "Gizlilik politikası",
    "AGB": "Genel iş şartları ve koşulları",
    "Cookies": "Çerezler",
    "about": "HAKKIMIZDA",
    "our_magazine": "Dergimiz",
    "for_companies": "Şirketler için",
    "contact": "Temas etmek",
    "no_answer_found": "Soru yanıtlanmadı mı?",
    "jobs": "İşler",
    "become_partner": "Ortak olun",
    "shop": "Mağaza",
    "experiences": "Deneyimler",
    "legal": "YASAL",
    "social_media": "SOSYAL MEDYA",
    "frequently_asked_questions": "SSS",
    "vendor_faq.how_work_question": "BASAR ortaklar için nasıl çalışıyor?",
    "vendor_faq.how_work_answer": "Çeşitli yerel işletmelerle ortaklık kurduk ve 2'si 1 arada hizmetler veya ürünlerde indirimler gibi benzersiz teklifler üzerinde pazarlık yaptık. Bazaar uygulaması restoranlar, kuaförler, atölyeler, çiçekçiler ve daha birçok sektördeki bu teklifleri görüntülemenize, rezervasyon yapmanıza ve kullanmanıza olanak tanır.",
    "vendor_faq.participating_businesses_question": "Hangi şirketler katılıyor?",
    "vendor_faq.participating_businesses_answer": "Binlerce yerel işletme şu anda çeşitli özel tekliflerle katılıyor. Gastronomiden hizmetlere ve ürünlere kadar birçok kategoride harika teklifler bulacaksınız.",
    "vendor_faq.app_free_question": "Uygulama şu anda ücretsiz mi?",
    "vendor_faq.app_free_answer": "Evet, BAŞAR uygulaması şu anda tamamen ücretsizdir. Ortaklar herhangi bir ücret ödemeden katılabilir ve teklif sunabilir.",
    "vendor_faq.create_store_question": "BASAR'da mağaza nasıl oluşturulur?",
    "vendor_faq.create_store_answer": "BASAR'da mağazanızı oluşturmak kolay ve hızlıdır. Doğrudan uygulamada yalnızca birkaç tıklamayla işletme profilinizi oluşturabilirsiniz.",
    "vendor_faq.manage_coupons_question": "Kendi kuponlarımı oluşturup yönetebilir miyim?",
    "vendor_faq.manage_coupons_answer": "Evet, kendi kuponlarınızı oluşturma ve silme konusunda tam kontrole sahipsiniz. İşletmenize en uygun indirimleri, teklifleri ve koşulları belirleyin. Hiçbir satış yöneticisi işinizi, pazarlamanızı rahatsız etmez.",
    "faq.general.category": "GENEL",
    "faq.general.questions[0].question": "Çarşı kavramı nedir?",
    "faq.general.questions[0].answer": "Çeşitli yerel işletmelerle ortaklık kurduk ve aşağıdakiler gibi özel tanıtım kuponları üzerinde pazarlık yaptık: B. 1 alana 2 teklifleri veya ürünlerde indirimler. Bazaar uygulamasıyla restoranlar, kuaför salonları, atölyeler, çiçekçiler ve daha fazlası gibi çeşitli sektörlerdeki bu tekliflere kolayca göz atabilir, rezervasyon yapabilir ve bu tekliflerden yararlanabilirsiniz.",
    "faq.general.questions[1].question": "Hangi şirketler katılıyor?",
    "faq.general.questions[1].answer": "Binlerce yerel işletme şu anda çeşitli özel tekliflerle katılıyor. Yemekten hizmet ve ürünlere kadar birçok kategoride harika fırsatlar bulacaksınız.",
    "start": "Başlangıç",
    "price": "Fiyat",
    "redeem_error_notToday_title": "Kupon bugün kullanılamaz.",
    "redeem_error_notToday_message": "Kupon yalnızca belirtilen günlerde kullanılabilir.",
    "redeemDays.monday": "Ay",
    "redeemDays.tuesday": "Yapmak",
    "redeemDays.wednesday": "Biz",
    "redeemDays.thursday": "Bu",
    "redeemDays.friday": "Bayan",
    "redeemDays.saturday": "Doygunluk",
    "redeemDays.sunday": "Su",
    "coupon_factory.your_coupon_active": "Kuponunuz artık aktif ve yakında müşterileriniz tarafından görülebilecek!",
    "coupon_factory.valid_days_header": "Geçerli günler",
    "coupon_factory.valid_days_subHeader": "Müşterilerin kuponları kullanmasına izin verilen günler.",
    "error_messages.something_wrong": "Bir şeyler ters gitti - Lütfen daha sonra tekrar deneyin veya sorun devam ederse bizimle iletişime geçin.",
    "store_factory.store_not_found_input_manual": "Mağaza bulunamadı mı? Manuel adres girişi.",
    "sent_review": "İncelemeyi gönderin.",
    "review_your_visit_at": "Ziyaretinizi değerlendirin @",
    "search": "Aramak",
    "faq_title": "SSS (Sık Sorulan Sorular)",
    "faq_title_reverse": "Sıkça Sorulan Sorular (SSS)",
    "vendor_faq_question_concept": "BAŞAR nasıl çalışır?",
    "vendor_faq_answer_concept": "BASAR'ın konsepti basit ve etkilidir. Serbest meslek sahibi ve fiziksel bir işletmeye sahip biri olarak, mağazanızı tanıtmak ve kupon oluşturmak için BAŞAR Business uygulamasını kullanabilirsiniz. Kullanıcılar bu kuponları BASAR kullanıcı uygulaması aracılığıyla bulabilir ve doğrudan mağazanızda kullanabilirler. Bu sayede yerel işletmeleri büyük perakende zincirlerine karşı rekabette güçlendiriyoruz.",
    "vendor_faq_question_costs": "Benim için herhangi bir masraf var mı?",
    "vendor_faq_answer_costs": "Hayır, BAŞAR perakendeciler için tamamen ücretsizdir. Kullanıcılar tüm kuponlara erişebilmek için abone olurlar. Bu, platform maliyetlerini karşılamamıza ve yerel işletmelerin, pahalı pazarlama ve müşteri sadakat programlarına yatırım yapan büyük zincirlere karşı bile rekabetçi olmalarına olanak tanıyor.",
    "vendor_faq_question_minium_requirements": "Hangi gereksinimleri karşılamam gerekiyor?",
    "vendor_faq_answer_minium_requirements": "Müşterilere yerinde hizmet vereceğiniz fiziksel bir mağazaya ihtiyacınız var - ör. B. restoran, kuaför, atölye veya benzeri. Ayrıca siz veya çalışanlarınız, ödeme sırasında vaat edilen indirimleri kolaylıkla uygulayabilmelisiniz.",
    "vendor_faq_question_interface_cashier": "Yazar kasamı entegre etmem gerekiyor mu?",
    "vendor_faq_answer_interface_cashier": "Hayır, BASAR klasik dört göz prensibiyle çok rahat çalışıyor. Müşteri, kullanılan kuponu akıllı telefonunda size gösterir ve siz de indirimi kabul edersiniz. Pahalı veya karmaşık POS sistemi entegrasyonlarına gerek yoktur. Önemli olan sizin veya çalışanlarınızın indirimleri doğru uygulayabilmesidir.",
    "vendor_faq_question_shop_creation": "Mağazamı nasıl oluşturabilirim?",
    "vendor_faq_answer_shop_creation": "Mağazanızı “Mağaza” sekmesinde oluşturabilirsiniz. İşletmenizi veritabanımızda arayın veya manuel olarak oluşturun. İletişim bilgilerini, açılış saatlerini, fotoğrafı ve uygun kategoriyi ekleyin. Bilgileri kontrol edeceğiz ve herhangi bir sorunuz varsa sizinle iletişime geçeceğiz. Doğrulama genellikle bir iş günü sürer; bunun ardından hemen kupon oluşturabilirsiniz.",
    "vendor_faq_question_coupons": "Kuponları nasıl oluşturabilirim?",
    "vendor_faq_answer_coupons": "Mağazanızı oluşturduktan sonra “Kuponlar” sekmesinden kupon fabrikasını başlatabilirsiniz. Burada bilgilerinizi hızlı ve kolay bir şekilde ekleyebileceğiniz hazır şablonlar bulacaksınız. Önizlemeden sonra kuponu oluşturabilirsiniz ve kısa süre içinde yayına girecektir. Gereksinim: Mağazanızın doğrulanması gerekir.",
    "vendor_faq_question_storeinfos_update": "İşletme bilgilerimi nasıl güncellerim?",
    "vendor_faq_answer_storeinfos_update": "Mağazanıza gidin ve “Bilgi” sekmesine tıklayın. Burada ilgili düzenleme düğmelerini kullanarak iletişim bilgilerini, açılış saatlerini ve görselleri güncelleyebilirsiniz.",
    "vendor_faq_question_abuse": "BASAR bir kuponun birden fazla kullanılmasını nasıl önler?",
    "vendor_faq_answer_abuse": "Her kullanıcı telefon numarasıyla kaydolur ve bu nedenle açıkça tanımlanabilir. Kupon oluştururken kuponun ne sıklıkta kullanılabileceğini belirtebilirsiniz, ör. B. yalnızca 90 günde bir. Bu şekilde kullanım adil ve kontrollü kalır.",
    "vendor_faq_question_help_creation": "Mağazayı veya kuponları oluşturmamıza yardımcı olur musunuz?",
    "vendor_faq_answer_help_creation": "Elbette! Mağazanızı veya kuponlarınızı ayarlama konusunda yardıma ihtiyacınız olursa size yardımcı olmaktan mutluluk duyarız. İstediğiniz zaman WhatsApp üzerinden bizimle iletişime geçebilirsiniz. Numarayı ayarlarda “Geri Bildirim ve Destek” altında bulabilirsiniz.",
    "vendor_faq_question_how_to_contact": "Size nasıl ulaşabilirim?",
    "vendor_faq_answer_how_to_contact": "Bize basar@basar-digital.com adresinden e-posta göndererek veya doğrudan WhatsApp üzerinden ulaşabilirsiniz. WhatsApp numarasını ayarlarda “Geri Bildirim ve Destek” bölümünde bulabilirsiniz.",
    "create_store_and_coupons": "Mağazanızı ve kuponlarınızı oluşturun",
    "click_here_for_help": "Daha fazla bilgiye ihtiyacınız varsa burayı tıklayın.",
    "vendorOnboardingTutorial_title_step1": "Kendi mağazanızı arayın ve oluşturun.",
    "vendorOnboardingTutorial_description_step1": "Arama işlevini kullanarak işletmenizi bulun veya kendiniz oluşturun. İletişim, açılış saatleri, resimler ve kategoriler gibi tüm önemli bilgileri ekleyin.",
    "vendorOnboardingTutorial_imageCaption_step1": "Mağazanız, başlangıcınız: Mevcut bir mağazayı eklemenize veya yeni bir mağaza oluşturmanıza bakılmaksızın, yalnızca birkaç tıklamayla müşterileriniz tarafından görünürsünüz.",
    "vendorOnboardingTutorial_title_step2": "Karşı konulmaz kuponlar oluşturun.",
    "vendorOnboardingTutorial_description_step2": "Özelleştirilmiş teklifler oluşturmak için kullanımı kolay kupon fabrikamızı kullanın. Müşterilerinizi özel indirimlerle memnun edin ve işinize yeni bir ivme kazandırın.",
    "vendorOnboardingTutorial_imageCaption_step2": "Kupon fabrikasıyla müşterilerinizi şaşırtabilirsiniz: sadece birkaç saniye içinde bireysel teklifler oluşturun ve müşteri bağlılığınızı güçlendirin.",
    "vendorOnboardingTutorial_title_step3": "Coşkulu müşterileri sabırsızlıkla bekliyoruz.",
    "vendorOnboardingTutorial_description_step3": "Tekliflerinizle yeni müşteriler çeker ve düzenli müşterilerinizi şaşırtırsınız. Müşteriler mağazanızı ve kuponlarınızı doğrudan BASAR kullanıcı uygulamasında bulabilir. Kuponları sitede kullanabilir ve indirimi doğrudan kasada uygulayabilirsiniz.",
    "vendorOnboardingTutorial_imageCaption_step3": "Sizin işiniz, onların neşesi: Daha fazla müşteri, daha fazla satış – BASAR kullanıcı uygulamasıyla mağazanız ilk tercih olacak.",
    "category": "Kategori",
    "loadings_favorites": "Favoriler Yükleniyor",
    "feature_so_great": "Bu özellik o kadar harika ki ücretsiz değil! Kaydolun ve başlayın.",
    "login_and_start": "Giriş yapın ve başlayın",
    "oops_something_missing": "Hata! Hala bir şeyler eksik!",
    "coupon_factory": "Kupon fabrikası",
    "coupon_factory.infoHeader_name_of_product": "Ürünün adı",
    "coupon_factory.infoBody_name_of_product": "Müşterinin satın almasını istediğiniz ürün veya hizmetin adını girin.",
    "coupon_factory.infoHeader_value_of_product": "Ürünün değeri",
    "coupon_factory. infoBody_value_of_product": "Ürünün mağazanızda fiyatı ne kadar?",
    "coupon_factory.infoHeader_count_of_product": "Ürün sayısı",
    "coupon_factory.infoBody_count_of_product": "Bu üründen ne kadar satın alması gerekiyor?",
    "coupon_factory.infoHeader_minimum_order_volume": "Minimum sipariş değeri / minimum satın alma değeri",
    "coupon_factory.infoBody_minimum_order_volume": "Müşterinin fayda sağlaması için en az ne kadar harcama yapması gerekir?",
    "coupon_factory.infoHeader_name_of_benefit_product": "Avantajlı ürünün adı",
    "coupon_factory.infoBody_name_of_benefit_product": "Müşterinin alacağı ürün veya hizmetin adını buraya girin.",
    "coupon_factory.infoHeader_value_of_benefit_product": "Avantajlı ürünün değeri",
    "coupon_factory.infoBody_value_of_benefit_product": "Teklif fiyatı olmadan ürünün normalde maliyeti ne kadardır?",
    "coupon_factory.infoHeader_discount_amount": "İndirim tutarını belirtin",
    "coupon_factory.infoBody_discount_amount": "Parça başına indirim ne kadardır - € veya % olarak.",
    "coupon_factory.infoHeader_order_discount_amount": "İndirim tutarını belirtin",
    "coupon_factory.infoBody_order_discount_amount": "Satın alma veya siparişteki indirim ne kadardır - € veya % olarak.",
    "delete_coupon": "Kuponu sil",
    "generate": "Oluştur",
    "delete_coupon_text": "Bu kuponu silmek istediğinizden emin misiniz? Bu eylem geri alınamaz.",
    "delete_coupon_info": "Dikkat: Kuponunuz gün sonuna kadar aktif olacak ve ertesi günden itibaren kullanıcılar tarafından görülemeyecek.",
    "validation_all_inputs_required": "Tüm alanların doğru bir şekilde doldurulması gerekmektedir.",
    "store_factory.contact_and_opentimes": "İletişim ve açılış saatleri",
    "store_factory.always_editable_later": "Daha sonra istediğiniz zaman düzenleyebilirsiniz.",
    "choose_category_and_image": "Resim ve kategoriyi seçin.",
    "store_factory.main_categories_limit": "Bir kategori seçin.",
    "searchdata_openstreetmap": "Arama verileri © OpenStreetMap",
    "success.opentimes_updated": "Açılış saatleri başarıyla güncellendi!",
    "success.contactdetails_updated": "İletişim bilgileri başarıyla güncellendi!",
    "editcontact": "İletişim ayrıntılarını düzenleyin",
    "from": "İtibaren",
    "until": "Değin",
    "weekday": "Gün",
    "open_questionmark": "Açık?",
    "coupon_factory.contact_if_issue": "Lütfen tüm bilgileri kontrol edin. Hala yardıma ihtiyacınız varsa lütfen bizimle buradan iletişime geçmekten çekinmeyin",
    "respawn_time": "Yeniden doğma süresi",
    "coupon_factory.coupon_in_three_steps": "3 adımda kupon oluşturun.",
    "store_factory.your_store_on_map": "İşletmeniz haritada",
    "coupon_factory.infoHeader_need_help": "Desteğe mi ihtiyacınız var?",
    "coupon_factory.infoBody_need_help_whatsapp": "Whatsapp aracılığıyla doğrudan buradan bize ulaşın.",
    "coupon_factory.check_preview": "Kuponunuzu önizleyin.",
    "coupon_factory.preview": "Önizleme",
    "coupon_factory.check_details": "Tüm bilgiler doğru mu? Kuponunuzu yayınlamak için Oluştur'a tıklayın!",
    "coupon_factory.respawn_time_description": "Bir müşteri kuponu kullandıktan sonra yeniden etkinleştirebildiğinde. Artık bir teklif sunmak istemiyorsanız teklifi kendiniz silebilirsiniz.",
    "coupon_factory.respawn_time_short_description": "Müşterinin kuponu tekrar etkinleştirebilmesi için ne kadar beklemesi gerektiği.",
    "coupon_factory.thirty_days_recommendation": "Müşteri kuponu 30 gün sonra tekrar kullanabilir. Düzenli olarak sunulan ve geri gelen müşteriler için cazip teklifler için idealdir.",
    "coupon_factory.ninety_days_recommendation": "Kupon 90 gün sonra tekrar kullanılabilir. Bu seçenek, yeni müşterilere yönelik daha uzun teklifler için işe yarar.",
    "coupon_factory.weekly_recommendation": "Kupon haftada bir kez tekrar kullanılabilir. Düzenli olarak yenilenmesi gereken kısa vadeli promosyonlar için mükemmeldir.",
    "coupon_factory.thirty_days_recommendation_short": "Düzenli olarak sunulan teklifler için idealdir.",
    "coupon_factory.ninety_days_recommendation_short": "Nadir yeni müşteri teklifleri için idealdir.",
    "coupon_factory.weekly_recommendation_short": "Kısa promosyon teklifleri için idealdir.",
    "coupon_factory.mainly_for_restaurants": "Genellikle sadece gastronomi için geçerlidir.",
    "coupon_factory.default_set": "Varsayılan olarak ayarlayın.",
    "coupon_factory.redeem_location_onsite_info": "Müşterinin mağazadan satın alması durumunda geçerlidir.",
    "coupon_factory.redeem_location_togo_info": "Müşterinin yanında götüreceği bir şey satın alması durumunda geçerlidir; götürülecek bir kebap.",
    "coupon_factory.choose_coupon_type": "Kupon türlerini seçin",
    "coupon_factory.choose_coupon_type_details": "Mağazanız için oluşturmak istediğiniz kupon türünü seçin.",
    "coupon_factory.example_header_one": "10€ para iadesi",
    "coupon_factory.example_body_one": "Minimum 50€ harcamayla 10€ indirim kazanın",
    "coupon_factory.example_headertitle_one": "Para iadesi",
    "coupon_factory.example_subheader_one": "Yeni müşterileriniz satın alma/siparişlerinde doğrudan indirim alacaktır.",
    "coupon_factory.example_header_two": "1x içecek HEDİYESİ",
    "coupon_factory.example_body_two": "Minimum sipariş değeri olmaksızın 1 adet çikolatayı ücretsiz alırsınız",
    "coupon_factory.example_headertitle_two": "HEDİYE",
    "coupon_factory.example_subheader_two": "Yeni müşterileriniz alışverişleriyle birlikte bir hediye alacaklar.",
    "coupon_factory.example_header_three": "2'ye 1 - patates kızartması",
    "coupon_factory.example_body_three": "1 adet patates kızartması aldığınızda 1 adet patates kızartması bedava oluyor. Aynı fiyattaki veya daha ucuz olan ürün için geçerlidir.",
    "coupon_factory.example_headertitle_three": "1'e 2",
    "coupon_factory.example_subheader_three": "Yeni müşterileriniz bir ürün fiyatına 2 ürün alır.",
    "coupon_factory.example_header_four": "Ceketlerde %50 doğrudan indirim",
    "coupon_factory.example_body_four": "Minimum sipariş değeri olmaksızın her biri %50 indirimli 1 x buket çiçek alacaksınız.",
    "coupon_factory.example_headertitle_four": "SATIŞ",
    "coupon_factory.example_subheader_four": "Müşterileriniz belirli bir üründe doğrudan indirim alır.",
    "coupon_factory.example_header_individual": "Bireysel olarak",
    "coupon_factory.example_body_individual": "Bireysel anlaşma",
    "coupon_factory.example_headertitle_individual": "Bireysel olarak",
    "coupon_factory.example_subheader_individual": "Müşterileriniz için tamamen bireysel bir kupon oluşturun.",
    "welcome": "Hoş geldin",
    "languages.de": "🇩🇪 Almanca",
    "languages.en": "🇺🇸İngilizce",
    "languages.tr": "🇹🇷Türkçe",
    "languages.ar": "🇸🇦 Arapça",
    "languages.fr": "🇫🇷 Fransızca",
    "languages.es": "🇪🇸İspanyolca",
    "languages.it": "🇮🇹 İtalyanca",
    "languages.pl": "🇵🇱Lehçe",
    "languages.ru": "🇷🇺Rusça",
    "popular_categories": "Popüler Kategoriler",
    "no_matching_categories_found": "Eşleşen kategori bulunamadı",
    "hello": "Almanya'dan merhaba",
    "with_coupons": "Kuponlarla",
    "cities_with_digital_basar": "Dijital pazarı olan şehirler",
    "use_your_location": "Konumunuzu kullanın",
    "no_matching_cities_found": "Eşleşen şehir bulunamadı",
    "discover": "Keşfetmek",
    "favorites": "Favoriler",
    "profile": "Profiller",
    "delete": "Silmek",
    "openingTimes": "Çalışma saatleri",
    "editOpeningTimes": "Açılış saatlerini düzenle",
    "days.monday": "Pazartesi",
    "days.tuesday": "Çarşamba",
    "days.wednesday": "Çarşamba",
    "days.thursday": "Perşembe",
    "days.friday": "Cuma",
    "days.saturday": "Cumartesi",
    "days.sunday": "Pazar",
    "command_searchForYourBusiness": "İşletmenizi arayın",
    "placeholder_find_store_google": "İşletmenizi Google'da bulun",
    "permission_to_camera_roll": "Fotoğraf kitaplığına erişim gereklidir!",
    "businessMustBeOnGoogleMaps": "İşletmenizin Google Haritalar'da bulunabilir olması gerekir. Herhangi bir sorunla karşılaşırsanız lütfen bizimle buradan iletişime geçmekten çekinmeyin.",
    "closed": "Kapalı",
    "location": "Konum",
    "coupons": "Kuponlar",
    "activated_coupons": "Etkinleştirilen kuponlar",
    "redeemed_coupons": "Kullanılan kuponlar",
    "activate": "Etkinleştir",
    "redeem_now": "Şimdi kullan",
    "coupon_redeemed_message": "Kupon kullanıldı - böyle devam edin! Yerel ticareti bu şekilde destekliyoruz.",
    "coupon_redeemed_message_title": "Kupon kullanıldı!",
    "coupon_redeemed_message_bodxy": "Aynen böyle devam! Yerel ticareti bu şekilde destekliyoruz.",
    "share_message": "Merhaba, BASAR'dayım. Bu bağlantıyı kullanarak uygulamayı indirin.",
    "share_success": "Başarıyla paylaşın!",
    "share_dismissed": "Paylaşım iptal edildi",
    "share_failed": "Paylaşım başarısız oldu:",
    "report_problem": "Sorun bildir",
    "deactivate_coupon": "Kuponu devre dışı bırak",
    "info": "Bilgi",
    "more": "Daha",
    "Information about this Coupons": "Bu kupon hakkında bilgi",
    "command_mention_basar_for_redeem": "Ödeme yapmadan önce BASAR çekinizin olduğunu belirtin ve onu bir çalışanın önünde kullanın. Ziyaret başına yalnızca bir kupon kullanılabilir.",
    "Redeem Location": "Ödeme konumu",
    "redeem_location_info": "Teklifin yerinde mi yoksa paketli mi olduğu",
    "Reactivation time": "Yeniden etkinleştirme süresi",
    "reactivation_info": "Teklifi kullandıktan sonra teklifin sizin için ne zaman tekrar aktif olacağı.",
    "reviews": "Yorumlar",
    "review": "Yorumlar",
    "show_all_reviews": "Tüm değerlendirmeleri görüntüle",
    "show_all": "Tümünü görüntüle",
    "REVIEW_SENT": "İnceleme gönderildi.",
    "REVIEW_THANKS": "İncelemeniz için teşekkür ederiz!",
    "REVIEW_WRITE": "Yorumunuzu yazın...",
    "loading": "Yükleniyor",
    "noReviewsAvailable": "Henüz yorum yok",
    "noStoreInformationAvailable": "Henüz bilgi yok",
    "advetorials.vendor.become_vendor_title": "Sitenizde yerel bir mağazanız var mı?",
    "advetorials.vendor.become_vendor_body": "O halde hemen uygulamamızın bayisi olun. Yerel işletmenizi desteklemek isteyen birçok kullanıcımız var.",
    "advetorials.vendor.download_vendor_app": "Satıcı uygulamasını App Store'dan indirin.",
    "become_Member": "Üye ol",
    "enter_discount": "İndirim kodunu girin",
    "translated_by_ai": "Çeviriler yapay zeka kullanılarak oluşturulmuş olabilir ve bu nedenle her zaman mükemmel olmayabilir.",
    "closeButton": "Kapalı",
    "edit_profile": "Profili düzenle",
    "validation_invalid_input": "Geçersiz giriş",
    "validation_required_input": "Alan doldurulmalıdır",
    "success": "Onaylandı",
    "error": "Hata",
    "profile_update_success": "Profil başarıyla güncellendi!",
    "profile_image_update_success": "Profil resmi başarıyla güncellendi",
    "profile_image_update_error": "Profil resmi güncellenemedi",
    "tutorial": "Öğretici",
    "settings": "Ayarlar",
    "feedbackAndSupport": "Geri Bildirim ve Destek",
    "changeToVendorMode": "Bayi moduna geç",
    "changeToUserMode": "Müşteri moduna geç",
    "registerAsPartner": "Ortak olarak kaydolun",
    "logout": "Oturumu kapat",
    "first_name": "İlk adı",
    "last_name": "Soy isim",
    "email": "E-posta",
    "zipCode": "Posta kodu",
    "edit": "Düzenlemek",
    "delete_account": "Profilleri sil",
    "delete_account_message": "Profilinizi silmek istediğinizden emin misiniz? Tüm avantajlarınız ve ayarlarınız geri dönüşü olmayan bir şekilde kaybolacak.",
    "delete_account_success": "Profil başarıyla silindi",
    "delete_account_success_message": "Profiliniz başarıyla silindi. 👋 Birbirimizi yakın zamanda tekrar görmeyi umuyoruz",
    "save": "Kaydetmek",
    "back": "Pişmek",
    "continue": "Devam etmek",
    "later": "Daha sonra",
    "terms_and_conditions": "Genel iş şartları ve koşulları",
    "agree_to_terms": "<terms>Genel Şartlar ve Koşullar</terms>'ı kabul ediyorum.",
    "phonenumber": "Telefon numarası",
    "enter_phonenumber": "Telefon numarasını girin",
    "login_no_password_info": "SMS koduyla kolay giriş. Yani şifreyi hatırlamanıza gerek yok.",
    "enter_code": "Kodu girin",
    "sent_to": "Gönderildi",
    "search_country_code": "Ülkeye veya alan koduna göre arayın",
    "command_agree": "Lütfen kabul edin",
    "command_agree_to_terms": "Devam etmek için şartları kabul etmelisiniz.",
    "otp": "Tek kullanımlık şifre",
    "enter_otp": "Tek kullanımlık şifreyi girin",
    "verify_otp": "Tek kullanımlık şifreyi onaylayın",
    "otp_expired": "Tek kullanımlık şifrenin süresi doldu",
    "otp_incorrect": "Tek kullanımlık şifre yanlış",
    "otp_verification_error": "Yanlış veya süresi dolmuş tek kullanımlık şifre girildi.",
    "command_create_profile_to_start": "Başlamak için profilinizi oluşturun!",
    "command_create_company_to_start": "Başlamak için şirketinizi kaydedin.",
    "info_create_shop_later": "Bir sonraki adımda mağazanız hakkında bilgi verebilirsiniz.",
    "your_concern": "Talebiniz",
    "describe_your_concern": "Talebinizi bize bildirin.",
    "contact_you_asap": "Sizinle mümkün olan en kısa sürede iletişime geçeceğiz.",
    "contact_via_whatsapp": "Whatsapp aracılığıyla bize ulaşın",
    "change_image": "Resmi değiştir",
    "edit_store_profile": "İşletme Profilini Düzenle",
    "error_loading_store": "İş verileri yüklenirken hata oluştu",
    "error_messages.no_stores.no_stores_nearby_found_title": "Yakınınızda mağaza bulunamadı",
    "error_messages.no_stores.no_stores_nearby_found_body": "Görünüşe göre henüz hızlı değiliz. Ama oraya ulaşacağız; endişelenmeyin. Aramayı kullanarak şehri değiştirin veya haritayı kullanarak şehre ilerleyin.",
    "error_messages.conflict.store_exists_title": "Bu adresteki işletme zaten kayıtlı.",
    "error_messages.conflict.store_exists_body": "Zaten kayıtlı bir mağaza oluşturamazsınız. Bunun bir hata olduğunu düşünüyorsanız lütfen bizimle buradan iletişime geçin.",
    "funny_error_message": "Hata! Bir şeyler ters gitti.",
    "page_not_found": "Sayfa yüklenemedi.",
    "page_not_found_connect_to_wifi": "Lütfen internete bağlı olduğunuzdan emin olun.",
    "page_not_found_help_message": "Sorun devam ederse lütfen bizimle iletişime geçin",
    "cancel": "İptal etmek",
    "partner_login": "İş ortağı girişi",
    "partner_register": "İş ortağı kaydı",
    "command_no_account_register": "Hesabın yok mu? Buradan kaydolun.",
    "command_have_account_login": "Hesabınız var mı? Buradan oturum açın.",
    "login": "Giriş yapmak",
    "register": "Kayıt",
    "please_enter_email": "Lütfen e-posta sağlayın",
    "email_sent": "E-posta gönderildi",
    "password_reset_email_sent": "Şifreyi sıfırlamak için e-posta gönderildi.",
    "password_reset_failed": "Şifre sıfırlama başarısız oldu.",
    "forgot_password": "Parolanızı mı unuttunuz",
    "password_reset_rate_limit": "Güvenlik nedeniyle bu isteği yalnızca 60 saniyede bir yapabilirsiniz. Lütfen bekleyin ve tekrar deneyin.",
    "back_to_login": "Girişe geri dön",
    "company_profile": "Şirket Profili",
    "company_details": "Şirket ayrıntıları",
    "company_email": "Şirket e-postası",
    "company_name": "Firma Adı",
    "company_address": "Şirket adresi",
    "company_zipCode": "Posta Kodu",
    "company_city": "Şehir",
    "company_country": "Ülke",
    "company_phone": "Şirket telefon numarası",
    "company_pocName": "Ad, soyad (irtibat kişisi)",
    "company_pocRole": "Rol seçin (irtibat kişisi)",
    "store_image": "Dükkanın resmi",
    "vendor_delete_account": "Profilinizi silmek istediğinizden emin misiniz?",
    "vendor_delete_account_body": "Şu anda profilinizi yalnızca WhatsApp aracılığıyla bizimle iletişime geçerek veya basar@basar-digital.com adresine e-posta göndererek silebilirsiniz.",
    "select_a_category": "Bir kategori seçin",
    "categories.grocery": "Bakkaliye",
    "categories.restaurants": "Restoran",
    "categories.hairSalon": "Berber",
    "categories.electronics": "Elektronik",
    "categories.beauty_wellness": "Güzellik ve Sağlık",
    "categories.other": "Diğer",
    "categories.non_food_retail": "Perakende",
    "categories.home_and_garden": "Ev ve bahçe",
    "categories.automotive": "Hareketlilik",
    "categories.flowers_and_gifts": "Çiçekler ve hediyeler",
    "categories.activities": "Faaliyetler",
    "categories.cafe_and_dessert": "Kafe ve tatlı",
    "categories.farm_shop": "Çiftlik dükkanı",
    "subcategories.convenienceStore": "Mini market",
    "subcategories.eastEuropean": "Doğu Avrupa",
    "subcategories.beverageMarket": "İçecek pazarı",
    "subcategories.orientalGrocery": "Oryantal",
    "subcategories.indianGrocery": "Hint yemekleri",
    "subcategories.importedGrocery": "Içe aktarmak",
    "subcategories.bioOrganic": "Organik ve organik",
    "subcategories.africanGrocery": "Afrika",
    "subcategories.turkishSupermarket": "Türkçe",
    "subcategories.latinAmericanSupermarket": "Latin Amerika",
    "subcategories.middleEasternSupermarket": "Orta Doğu",
    "subcategories.russianSupermarket": "Rusça",
    "subcategories.polishSupermarket": "Lehçe",
    "subcategories.mediterraneanSupermarket": "Akdeniz",
    "subcategories.veganVegetarianSupermarket": "Vegan/vejetaryen",
    "subcategories.halalMarket": "Helal",
    "subcategories.kosherSupermarket": "Kaşer",
    "subcategories.southAsianSupermarket": "Güney Asya",
    "subcategories.germanCuisine": "Almanca",
    "subcategories.frenchRestaurant": "Fransızca",
    "subcategories.spanishTapasBar": "İspanyol",
    "subcategories.greekTavern": "Yunan",
    "subcategories.mexicanRestaurant": "Meksika",
    "subcategories.indianRestaurant": "Hint",
    "subcategories.sushiBar": "Suşi barı",
    "subcategories.steakhouse": "Et restoranı",
    "subcategories.vegetarianVeganRestaurant": "Vegan/vejetaryen",
    "subcategories.foodTruck": "Yiyecek kamyonu",
    "subcategories.fusionCuisine": "Uluslararası",
    "subcategories.medievalTavern": "Ortaçağ",
    "subcategories.patisserie": "Pastane",
    "subcategories.lowCarb": "Düşük karbonhidrat",
    "subcategories.brideAndEvent": "Gelin ve Etkinlik",
    "subcategories.afroBarber": "Afrikalı Berber",
    "subcategories.hairSpecialist": "Saç uzmanı",
    "subcategories.permanentHairRemoval": "Kalıcı epilasyon",
    "subcategories.hairReplacement": "Epilasyon",
    "subcategories.barberServices": "Saç Hizmetleri",
    "subcategories.veganCafe": "Vegan",
    "subcategories.outdoorActivities": "Açık hava etkinlikleri",
    "subcategories.eventDecorators": "Etkinlik Dekoru",
    "subcategories.indoorPlants": "Kapalı bitkiler",
    "subcategories.outdoorPlants": "Dış mekan bitkileri",
    "subcategories.ballonAndParty": "Balonlar ve Parti Malzemeleri",
    "subcategories.handmade": "El yapımı",
    "subcategories.candleAndFragrances": "Mumlar ve Kokular",
    "subcategories.autoDetailing": "Araç hazırlığı",
    "subcategories.autoBodyAndPaint": "Gövde ve boya",
    "subcategories.carRental": "Araba kiralama",
    "subcategories.autoGlass": "Otomobil camı",
    "subcategories.inspection": "Denetleme",
    "subcategories.oilChange": "Yağ değişimi",
    "subcategories.motorcycle": "Motosiklet",
    "subcategories.autoAccessories": "Araba aksesuarları",
    "subcategories.autoTuning": "Ayarlama atölyesi",
    "subcategories.homeDecor": "Ev dekorasyonu",
    "subcategories.homeImprovement": "Ev geliştirme",
    "subcategories.lightingStore": "Lambalar ve Işıklar",
    "subcategories.applianceStore": "Ev aletleri",
    "subcategories.kitchenAndBath": "Mutfak ve Sıhhi Tesisat",
    "subcategories.securityAndSmartHome": "Güvenlik ve Akıllı Ev",
    "subcategories.diy": "Kendin Yap",
    "subcategories.pharmacy": "Eczane",
    "subcategories.medicalSupply": "Tıbbi mağaza",
    "subcategories.jewlery": "Kuyumcu",
    "subcategories.toyStore": "Oyuncak dükkanı",
    "subcategories.musicStore": "Müzik ve Enstrümanlar",
    "subcategories.secondhandStores": "İkinci el",
    "subcategories.travelAgency": "Seyahat acentesi",
    "subcategories.photoStudio": "Fotoğraf stüdyosu",
    "subcategories.tailorShop": "Terzilik",
    "subcategories.cleaningService": "Temizlik hizmeti",
    "subcategories.shoeRepair": "Ayakkabı tamiri",
    "subcategories.keyCopyService": "Çilingir hizmeti",
    "subcategories.laundryService": "Çamaşırhane hizmeti",
    "subcategories.locksmith": "Çilingir",
    "subcategories.mobileRepair": "Cep telefonu tamiri",
    "subcategories.computerRepair": "Bilgisayar onarımı",
    "subcategories.packagingService": "Paketleme hizmeti",
    "subcategories.printShop": "Matbaa",
    "subcategories.eventPlanning": "Etkinlik planlama",
    "subcategories.regionalProducts": "Bölgesel ürünler",
    "subcategories.butcherGrocery": "Kasap",
    "subcategories.bakeryGrocery": "Pişmiş ürünler",
    "subcategories.asianGrocery": "Asya",
    "subcategories.eastEuropaen": "Doğu Avrupa",
    "subcategories.farmGrocery": "Çiftlik dükkanı",
    "subcategories.halalRestaurant": "Helal",
    "subcategories.asianRestaurants": "Asya",
    "subcategories.seafoodRestaurant": "Deniz ürünleri",
    "subcategories.italianRestaurant": "İtalyan",
    "subcategories.burgerRestaurant": "Burgerler",
    "subcategories.menHairSalon": "Beyler",
    "subcategories.kidsHairSalon": "Çocuklar",
    "subcategories.womenHairSalon": "Kadınlar",
    "subcategories.gadgets": "Araçlar",
    "subcategories.vegetarianRestaurant": "Vejetaryen",
    "subcategories.mediterraneanRestaurant": "Akdeniz",
    "subcategories.fishMarket": "Balık pazarı",
    "subcategories.imker": "Arıcı",
    "subcategories.makeup": "Makyaj yapmak",
    "subcategories.barbeque": "Barbekü",
    "subcategories.bubble_tea": "Kabarcık çayı",
    "subcategories.african": "Afrika",
    "subcategories.hair_treatments": "Saç bakımları",
    "subcategories.hair_removal": "Epilasyon",
    "subcategories.teeth_whitening": "Diş beyazlatma",
    "subcategories.acupuncture": "Akupunktur",
    "subcategories.nail_salon": "Tırnak salonu",
    "subcategories.therapies": "Terapiler",
    "subcategories.meat": "Et",
    "subcategories.buffet": "Büfeler",
    "subcategories.fried": "Kızarmış yiyecek",
    "subcategories.fast_food": "Fast food",
    "subcategories.breakfast_brunch": "Kahvaltı | brunch",
    "subcategories.bistro_imbiss": "Bistro | atıştırmalık",
    "subcategories.manicure_pedicure": "Manikür ve Pedikür",
    "subcategories.facial_treatments": "Yüz Bakımı",
    "subcategories.cosmetic_supply": "Kozmetik ürünler",
    "subcategories.beverage_market": "İçecek pazarı",
    "subcategories.dairyGrocery": "Günlük",
    "subcategories.vegetableGrocery": "Sebze",
    "subcategories.dessertBar": "Tatlılar",
    "subcategories.crepes": "Krep",
    "subcategories.waffles": "Waffle",
    "subcategories.juices": "Meyve suları",
    "subcategories.tea_house": "Çayevi",
    "subcategories.sweet_pastry": "Tatlı hamur işleri",
    "subcategories.candy_shop": "Şeker dükkanı",
    "subcategories.dairyFarmShop": "Günlük",
    "subcategories.fruit_vegetable": "Meyve ve sebzeler",
    "subcategories.eggs": "Yumurtalar",
    "subcategories.bio_organic": "Organik ve organik",
    "subcategories.scooping": "Çukurluğu",
    "subcategories.indian_grocery": "Hint",
    "subcategories.imported_grocery": "Içe aktarmak",
    "subcategories.spaWellness": "Spa ve sağlıklı yaşam",
    "subcategories.massageCenter": "Masajlar",
    "subcategories.gifts": "Hediyeler",
    "subcategories.cafe": "Kafe",
    "subcategories.iceCreamParlor": "Dondurma salonu",
    "subcategories.bakeryCafe": "Pişmiş ürünler",
    "subcategories.sportsCenter": "Spor",
    "subcategories.kidsPlayArea": "Çocuk oyun parkı",
    "subcategories.art": "Sanat",
    "subcategories.cinema": "Sinema",
    "subcategories.theater": "Tiyatro",
    "subcategories.escape_room": "Kaçış odaları",
    "subcategories.climbing": "Tırmanmak",
    "subcategories.musuem": "Müze",
    "subcategories.flowerShop": "Çiçekler",
    "subcategories.giftShop": "Hediyeler",
    "subcategories.carRepair": "Tamirat",
    "subcategories.carWash": "Yıkamak",
    "subcategories.tireShop": "Lastikler",
    "subcategories.furnitureStore": "Mobilya",
    "subcategories.gardenCenter": "Bahçe",
    "subcategories.clothingStore": "Giyim mağazası",
    "subcategories.electronicsStore": "Elektronik",
    "subcategories.petStore": "Evcil hayvan malzemeleri",
    "subcategories.zooStore": "Hayvanat bahçesi malzemeleri",
    "subcategories.bookstore": "Kitaplar ve Dergiler",
    "subcategories.cosmetic": "Kozmetik",
    "keywords.oriental": "Oryantal",
    "keywords.middleEastern": "Orta Doğu",
    "keywords.arabic": "Arapça",
    "keywords.turkish": "Türkçe",
    "keywords.moroccan": "Fas",
    "keywords.persian": "Farsça",
    "keywords.butcher": "Kasap",
    "keywords.meat": "Et",
    "keywords.sausage": "Sosis",
    "keywords.meatShop": "Kasap dükkanı",
    "keywords.slaughterhouse": "Kasap",
    "keywords.bakery": "Fırın",
    "keywords.bread": "Ekmek",
    "keywords.pastry": "Hamur işleri",
    "keywords.roll": "Ekmek ruloları",
    "keywords.cake": "Kek",
    "keywords.patisserie": "Pastane",
    "keywords.asian": "Asya",
    "keywords.chinese": "Çince",
    "keywords.japanese": "Japonca",
    "keywords.thai": "Tay dili",
    "keywords.vietnamese": "Vietnam",
    "keywords.korean": "Korece",
    "keywords.indian": "Hint",
    "keywords.farmShop": "Çiftlik dükkanı",
    "keywords.farmersMarket": "Çiftçi pazarı",
    "keywords.regional": "Bölgesel",
    "keywords.organic": "Organik",
    "keywords.fresh": "Taze",
    "keywords.agricultural": "Tarımsal",
    "keywords.halal": "Helal",
    "keywords.halalCertified": "Helal sertifikalı",
    "keywords.islamic": "İslami",
    "keywords.muslim": "Müslüman",
    "keywords.doner": "Döner kebap",
    "keywords.sushi": "Suşi",
    "keywords.fish": "Balık",
    "keywords.seafood": "Deniz ürünleri",
    "keywords.salmon": "Somon",
    "keywords.shrimp": "Karides",
    "keywords.italian": "İtalyan",
    "keywords.pizza": "Pizza",
    "keywords.pasta": "Makarna",
    "keywords.risotto": "Risotto",
    "keywords.tiramisu": "Tiramisu",
    "keywords.antipasti": "Antipasti",
    "keywords.burger": "Vatandaşlar",
    "keywords.hamburger": "Hamburger",
    "keywords.cheeseburger": "Çizburger",
    "keywords.fries": "Friz",
    "keywords.fastFood": "Fast food",
    "keywords.menHairdresser": "Erkek kuaförü",
    "keywords.menSalon": "Erkek salonu",
    "keywords.barber": "Berber",
    "keywords.shaving": "Tıraş olmak",
    "keywords.haircutForMen": "Erkekler için saç kesimi",
    "keywords.kidsHairdresser": "Çocuk kuaförü",
    "keywords.kidCut": "Çocuk kesimi",
    "keywords.kidHaircut": "Çocuk saç kesimi",
    "keywords.haircutForKids": "Çocuklar için saç kesimi",
    "keywords.kidStyling": "Çocuk stili",
    "keywords.womenHairdresser": "Kadın kuaförü",
    "keywords.womenSalon": "Bayanlar salonu",
    "keywords.haircutForWomen": "Kadınlar için saç kesimi",
    "keywords.dyeing": "Boyama",
    "keywords.stylingForWomen": "Kadınlar için stil sahibi olmak",
    "keywords.technology": "Teknoloji",
    "keywords.electronics": "Elektronik",
    "keywords.devices": "Cihazlar",
    "keywords.accessories": "Aksesuarlar",
    "units.km": "Kilometre",
    "open_24_hours": "24 saat açık",
    "favorites_and_bookings": "Favoriler ve rezervasyonlar",
    "no_favorites": "Favori yok!",
    "no_favorites_text": "Henüz favori eklemediniz. Yerel mağazalarınıza göz atın ve daha sonra daha kolay erişim için onları favori olarak kaydedin!",
    "no_coupons": "Kupon yok!",
    "no_coupons_text": "Henüz herhangi bir kuponu etkinleştirmediniz. İndirimlerden yararlanmak için kuponları keşfedin ve etkinleştirin!",
    "onboardingTutorial_title_step1": "Keşfetmek",
    "onboardingTutorial_description_step1": "Restoranlar, süpermarketler, kuaförler, güzellik salonları, atölyeler ve çok daha fazlasının yer aldığı yerel ticaret dünyasını keşfedin!",
    "onboardingTutorial_imageCaption_step1": "Her şey orada, her şey yakında.",
    "onboardingTutorial_title_step2": "Kullan",
    "onboardingTutorial_description_step2": "Yerel perakende ortaklarımız sizi ürün veya hizmetlere yönelik doğrudan indirimlerden ve hediyelerden yararlanmaya davet ediyor.",
    "onboardingTutorial_imageCaption_step2": "Yereli desteklemek ve tasarruf etmek mümkün değil mi? Ancak!",
    "onboardingTutorial_title_step3": "Kaydetmek",
    "onboardingTutorial_description_step3": "Tüm fırsatlar cebinizde teslim edilmeye hazır. Rezervasyonunuzu mağazadaki çalışana göstermeniz yeterli. Yerel ticareti böyle destekliyoruz!",
    "onboardingTutorial_imageCaption_step3": "Herkes için hızlı ve kolay.",
    "confirm": "Onaylamak",
    "start_now": "Şimdi başla",
    "next": "Daha öte",
    "submit": "Göndermek",
    "company_name_required": "Şirket adı gerekli",
    "company_email_required": "Şirket e-postası gerekli",
    "invalid_email": "Geçersiz e-posta adresi",
    "invalid_zipCode": "Geçersiz posta kodu",
    "company_address_required": "Şirket adresi gerekli",
    "company_zipCode_required": "Posta kodu gerekli",
    "company_city_required": "Şehir gerekli",
    "company_country_required": "Ülke gerekli",
    "company_phone_required": "Şirket telefonu gereklidir",
    "invalid_phone": "Geçersiz telefon numarası",
    "placeholder_company_name": "Şirket adını girin",
    "placeholder_company_email": "Şirket e-postasını girin",
    "placeholder_company_address": "Sokak ve ev numarasını girin",
    "placeholder_company_zipCode": "Posta kodunu girin",
    "placeholder_company_city": "Şehri girin",
    "placeholder_company_country": "Ülkeyi girin",
    "placeholder_company_phone": "Telefon numarasını girin",
    "placeholder_company_pocName": "İrtibat kurulacak kişinin adını ve soyadını girin",
    "placeholder_company_pocRole": "İrtibat kişisinin rolünü seçin",
    "no_stores": "Hiçbir işletme yaratılmadı",
    "no_stores_text": "Henüz mağaza eklemediniz. Kupon sunabilmek için burayı tıklayın ve mağazanızı hemen oluşturun.",
    "more_stores": "Başka şubeniz var mı?",
    "more_stores_text": "Kupon oluşturabileceğiniz başka bir mağaza eklemek için burayı tıklayın.",
    "stores_of_company": "Şirketinizin şubeleri",
    "vendor_poc_roles.owner": "Mal sahibi",
    "vendor_poc_roles.employee": "Çalışan",
    "vendor_poc_roles.else": "Diğer",
    "coupon.ON_SITE": "Sitede",
    "coupon.TO_GO": "Gitmek",
    "coupon.NINETY_DAYS": "90 gün",
    "coupon.THIRTY_DAYS": "30 gün",
    "coupon.WEEKLY": "Haftalık",
    "coupon.ONE_YEAR": "Yıllık",
    "coupon.DAILY": "Günlük",
    "coupon.THREE_DAYS": "3 gün",
    "coupon.HUNDRED_EIGHTY_DAYS": "180 gün",
    "paywall.price_description_yearly": "Yıllık ödeme",
    "paywall.price_description_monthly": "Aylık ödemeler",
    "monetization.paywall_title": "Tüm fırsatlar. Hemen kaydedin.",
    "monetization.maybe_later": "Belki daha sonra",
    "monetization.monthly": "Aylık",
    "monetization.yearly": "Yıllık",
    "monetization.monthly_marketing.first_benefit": "Genellikle sadece 1-2 satın alma işlemine değer!",
    "monetization.monthly_marketing.second_benefit": "Aylık iptal edilebilir!",
    "monetization.monthly_marketing.third_benefit": "Tüm kuponları hemen kullanın.",
    "monetization.yearly_marketing.first_benefit": "Yılda sadece 3-4 satın alma işlemine değer!",
    "monetization.yearly_marketing.second_benefit": "Bir kere öde, bir yıl keyfini çıkar!",
    "monetization.yearly_marketing.third_benefit": "Tüm kuponları hemen kullanın.",
    "monetization.price_description_effective_per_month": "Aylık etkili",
    "monetization.price_description_per_month": "Aylık",
    "monetization.confirmation_title": "Başarılı satın alma!",
    "monetization.confirmation_message": "Satın aldığınız için teşekkür ederiz. Artık tüm premium özelliklere erişebilirsiniz.",
    "validation.setProductName": "Ürün adı ayarlanmalıdır.",
    "for": "İçin",
    "coupon_factory.2for1_title": "2 alana 1 bedava",
    "coupon_factory.2for1_description": "Veya daha da fazlası. Adet teklifi oluşturun",
    "coupon_factory.win_customers_and_money": "Müşteri ve satış kazanın.",
    "coupon_factory.example": "Örnek kupon",
    "coupon_factory.create_now": "Şimdi oluştur",
    "coupon_factory.create_coupon_marketing_header": "Şimdi ücretsiz olarak yeni müşteriler kazanın",
    "coupon_factory.create_coupon_marketing_body": "Kuponunuzu yalnızca birkaç adımda oluşturun.",
    "coupon_factory.create_coupon_benefit_header_1": "🎯 Yeni müşteriler",
    "coupon_factory.create_coupon_benefit_body_1": "Yeni müşterilere doğrudan akıllı telefonunuz aracılığıyla ulaşın",
    "coupon_factory.create_coupon_benefit_header_2": "🚀 Kolay ve Hızlı",
    "coupon_factory.create_coupon_benefit_body_2": "Anlaşmaları her zamankinden daha kolay oluşturun.",
    "coupon_factory.create_coupon_benefit_header_3": "💸 Daha fazla satış",
    "coupon_factory.create_coupon_benefit_body_3": "Müşterilerinize özel kuponlarla satışlarınızı artırın.",
    "coupon_factory.create_coupon_card": "Bir kupon oluşturun!",
    "coupon_factory.create_coupon_card_info": "3 adımda kupon oluşturmak için burayı tıklayın.",
    "coupon_factory.create_coupon": "Bir kupon oluşturun!",
    "coupon_factory.create_coupon_body": "Yeni müşteriler için reklamcılığı mükemmelleştirmenin üç adımı!",
    "coupon_factory.condition_for_customer": "Müşteriler için koşul belirleyin",
    "coupon_factory.choose_condition": "Koşul seçin",
    "coupon_factory.choose_condition_info": "Müşterinin fayda sağlamak için yapması gerekenler",
    "coupon_factory.minimum_order_value": "Minimum sipariş değeri",
    "coupon_factory.minimum_order_value_info": "Müşterinin belirli bir miktar harcaması gerekir",
    "coupon_factory.product_purchase": "Ürün satın alma",
    "coupon_factory.product_purchase_info": "Müşteri belirli ürünleri satın almalıdır",
    "coupon_factory.direct_rebate": "Üründe doğrudan indirim",
    "coupon_factory.no_conditions": "Koşul yok",
    "coupon_factory.no_conditions_set": "Hiçbir koşul belirlenmedi",
    "coupon_factory.go_to_next_step": "Sonraki adıma git",
    "coupon_factory.input_mov": "Minimum satın alma değerini belirtin",
    "coupon_factory.count_of_products": "Ürün sayısı",
    "coupon_factory.product_name": "Ürünün adı",
    "coupon_factory.value_of_product_per_piece": "Ürünün değeri (adet başına)",
    "coupon_factory.order_discount": "Satın almada indirim",
    "coupon_factory.product_discount": "Bir üründe indirim",
    "coupon_factory.free_product": "Ücretsiz ürün",
    "coupon_factory.discount": "İndirim",
    "coupon_factory.enter_discount_amount": "İndirim tutarını girin",
    "coupon_factory.redeem_location_conditons": "İndirim koşulları",
    "coupon_factory.redeem_location_info": "Müşterileriniz indirimden nerede yararlanabilir?",
    "coupon_factory.choose_discount": "İndirim",
    "coupon_factory.choose_discount_info": "Müşteriniz ne tür bir indirim alıyor?",
    "coupon_factory.carousel_header_one": "Kupon seç",
    "coupon_factory.carousel_body_one": "Oluşturmak istediğiniz kuponu seçin.",
    "coupon_factory.carousel_header_two": "Koşulu ayarla",
    "coupon_factory.carousel_body_two": "Müşterinin ne yapması gerektiğini belirleyin.",
    "coupon_factory.carousel_header_three": "Avantajları ayarlayın",
    "coupon_factory.carousel_body_three": "Müşterinin ne alacağını belirleyin.",
    "coupon_factory.product_quantitiy": "Müşteri başına miktar",
    "coupon_factory.product_quantitiy_info": "İndirim adet başına geçerlidir!",
    "coupon_factory.product_quantitiy_free_product_info": "Kaç adet ÜCRETSİZ alıyor?",
    "coupon_factory.same_product": "Aynı ürün",
    "coupon_factory.same_product_info": "Müşteri aynı ürünü alır.",
    "coupon_factory.other_product": "Farklı ürün",
    "coupon_factory.other_product_info": "Lütfen ürün adını belirtin.",
    "coupon_factory.price_per_unit": "Parça başına fiyat",
    "coupon_factory.error_invalid_input": "Geçersiz giriş",
    "coupon_factory.error_rebate_higher_as_order_value": "İndirim, satın alma/ürün tutarından yüksek olamaz",
    "coupon_factory.error_rebate_higher_as_product_value": "İndirim, satın alma/ürün tutarından yüksek olamaz",
    "coupon_factory.max_created_coupon_card": "Maksimum kupon sayısına ulaşıldı",
    "coupon_factory.max_created_coupon_card_info": "Kuponlarınız için mevcut maksimum tutara ulaştınız.",
    "coupon_factory.max_created_stores": "Maksimum mağazaya ulaşıldı",
    "coupon_factory.max_created_stores_info": "Oluşturulabilecek mevcut maksimum fırsat sayısına ulaştınız. Başka bir mağaza oluşturmak istiyorsanız lütfen ayarlardaki iletişim formunu kullanarak bizimle iletişime geçin.",
    "details": "Detaylar",
    "enter_details": "Ayrıntıları sağlayın",
    "store_factory.store_conflict": "Mağazada sorun oluştu",
    "store_factory.store_conflict_info": "Google'da seçilen mağaza Bazaar'da zaten kayıtlı. Lütfen bir kez kontrol edin. Hala sorun yaşıyorsanız lütfen bizimle iletişime geçmekten çekinmeyin.",
    "store_factory.wrong_store": "Yanlış iş mi seçildi?",
    "store_factory.wrong_store_search_again": "Tekrar yeniden başlatmak istiyor musunuz? Google'daki bilgilerinizin doğru olması gerektiğini lütfen unutmayın.",
    "store_factory.restart": "Tekrar başlat",
    "store_factory.what_customers_see": "Müşterilerinizin gördüğü şey budur",
    "store_factory.check_details": "Bilgilerinizin doğru olup olmadığını kontrol edin.",
    "store_factory.store_name": "Şubenin adı",
    "store_factory.store_name_example": "İşletmenizin adı",
    "store_factory.store_phone": "Müşterileriniz için telefon numarası",
    "store_factory.store_email": "Müşterileriniz için e-posta",
    "store_factory.choose_category": "Kategori seç",
    "store_factory.choose_category_info": "Bir ana kategori ve en fazla 3 alt kategori seçin ve mağazanızın bir görselini yükleyin.",
    "store_factory.main_category": "Ana kategori",
    "store_factory.sub_categories": "Alt kategoriler",
    "store_factory.upload_store_img": "Mağazanın resmini yükleyin",
    "store_factory.store_img": "Mağazadan fotoğraf",
    "store_factory.max_achieved": "Maksimuma ulaşıldı",
    "store_factory.sub_categories_limit": "En fazla 3 alt kategori seçebilirsiniz.",
    "store_factory.categories_and_image_required": "Kategoriler ve resimler gereklidir!",
    "store_factory.zip_code": "Posta Kodu",
    "store_factory.zip_code_placeholder": "Örn. 44801",
    "store_factory.claim_store": "İşi devral",
    "store_factory.house_number": "Ev numarası",
    "store_factory.house_number_placeholder": "Örn. 33",
    "store_factory.street": "Sokak",
    "store_factory.street_placeholder": "Örn. Çarşı Caddesi",
    "store_factory.city": "Şehir",
    "store_factory.city_placeholder": "Örn. Bochum",
    "store_factory.success": "Mağaza başarıyla oluşturuldu",
    "store_factory.success_info": "Mağazanız başarıyla oluşturuldu ve şu anda doğrulanıyor.",
    "store_factory.success_verification": "Doğrulama genellikle 1-2 iş günü sürer. Doğrulama tamamlandıktan sonra mağazanız yayına girecek ve müşterileriniz tarafından görülebilecektir.",
    "store_factory.verification_pending": "Doğrulama bekleniyor",
    "store_factory.verification_pending_info": "Şu anda bir doğrulama süreci devam ediyor. Sizden daha fazla bilgiye ihtiyacımız olursa sizinle iletişime geçeceğiz. Herhangi bir sorunuz varsa lütfen bizimle iletişime geçmekten çekinmeyin.",
    "store_factory.no_coupons": "Henüz kupon yok",
    "store_factory.no_coupons_info": "Henüz kupon oluşturmadınız. Kupon oluşturmak için mağazaya tıklayın.",
    "to_dashboard": "Kontrol paneline",
    "error_unexpected": "Beklenmeyen bir hata oluştu. Tekrar deneyin. Sorun devam ederse lütfen bizimle iletişime geçmekten çekinmeyin.",
    "check_inputs": "Bilgileri kontrol edin",
    "warning": "Tehlike",
    "progress_deleted_info": "Buraya geri dönmeniz halinde geçici durumunuz silinecektir. Emin misin?",
    "notification": "Bildiri",
    "notifications": "Bildirimler",
    "request_notification_permission": "Anlık bildirimler etkinleştirilsin mi?",
    "denied_notification_permission": "Anlık bildirimler reddedildi",
    "denied": "Reddedildi",
    "activate_notification_in_settings": "Anında bildirimleri Ayarlar'da etkinleştirebilirsiniz",
    "notifcationsTitle.message1": "🥳 Şimdi yerel indirim zamanı!",
    "notifcationsBody.message1": "Yerel mağazalara özel kuponlarla şimdi tasarruf edin! İster yemek, moda veya eğlence olsun, harika indirimleri keşfedin ve topluluğunuzu destekleyin!"
  },
  "fr": {
    "!add_row_below": "VVV Ajouter une ligne sous VVV",
    "so_easy_for_partners": "C'est aussi simple que cela pour les partenaires",
    "so_easy_for_partners.register_store": "Enregistrez votre entreprise rapidement et facilement pour toucher plus de clients.",
    "so_easy_for_partners.create_coupon": "Créez des bons d'achat individuels pour vos clients, augmentez vos ventes et gagnez de nouveaux clients – tout cela selon vos souhaits.",
    "so_easy_for_partners.manage_store": "Gérez facilement votre magasin - ajustez les horaires d'ouverture, les images et les bons d'achat selon vos besoins à tout moment.",
    "change_language": "Changer de langue",
    "english_USA": "🇫🇷 Anglais",
    "german_Germany": "🇩🇪 Allemand",
    "Imprint": "Imprimer",
    "Datenschutz": "Politique de confidentialité",
    "AGB": "Conditions générales de vente",
    "Cookies": "Cookies",
    "about": "À PROPOS DE NOUS",
    "our_magazine": "Notre magazine",
    "for_companies": "Pour les entreprises",
    "contact": "Contact",
    "no_answer_found": "Question sans réponse ?",
    "jobs": "Emplois",
    "become_partner": "Devenez partenaire",
    "shop": "Boutique",
    "experiences": "Expériences",
    "legal": "LÉGAL",
    "social_media": "RÉSEAUX SOCIAUX",
    "frequently_asked_questions": "FAQ",
    "vendor_faq.how_work_question": "Comment BASAR fonctionne-t-il pour les partenaires ?",
    "vendor_faq.how_work_answer": "Nous avons établi des partenariats avec diverses entreprises locales et négocié des offres uniques telles que des services 2 pour 1 ou des réductions sur des produits. L'application Bazaar vous permet de consulter, réserver et utiliser ces offres dans de nombreux secteurs tels que les restaurants, les coiffeurs, les ateliers, les fleuristes et bien d'autres.",
    "vendor_faq.participating_businesses_question": "Quelles entreprises y participent ?",
    "vendor_faq.participating_businesses_answer": "Des milliers d'entreprises locales participent actuellement avec une variété d'offres exclusives. De la gastronomie aux services en passant par les produits, vous trouverez des offres intéressantes dans de nombreuses catégories.",
    "vendor_faq.app_free_question": "L'application est-elle actuellement gratuite ?",
    "vendor_faq.app_free_answer": "Oui, l'application BASAR est actuellement entièrement gratuite. Les partenaires peuvent participer et proposer des offres sans aucun frais.",
    "vendor_faq.create_store_question": "Comment créer une boutique sur BASAR ?",
    "vendor_faq.create_store_answer": "Créer votre boutique sur BASAR est simple et rapide. Vous pouvez créer votre profil professionnel en quelques clics directement dans l'application.",
    "vendor_faq.manage_coupons_question": "Puis-je créer et gérer mes propres coupons ?",
    "vendor_faq.manage_coupons_answer": "Oui, vous avez un contrôle total sur la création et la suppression de vos propres coupons. Définissez des remises, des offres et des conditions qui conviennent le mieux à votre entreprise. Aucun responsable commercial ne perturbe votre activité, votre marketing.",
    "faq.general.category": "GÉNÉRAL",
    "faq.general.questions[0].question": "Quel est le concept du Bazar ?",
    "faq.general.questions[0].answer": "Nous avons établi des partenariats avec diverses entreprises locales et négocié des bons de lancement exclusifs tels que : B. Des offres 2 pour 1 ou des réductions sur les produits. Avec l'application Bazaar, vous pouvez facilement parcourir, réserver et utiliser ces offres dans divers secteurs tels que les restaurants, les salons de coiffure, les ateliers, les fleuristes et bien plus encore.",
    "faq.general.questions[1].question": "Quelles entreprises y participent ?",
    "faq.general.questions[1].answer": "Des milliers d'entreprises locales participent actuellement avec une variété d'offres exclusives. De la restauration aux services et produits, vous trouverez des offres intéressantes dans de nombreuses catégories.",
    "start": "Commencer",
    "price": "Prix",
    "redeem_error_notToday_title": "Coupon non échangeable aujourd'hui.",
    "redeem_error_notToday_message": "Le coupon ne peut être utilisé que les jours spécifiés.",
    "redeemDays.monday": "Mo",
    "redeemDays.tuesday": "Faire",
    "redeemDays.wednesday": "Nous",
    "redeemDays.thursday": "Ème",
    "redeemDays.friday": "MS",
    "redeemDays.saturday": "Assis",
    "redeemDays.sunday": "Su",
    "coupon_factory.your_coupon_active": "Votre coupon est désormais actif et sera bientôt visible par vos clients !",
    "coupon_factory.valid_days_header": "Jours valides",
    "coupon_factory.valid_days_subHeader": "Jours pendant lesquels les clients sont autorisés à utiliser les coupons.",
    "error_messages.something_wrong": "Quelque chose s'est mal passé - Veuillez réessayer plus tard ou contactez-nous si le problème persiste.",
    "store_factory.store_not_found_input_manual": "Magasin introuvable ? Saisie manuelle de l'adresse.",
    "sent_review": "Soumettre votre avis.",
    "review_your_visit_at": "Évaluez votre visite @",
    "search": "Recherche",
    "faq_title": "FAQ (Foire aux questions)",
    "faq_title_reverse": "Foire aux questions (FAQ)",
    "vendor_faq_question_concept": "Comment fonctionne BASAR ?",
    "vendor_faq_answer_concept": "Le concept de BASAR est simple et efficace. En tant qu'indépendant possédant une entreprise physique, vous pouvez utiliser l'application BASAR Business pour présenter votre magasin et créer des coupons. Les utilisateurs peuvent trouver ces coupons via l'application utilisateur BASAR et les utiliser directement dans votre magasin. Nous renforçons ainsi les entreprises locales face à la concurrence des grandes chaînes de vente au détail.",
    "vendor_faq_question_costs": "Y a-t-il des frais pour moi ?",
    "vendor_faq_answer_costs": "Non, BASAR est entièrement gratuit pour les détaillants. Les utilisateurs s'abonnent pour avoir accès à tous les coupons. Cela nous permet de couvrir les coûts de la plateforme et de permettre aux entreprises locales d'être compétitives, même face aux grandes chaînes qui investissent dans des programmes coûteux de marketing et de fidélisation de la clientèle.",
    "vendor_faq_question_minium_requirements": "Quelles exigences dois-je remplir ?",
    "vendor_faq_answer_minium_requirements": "Vous avez besoin d'un magasin physique où vous servez les clients sur place - par ex. B. un restaurant, un coiffeur, un atelier ou similaire. De plus, vous ou vos employés devriez pouvoir accorder facilement les réductions promises à la caisse.",
    "vendor_faq_question_interface_cashier": "Dois-je intégrer ma caisse enregistreuse ?",
    "vendor_faq_answer_interface_cashier": "Non, BASAR fonctionne très facilement avec un principe classique à quatre yeux. Le client vous montre le coupon utilisé sur son smartphone et vous accordez la réduction. Aucune intégration de système de point de vente coûteuse ou compliquée n’est nécessaire. La seule chose importante est que vous ou vos employés puissiez appliquer correctement les réductions.",
    "vendor_faq_question_shop_creation": "Comment créer ma boutique ?",
    "vendor_faq_answer_shop_creation": "Vous pouvez créer votre boutique dans l'onglet « Boutique ». Recherchez votre entreprise dans notre base de données ou créez-la manuellement. Ajoutez les informations de contact, les horaires d’ouverture, une photo et la catégorie appropriée. Nous vérifierons les informations et vous contacterons si vous avez des questions. La vérification prend généralement un jour ouvrable. Vous pouvez ensuite créer des coupons immédiatement.",
    "vendor_faq_question_coupons": "Comment puis-je créer des coupons ?",
    "vendor_faq_answer_coupons": "Après avoir créé votre boutique, vous pouvez démarrer la fabrique de coupons dans l'onglet « Coupons ». Vous y trouverez des modèles prêts à l’emploi auxquels vous pourrez ajouter rapidement et facilement vos informations. Après l'aperçu, vous pouvez créer le coupon et il sera mis en ligne dans un court laps de temps. Condition : Votre boutique doit être vérifiée.",
    "vendor_faq_question_storeinfos_update": "Comment mettre à jour les informations de mon entreprise ?",
    "vendor_faq_answer_storeinfos_update": "Rendez-vous dans votre boutique et cliquez sur l'onglet « Info ». Là, vous pouvez mettre à jour les informations de contact, les horaires d'ouverture et les images à l'aide des boutons d'édition correspondants.",
    "vendor_faq_question_abuse": "Comment BASAR empêche-t-il les remboursements multiples d’un coupon ?",
    "vendor_faq_answer_abuse": "Chaque utilisateur s'inscrit avec son numéro de téléphone et est donc clairement identifiable. Lors de la création d'un coupon, vous pouvez spécifier la fréquence à laquelle un coupon peut être utilisé, par ex. B. seulement tous les 90 jours. De cette manière, l'utilisation reste équitable et contrôlée.",
    "vendor_faq_question_help_creation": "Allez-vous nous aider à créer la boutique ou les coupons ?",
    "vendor_faq_answer_help_creation": "Bien sûr! Nous serons heureux de vous aider si vous avez besoin d'aide pour configurer votre magasin ou vos coupons. Vous pouvez nous contacter à tout moment via WhatsApp. Vous pouvez trouver le numéro dans les paramètres sous « Commentaires et assistance ».",
    "vendor_faq_question_how_to_contact": "Comment puis-je vous joindre ?",
    "vendor_faq_answer_how_to_contact": "Vous pouvez nous joindre par email à basar@basar-digital.com ou directement via WhatsApp. Vous pouvez trouver le numéro WhatsApp dans les paramètres sous « Commentaires et assistance ».",
    "create_store_and_coupons": "Créez votre boutique et vos coupons",
    "click_here_for_help": "Cliquez ici si vous avez besoin de plus d'informations.",
    "vendorOnboardingTutorial_title_step1": "Recherchez et créez votre propre boutique.",
    "vendorOnboardingTutorial_description_step1": "Trouvez votre entreprise grâce à la fonction de recherche ou créez-la simplement vous-même. Ajoutez toutes les informations importantes telles que les contacts, les horaires d'ouverture, les images et les catégories.",
    "vendorOnboardingTutorial_imageCaption_step1": "Votre boutique, votre démarrage : en quelques clics, vous êtes visible pour vos clients, que vous ajoutiez une boutique existante ou que vous en créiez une nouvelle.",
    "vendorOnboardingTutorial_title_step2": "Créez des coupons irrésistibles.",
    "vendorOnboardingTutorial_description_step2": "Utilisez notre usine de coupons facile à utiliser pour créer des offres personnalisées. Faites plaisir à vos clients avec des remises exclusives et donnez un nouvel élan à votre entreprise.",
    "vendorOnboardingTutorial_imageCaption_step2": "Avec la fabrique de coupons, vous pouvez surprendre vos clients : créez des offres individuelles en quelques secondes seulement et renforcez la fidélité de vos clients.",
    "vendorOnboardingTutorial_title_step3": "Attendez-vous à des clients enthousiastes.",
    "vendorOnboardingTutorial_description_step3": "Avec vos offres, vous attirez de nouveaux clients et surprenez vos clients réguliers. Les clients peuvent trouver votre magasin et vos coupons directement dans l'application utilisateur BASAR. Vous échangez les coupons sur place et vous pouvez appliquer la réduction directement à la caisse.",
    "vendorOnboardingTutorial_imageCaption_step3": "Votre entreprise, leur joie : Plus de clients, plus de ventes – avec l'application utilisateur BASAR, votre boutique sera le premier choix.",
    "category": "Catégorie",
    "loadings_favorites": "Chargement des favoris",
    "feature_so_great": "Cette fonctionnalité est tellement géniale qu’elle n’est pas gratuite ! Inscrivez-vous et commencez.",
    "login_and_start": "Connectez-vous et commencez",
    "oops_something_missing": "Oups, il manque encore quelque chose !",
    "coupon_factory": "Usine de coupons",
    "coupon_factory.infoHeader_name_of_product": "Nom du produit",
    "coupon_factory.infoBody_name_of_product": "Saisissez le nom du produit ou du service que vous souhaitez que le client achète.",
    "coupon_factory.infoHeader_value_of_product": "Valeur du produit",
    "coupon_factory. infoBody_value_of_product": "Combien coûte le produit dans votre magasin ?",
    "coupon_factory.infoHeader_count_of_product": "Nombre de produits",
    "coupon_factory.infoBody_count_of_product": "Combien de ce produit il devrait acheter.",
    "coupon_factory.infoHeader_minimum_order_volume": "Valeur minimale de commande / valeur minimale d'achat",
    "coupon_factory.infoBody_minimum_order_volume": "Combien le client doit-il dépenser au moins pour en bénéficier ?",
    "coupon_factory.infoHeader_name_of_benefit_product": "Nom du produit avantage",
    "coupon_factory.infoBody_name_of_benefit_product": "Entrez ici le nom du produit ou du service que le client recevra.",
    "coupon_factory.infoHeader_value_of_benefit_product": "Valeur du produit avantage",
    "coupon_factory.infoBody_value_of_benefit_product": "Combien coûte normalement le produit sans prix d’offre ?",
    "coupon_factory.infoHeader_discount_amount": "Préciser le montant de la remise",
    "coupon_factory.infoBody_discount_amount": "Quel est le montant de la remise par pièce - en € ou %.",
    "coupon_factory.infoHeader_order_discount_amount": "Préciser le montant de la remise",
    "coupon_factory.infoBody_order_discount_amount": "Quel est le montant de la remise sur l'achat ou la commande - en € ou %.",
    "delete_coupon": "Supprimer le coupon",
    "generate": "Générer",
    "delete_coupon_text": "Êtes-vous sûr de vouloir supprimer ce coupon ? Cette action ne peut pas être annulée.",
    "delete_coupon_info": "Attention : Votre coupon est toujours actif jusqu'en fin de journée et ne sera plus visible par les utilisateurs à partir du lendemain.",
    "validation_all_inputs_required": "Tous les champs doivent être remplis correctement.",
    "store_factory.contact_and_opentimes": "Contacts et horaires d'ouverture",
    "store_factory.always_editable_later": "Vous pourrez toujours le modifier plus tard.",
    "choose_category_and_image": "Sélectionnez l'image et la catégorie.",
    "store_factory.main_categories_limit": "Sélectionnez une catégorie.",
    "searchdata_openstreetmap": "Rechercher des données © OpenStreetMap",
    "success.opentimes_updated": "Horaires d'ouverture mis à jour avec succès !",
    "success.contactdetails_updated": "Coordonnées mises à jour avec succès !",
    "editcontact": "Modifier les coordonnées",
    "from": "Depuis",
    "until": "Jusqu'à",
    "weekday": "Jour",
    "open_questionmark": "Ouvrir?",
    "coupon_factory.contact_if_issue": "Veuillez vérifier toutes les informations. Si vous avez encore besoin d'aide, n'hésitez pas à nous contacter ici",
    "respawn_time": "Temps de réapparition",
    "coupon_factory.coupon_in_three_steps": "Créez un coupon en 3 étapes.",
    "store_factory.your_store_on_map": "Votre entreprise sur la carte",
    "coupon_factory.infoHeader_need_help": "Avez-vous besoin de soutien ?",
    "coupon_factory.infoBody_need_help_whatsapp": "Contactez-nous directement ici via Whatsapp.",
    "coupon_factory.check_preview": "Prévisualisez votre coupon.",
    "coupon_factory.preview": "Aperçu",
    "coupon_factory.check_details": "Toutes les informations sont-elles correctes ? Cliquez sur Générer pour faire vivre votre coupon !",
    "coupon_factory.respawn_time_description": "Lorsqu'un client peut réactiver le coupon après l'avoir échangé. Si vous ne souhaitez plus proposer d'offre, vous pouvez la supprimer vous-même.",
    "coupon_factory.respawn_time_short_description": "Combien de temps le client doit attendre avant de pouvoir réactiver le coupon.",
    "coupon_factory.thirty_days_recommendation": "Le client peut à nouveau utiliser le coupon après 30 jours. Idéal pour les offres régulièrement disponibles et attractives pour les clients fidèles.",
    "coupon_factory.ninety_days_recommendation": "Le coupon peut être réutilisé après 90 jours. Cette option fonctionne bien pour les offres plus longues destinées aux nouveaux clients.",
    "coupon_factory.weekly_recommendation": "Le coupon peut être utilisé à nouveau une fois par semaine. Parfait pour les promotions à court terme qui doivent être renouvelées régulièrement.",
    "coupon_factory.thirty_days_recommendation_short": "Idéal pour les offres régulièrement disponibles.",
    "coupon_factory.ninety_days_recommendation_short": "Idéal pour les nouvelles offres clients rares.",
    "coupon_factory.weekly_recommendation_short": "Idéal pour les offres promotionnelles courtes.",
    "coupon_factory.mainly_for_restaurants": "S'applique généralement uniquement à la gastronomie.",
    "coupon_factory.default_set": "Défini par défaut.",
    "coupon_factory.redeem_location_onsite_info": "Valable si le client achète en magasin.",
    "coupon_factory.redeem_location_togo_info": "Valable si le client achète quelque chose à emporter, par ex. un kebab à emporter.",
    "coupon_factory.choose_coupon_type": "Sélectionnez les types de coupons",
    "coupon_factory.choose_coupon_type_details": "Sélectionnez le type de coupon que vous souhaitez créer pour votre boutique.",
    "coupon_factory.example_header_one": "10 € de remise",
    "coupon_factory.example_body_one": "Bénéficiez d'une réduction de 10 € avec une dépense minimum de 50 €",
    "coupon_factory.example_headertitle_one": "Remise en argent",
    "coupon_factory.example_subheader_one": "Vos nouveaux clients bénéficieront d’une remise directe sur leur achat/commande.",
    "coupon_factory.example_header_two": "1x boisson CADEAU",
    "coupon_factory.example_body_two": "Vous obtenez 1x chocolat gratuitement sans minimum de commande",
    "coupon_factory.example_headertitle_two": "CADEAU",
    "coupon_factory.example_subheader_two": "Vos nouveaux clients recevront un cadeau avec leur achat.",
    "coupon_factory.example_header_three": "2 pour 1 - frites",
    "coupon_factory.example_body_three": "Lorsque vous achetez 1 x frites, vous obtenez 1x frites gratuitement. S'applique au produit du même prix ou moins cher.",
    "coupon_factory.example_headertitle_three": "2 pour 1",
    "coupon_factory.example_subheader_three": "Vos nouveaux clients reçoivent 2 produits pour le prix d'un.",
    "coupon_factory.example_header_four": "50% de réduction directe sur les vestes",
    "coupon_factory.example_body_four": "Vous recevrez 1 x bouquet de fleurs avec une réduction de 50 % chacun sans minimum de commande.",
    "coupon_factory.example_headertitle_four": "VENTE",
    "coupon_factory.example_subheader_four": "Vos clients bénéficient d'une remise directe sur un produit spécifique.",
    "coupon_factory.example_header_individual": "Individuellement",
    "coupon_factory.example_body_individual": "Offre individuelle",
    "coupon_factory.example_headertitle_individual": "Individuellement",
    "coupon_factory.example_subheader_individual": "Créez un coupon complètement individuel pour vos clients.",
    "welcome": "Accueillir",
    "languages.de": "🇩🇪 Allemand",
    "languages.en": "🇺🇸anglais",
    "languages.tr": "🇹🇷 turc",
    "languages.ar": "🇸🇦 Arabe",
    "languages.fr": "🇫🇷 Français",
    "languages.es": "Espagnol",
    "languages.it": "🇮🇹 italien",
    "languages.pl": "🇵🇱Polonais",
    "languages.ru": "🇷🇺Russe",
    "popular_categories": "Catégories populaires",
    "no_matching_categories_found": "Aucune catégorie correspondante trouvée",
    "hello": "Bonjour d'Allemagne",
    "with_coupons": "Avec des coupons",
    "cities_with_digital_basar": "Des villes dotées d'un bazar numérique",
    "use_your_location": "Utilisez votre emplacement",
    "no_matching_cities_found": "Aucune ville correspondante trouvée",
    "discover": "Découvrir",
    "favorites": "Favoris",
    "profile": "Profils",
    "delete": "Supprimer",
    "openingTimes": "Horaires d'ouverture",
    "editOpeningTimes": "Modifier les heures d'ouverture",
    "days.monday": "Lundi",
    "days.tuesday": "Mercredi",
    "days.wednesday": "Mercredi",
    "days.thursday": "Jeudi",
    "days.friday": "Vendredi",
    "days.saturday": "Samedi",
    "days.sunday": "Dimanche",
    "command_searchForYourBusiness": "Recherchez votre entreprise",
    "placeholder_find_store_google": "Trouvez votre entreprise sur Google",
    "permission_to_camera_roll": "L'accès à la photothèque est obligatoire !",
    "businessMustBeOnGoogleMaps": "Votre entreprise doit être visible sur Google Maps. Si vous rencontrez des problèmes, n'hésitez pas à nous contacter ici.",
    "closed": "Fermé",
    "location": "Emplacement",
    "coupons": "Coupons",
    "activated_coupons": "Coupons activés",
    "redeemed_coupons": "Coupons échangés",
    "activate": "Activer",
    "redeem_now": "Échangez maintenant",
    "coupon_redeemed_message": "Coupon utilisé - continuez comme ça ! C'est ainsi que nous soutenons le commerce local.",
    "coupon_redeemed_message_title": "Coupon échangé !",
    "coupon_redeemed_message_bodxy": "Continuez comme ça! C'est ainsi que nous soutenons le commerce local.",
    "share_message": "Hé, je suis à BASAR. Téléchargez l'application en utilisant ce lien.",
    "share_success": "Partagez avec succès !",
    "share_dismissed": "Partage annulé",
    "share_failed": "Échec du partage :",
    "report_problem": "Signaler un problème",
    "deactivate_coupon": "Désactiver le coupon",
    "info": "Infos",
    "more": "Plus",
    "Information about this Coupons": "Informations sur ce coupon",
    "command_mention_basar_for_redeem": "Avant de payer, mentionnez que vous disposez d'un bon BASAR et échangez-le devant un employé. Un seul coupon peut être échangé par visite.",
    "Redeem Location": "Lieu de remboursement",
    "redeem_location_info": "Que l'offre soit sur place ou à emporter",
    "Reactivation time": "Temps de réactivation",
    "reactivation_info": "Quand l’offre sera à nouveau active pour vous après l’avoir utilisée.",
    "reviews": "Avis",
    "review": "Avis",
    "show_all_reviews": "Voir tous les avis",
    "show_all": "Voir tout",
    "REVIEW_SENT": "Avis soumis.",
    "REVIEW_THANKS": "Merci pour votre avis !",
    "REVIEW_WRITE": "Écrivez votre avis...",
    "loading": "Chargement",
    "noReviewsAvailable": "Pas encore de commentaires",
    "noStoreInformationAvailable": "Aucune information pour l'instant",
    "advetorials.vendor.become_vendor_title": "Avez-vous un magasin local sur place ?",
    "advetorials.vendor.become_vendor_body": "Alors devenez revendeur sur notre application dès maintenant. Nous avons de nombreux utilisateurs qui souhaitent soutenir votre entreprise locale.",
    "advetorials.vendor.download_vendor_app": "Téléchargez l'application du détaillant depuis l'App Store.",
    "become_Member": "Devenez membre",
    "enter_discount": "Entrez le code de réduction",
    "translated_by_ai": "Les traductions peuvent avoir été créées à l’aide de l’IA et ne sont donc pas toujours parfaites.",
    "closeButton": "Fermer",
    "edit_profile": "Modifier le profil",
    "validation_invalid_input": "Entrée invalide",
    "validation_required_input": "Le champ doit être rempli",
    "success": "Confirmé",
    "error": "Erreur",
    "profile_update_success": "Profil mis à jour avec succès !",
    "profile_image_update_success": "Photo de profil mise à jour avec succès",
    "profile_image_update_error": "Échec de la mise à jour de la photo de profil",
    "tutorial": "Tutoriel",
    "settings": "Paramètres",
    "feedbackAndSupport": "Commentaires et assistance",
    "changeToVendorMode": "Passer en mode concessionnaire",
    "changeToUserMode": "Passer en mode client",
    "registerAsPartner": "S'inscrire en tant que partenaire",
    "logout": "Se déconnecter",
    "first_name": "Prénom",
    "last_name": "Nom de famille",
    "email": "E-mail",
    "zipCode": "Code postal",
    "edit": "Modifier",
    "delete_account": "Supprimer des profils",
    "delete_account_message": "Etes-vous sûr de vouloir supprimer votre profil ? Tous vos avantages et paramètres seront irrémédiablement perdus.",
    "delete_account_success": "Profil supprimé avec succès",
    "delete_account_success_message": "Votre profil a été supprimé avec succès. 👋 Nous espérons nous revoir bientôt",
    "save": "Sauvegarder",
    "back": "Cuire",
    "continue": "Continuer",
    "later": "Plus tard",
    "terms_and_conditions": "Conditions générales de vente",
    "agree_to_terms": "J'accepte les <terms>Conditions générales</terms>.",
    "phonenumber": "Numéro de téléphone",
    "enter_phonenumber": "Entrez le numéro de téléphone",
    "login_no_password_info": "Connexion facile via code SMS. Vous n'avez donc pas besoin de mémoriser un mot de passe.",
    "enter_code": "Entrez le code",
    "sent_to": "Envoyé à",
    "search_country_code": "Rechercher par pays ou indicatif régional",
    "command_agree": "Veuillez accepter",
    "command_agree_to_terms": "Vous devez accepter les conditions pour continuer.",
    "otp": "Mot de passe à usage unique",
    "enter_otp": "Entrez un mot de passe à usage unique",
    "verify_otp": "Confirmer le mot de passe à usage unique",
    "otp_expired": "Mot de passe à usage unique expiré",
    "otp_incorrect": "Mot de passe à usage unique incorrect",
    "otp_verification_error": "Mot de passe à usage unique incorrect ou expiré saisi.",
    "command_create_profile_to_start": "Créez votre profil pour commencer !",
    "command_create_company_to_start": "Enregistrez votre entreprise pour commencer.",
    "info_create_shop_later": "Vous pouvez fournir des informations sur votre boutique à l'étape suivante.",
    "your_concern": "Votre demande",
    "describe_your_concern": "Faites-nous part de votre demande.",
    "contact_you_asap": "Nous prendrons contact avec vous dans les plus brefs délais.",
    "contact_via_whatsapp": "Contactez-nous via Whatsapp",
    "change_image": "Changer l'image",
    "edit_store_profile": "Modifier le profil de l'entreprise",
    "error_loading_store": "Erreur lors du chargement des données d'entreprise",
    "error_messages.no_stores.no_stores_nearby_found_title": "Aucun magasin trouvé près de chez vous",
    "error_messages.no_stores.no_stores_nearby_found_body": "Apparemment, nous ne sommes pas encore rapides. Mais nous y arriverons – ne vous inquiétez pas. Changez la ville à l'aide de la recherche ou faites-la défiler à l'aide de la carte.",
    "error_messages.conflict.store_exists_title": "L'entreprise à cette adresse est déjà enregistrée.",
    "error_messages.conflict.store_exists_body": "Vous ne pouvez pas créer une boutique déjà enregistrée. Si vous pensez qu'il s'agit d'une erreur, veuillez nous contacter ici.",
    "funny_error_message": "Oups, quelque chose s'est mal passé.",
    "page_not_found": "La page n'a pas pu être chargée.",
    "page_not_found_connect_to_wifi": "Veuillez vous assurer que vous êtes connecté à Internet.",
    "page_not_found_help_message": "Si le problème persiste, veuillez nous contacter",
    "cancel": "Annuler",
    "partner_login": "Connexion partenaire",
    "partner_register": "Inscription des partenaires",
    "command_no_account_register": "Pas de compte ? Inscrivez-vous ici.",
    "command_have_account_login": "Avez-vous un compte ? Connectez-vous ici.",
    "login": "Se connecter",
    "register": "Inscription",
    "please_enter_email": "Veuillez fournir un e-mail",
    "email_sent": "E-mail envoyé",
    "password_reset_email_sent": "E-mail envoyé pour réinitialiser le mot de passe.",
    "password_reset_failed": "Échec de la réinitialisation du mot de passe.",
    "forgot_password": "Mot de passe oublié",
    "password_reset_rate_limit": "Pour des raisons de sécurité, vous ne pouvez effectuer cette demande qu'une fois toutes les 60 secondes. Veuillez patienter et réessayer.",
    "back_to_login": "Retour à la connexion",
    "company_profile": "Profil de l'entreprise",
    "company_details": "Détails de l'entreprise",
    "company_email": "E-mail de l'entreprise",
    "company_name": "Nom de l'entreprise",
    "company_address": "Adresse de l'entreprise",
    "company_zipCode": "Code Postal",
    "company_city": "Ville",
    "company_country": "Pays",
    "company_phone": "Numéro de téléphone de l'entreprise",
    "company_pocName": "Prénom, nom (personne de contact)",
    "company_pocRole": "Sélectionnez le rôle (personne de contact)",
    "store_image": "Photo de la boutique",
    "vendor_delete_account": "Etes-vous sûr de vouloir supprimer votre profil ?",
    "vendor_delete_account_body": "Actuellement, votre profil ne peut être supprimé qu'en nous contactant via WhatsApp ou par email à basar@basar-digital.com",
    "select_a_category": "Sélectionnez une catégorie",
    "categories.grocery": "Épiceries",
    "categories.restaurants": "Restaurant",
    "categories.hairSalon": "Coiffeur",
    "categories.electronics": "Électronique",
    "categories.beauty_wellness": "Beauté et bien-être",
    "categories.other": "Autre",
    "categories.non_food_retail": "Vente au détail",
    "categories.home_and_garden": "Maison et jardin",
    "categories.automotive": "Mobilité",
    "categories.flowers_and_gifts": "Fleurs et cadeaux",
    "categories.activities": "Activités",
    "categories.cafe_and_dessert": "Café & dessert",
    "categories.farm_shop": "Boutique de la ferme",
    "subcategories.convenienceStore": "Mini-marché",
    "subcategories.eastEuropean": "Europe de l'Est",
    "subcategories.beverageMarket": "Marché des boissons",
    "subcategories.orientalGrocery": "Oriental",
    "subcategories.indianGrocery": "Aliments indiens",
    "subcategories.importedGrocery": "Importer",
    "subcategories.bioOrganic": "Bio & Bio",
    "subcategories.africanGrocery": "Africain",
    "subcategories.turkishSupermarket": "Turc",
    "subcategories.latinAmericanSupermarket": "Amérique Latine",
    "subcategories.middleEasternSupermarket": "Moyen-Orient",
    "subcategories.russianSupermarket": "Russe",
    "subcategories.polishSupermarket": "Polonais",
    "subcategories.mediterraneanSupermarket": "Méditerranéen",
    "subcategories.veganVegetarianSupermarket": "Végétalien/végétarien",
    "subcategories.halalMarket": "Halal",
    "subcategories.kosherSupermarket": "Kascher",
    "subcategories.southAsianSupermarket": "Asiatique du Sud",
    "subcategories.germanCuisine": "Allemand",
    "subcategories.frenchRestaurant": "Français",
    "subcategories.spanishTapasBar": "Espagnol",
    "subcategories.greekTavern": "Grec",
    "subcategories.mexicanRestaurant": "Mexicain",
    "subcategories.indianRestaurant": "Indien",
    "subcategories.sushiBar": "Bar à sushis",
    "subcategories.steakhouse": "Steakhouse",
    "subcategories.vegetarianVeganRestaurant": "Végétalien/végétarien",
    "subcategories.foodTruck": "Camion de restauration",
    "subcategories.fusionCuisine": "International",
    "subcategories.medievalTavern": "Médiéval",
    "subcategories.patisserie": "Pâtisserie",
    "subcategories.lowCarb": "Faible teneur en glucides",
    "subcategories.brideAndEvent": "Mariée et événement",
    "subcategories.afroBarber": "Barbier Afro",
    "subcategories.hairSpecialist": "Spécialiste des cheveux",
    "subcategories.permanentHairRemoval": "Épilation définitive",
    "subcategories.hairReplacement": "Épilation",
    "subcategories.barberServices": "Services de coiffure",
    "subcategories.veganCafe": "Végétalien",
    "subcategories.outdoorActivities": "Activités de plein air",
    "subcategories.eventDecorators": "Déco événementielle",
    "subcategories.indoorPlants": "Plantes d'intérieur",
    "subcategories.outdoorPlants": "Plantes d'extérieur",
    "subcategories.ballonAndParty": "Ballons et articles de fête",
    "subcategories.handmade": "Fait à la main",
    "subcategories.candleAndFragrances": "Bougies et parfums",
    "subcategories.autoDetailing": "Préparation du véhicule",
    "subcategories.autoBodyAndPaint": "Carrosserie et peinture",
    "subcategories.carRental": "Location de voitures",
    "subcategories.autoGlass": "Verre automobile",
    "subcategories.inspection": "Inspection",
    "subcategories.oilChange": "Vidange",
    "subcategories.motorcycle": "Moto",
    "subcategories.autoAccessories": "Accessoires de voiture",
    "subcategories.autoTuning": "Atelier de réglage",
    "subcategories.homeDecor": "Décoration de la maison",
    "subcategories.homeImprovement": "Amélioration de l'habitat",
    "subcategories.lightingStore": "Lampes et Lumières",
    "subcategories.applianceStore": "Appareils électroménagers",
    "subcategories.kitchenAndBath": "Cuisine & Sanitaires",
    "subcategories.securityAndSmartHome": "Sécurité et maison intelligente",
    "subcategories.diy": "BRICOLAGE",
    "subcategories.pharmacy": "Pharmacie",
    "subcategories.medicalSupply": "Magasin médical",
    "subcategories.jewlery": "Bijoutier",
    "subcategories.toyStore": "Magasin de jouets",
    "subcategories.musicStore": "Musique et instruments",
    "subcategories.secondhandStores": "D'occasion",
    "subcategories.travelAgency": "Agence de voyage",
    "subcategories.photoStudio": "Studio photo",
    "subcategories.tailorShop": "Adaptation",
    "subcategories.cleaningService": "Service de nettoyage",
    "subcategories.shoeRepair": "Réparation de chaussures",
    "subcategories.keyCopyService": "Service de serrurerie",
    "subcategories.laundryService": "Blanchisserie",
    "subcategories.locksmith": "Serrurier",
    "subcategories.mobileRepair": "Réparation de téléphone portable",
    "subcategories.computerRepair": "Réparation d'ordinateur",
    "subcategories.packagingService": "Service d'emballage",
    "subcategories.printShop": "Imprimerie",
    "subcategories.eventPlanning": "Planification d'événements",
    "subcategories.regionalProducts": "Produits régionaux",
    "subcategories.butcherGrocery": "Boucher",
    "subcategories.bakeryGrocery": "Produits de boulangerie",
    "subcategories.asianGrocery": "Asiatique",
    "subcategories.eastEuropaen": "Europe de l'Est",
    "subcategories.farmGrocery": "Boutique de la ferme",
    "subcategories.halalRestaurant": "Halal",
    "subcategories.asianRestaurants": "Asiatique",
    "subcategories.seafoodRestaurant": "Fruit de mer",
    "subcategories.italianRestaurant": "Italien",
    "subcategories.burgerRestaurant": "Des hamburgers",
    "subcategories.menHairSalon": "Messieurs",
    "subcategories.kidsHairSalon": "Enfants",
    "subcategories.womenHairSalon": "Femmes",
    "subcategories.gadgets": "Gadgets",
    "subcategories.vegetarianRestaurant": "Végétarien",
    "subcategories.mediterraneanRestaurant": "Méditerranéen",
    "subcategories.fishMarket": "Marché aux poissons",
    "subcategories.imker": "Apiculteur",
    "subcategories.makeup": "Se maquiller",
    "subcategories.barbeque": "Barbecue",
    "subcategories.bubble_tea": "Thé aux bulles",
    "subcategories.african": "Africain",
    "subcategories.hair_treatments": "Traitements capillaires",
    "subcategories.hair_removal": "Épilation",
    "subcategories.teeth_whitening": "Blanchiment des dents",
    "subcategories.acupuncture": "Acupuncture",
    "subcategories.nail_salon": "Salon de manucure",
    "subcategories.therapies": "Thérapies",
    "subcategories.meat": "Viande",
    "subcategories.buffet": "Buffets",
    "subcategories.fried": "Friture",
    "subcategories.fast_food": "Restauration rapide",
    "subcategories.breakfast_brunch": "Petit déjeuner | brunch",
    "subcategories.bistro_imbiss": "Bistrot | collation",
    "subcategories.manicure_pedicure": "Manucures et Pédicures",
    "subcategories.facial_treatments": "Soins du visage",
    "subcategories.cosmetic_supply": "Produits cosmétiques",
    "subcategories.beverage_market": "Marché des boissons",
    "subcategories.dairyGrocery": "Laitier",
    "subcategories.vegetableGrocery": "Légumes",
    "subcategories.dessertBar": "Desserts",
    "subcategories.crepes": "Crêpes",
    "subcategories.waffles": "Gaufres",
    "subcategories.juices": "Jus",
    "subcategories.tea_house": "Salon de thé",
    "subcategories.sweet_pastry": "Pâtisseries sucrées",
    "subcategories.candy_shop": "Magasin de bonbons",
    "subcategories.dairyFarmShop": "Laitier",
    "subcategories.fruit_vegetable": "Fruits et légumes",
    "subcategories.eggs": "Œufs",
    "subcategories.bio_organic": "Bio & Bio",
    "subcategories.scooping": "Ventouses",
    "subcategories.indian_grocery": "Indien",
    "subcategories.imported_grocery": "Importer",
    "subcategories.spaWellness": "Spa et bien-être",
    "subcategories.massageCenter": "Massages",
    "subcategories.gifts": "Cadeaux",
    "subcategories.cafe": "Café",
    "subcategories.iceCreamParlor": "Glacier",
    "subcategories.bakeryCafe": "Produits de boulangerie",
    "subcategories.sportsCenter": "Sport",
    "subcategories.kidsPlayArea": "Parc de jeux pour enfants",
    "subcategories.art": "Art",
    "subcategories.cinema": "Cinéma",
    "subcategories.theater": "Théâtre",
    "subcategories.escape_room": "Salles d'évasion",
    "subcategories.climbing": "Grimper",
    "subcategories.musuem": "Musée",
    "subcategories.flowerShop": "Fleurs",
    "subcategories.giftShop": "Cadeaux",
    "subcategories.carRepair": "Réparation",
    "subcategories.carWash": "Laver",
    "subcategories.tireShop": "Pneus",
    "subcategories.furnitureStore": "Meubles",
    "subcategories.gardenCenter": "Jardin",
    "subcategories.clothingStore": "Magasin de vêtements",
    "subcategories.electronicsStore": "Électronique",
    "subcategories.petStore": "Fournitures pour animaux de compagnie",
    "subcategories.zooStore": "Fournitures pour zoo",
    "subcategories.bookstore": "Livres et revues",
    "subcategories.cosmetic": "Produits de beauté",
    "keywords.oriental": "Oriental",
    "keywords.middleEastern": "Moyen-Orient",
    "keywords.arabic": "Arabe",
    "keywords.turkish": "Turc",
    "keywords.moroccan": "Marocain",
    "keywords.persian": "Persan",
    "keywords.butcher": "Boucher",
    "keywords.meat": "Viande",
    "keywords.sausage": "Saucisse",
    "keywords.meatShop": "Boucherie",
    "keywords.slaughterhouse": "Boucher",
    "keywords.bakery": "Boulangerie",
    "keywords.bread": "Pain",
    "keywords.pastry": "Pâtisseries",
    "keywords.roll": "Petits pains",
    "keywords.cake": "Gâteau",
    "keywords.patisserie": "Pâtisserie",
    "keywords.asian": "Asiatique",
    "keywords.chinese": "Chinois",
    "keywords.japanese": "Japonais",
    "keywords.thai": "Thaïlandais",
    "keywords.vietnamese": "Vietnamien",
    "keywords.korean": "Coréen",
    "keywords.indian": "Indien",
    "keywords.farmShop": "Boutique de la ferme",
    "keywords.farmersMarket": "Marché fermier",
    "keywords.regional": "Régional",
    "keywords.organic": "Organique",
    "keywords.fresh": "Frais",
    "keywords.agricultural": "Agricole",
    "keywords.halal": "Halal",
    "keywords.halalCertified": "Certifié Halal",
    "keywords.islamic": "Islamique",
    "keywords.muslim": "Musulman",
    "keywords.doner": "Döner kebab",
    "keywords.sushi": "Sushis",
    "keywords.fish": "Poisson",
    "keywords.seafood": "Fruit de mer",
    "keywords.salmon": "Saumon",
    "keywords.shrimp": "Crevette",
    "keywords.italian": "Italien",
    "keywords.pizza": "Pizza",
    "keywords.pasta": "Pâtes",
    "keywords.risotto": "Risotto",
    "keywords.tiramisu": "Tiramisu",
    "keywords.antipasti": "Antipasti",
    "keywords.burger": "Citoyens",
    "keywords.hamburger": "Hamburger",
    "keywords.cheeseburger": "Hamburgers au fromage",
    "keywords.fries": "Frise",
    "keywords.fastFood": "Restauration rapide",
    "keywords.menHairdresser": "Coiffeur pour hommes",
    "keywords.menSalon": "Salon pour hommes",
    "keywords.barber": "Coiffeur",
    "keywords.shaving": "Raser",
    "keywords.haircutForMen": "Coupe de cheveux pour hommes",
    "keywords.kidsHairdresser": "Coiffeuse pour enfants",
    "keywords.kidCut": "Coupe enfant",
    "keywords.kidHaircut": "Coupe de cheveux pour enfants",
    "keywords.haircutForKids": "Coupe de cheveux pour les enfants",
    "keywords.kidStyling": "Style pour enfants",
    "keywords.womenHairdresser": "Coiffeuse pour femmes",
    "keywords.womenSalon": "Salon des dames",
    "keywords.haircutForWomen": "Coupe de cheveux pour les femmes",
    "keywords.dyeing": "Teinture",
    "keywords.stylingForWomen": "Stylisme pour les femmes",
    "keywords.technology": "Technologie",
    "keywords.electronics": "Électronique",
    "keywords.devices": "Appareils",
    "keywords.accessories": "Accessoires",
    "units.km": "Km",
    "open_24_hours": "Ouvert 24 heures",
    "favorites_and_bookings": "Favoris et réservations",
    "no_favorites": "Aucun favori disponible !",
    "no_favorites_text": "Vous n'avez pas encore ajouté de favoris. Parcourez vos magasins locaux et enregistrez-les comme favoris pour un accès plus facile plus tard !",
    "no_coupons": "Aucun coupon disponible !",
    "no_coupons_text": "Vous n'avez encore activé aucun coupon. Découvrez et activez les coupons pour profiter de réductions !",
    "onboardingTutorial_title_step1": "Découvrir",
    "onboardingTutorial_description_step1": "Découvrez le monde du commerce local, qu'il s'agisse de restaurants, supermarchés, coiffeurs, salons de beauté, ateliers et bien plus encore !",
    "onboardingTutorial_imageCaption_step1": "Tout est là, tout est proche.",
    "onboardingTutorial_title_step2": "Racheter",
    "onboardingTutorial_description_step2": "Nos partenaires commerciaux locaux vous invitent à bénéficier de remises directes et de cadeaux pour des produits ou services.",
    "onboardingTutorial_imageCaption_step2": "Soutenir le local et économiser de l'argent n'est pas possible ? Mais!",
    "onboardingTutorial_title_step3": "Sauvegarder",
    "onboardingTutorial_description_step3": "Toutes les offres à portée de main dans votre poche. Vous présentez simplement votre réservation à l'employé du magasin. C’est ainsi que nous soutenons le commerce local !",
    "onboardingTutorial_imageCaption_step3": "Rapide et facile pour tout le monde.",
    "confirm": "Confirmer",
    "start_now": "Commencez maintenant",
    "next": "Plus loin",
    "submit": "Soumettre",
    "company_name_required": "Le nom de l'entreprise est requis",
    "company_email_required": "L'adresse e-mail de l'entreprise est requise",
    "invalid_email": "Adresse e-mail invalide",
    "invalid_zipCode": "Code postal invalide",
    "company_address_required": "L'adresse de l'entreprise est requise",
    "company_zipCode_required": "Le code postal est requis",
    "company_city_required": "La ville est obligatoire",
    "company_country_required": "Le pays est requis",
    "company_phone_required": "Le téléphone de l'entreprise est requis",
    "invalid_phone": "Numéro de téléphone invalide",
    "placeholder_company_name": "Entrez le nom de l'entreprise",
    "placeholder_company_email": "Entrez l'e-mail de l'entreprise",
    "placeholder_company_address": "Entrez la rue et le numéro de la maison",
    "placeholder_company_zipCode": "Entrez le code postal",
    "placeholder_company_city": "Entrez la ville",
    "placeholder_company_country": "Entrez le pays",
    "placeholder_company_phone": "Entrez le numéro de téléphone",
    "placeholder_company_pocName": "Saisissez le prénom et le nom de la personne à contacter",
    "placeholder_company_pocRole": "Sélectionnez le rôle de la personne de contact",
    "no_stores": "Aucune entreprise créée",
    "no_stores_text": "Vous n'avez pas encore ajouté de magasin. Cliquez ici et créez votre boutique dès maintenant en un éclair pour pouvoir proposer des coupons.",
    "more_stores": "Avez-vous une autre succursale?",
    "more_stores_text": "Cliquez ici pour ajouter un autre magasin où vous pouvez également créer des coupons.",
    "stores_of_company": "Succursales de votre entreprise",
    "vendor_poc_roles.owner": "Propriétaire",
    "vendor_poc_roles.employee": "Employé",
    "vendor_poc_roles.else": "Autre",
    "coupon.ON_SITE": "Sur place",
    "coupon.TO_GO": "Aller",
    "coupon.NINETY_DAYS": "90 jours",
    "coupon.THIRTY_DAYS": "30 jours",
    "coupon.WEEKLY": "Hebdomadaire",
    "coupon.ONE_YEAR": "Annuel",
    "coupon.DAILY": "Tous les jours",
    "coupon.THREE_DAYS": "3 jours",
    "coupon.HUNDRED_EIGHTY_DAYS": "180 jours",
    "paywall.price_description_yearly": "Paiement annuel",
    "paywall.price_description_monthly": "Paiements mensuels",
    "monetization.paywall_title": "Toutes les offres. Enregistrez immédiatement.",
    "monetization.maybe_later": "Peut-être plus tard",
    "monetization.monthly": "Mensuel",
    "monetization.yearly": "Annuel",
    "monetization.monthly_marketing.first_benefit": "Cela vaut généralement le coup pour seulement 1 à 2 achats !",
    "monetization.monthly_marketing.second_benefit": "Peut être annulé mensuellement!",
    "monetization.monthly_marketing.third_benefit": "Échangez tous les coupons immédiatement.",
    "monetization.yearly_marketing.first_benefit": "Cela vaut le coup pour seulement 3 à 4 achats par an !",
    "monetization.yearly_marketing.second_benefit": "Payez une fois, profitez pendant un an !",
    "monetization.yearly_marketing.third_benefit": "Échangez tous les coupons immédiatement.",
    "monetization.price_description_effective_per_month": "Effectif par mois",
    "monetization.price_description_per_month": "Par mois",
    "monetization.confirmation_title": "Achat réussi !",
    "monetization.confirmation_message": "Merci pour votre achat. Vous avez désormais accès à toutes les fonctionnalités premium.",
    "validation.setProductName": "Le nom du produit doit être défini.",
    "for": "Pour",
    "coupon_factory.2for1_title": "Achetez-en 2, obtenez-en 1 gratuitement",
    "coupon_factory.2for1_description": "Ou même plus. Créer une offre de quantité",
    "coupon_factory.win_customers_and_money": "Gagnez des clients et des ventes.",
    "coupon_factory.example": "Exemple de coupon",
    "coupon_factory.create_now": "Créer maintenant",
    "coupon_factory.create_coupon_marketing_header": "Obtenez de nouveaux clients gratuitement dès maintenant",
    "coupon_factory.create_coupon_marketing_body": "Créez votre coupon en quelques étapes seulement.",
    "coupon_factory.create_coupon_benefit_header_1": "🎯 Nouveaux clients",
    "coupon_factory.create_coupon_benefit_body_1": "Touchez de nouveaux clients directement via votre smartphone",
    "coupon_factory.create_coupon_benefit_header_2": "🚀 Facile et rapide",
    "coupon_factory.create_coupon_benefit_body_2": "Créez des offres plus facilement que jamais.",
    "coupon_factory.create_coupon_benefit_header_3": "💸 Plus de ventes",
    "coupon_factory.create_coupon_benefit_body_3": "Augmentez vos ventes avec des coupons pour vos clients.",
    "coupon_factory.create_coupon_card": "Créez un coupon !",
    "coupon_factory.create_coupon_card_info": "Cliquez ici pour créer un coupon en 3 étapes.",
    "coupon_factory.create_coupon": "Créez un coupon !",
    "coupon_factory.create_coupon_body": "Trois étapes pour perfectionner la publicité auprès des nouveaux clients !",
    "coupon_factory.condition_for_customer": "Définir une condition pour les clients",
    "coupon_factory.choose_condition": "Sélectionner une condition",
    "coupon_factory.choose_condition_info": "Ce que le client doit faire pour en bénéficier",
    "coupon_factory.minimum_order_value": "Valeur minimale de commande",
    "coupon_factory.minimum_order_value_info": "Le client doit dépenser un certain montant",
    "coupon_factory.product_purchase": "Achat de produit",
    "coupon_factory.product_purchase_info": "Le client doit acheter certains produits",
    "coupon_factory.direct_rebate": "Remise directe sur le produit",
    "coupon_factory.no_conditions": "Aucune condition",
    "coupon_factory.no_conditions_set": "Aucune condition définie",
    "coupon_factory.go_to_next_step": "Passer à l'étape suivante",
    "coupon_factory.input_mov": "Spécifiez la valeur d'achat minimale",
    "coupon_factory.count_of_products": "Nombre de produits",
    "coupon_factory.product_name": "Nom du produit",
    "coupon_factory.value_of_product_per_piece": "Valeur du produit (par pièce)",
    "coupon_factory.order_discount": "Remise à l'achat",
    "coupon_factory.product_discount": "Remise sur un produit",
    "coupon_factory.free_product": "Produit gratuit",
    "coupon_factory.discount": "Rabais",
    "coupon_factory.enter_discount_amount": "Entrez le montant de la remise",
    "coupon_factory.redeem_location_conditons": "Conditions de réduction",
    "coupon_factory.redeem_location_info": "Où vos clients peuvent-ils bénéficier de la réduction ?",
    "coupon_factory.choose_discount": "Rabais",
    "coupon_factory.choose_discount_info": "De quel type de remise votre client bénéficie-t-il ?",
    "coupon_factory.carousel_header_one": "Sélectionnez le coupon",
    "coupon_factory.carousel_body_one": "Sélectionnez le coupon que vous souhaitez créer.",
    "coupon_factory.carousel_header_two": "Définir la condition",
    "coupon_factory.carousel_body_two": "Déterminez ce que le client doit faire.",
    "coupon_factory.carousel_header_three": "Définir les avantages",
    "coupon_factory.carousel_body_three": "Déterminez ce que le client recevra.",
    "coupon_factory.product_quantitiy": "Quantité par client",
    "coupon_factory.product_quantitiy_info": "La remise s'applique par pièce !",
    "coupon_factory.product_quantitiy_free_product_info": "Combien de pièces obtient-il GRATUITEMENT ?",
    "coupon_factory.same_product": "Même produit",
    "coupon_factory.same_product_info": "Le client reçoit le même produit.",
    "coupon_factory.other_product": "Produit différent",
    "coupon_factory.other_product_info": "Veuillez fournir le nom du produit.",
    "coupon_factory.price_per_unit": "Prix ​​par pièce",
    "coupon_factory.error_invalid_input": "Entrée invalide",
    "coupon_factory.error_rebate_higher_as_order_value": "La remise ne peut pas être supérieure au montant de l'achat/du produit",
    "coupon_factory.error_rebate_higher_as_product_value": "La remise ne peut pas être supérieure au montant de l'achat/du produit",
    "coupon_factory.max_created_coupon_card": "Nombre maximum de coupons atteint",
    "coupon_factory.max_created_coupon_card_info": "Vous avez atteint le maximum actuel de vos coupons.",
    "coupon_factory.max_created_stores": "Nombre maximum de magasins atteint",
    "coupon_factory.max_created_stores_info": "Vous avez atteint le maximum actuel de transactions pouvant être créées. Si vous souhaitez créer une autre boutique, merci de nous contacter via le formulaire de contact dans les paramètres.",
    "details": "Détails",
    "enter_details": "Fournir des détails",
    "store_factory.store_conflict": "Un problème est survenu avec le magasin",
    "store_factory.store_conflict_info": "La boutique sélectionnée sur Google a déjà été enregistrée sur Bazaar. Veuillez vérifier une fois. Si vous rencontrez toujours des problèmes, n'hésitez pas à nous contacter.",
    "store_factory.wrong_store": "Mauvaise entreprise choisie ?",
    "store_factory.wrong_store_search_again": "Voulez-vous redémarrer ? Veuillez noter que vos informations sur Google doivent être correctes.",
    "store_factory.restart": "Redémarrage",
    "store_factory.what_customers_see": "C'est ce que voient vos clients",
    "store_factory.check_details": "Vérifiez si vos informations sont correctes.",
    "store_factory.store_name": "Nom de la succursale",
    "store_factory.store_name_example": "Nom de votre entreprise",
    "store_factory.store_phone": "Numéro de téléphone de vos clients",
    "store_factory.store_email": "E-mail pour vos clients",
    "store_factory.choose_category": "Sélectionnez la catégorie",
    "store_factory.choose_category_info": "Choisissez une catégorie principale, jusqu'à 3 sous-catégories et téléchargez une image de votre boutique.",
    "store_factory.main_category": "Catégorie principale",
    "store_factory.sub_categories": "Sous-catégories",
    "store_factory.upload_store_img": "Télécharger l'image du magasin",
    "store_factory.store_img": "Photo de la boutique",
    "store_factory.max_achieved": "Maximum atteint",
    "store_factory.sub_categories_limit": "Vous pouvez sélectionner un maximum de 3 sous-catégories.",
    "store_factory.categories_and_image_required": "Les catégories et les images sont nécessaires !",
    "store_factory.zip_code": "Code Postal",
    "store_factory.zip_code_placeholder": "Par ex. 44801",
    "store_factory.claim_store": "Reprendre l'entreprise",
    "store_factory.house_number": "Numéro de maison",
    "store_factory.house_number_placeholder": "Par ex. 33",
    "store_factory.street": "Rue",
    "store_factory.street_placeholder": "Par ex. Rue du Bazar",
    "store_factory.city": "Ville",
    "store_factory.city_placeholder": "Par ex. Bochum",
    "store_factory.success": "Boutique créée avec succès",
    "store_factory.success_info": "Votre boutique a été créée avec succès et est en cours de vérification.",
    "store_factory.success_verification": "La vérification prend généralement 1 à 2 jours ouvrables. Une fois la vérification terminée, votre boutique sera mise en ligne et sera visible par les clients.",
    "store_factory.verification_pending": "Vérification en attente",
    "store_factory.verification_pending_info": "Un processus de vérification est actuellement en cours. Nous vous contacterons si nous avons besoin de plus d'informations de votre part. Si vous avez des questions, n'hésitez pas à nous contacter.",
    "store_factory.no_coupons": "Pas encore de coupons",
    "store_factory.no_coupons_info": "Vous n'avez pas encore créé de coupons. Cliquez sur le magasin pour créer un coupon.",
    "to_dashboard": "Vers le tableau de bord",
    "error_unexpected": "Une erreur inattendue s'est produite. Essayer à nouveau. Si le problème persiste, n'hésitez pas à nous contacter.",
    "check_inputs": "Vérifier les informations",
    "warning": "Danger",
    "progress_deleted_info": "Votre statut provisoire sera supprimé si vous revenez ici. Es-tu sûr?",
    "notification": "Notification",
    "notifications": "Notifications",
    "request_notification_permission": "Activer les notifications push ?",
    "denied_notification_permission": "Notifications push rejetées",
    "denied": "Refusé",
    "activate_notification_in_settings": "Vous pouvez activer les notifications push dans Paramètres",
    "notifcationsTitle.message1": "🥳 C'est l'heure des réductions locales !",
    "notifcationsBody.message1": "Économisez maintenant avec des coupons exclusifs pour les magasins locaux ! Qu'il s'agisse de nourriture, de mode ou de loisirs, découvrez des réductions exceptionnelles et soutenez votre communauté !"
  },
  "ar": {
    "!add_row_below": "VVV أضف سطرًا أسفل VVV",
    "so_easy_for_partners": "الأمر بهذه السهولة بالنسبة للشركاء",
    "so_easy_for_partners.register_store": "سجل عملك بسرعة وسهولة للوصول إلى المزيد من العملاء.",
    "so_easy_for_partners.create_coupon": "قم بإنشاء قسائم فردية لعملائك، وقم بزيادة المبيعات وكسب عملاء جدد – كل شيء حسب رغباتك.",
    "so_easy_for_partners.manage_store": "قم بإدارة متجرك بسهولة - اضبط أوقات العمل والصور والقسائم حسب الحاجة في أي وقت.",
    "change_language": "تغيير اللغة",
    "english_USA": "🇬🇧 الإنجليزية",
    "german_Germany": "🇩🇪 ألماني",
    "Imprint": "بصمة",
    "Datenschutz": "سياسة الخصوصية",
    "AGB": "الشروط والأحكام العامة للأعمال",
    "Cookies": "ملفات تعريف الارتباط",
    "about": "معلومات عنا",
    "our_magazine": "مجلتنا",
    "for_companies": "للشركات",
    "contact": "اتصال",
    "no_answer_found": "سؤال لم تتم الإجابة عليه؟",
    "jobs": "وظائف",
    "become_partner": "كن شريكا",
    "shop": "محل",
    "experiences": "الخبرات",
    "legal": "قانوني",
    "social_media": "وسائل التواصل الاجتماعي",
    "frequently_asked_questions": "التعليمات",
    "vendor_faq.how_work_question": "كيف يعمل BASAR للشركاء؟",
    "vendor_faq.how_work_answer": "لقد عقدنا شراكة مع العديد من الشركات المحلية وتفاوضنا على عروض فريدة مثل خدمات 2 مقابل 1 أو خصومات على المنتجات. يتيح لك تطبيق Bazaar عرض هذه العروض وحجزها واسترداد قيمتها عبر العديد من الصناعات مثل المطاعم ومصففي الشعر وورش العمل ومحلات الزهور وغيرها الكثير.",
    "vendor_faq.participating_businesses_question": "ما هي الشركات المشاركة؟",
    "vendor_faq.participating_businesses_answer": "تشارك آلاف الشركات المحلية حاليًا بمجموعة متنوعة من العروض الحصرية. من فن الطهي إلى الخدمات إلى المنتجات، ستجد عروضًا رائعة في العديد من الفئات.",
    "vendor_faq.app_free_question": "هل التطبيق مجاني حاليا؟",
    "vendor_faq.app_free_answer": "نعم، تطبيق BASAR حاليًا مجاني تمامًا. يمكن للشركاء المشاركة وتقديم العروض دون أي رسوم.",
    "vendor_faq.create_store_question": "كيفية إنشاء متجر على BASAR؟",
    "vendor_faq.create_store_answer": "إن إنشاء متجرك على BASAR أمر سهل وسريع. يمكنك إعداد ملف تعريف عملك ببضع نقرات مباشرة في التطبيق.",
    "vendor_faq.manage_coupons_question": "هل يمكنني إنشاء وإدارة القسائم الخاصة بي؟",
    "vendor_faq.manage_coupons_answer": "نعم، لديك السيطرة الكاملة على إنشاء وحذف القسائم الخاصة بك. قم بتعيين الخصومات والعروض والشروط التي تناسب عملك. لا يوجد مديرو مبيعات يزعجون عملك وتسويقك.",
    "faq.general.category": "عام",
    "faq.general.questions[0].question": "ما هو مفهوم البازار؟",
    "faq.general.questions[0].answer": "لقد عقدنا شراكة مع العديد من الشركات المحلية وتفاوضنا على قسائم تمهيدية حصرية مثل: عروض B.2 مقابل 1 أو الخصومات على المنتجات. باستخدام تطبيق Bazaar، يمكنك بسهولة تصفح هذه العروض وحجزها واسترداد قيمتها عبر مجموعة متنوعة من الصناعات مثل المطاعم وصالونات تصفيف الشعر وورش العمل ومحلات الزهور والمزيد.",
    "faq.general.questions[1].question": "ما هي الشركات المشاركة؟",
    "faq.general.questions[1].answer": "تشارك آلاف الشركات المحلية حاليًا بمجموعة متنوعة من العروض الحصرية. من الطعام إلى الخدمات والمنتجات، ستجد صفقات رائعة في العديد من الفئات.",
    "start": "يبدأ",
    "price": "سعر",
    "redeem_error_notToday_title": "القسيمة غير قابلة للاسترداد اليوم.",
    "redeem_error_notToday_message": "لا يمكن استرداد القسيمة إلا في الأيام المحددة.",
    "redeemDays.monday": "شهر",
    "redeemDays.tuesday": "يفعل",
    "redeemDays.wednesday": "نحن",
    "redeemDays.thursday": "ذ",
    "redeemDays.friday": "آنسة",
    "redeemDays.saturday": "قعد",
    "redeemDays.sunday": "سو",
    "coupon_factory.your_coupon_active": "قسيمتك نشطة الآن وستكون مرئية لعملائك قريبًا!",
    "coupon_factory.valid_days_header": "أيام صالحة",
    "coupon_factory.valid_days_subHeader": "الأيام التي يُسمح فيها للعملاء باسترداد القسائم.",
    "error_messages.something_wrong": "حدث خطأ ما - يرجى المحاولة مرة أخرى لاحقًا أو الاتصال بنا إذا استمرت المشكلة.",
    "store_factory.store_not_found_input_manual": "لم يتم العثور على المتجر؟ إدخال العنوان يدويا.",
    "sent_review": "إرسال المراجعة.",
    "review_your_visit_at": "قيم زيارتك@",
    "search": "يبحث",
    "faq_title": "الأسئلة الشائعة (الأسئلة المتداولة)",
    "faq_title_reverse": "الأسئلة المتداولة (الأسئلة الشائعة)",
    "vendor_faq_question_concept": "كيف يعمل باسار؟",
    "vendor_faq_answer_concept": "مفهوم BASAR بسيط وفعال. باعتبارك شخصًا يعمل لحسابه الخاص ولديه أعمال تجارية، يمكنك استخدام تطبيق BASAR Business لتقديم متجرك وإنشاء كوبونات. يمكن للمستخدمين العثور على هذه القسائم عبر تطبيق المستخدم BASAR واستردادها مباشرة في متجرك. وبهذه الطريقة، نقوم بتعزيز الشركات المحلية في المنافسة ضد سلاسل البيع بالتجزئة الكبيرة.",
    "vendor_faq_question_costs": "هل هناك أي تكاليف بالنسبة لي؟",
    "vendor_faq_answer_costs": "لا، BASAR مجاني تمامًا لتجار التجزئة. يشترك المستخدمون للوصول إلى جميع القسائم. يتيح لنا ذلك تغطية تكاليف النظام الأساسي وتمكين الشركات المحلية من المنافسة - حتى ضد السلاسل الكبيرة التي تستثمر في برامج التسويق وولاء العملاء باهظة الثمن.",
    "vendor_faq_question_minium_requirements": "ما هي المتطلبات التي يجب علي تلبيتها؟",
    "vendor_faq_answer_minium_requirements": "أنت بحاجة إلى متجر فعلي حيث يمكنك خدمة العملاء في الموقع - على سبيل المثال. ب- مطعم أو كوافير أو ورشة أو ما شابه ذلك. بالإضافة إلى ذلك، يجب أن تكون أنت أو موظفيك قادرين على منح الخصومات الموعودة بسهولة عند الخروج.",
    "vendor_faq_question_interface_cashier": "هل يجب علي دمج السجل النقدي الخاص بي؟",
    "vendor_faq_answer_interface_cashier": "لا، يعمل BASAR بسهولة شديدة باستخدام مبدأ العيون الأربع الكلاسيكي. يعرض لك العميل القسيمة المستردة على هاتفه الذكي وتمنحه الخصم. ليست هناك حاجة إلى عمليات تكامل مكلفة أو معقدة لنظام نقاط البيع. الشيء الوحيد المهم هو أن تتمكن أنت أو موظفيك من تنفيذ الخصومات بشكل صحيح.",
    "vendor_faq_question_shop_creation": "كيف أقوم بإنشاء متجري؟",
    "vendor_faq_answer_shop_creation": "يمكنك إنشاء متجرك في علامة التبويب \"المتجر\". ابحث عن عملك في قاعدة بياناتنا أو أنشئه يدويًا. أضف معلومات الاتصال ومواعيد العمل والصورة والفئة المناسبة. سوف نتحقق من المعلومات ونتصل بك إذا كانت لديك أي أسئلة. عادةً ما يستغرق التحقق يوم عمل واحد - وبعد ذلك يمكنك إنشاء كوبونات على الفور.",
    "vendor_faq_question_coupons": "كيف يمكنني إنشاء كوبونات؟",
    "vendor_faq_answer_coupons": "بعد إنشاء متجرك، يمكنك بدء مصنع الكوبونات في علامة التبويب \"الكوبونات\". ستجد هناك قوالب جاهزة يمكنك إضافة معلوماتك إليها بسرعة وسهولة. بعد المعاينة، يمكنك إنشاء القسيمة وسيتم نشرها خلال فترة زمنية قصيرة. المتطلبات: يجب التحقق من متجرك.",
    "vendor_faq_question_storeinfos_update": "كيف أقوم بتحديث معلومات عملي؟",
    "vendor_faq_answer_storeinfos_update": "انتقل إلى متجرك وانقر على علامة التبويب \"المعلومات\". هناك يمكنك تحديث معلومات الاتصال ومواعيد العمل والصور باستخدام أزرار التحرير المقابلة.",
    "vendor_faq_question_abuse": "كيف يمنع BASAR الاسترداد المتعدد للقسيمة؟",
    "vendor_faq_answer_abuse": "يقوم كل مستخدم بالتسجيل باستخدام رقم هاتفه وبالتالي يمكن التعرف عليه بوضوح. عند إنشاء قسيمة، يمكنك تحديد عدد المرات التي يمكن فيها استرداد القسيمة، على سبيل المثال. ب. فقط كل 90 يومًا. بهذه الطريقة يظل الاستخدام عادلاً وخاضعًا للرقابة.",
    "vendor_faq_question_help_creation": "هل ستساعدنا في إنشاء المتجر أو الكوبونات؟",
    "vendor_faq_answer_help_creation": "بالطبع! يسعدنا مساعدتك إذا كنت بحاجة إلى مساعدة في إعداد متجرك أو كوبوناتك. يمكنك التواصل معنا في أي وقت عبر الواتساب. يمكنك العثور على الرقم في الإعدادات ضمن \"الملاحظات والدعم\".",
    "vendor_faq_question_how_to_contact": "كيف يمكنني الوصول إليك؟",
    "vendor_faq_answer_how_to_contact": "يمكنك التواصل معنا عبر البريد الإلكتروني basar@basar-digital.com أو مباشرة عبر WhatsApp. يمكنك العثور على رقم WhatsApp في الإعدادات ضمن \"الملاحظات والدعم\".",
    "create_store_and_coupons": "إنشاء متجرك وكوبونات",
    "click_here_for_help": "انقر هنا إذا كنت بحاجة إلى مزيد من المعلومات.",
    "vendorOnboardingTutorial_title_step1": "بحث وإنشاء متجرك الخاص.",
    "vendorOnboardingTutorial_description_step1": "ابحث عن عملك باستخدام وظيفة البحث أو ببساطة قم بإنشائه بنفسك. أضف جميع المعلومات المهمة مثل جهات الاتصال ومواعيد العمل والصور والفئات.",
    "vendorOnboardingTutorial_imageCaption_step1": "متجرك، بدايتك: ببضع نقرات فقط، ستكون مرئيًا لعملائك - بغض النظر عما إذا كنت تضيف متجرًا موجودًا أو تنشئ متجرًا جديدًا.",
    "vendorOnboardingTutorial_title_step2": "إنشاء كوبونات لا تقاوم.",
    "vendorOnboardingTutorial_description_step2": "استخدم مصنع القسيمة سهل الاستخدام الخاص بنا لإنشاء عروض مخصصة. أسعد العملاء بخصومات حصرية وأضف زخمًا جديدًا إلى عملك.",
    "vendorOnboardingTutorial_imageCaption_step2": "مع مصنع القسيمة، يمكنك إبهار العملاء: إنشاء عروض فردية في ثوانٍ معدودة وتعزيز ولاء عملائك.",
    "vendorOnboardingTutorial_title_step3": "نتطلع إلى العملاء المتحمسين.",
    "vendorOnboardingTutorial_description_step3": "من خلال عروضك، يمكنك جذب عملاء جدد ومفاجأة عملائك الدائمين. يمكن للعملاء العثور على متجرك وكوبوناتك مباشرة في تطبيق مستخدم BASAR، ويمكنك استرداد القسائم في الموقع ويمكنك تطبيق الخصم مباشرة عند الخروج.",
    "vendorOnboardingTutorial_imageCaption_step3": "عملك، فرحتهم: المزيد من العملاء، المزيد من المبيعات - مع تطبيق المستخدم BASAR، سيكون متجرك هو الخيار الأول.",
    "category": "فئة",
    "loadings_favorites": "جاري تحميل المفضلة",
    "feature_so_great": "هذه الميزة رائعة جدًا لدرجة أنها ليست مجانية! سجل وابدأ.",
    "login_and_start": "تسجيل الدخول والبدء",
    "oops_something_missing": "عفوًا، لا يزال هناك شيء مفقود!",
    "coupon_factory": "مصنع القسيمة",
    "coupon_factory.infoHeader_name_of_product": "اسم المنتج",
    "coupon_factory.infoBody_name_of_product": "أدخل اسم المنتج أو الخدمة التي تريد أن يشتريها العميل.",
    "coupon_factory.infoHeader_value_of_product": "قيمة المنتج",
    "coupon_factory. infoBody_value_of_product": "ما هي تكلفة المنتج في متجرك؟",
    "coupon_factory.infoHeader_count_of_product": "عدد المنتجات",
    "coupon_factory.infoBody_count_of_product": "كم من هذا المنتج يجب أن يشتري.",
    "coupon_factory.infoHeader_minimum_order_volume": "الحد الأدنى لقيمة الطلب / الحد الأدنى لقيمة الشراء",
    "coupon_factory.infoBody_minimum_order_volume": "ما هو المبلغ الذي يجب أن ينفقه العميل على الأقل للاستفادة؟",
    "coupon_factory.infoHeader_name_of_benefit_product": "اسم المنتج المميز",
    "coupon_factory.infoBody_name_of_benefit_product": "أدخل اسم المنتج أو الخدمة التي سيحصل عليها العميل هنا.",
    "coupon_factory.infoHeader_value_of_benefit_product": "قيمة المنتج المميز",
    "coupon_factory.infoBody_value_of_benefit_product": "ما هي تكلفة المنتج عادة بدون سعر العرض؟",
    "coupon_factory.infoHeader_discount_amount": "تحديد مبلغ الخصم",
    "coupon_factory.infoBody_discount_amount": "كم هو الخصم لكل قطعة - باليورو أو٪.",
    "coupon_factory.infoHeader_order_discount_amount": "تحديد مبلغ الخصم",
    "coupon_factory.infoBody_order_discount_amount": "ما هو الخصم على الشراء أو الطلب - باليورو أو٪.",
    "delete_coupon": "حذف القسيمة",
    "generate": "يولد",
    "delete_coupon_text": "هل أنت متأكد أنك تريد حذف هذه القسيمة؟ لا يمكن التراجع عن هذا الإجراء.",
    "delete_coupon_info": "تنبيه: لا تزال قسيمتك نشطة حتى نهاية اليوم ولن تكون مرئية للمستخدمين اعتبارًا من اليوم التالي.",
    "validation_all_inputs_required": "يجب ملء جميع الحقول بشكل صحيح.",
    "store_factory.contact_and_opentimes": "الاتصال وساعات العمل",
    "store_factory.always_editable_later": "يمكنك دائمًا تعديله لاحقًا.",
    "choose_category_and_image": "حدد الصورة والفئة.",
    "store_factory.main_categories_limit": "حدد فئة.",
    "searchdata_openstreetmap": "بحث البيانات © OpenStreetMap",
    "success.opentimes_updated": "تم تحديث أوقات العمل بنجاح!",
    "success.contactdetails_updated": "تم تحديث تفاصيل الاتصال بنجاح!",
    "editcontact": "تحرير تفاصيل الاتصال",
    "from": "من",
    "until": "حتى",
    "weekday": "يوم",
    "open_questionmark": "يفتح؟",
    "coupon_factory.contact_if_issue": "يرجى التحقق من جميع المعلومات. إذا كنت لا تزال بحاجة إلى المساعدة، فلا تتردد في الاتصال بنا هنا",
    "respawn_time": "وقت العودة",
    "coupon_factory.coupon_in_three_steps": "قم بإنشاء قسيمة في 3 خطوات.",
    "store_factory.your_store_on_map": "عملك على الخريطة",
    "coupon_factory.infoHeader_need_help": "هل تحتاج إلى دعم؟",
    "coupon_factory.infoBody_need_help_whatsapp": "تواصل معنا مباشرة هنا عبر الواتساب.",
    "coupon_factory.check_preview": "معاينة القسيمة الخاصة بك.",
    "coupon_factory.preview": "معاينة",
    "coupon_factory.check_details": "هل كل المعلومات صحيحة؟ انقر فوق \"إنشاء\" لتفعيل قسيمتك!",
    "coupon_factory.respawn_time_description": "عندما يتمكن العميل من إعادة تنشيط القسيمة بعد استردادها. إذا لم تعد ترغب في تقديم عرض، فيمكنك حذفه بنفسك.",
    "coupon_factory.respawn_time_short_description": "المدة التي يتعين على العميل انتظارها قبل أن يتمكن من تفعيل القسيمة مرة أخرى.",
    "coupon_factory.thirty_days_recommendation": "يمكن للعميل استرداد القسيمة مرة أخرى بعد 30 يومًا. مثالية للعروض المتوفرة بانتظام وجذابة للعملاء العائدين.",
    "coupon_factory.ninety_days_recommendation": "يمكن استخدام القسيمة مرة أخرى بعد 90 يومًا. يعمل هذا الخيار بشكل جيد مع العروض الأطول التي تستهدف العملاء الجدد.",
    "coupon_factory.weekly_recommendation": "يمكن استرداد الكوبون مرة أخرى مرة واحدة في الأسبوع. مثالي للعروض الترويجية قصيرة المدى التي تحتاج إلى التجديد بانتظام.",
    "coupon_factory.thirty_days_recommendation_short": "مثالية للعروض المتاحة بانتظام.",
    "coupon_factory.ninety_days_recommendation_short": "مثالية لعروض العملاء الجدد النادرة.",
    "coupon_factory.weekly_recommendation_short": "مثالية للعروض الترويجية القصيرة.",
    "coupon_factory.mainly_for_restaurants": "عادة ما ينطبق فقط على فن الطهو.",
    "coupon_factory.default_set": "تعيين افتراضيا.",
    "coupon_factory.redeem_location_onsite_info": "صالحة إذا قام العميل بالشراء في المتجر.",
    "coupon_factory.redeem_location_togo_info": "صالح إذا اشترى العميل شيئًا ليأخذه معه، على سبيل المثال. كباب ليأخذ بعيدا.",
    "coupon_factory.choose_coupon_type": "حدد أنواع القسيمة",
    "coupon_factory.choose_coupon_type_details": "حدد نوع القسيمة التي تريد إنشاءها لمتجرك.",
    "coupon_factory.example_header_one": "استرداد نقدي 10 يورو",
    "coupon_factory.example_body_one": "احصل على خصم 10 يورو بحد أدنى للإنفاق 50 يورو",
    "coupon_factory.example_headertitle_one": "استرداد النقود",
    "coupon_factory.example_subheader_one": "سيحصل عملاؤك الجدد على خصم مباشر على الشراء/الطلب.",
    "coupon_factory.example_header_two": "1 × هدية مشروب",
    "coupon_factory.example_body_two": "تحصل على 1x شوكولاتة مجانًا بدون حد أدنى لقيمة الطلب",
    "coupon_factory.example_headertitle_two": "هدية",
    "coupon_factory.example_subheader_two": "سيحصل عملاؤك الجدد على هدية عند شرائهم.",
    "coupon_factory.example_header_three": "2 ب 1 - بطاطا مقلية",
    "coupon_factory.example_body_three": "عند شرائك 1 × بطاطس تحصل على 1 × بطاطس مجانًا. ينطبق على المنتج بنفس السعر أو أرخص.",
    "coupon_factory.example_headertitle_three": "2 مقابل 1",
    "coupon_factory.example_subheader_three": "يحصل عملاؤك الجدد على منتجين بسعر منتج واحد.",
    "coupon_factory.example_header_four": "خصم مباشر 50% على السترات",
    "coupon_factory.example_body_four": "سوف تحصل على 1 × باقة من الزهور مع خصم 50% على كل منها بدون حد أدنى لقيمة الطلب.",
    "coupon_factory.example_headertitle_four": "أُوكَازيُون",
    "coupon_factory.example_subheader_four": "يحصل عملاؤك على خصم مباشر على منتج معين.",
    "coupon_factory.example_header_individual": "بشكل فردي",
    "coupon_factory.example_body_individual": "صفقة فردية",
    "coupon_factory.example_headertitle_individual": "بشكل فردي",
    "coupon_factory.example_subheader_individual": "قم بإنشاء قسيمة فردية تمامًا لعملائك.",
    "welcome": "مرحباً",
    "languages.de": "🇩🇪 ألماني",
    "languages.en": "🇺🇸الإنجليزية",
    "languages.tr": "🇹🇷 تركي",
    "languages.ar": "🇸🇦 عربي",
    "languages.fr": "🇫🇷 فرنسية",
    "languages.es": "🇪🇸الإسبانية",
    "languages.it": "🇮🇹 ايطالي",
    "languages.pl": "🇵🇱بولندية",
    "languages.ru": "🇷🇺روسي",
    "popular_categories": "الفئات الشعبية",
    "no_matching_categories_found": "لم يتم العثور على فئات مطابقة",
    "hello": "مرحبا من ألمانيا",
    "with_coupons": "مع كوبونات",
    "cities_with_digital_basar": "مدن بها بازار رقمي",
    "use_your_location": "استخدم موقعك",
    "no_matching_cities_found": "لم يتم العثور على مدن مطابقة",
    "discover": "يكتشف",
    "favorites": "المفضلة",
    "profile": "الملفات الشخصية",
    "delete": "يمسح",
    "openingTimes": "ساعات العمل",
    "editOpeningTimes": "تعديل ساعات العمل",
    "days.monday": "الاثنين",
    "days.tuesday": "الأربعاء",
    "days.wednesday": "الأربعاء",
    "days.thursday": "يوم الخميس",
    "days.friday": "جمعة",
    "days.saturday": "السبت",
    "days.sunday": "الأحد",
    "command_searchForYourBusiness": "ابحث عن عملك",
    "placeholder_find_store_google": "ابحث عن عملك على جوجل",
    "permission_to_camera_roll": "مطلوب الوصول إلى مكتبة الصور!",
    "businessMustBeOnGoogleMaps": "يجب أن يكون نشاطك التجاري قابلاً للاكتشاف على خرائط Google. إذا كان لديك أي مشاكل، فلا تتردد في الاتصال بنا هنا.",
    "closed": "مغلق",
    "location": "موقع",
    "coupons": "كوبونات",
    "activated_coupons": "كوبونات مفعلة",
    "redeemed_coupons": "كوبونات مستردة",
    "activate": "فعل",
    "redeem_now": "استرد الآن",
    "coupon_redeemed_message": "تم استرداد القسيمة - استمر في ذلك! هذه هي الطريقة التي ندعم بها التجارة المحلية.",
    "coupon_redeemed_message_title": "تم استرداد القسيمة!",
    "coupon_redeemed_message_bodxy": "استمر! هذه هي الطريقة التي ندعم بها التجارة المحلية.",
    "share_message": "مرحبًا، أنا في البصرة. قم بتنزيل التطبيق باستخدام هذا الرابط.",
    "share_success": "شارك بنجاح!",
    "share_dismissed": "تم إلغاء المشاركة",
    "share_failed": "فشلت المشاركة:",
    "report_problem": "الإبلاغ عن مشكلة",
    "deactivate_coupon": "إلغاء تنشيط القسيمة",
    "info": "معلومات",
    "more": "أكثر",
    "Information about this Coupons": "معلومات حول هذه القسيمة",
    "command_mention_basar_for_redeem": "قبل الدفع، أذكر أن لديك قسيمة BASAR وقم باستردادها أمام أحد الموظفين. يمكن استبدال قسيمة واحدة فقط في كل زيارة.",
    "Redeem Location": "موقع الفداء",
    "redeem_location_info": "سواء كان العرض في الموقع أو ليأخذ بعيدا",
    "Reactivation time": "وقت إعادة التنشيط",
    "reactivation_info": "عندما يصبح العرض نشطًا مرة أخرى بالنسبة لك بعد استرداده.",
    "reviews": "التعليقات",
    "review": "التعليقات",
    "show_all_reviews": "عرض جميع التقييمات",
    "show_all": "عرض الكل",
    "REVIEW_SENT": "تم إرسال المراجعة.",
    "REVIEW_THANKS": "شكرا لمراجعتك!",
    "REVIEW_WRITE": "أكتب رأيك...",
    "loading": "تحميل",
    "noReviewsAvailable": "لا توجد تعليقات حتى الآن",
    "noStoreInformationAvailable": "لا توجد معلومات حتى الآن",
    "advetorials.vendor.become_vendor_title": "هل لديك متجر محلي في الموقع؟",
    "advetorials.vendor.become_vendor_body": "ثم كن تاجرًا على تطبيقنا الآن. لدينا العديد من المستخدمين الذين يرغبون في دعم عملك المحلي.",
    "advetorials.vendor.download_vendor_app": "قم بتنزيل تطبيق البيع بالتجزئة من متجر التطبيقات.",
    "become_Member": "كن عضوا",
    "enter_discount": "أدخل رمز الخصم",
    "translated_by_ai": "ربما تم إنشاء الترجمات باستخدام الذكاء الاصطناعي، وبالتالي فهي ليست مثالية دائمًا.",
    "closeButton": "يغلق",
    "edit_profile": "تحرير الملف الشخصي",
    "validation_invalid_input": "إدخال غير صالح",
    "validation_required_input": "يجب ملء الحقل",
    "success": "مؤكد",
    "error": "خطأ",
    "profile_update_success": "تم تحديث الملف الشخصي بنجاح!",
    "profile_image_update_success": "تم تحديث الصورة الشخصية بنجاح",
    "profile_image_update_error": "فشل تحديث صورة الملف الشخصي",
    "tutorial": "درس تعليمي",
    "settings": "إعدادات",
    "feedbackAndSupport": "ردود الفعل والدعم",
    "changeToVendorMode": "قم بالتبديل إلى وضع الموزع",
    "changeToUserMode": "التبديل إلى وضع العميل",
    "registerAsPartner": "سجل كشريك",
    "logout": "تسجيل الخروج",
    "first_name": "الاسم الأول",
    "last_name": "اسم العائلة",
    "email": "بريد إلكتروني",
    "zipCode": "الرمز البريدي",
    "edit": "يحرر",
    "delete_account": "حذف الملفات الشخصية",
    "delete_account_message": "هل أنت متأكد أنك تريد حذف ملف التعريف الخاص بك؟ سيتم فقدان جميع المزايا والإعدادات الخاصة بك إلى الأبد.",
    "delete_account_success": "تم حذف الملف الشخصي بنجاح",
    "delete_account_success_message": "لقد تم حذف ملف التعريف الخاص بك بنجاح. 👋 نأمل أن نرى بعضنا البعض مرة أخرى قريبًا",
    "save": "يحفظ",
    "back": "خبز",
    "continue": "يكمل",
    "later": "لاحقاً",
    "terms_and_conditions": "الشروط والأحكام العامة للأعمال",
    "agree_to_terms": "أوافق على <terms>الشروط والأحكام العامة</terms>.",
    "phonenumber": "رقم الهاتف",
    "enter_phonenumber": "أدخل رقم الهاتف",
    "login_no_password_info": "تسجيل دخول سهل عبر رمز الرسائل القصيرة. لذلك لا تحتاج إلى تذكر كلمة المرور.",
    "enter_code": "أدخل الرمز",
    "sent_to": "تم الإرسال إلى",
    "search_country_code": "البحث حسب رمز البلد أو المنطقة",
    "command_agree": "يرجى الموافقة",
    "command_agree_to_terms": "يجب عليك الموافقة على الشروط للمتابعة.",
    "otp": "كلمة المرور لمرة واحدة",
    "enter_otp": "أدخل كلمة المرور لمرة واحدة",
    "verify_otp": "تأكيد كلمة المرور لمرة واحدة",
    "otp_expired": "انتهت صلاحية كلمة المرور لمرة واحدة",
    "otp_incorrect": "كلمة المرور لمرة واحدة غير صحيحة",
    "otp_verification_error": "تم إدخال كلمة مرور لمرة واحدة غير صحيحة أو منتهية الصلاحية.",
    "command_create_profile_to_start": "قم بإنشاء ملف التعريف الخاص بك للبدء!",
    "command_create_company_to_start": "قم بتسجيل شركتك للبدء.",
    "info_create_shop_later": "يمكنك تقديم معلومات حول متجرك في الخطوة التالية.",
    "your_concern": "طلبك",
    "describe_your_concern": "أخبرنا طلبك.",
    "contact_you_asap": "سوف نتواصل معك في أسرع وقت ممكن.",
    "contact_via_whatsapp": "تواصل معنا عبر الواتساب",
    "change_image": "تغيير الصورة",
    "edit_store_profile": "تحرير الملف الشخصي للأعمال",
    "error_loading_store": "حدث خطأ أثناء تحميل بيانات العمل",
    "error_messages.no_stores.no_stores_nearby_found_title": "لم يتم العثور على متاجر بالقرب منك",
    "error_messages.no_stores.no_stores_nearby_found_body": "يبدو أننا لسنا سريعين بعد. لكننا سنصل إلى هناك - لا تقلق. قم بتغيير المدينة باستخدام البحث أو قم بالتمرير إليها باستخدام الخريطة.",
    "error_messages.conflict.store_exists_title": "لقد تم بالفعل تسجيل النشاط التجاري الموجود في هذا العنوان.",
    "error_messages.conflict.store_exists_body": "لا يمكنك إنشاء متجر مسجل بالفعل. إذا كنت تعتقد أن هذا خطأ، يرجى الاتصال بنا هنا.",
    "funny_error_message": "عفوًا، حدث خطأ ما.",
    "page_not_found": "لا يمكن تحميل الصفحة.",
    "page_not_found_connect_to_wifi": "يرجى التأكد من أنك متصل بالإنترنت.",
    "page_not_found_help_message": "إذا استمرت المشكلة، يرجى الاتصال بنا",
    "cancel": "يلغي",
    "partner_login": "تسجيل دخول الشريك",
    "partner_register": "تسجيل الشريك",
    "command_no_account_register": "لا يوجد حساب؟ سجل هنا.",
    "command_have_account_login": "هل لديك حساب؟ تسجيل الدخول هنا.",
    "login": "تسجيل الدخول",
    "register": "تسجيل",
    "please_enter_email": "يرجى تقديم البريد الإلكتروني",
    "email_sent": "تم إرسال البريد الإلكتروني",
    "password_reset_email_sent": "تم إرسال البريد الإلكتروني لإعادة تعيين كلمة المرور.",
    "password_reset_failed": "فشلت إعادة تعيين كلمة المرور.",
    "forgot_password": "هل نسيت كلمة السر",
    "password_reset_rate_limit": "لأسباب أمنية، يمكنك فقط تقديم هذا الطلب مرة واحدة كل 60 ثانية. يرجى الانتظار والمحاولة مرة أخرى.",
    "back_to_login": "العودة لتسجيل الدخول",
    "company_profile": "ملف الشركة",
    "company_details": "تفاصيل الشركة",
    "company_email": "البريد الإلكتروني للشركة",
    "company_name": "اسم الشركة",
    "company_address": "عنوان الشركة",
    "company_zipCode": "رمز بريدي",
    "company_city": "مدينة",
    "company_country": "دولة",
    "company_phone": "رقم هاتف الشركة",
    "company_pocName": "الاسم الأول، الاسم الأخير (شخص الاتصال)",
    "company_pocRole": "اختر الدور (شخص الاتصال)",
    "store_image": "صورة المحل",
    "vendor_delete_account": "هل أنت متأكد أنك تريد حذف ملف التعريف الخاص بك؟",
    "vendor_delete_account_body": "حاليًا، لا يمكن حذف ملفك الشخصي إلا عن طريق التواصل معنا عبر WhatsApp أو عبر البريد الإلكتروني على basar@basar-digital.com",
    "select_a_category": "حدد فئة",
    "categories.grocery": "بقالة",
    "categories.restaurants": "مطعم",
    "categories.hairSalon": "حلاق",
    "categories.electronics": "إلكترونيات",
    "categories.beauty_wellness": "الجمال والعافية",
    "categories.other": "آخر",
    "categories.non_food_retail": "بيع بالتجزئة",
    "categories.home_and_garden": "المنزل والحديقة",
    "categories.automotive": "التنقل",
    "categories.flowers_and_gifts": "الزهور والهدايا",
    "categories.activities": "أنشطة",
    "categories.cafe_and_dessert": "مقهى وحلويات",
    "categories.farm_shop": "متجر المزرعة",
    "subcategories.convenienceStore": "ميني ماركت",
    "subcategories.eastEuropean": "أوروبا الشرقية",
    "subcategories.beverageMarket": "سوق المشروبات",
    "subcategories.orientalGrocery": "شرقية",
    "subcategories.indianGrocery": "الأطعمة الهندية",
    "subcategories.importedGrocery": "يستورد",
    "subcategories.bioOrganic": "عضوي وعضوي",
    "subcategories.africanGrocery": "الأفريقي",
    "subcategories.turkishSupermarket": "تركي",
    "subcategories.latinAmericanSupermarket": "أمريكا اللاتينية",
    "subcategories.middleEasternSupermarket": "الشرق الأوسط",
    "subcategories.russianSupermarket": "الروسية",
    "subcategories.polishSupermarket": "بولندي",
    "subcategories.mediterraneanSupermarket": "البحر الأبيض المتوسط",
    "subcategories.veganVegetarianSupermarket": "نباتي / نباتي",
    "subcategories.halalMarket": "حلال",
    "subcategories.kosherSupermarket": "كوشير",
    "subcategories.southAsianSupermarket": "جنوب آسيا",
    "subcategories.germanCuisine": "الألمانية",
    "subcategories.frenchRestaurant": "فرنسي",
    "subcategories.spanishTapasBar": "الأسبانية",
    "subcategories.greekTavern": "اليونانية",
    "subcategories.mexicanRestaurant": "مكسيكي",
    "subcategories.indianRestaurant": "هندي",
    "subcategories.sushiBar": "بار السوشي",
    "subcategories.steakhouse": "مطعم لحوم",
    "subcategories.vegetarianVeganRestaurant": "نباتي / نباتي",
    "subcategories.foodTruck": "شاحنة الغذاء",
    "subcategories.fusionCuisine": "دولي",
    "subcategories.medievalTavern": "العصور الوسطى",
    "subcategories.patisserie": "المعجنات",
    "subcategories.lowCarb": "منخفض الكربوهيدرات",
    "subcategories.brideAndEvent": "العروس والحدث",
    "subcategories.afroBarber": "حلاق افرو",
    "subcategories.hairSpecialist": "متخصص بالشعر",
    "subcategories.permanentHairRemoval": "إزالة الشعر بشكل دائم",
    "subcategories.hairReplacement": "إزالة الشعر",
    "subcategories.barberServices": "خدمات الشعر",
    "subcategories.veganCafe": "نباتي",
    "subcategories.outdoorActivities": "أنشطة خارجية",
    "subcategories.eventDecorators": "ديكو الحدث",
    "subcategories.indoorPlants": "نباتات داخلية",
    "subcategories.outdoorPlants": "نباتات خارجية",
    "subcategories.ballonAndParty": "البالونات ولوازم الحفلات",
    "subcategories.handmade": "صناعة يدوية",
    "subcategories.candleAndFragrances": "الشموع والعطور",
    "subcategories.autoDetailing": "إعداد السيارة",
    "subcategories.autoBodyAndPaint": "الجسم والطلاء",
    "subcategories.carRental": "تأجير سيارات",
    "subcategories.autoGlass": "زجاج السيارات",
    "subcategories.inspection": "تقتيش",
    "subcategories.oilChange": "تغيير الزيت",
    "subcategories.motorcycle": "دراجة نارية",
    "subcategories.autoAccessories": "اكسسوارات السيارات",
    "subcategories.autoTuning": "ورشة ضبط",
    "subcategories.homeDecor": "ديكور المنزل",
    "subcategories.homeImprovement": "تحسين المنزل",
    "subcategories.lightingStore": "مصابيح وأضواء",
    "subcategories.applianceStore": "الأجهزة المنزلية",
    "subcategories.kitchenAndBath": "المطبخ والصرف الصحي",
    "subcategories.securityAndSmartHome": "الأمن والمنزل الذكي",
    "subcategories.diy": "DIY",
    "subcategories.pharmacy": "صيدلية",
    "subcategories.medicalSupply": "متجر طبي",
    "subcategories.jewlery": "الجواهري",
    "subcategories.toyStore": "متجر ألعاب",
    "subcategories.musicStore": "الموسيقى والآلات",
    "subcategories.secondhandStores": "اليد الثانية",
    "subcategories.travelAgency": "وكالة سفر",
    "subcategories.photoStudio": "استوديو الصور",
    "subcategories.tailorShop": "الخياطة",
    "subcategories.cleaningService": "خدمة التنظيف",
    "subcategories.shoeRepair": "إصلاح الأحذية",
    "subcategories.keyCopyService": "خدمة الأقفال",
    "subcategories.laundryService": "خدمة غسيل الملابس",
    "subcategories.locksmith": "قفال",
    "subcategories.mobileRepair": "إصلاح الهاتف الخليوي",
    "subcategories.computerRepair": "إصلاح الكمبيوتر",
    "subcategories.packagingService": "خدمة التعبئة والتغليف",
    "subcategories.printShop": "محل طباعة",
    "subcategories.eventPlanning": "تخطيط الحدث",
    "subcategories.regionalProducts": "المنتجات الإقليمية",
    "subcategories.butcherGrocery": "جزار",
    "subcategories.bakeryGrocery": "السلع المخبوزة",
    "subcategories.asianGrocery": "الآسيوية",
    "subcategories.eastEuropaen": "أوروبا الشرقية",
    "subcategories.farmGrocery": "متجر المزرعة",
    "subcategories.halalRestaurant": "حلال",
    "subcategories.asianRestaurants": "الآسيوية",
    "subcategories.seafoodRestaurant": "المأكولات البحرية",
    "subcategories.italianRestaurant": "ايطالي",
    "subcategories.burgerRestaurant": "البرغر",
    "subcategories.menHairSalon": "السادة المحترمون",
    "subcategories.kidsHairSalon": "أطفال",
    "subcategories.womenHairSalon": "نحيف",
    "subcategories.gadgets": "الأدوات",
    "subcategories.vegetarianRestaurant": "نباتي",
    "subcategories.mediterraneanRestaurant": "البحر الأبيض المتوسط",
    "subcategories.fishMarket": "سوق السمك",
    "subcategories.imker": "النحال",
    "subcategories.makeup": "ماكياج",
    "subcategories.barbeque": "شواء",
    "subcategories.bubble_tea": "شاي الفقاعات",
    "subcategories.african": "الأفريقي",
    "subcategories.hair_treatments": "علاجات الشعر",
    "subcategories.hair_removal": "إزالة الشعر",
    "subcategories.teeth_whitening": "تبييض الأسنان",
    "subcategories.acupuncture": "الوخز بالإبر",
    "subcategories.nail_salon": "صالون الأظافر",
    "subcategories.therapies": "العلاجات",
    "subcategories.meat": "لحمة",
    "subcategories.buffet": "البوفيهات",
    "subcategories.fried": "طعام مقلي",
    "subcategories.fast_food": "الوجبات السريعة",
    "subcategories.breakfast_brunch": "الإفطار | وجبة فطور وغداء",
    "subcategories.bistro_imbiss": "بيسترو | وجبة خفيفة",
    "subcategories.manicure_pedicure": "عمليات تجميل الأظافر والباديكير",
    "subcategories.facial_treatments": "علاجات للوجه",
    "subcategories.cosmetic_supply": "منتجات التجميل",
    "subcategories.beverage_market": "سوق المشروبات",
    "subcategories.dairyGrocery": "ألبان",
    "subcategories.vegetableGrocery": "خضار",
    "subcategories.dessertBar": "الحلويات",
    "subcategories.crepes": "كريب",
    "subcategories.waffles": "الفطائر",
    "subcategories.juices": "العصائر",
    "subcategories.tea_house": "المقهى",
    "subcategories.sweet_pastry": "معجنات حلوة",
    "subcategories.candy_shop": "متجر الحلوى",
    "subcategories.dairyFarmShop": "ألبان",
    "subcategories.fruit_vegetable": "الفواكه والخضروات",
    "subcategories.eggs": "بيض",
    "subcategories.bio_organic": "عضوي وعضوي",
    "subcategories.scooping": "الحجامة",
    "subcategories.indian_grocery": "هندي",
    "subcategories.imported_grocery": "يستورد",
    "subcategories.spaWellness": "سبا والعافية",
    "subcategories.massageCenter": "التدليك",
    "subcategories.gifts": "الهدايا",
    "subcategories.cafe": "مقهى",
    "subcategories.iceCreamParlor": "صالة الآيس كريم",
    "subcategories.bakeryCafe": "السلع المخبوزة",
    "subcategories.sportsCenter": "رياضة",
    "subcategories.kidsPlayArea": "حديقة ألعاب للأطفال",
    "subcategories.art": "فن",
    "subcategories.cinema": "سينما",
    "subcategories.theater": "مسرح",
    "subcategories.escape_room": "غرف الهروب",
    "subcategories.climbing": "تسلق",
    "subcategories.musuem": "متحف",
    "subcategories.flowerShop": "زهور",
    "subcategories.giftShop": "الهدايا",
    "subcategories.carRepair": "بصلح",
    "subcategories.carWash": "غسل",
    "subcategories.tireShop": "الإطارات",
    "subcategories.furnitureStore": "أثاث",
    "subcategories.gardenCenter": "حديقة",
    "subcategories.clothingStore": "متجر ملابس",
    "subcategories.electronicsStore": "إلكترونيات",
    "subcategories.petStore": "مستلزمات الحيوانات الأليفة",
    "subcategories.zooStore": "مستلزمات حديقة الحيوان",
    "subcategories.bookstore": "الكتب والمجلات",
    "subcategories.cosmetic": "مستحضرات التجميل",
    "keywords.oriental": "شرقية",
    "keywords.middleEastern": "الشرق الأوسط",
    "keywords.arabic": "عربي",
    "keywords.turkish": "تركي",
    "keywords.moroccan": "مغربي",
    "keywords.persian": "الفارسية",
    "keywords.butcher": "جزار",
    "keywords.meat": "لحمة",
    "keywords.sausage": "سجق",
    "keywords.meatShop": "محل جزار",
    "keywords.slaughterhouse": "جزار",
    "keywords.bakery": "مخبز",
    "keywords.bread": "خبز",
    "keywords.pastry": "معجنات",
    "keywords.roll": "لفات الخبز",
    "keywords.cake": "كعكة",
    "keywords.patisserie": "متجر المعجنات",
    "keywords.asian": "الآسيوية",
    "keywords.chinese": "الصينية",
    "keywords.japanese": "اليابانية",
    "keywords.thai": "التايلاندية",
    "keywords.vietnamese": "الفيتنامية",
    "keywords.korean": "كوري",
    "keywords.indian": "هندي",
    "keywords.farmShop": "متجر المزرعة",
    "keywords.farmersMarket": "سوق المزارعين",
    "keywords.regional": "إقليمي",
    "keywords.organic": "عضوي",
    "keywords.fresh": "طازج",
    "keywords.agricultural": "الزراعية",
    "keywords.halal": "حلال",
    "keywords.halalCertified": "حلال معتمد",
    "keywords.islamic": "اسلامية",
    "keywords.muslim": "مسلم",
    "keywords.doner": "دونر كباب",
    "keywords.sushi": "سوشي",
    "keywords.fish": "سمكة",
    "keywords.seafood": "المأكولات البحرية",
    "keywords.salmon": "سمك السلمون",
    "keywords.shrimp": "جمبري",
    "keywords.italian": "ايطالي",
    "keywords.pizza": "بيتزا",
    "keywords.pasta": "معكرونة",
    "keywords.risotto": "ريسوتو",
    "keywords.tiramisu": "تيراميسو",
    "keywords.antipasti": "المقبلات",
    "keywords.burger": "المواطنين",
    "keywords.hamburger": "همبرغر",
    "keywords.cheeseburger": "البرجر بالجبن",
    "keywords.fries": "إفريز",
    "keywords.fastFood": "الوجبات السريعة",
    "keywords.menHairdresser": "كوافير رجالى",
    "keywords.menSalon": "صالون الرجال",
    "keywords.barber": "حلاق",
    "keywords.shaving": "حلاقة",
    "keywords.haircutForMen": "قص شعر للرجال",
    "keywords.kidsHairdresser": "مصفف شعر اطفال",
    "keywords.kidCut": "قطع الأطفال",
    "keywords.kidHaircut": "قصة شعر الاطفال",
    "keywords.haircutForKids": "قص شعر للأطفال",
    "keywords.kidStyling": "تصفيف الأطفال",
    "keywords.womenHairdresser": "كوافير نسائي",
    "keywords.womenSalon": "صالون للسيدات",
    "keywords.haircutForWomen": "قص شعر للنساء",
    "keywords.dyeing": "صباغة",
    "keywords.stylingForWomen": "التصميم للنساء",
    "keywords.technology": "تكنولوجيا",
    "keywords.electronics": "إلكترونيات",
    "keywords.devices": "الأجهزة",
    "keywords.accessories": "مُكَمِّلات",
    "units.km": "كم",
    "open_24_hours": "مفتوح 24 ساعة",
    "favorites_and_bookings": "المفضلة والحجوزات",
    "no_favorites": "لا توجد مفضلات متاحة!",
    "no_favorites_text": "لم تقم بإضافة أي مفضلة بعد. تصفح متاجرك المحلية واحفظها كمفضلة ليسهل الوصول إليها لاحقًا!",
    "no_coupons": "لا توجد كوبونات متاحة!",
    "no_coupons_text": "لم تقم بتفعيل أي كوبونات بعد. اكتشاف وتفعيل القسائم للاستمتاع بالخصومات!",
    "onboardingTutorial_title_step1": "يكتشف",
    "onboardingTutorial_description_step1": "اكتشف عالم التجارة المحلية، سواء المطاعم ومحلات السوبر ماركت ومصففي الشعر وصالونات التجميل وورش العمل وأكثر من ذلك بكثير!",
    "onboardingTutorial_imageCaption_step1": "كل شيء هناك، كل شيء قريب.",
    "onboardingTutorial_title_step2": "يسترد",
    "onboardingTutorial_description_step2": "يدعوك شركاؤنا المحليون للبيع بالتجزئة للاستفادة من الخصومات والهدايا المباشرة على المنتجات أو الخدمات.",
    "onboardingTutorial_imageCaption_step2": "دعم المحلي وتوفير المال ليس ممكنا؟ لكن!",
    "onboardingTutorial_title_step3": "يحفظ",
    "onboardingTutorial_description_step3": "جميع الصفقات جاهزة للتسليم في جيبك. ما عليك سوى إظهار حجزك للموظف في المتجر. هكذا ندعم التجارة المحلية!",
    "onboardingTutorial_imageCaption_step3": "سريعة وسهلة للجميع.",
    "confirm": "يتأكد",
    "start_now": "ابدأ الآن",
    "next": "إضافي",
    "submit": "يُقدِّم",
    "company_name_required": "اسم الشركة مطلوب",
    "company_email_required": "البريد الإلكتروني للشركة مطلوب",
    "invalid_email": "عنوان البريد الإلكتروني غير صالح",
    "invalid_zipCode": "الرمز البريدي غير صالح",
    "company_address_required": "عنوان الشركة مطلوب",
    "company_zipCode_required": "الرمز البريدي مطلوب",
    "company_city_required": "المدينة مطلوبة",
    "company_country_required": "البلد مطلوب",
    "company_phone_required": "مطلوب هاتف الشركة",
    "invalid_phone": "رقم الهاتف غير صالح",
    "placeholder_company_name": "أدخل اسم الشركة",
    "placeholder_company_email": "أدخل البريد الإلكتروني للشركة",
    "placeholder_company_address": "أدخل رقم الشارع والمنزل",
    "placeholder_company_zipCode": "أدخل الرمز البريدي",
    "placeholder_company_city": "أدخل المدينة",
    "placeholder_company_country": "أدخل البلد",
    "placeholder_company_phone": "أدخل رقم الهاتف",
    "placeholder_company_pocName": "أدخل الاسم الأول والاسم الأخير لجهة الاتصال",
    "placeholder_company_pocRole": "حدد دور جهة الاتصال",
    "no_stores": "لم يتم إنشاء أي عمل",
    "no_stores_text": "لم تقم بإضافة متجر بعد. انقر هنا وقم بإنشاء متجرك الآن في لمح البصر لتتمكن من تقديم الكوبونات.",
    "more_stores": "هل لديكم فرع آخر؟",
    "more_stores_text": "انقر هنا لإضافة متجر آخر حيث يمكنك أيضًا إنشاء كوبونات.",
    "stores_of_company": "فروع شركتك",
    "vendor_poc_roles.owner": "مالك",
    "vendor_poc_roles.employee": "موظف",
    "vendor_poc_roles.else": "آخر",
    "coupon.ON_SITE": "في الموقع",
    "coupon.TO_GO": "للذهاب",
    "coupon.NINETY_DAYS": "90 يوما",
    "coupon.THIRTY_DAYS": "30 يوما",
    "coupon.WEEKLY": "أسبوعي",
    "coupon.ONE_YEAR": "سنوي",
    "coupon.DAILY": "يوميًا",
    "coupon.THREE_DAYS": "3 أيام",
    "coupon.HUNDRED_EIGHTY_DAYS": "180 يوما",
    "paywall.price_description_yearly": "الدفع السنوي",
    "paywall.price_description_monthly": "دفعات شهرية",
    "monetization.paywall_title": "جميع الصفقات. احفظ على الفور.",
    "monetization.maybe_later": "ربما في وقت لاحق",
    "monetization.monthly": "شهريا",
    "monetization.yearly": "سنوي",
    "monetization.monthly_marketing.first_benefit": "عادة ما يكون الأمر يستحق ذلك مقابل 1-2 عملية شراء فقط!",
    "monetization.monthly_marketing.second_benefit": "يمكن إلغاؤها شهريا!",
    "monetization.monthly_marketing.third_benefit": "استبدل جميع القسائم على الفور.",
    "monetization.yearly_marketing.first_benefit": "الأمر يستحق ذلك مقابل 3-4 عمليات شراء سنويًا فقط!",
    "monetization.yearly_marketing.second_benefit": "ادفع مرة واحدة واستمتع لمدة عام!",
    "monetization.yearly_marketing.third_benefit": "استبدل جميع القسائم على الفور.",
    "monetization.price_description_effective_per_month": "فعالة شهريا",
    "monetization.price_description_per_month": "كل شهر",
    "monetization.confirmation_title": "عملية الشراء ناجحة!",
    "monetization.confirmation_message": "شكرا لك على الشراء. لديك الآن إمكانية الوصول إلى جميع الميزات المميزة.",
    "validation.setProductName": "يجب تعيين اسم المنتج.",
    "for": "ل",
    "coupon_factory.2for1_title": "اشتري 2 واحصل على 1 مجانا",
    "coupon_factory.2for1_description": "أو حتى أكثر. إنشاء عرض الكمية",
    "coupon_factory.win_customers_and_money": "كسب العملاء والمبيعات.",
    "coupon_factory.example": "قسيمة المثال",
    "coupon_factory.create_now": "أنشئ الآن",
    "coupon_factory.create_coupon_marketing_header": "احصل على عملاء جدد مجانا الآن",
    "coupon_factory.create_coupon_marketing_body": "قم بإنشاء قسيمتك في بضع خطوات فقط.",
    "coupon_factory.create_coupon_benefit_header_1": "🎯 عملاء جدد",
    "coupon_factory.create_coupon_benefit_body_1": "يمكنك الوصول إلى عملاء جدد مباشرةً عبر هاتفك الذكي",
    "coupon_factory.create_coupon_benefit_header_2": "🚀 سهل وسريع",
    "coupon_factory.create_coupon_benefit_body_2": "إنشاء الصفقات أسهل من أي وقت مضى.",
    "coupon_factory.create_coupon_benefit_header_3": "💸 المزيد من المبيعات",
    "coupon_factory.create_coupon_benefit_body_3": "زيادة مبيعاتك مع كوبونات لعملائك.",
    "coupon_factory.create_coupon_card": "إنشاء قسيمة!",
    "coupon_factory.create_coupon_card_info": "انقر هنا لإنشاء قسيمة في 3 خطوات.",
    "coupon_factory.create_coupon": "إنشاء قسيمة!",
    "coupon_factory.create_coupon_body": "ثلاث خطوات للإعلان المثالي للعملاء الجدد!",
    "coupon_factory.condition_for_customer": "تحديد الشرط للعملاء",
    "coupon_factory.choose_condition": "حدد الشرط",
    "coupon_factory.choose_condition_info": "ما يجب على العميل فعله ليستفيد",
    "coupon_factory.minimum_order_value": "الحد الأدنى لقيمة الطلب",
    "coupon_factory.minimum_order_value_info": "يجب على العميل أن ينفق مبلغًا معينًا",
    "coupon_factory.product_purchase": "شراء المنتج",
    "coupon_factory.product_purchase_info": "يجب على العميل شراء منتجات معينة",
    "coupon_factory.direct_rebate": "خصم مباشر على المنتج",
    "coupon_factory.no_conditions": "لا توجد شروط",
    "coupon_factory.no_conditions_set": "لم يتم تحديد أي شروط",
    "coupon_factory.go_to_next_step": "انتقل إلى الخطوة التالية",
    "coupon_factory.input_mov": "تحديد الحد الأدنى لقيمة الشراء",
    "coupon_factory.count_of_products": "عدد المنتجات",
    "coupon_factory.product_name": "اسم المنتج",
    "coupon_factory.value_of_product_per_piece": "قيمة المنتج (للقطعة الواحدة)",
    "coupon_factory.order_discount": "خصم على الشراء",
    "coupon_factory.product_discount": "خصم على منتج",
    "coupon_factory.free_product": "منتج مجاني",
    "coupon_factory.discount": "تخفيض",
    "coupon_factory.enter_discount_amount": "أدخل مبلغ الخصم",
    "coupon_factory.redeem_location_conditons": "شروط الخصم",
    "coupon_factory.redeem_location_info": "أين يمكن لعملائك استخدام الخصم؟",
    "coupon_factory.choose_discount": "تخفيض",
    "coupon_factory.choose_discount_info": "ما نوع الخصم الذي يحصل عليه عميلك؟",
    "coupon_factory.carousel_header_one": "اختر قسيمة",
    "coupon_factory.carousel_body_one": "حدد القسيمة التي تريد إنشاءها.",
    "coupon_factory.carousel_header_two": "ضبط الشرط",
    "coupon_factory.carousel_body_two": "تحديد ما يجب على العميل القيام به.",
    "coupon_factory.carousel_header_three": "تعيين الفوائد",
    "coupon_factory.carousel_body_three": "تحديد ما سيحصل عليه العميل.",
    "coupon_factory.product_quantitiy": "الكمية لكل عميل",
    "coupon_factory.product_quantitiy_info": "الخصم ينطبق على كل قطعة!",
    "coupon_factory.product_quantitiy_free_product_info": "كم قطعة يحصل عليها مجانا؟",
    "coupon_factory.same_product": "نفس المنتج",
    "coupon_factory.same_product_info": "يحصل العميل على نفس المنتج.",
    "coupon_factory.other_product": "منتج مختلف",
    "coupon_factory.other_product_info": "يرجى تقديم اسم المنتج.",
    "coupon_factory.price_per_unit": "السعر للقطعة الواحدة",
    "coupon_factory.error_invalid_input": "إدخال غير صالح",
    "coupon_factory.error_rebate_higher_as_order_value": "لا يمكن أن يكون الخصم أعلى من مبلغ الشراء/المنتج",
    "coupon_factory.error_rebate_higher_as_product_value": "لا يمكن أن يكون الخصم أعلى من مبلغ الشراء/المنتج",
    "coupon_factory.max_created_coupon_card": "تم الوصول إلى الحد الأقصى لعدد الكوبونات",
    "coupon_factory.max_created_coupon_card_info": "لقد وصلت إلى الحد الأقصى الحالي للكوبونات الخاصة بك.",
    "coupon_factory.max_created_stores": "تم الوصول إلى الحد الأقصى من المحلات التجارية",
    "coupon_factory.max_created_stores_info": "لقد وصلت إلى الحد الأقصى الحالي للصفقات التي يمكن إنشاؤها. إذا كنت ترغب في إنشاء متجر آخر، يرجى الاتصال بنا باستخدام نموذج الاتصال في الإعدادات.",
    "details": "تفاصيل",
    "enter_details": "تقديم التفاصيل",
    "store_factory.store_conflict": "حدثت مشكلة مع المتجر",
    "store_factory.store_conflict_info": "تم بالفعل تسجيل المتجر المحدد على Google في Bazaar. يرجى التحقق مرة واحدة. إذا كنت لا تزال تواجه مشاكل، فلا تتردد في الاتصال بنا.",
    "store_factory.wrong_store": "هل تم اختيار العمل بشكل خاطئ؟",
    "store_factory.wrong_store_search_again": "هل تريد إعادة التشغيل مرة أخرى؟ يرجى ملاحظة أن معلوماتك على جوجل يجب أن تكون صحيحة.",
    "store_factory.restart": "إعادة تشغيل",
    "store_factory.what_customers_see": "هذا ما يراه عملاؤك",
    "store_factory.check_details": "تحقق مما إذا كانت معلوماتك صحيحة.",
    "store_factory.store_name": "اسم الفرع",
    "store_factory.store_name_example": "اسم عملك",
    "store_factory.store_phone": "رقم الهاتف لعملائك",
    "store_factory.store_email": "البريد الإلكتروني لعملائك",
    "store_factory.choose_category": "اختر الفئة",
    "store_factory.choose_category_info": "اختر فئة رئيسية وما يصل إلى 3 فئات فرعية وقم بتحميل صورة لمتجرك.",
    "store_factory.main_category": "الفئة الرئيسية",
    "store_factory.sub_categories": "الفئات الفرعية",
    "store_factory.upload_store_img": "تحميل صورة المتجر",
    "store_factory.store_img": "صورة من المحل",
    "store_factory.max_achieved": "تم الوصول إلى الحد الأقصى",
    "store_factory.sub_categories_limit": "يمكنك تحديد 3 فئات فرعية كحد أقصى.",
    "store_factory.categories_and_image_required": "الفئات والصور ضرورية!",
    "store_factory.zip_code": "رمز بريدي",
    "store_factory.zip_code_placeholder": "على سبيل المثال 44801",
    "store_factory.claim_store": "تولي الأعمال",
    "store_factory.house_number": "رقم المنزل",
    "store_factory.house_number_placeholder": "على سبيل المثال 33",
    "store_factory.street": "شارع",
    "store_factory.street_placeholder": "على سبيل المثال شارع البازار",
    "store_factory.city": "مدينة",
    "store_factory.city_placeholder": "على سبيل المثال بوخوم",
    "store_factory.success": "تم إنشاء المتجر بنجاح",
    "store_factory.success_info": "تم إنشاء متجرك بنجاح ويتم الآن التحقق منه.",
    "store_factory.success_verification": "عادةً ما يستغرق التحقق من يوم إلى يومي عمل. بمجرد اكتمال التحقق، سيتم تشغيل متجرك وسيكون مرئيًا للعملاء.",
    "store_factory.verification_pending": "التحقق معلق",
    "store_factory.verification_pending_info": "عملية التحقق جارية حاليا. سنتواصل معك إذا كنا بحاجة إلى مزيد من المعلومات منك. إذا كان لديك أي أسئلة، فلا تتردد في الاتصال بنا.",
    "store_factory.no_coupons": "لا كوبونات بعد",
    "store_factory.no_coupons_info": "لم تقم بإنشاء أي كوبونات بعد. انقر على المتجر لإنشاء قسيمة.",
    "to_dashboard": "إلى لوحة القيادة",
    "error_unexpected": "حدث خطأ غير متوقع. حاول ثانية. إذا استمرت المشكلة، فلا تتردد في الاتصال بنا.",
    "check_inputs": "التحقق من المعلومات",
    "warning": "خطر",
    "progress_deleted_info": "سيتم حذف حالتك المؤقتة إذا عدت إلى هنا. هل أنت متأكد؟",
    "notification": "إشعار",
    "notifications": "إشعارات",
    "request_notification_permission": "تمكين دفع الإخطارات؟",
    "denied_notification_permission": "تم رفض إشعارات الدفع",
    "denied": "رفض",
    "activate_notification_in_settings": "يمكنك تمكين دفع الإخطارات في الإعدادات",
    "notifcationsTitle.message1": "🥳 حان وقت الخصومات المحلية!",
    "notifcationsBody.message1": "وفر الآن مع كوبونات حصرية للمتاجر المحلية! سواء كان الأمر يتعلق بالطعام أو الموضة أو الترفيه - اكتشف الخصومات الرائعة وادعم مجتمعك!"
  },
  "ru": {
    "!add_row_below": "VVV Добавить строку ниже VVV",
    "so_easy_for_partners": "Партнерам это так просто",
    "so_easy_for_partners.register_store": "Зарегистрируйте свой бизнес быстро и легко, чтобы привлечь больше клиентов.",
    "so_easy_for_partners.create_coupon": "Создавайте индивидуальные купоны для своих клиентов, увеличивайте продажи и привлекайте новых клиентов – все по вашему желанию.",
    "so_easy_for_partners.manage_store": "Управляйте своим магазином легко — в любое время настраивайте время открытия, изображения и ваучеры по мере необходимости.",
    "change_language": "Изменить язык",
    "english_USA": "🇬🇧 английский",
    "german_Germany": "🇩🇪 немецкий",
    "Imprint": "Выходные данные",
    "Datenschutz": "Политика конфиденциальности",
    "AGB": "Общие условия заключения сделок",
    "Cookies": "Файлы cookie",
    "about": "О НАС",
    "our_magazine": "Наш журнал",
    "for_companies": "Для компаний",
    "contact": "Контакт",
    "no_answer_found": "Вопрос без ответа?",
    "jobs": "Вакансии",
    "become_partner": "Стать партнером",
    "shop": "Магазин",
    "experiences": "Опыт",
    "legal": "ЮРИДИЧЕСКИЙ",
    "social_media": "СОЦИАЛЬНЫЕ СЕТИ",
    "frequently_asked_questions": "Часто задаваемые вопросы",
    "vendor_faq.how_work_question": "Как BASAR работает для партнеров?",
    "vendor_faq.how_work_answer": "Мы установили партнерские отношения с различными местными предприятиями и договорились об уникальных предложениях, таких как услуги «2 по цене 1» или скидки на продукты. Приложение Bazaar позволяет просматривать, бронировать и использовать эти предложения в различных отраслях, таких как рестораны, парикмахерские, мастерские, цветочные магазины и многие другие.",
    "vendor_faq.participating_businesses_question": "Какие компании принимают участие?",
    "vendor_faq.participating_businesses_answer": "Тысячи местных предприятий в настоящее время участвуют с различными эксклюзивными предложениями. От гастрономии до услуг и продуктов — вы найдете отличные предложения во многих категориях.",
    "vendor_faq.app_free_question": "Приложение на данный момент бесплатное?",
    "vendor_faq.app_free_answer": "Да, приложение BASAR в настоящее время полностью бесплатное. Партнеры могут участвовать и предоставлять предложения без каких-либо комиссий.",
    "vendor_faq.create_store_question": "Как создать магазин на BASAR?",
    "vendor_faq.create_store_answer": "Создать свой магазин на BASAR легко и быстро. Вы можете настроить свой бизнес-профиль всего за несколько кликов прямо в приложении.",
    "vendor_faq.manage_coupons_question": "Могу ли я создавать свои собственные купоны и управлять ими?",
    "vendor_faq.manage_coupons_answer": "Да, вы имеете полный контроль над созданием и удалением собственных купонов. Устанавливайте скидки, предложения и условия, которые лучше всего подходят вашему бизнесу. Никакие менеджеры по продажам не мешают вашему бизнесу, вашему маркетингу.",
    "faq.general.category": "ОБЩИЙ",
    "faq.general.questions[0].question": "В чем заключается концепция базара?",
    "faq.general.questions[0].answer": "Мы установили партнерские отношения с различными местными предприятиями и договорились об эксклюзивных вводных ваучерах, таких как: B. Предложения «2 по цене 1» или скидки на продукты. С помощью приложения Bazaar вы можете легко просматривать, бронировать и использовать эти предложения в различных отраслях, таких как рестораны, парикмахерские, мастерские, цветочные магазины и многое другое.",
    "faq.general.questions[1].question": "Какие компании принимают участие?",
    "faq.general.questions[1].answer": "Тысячи местных предприятий в настоящее время участвуют с различными эксклюзивными предложениями. От ресторанов до услуг и продуктов — вы найдете отличные предложения во многих категориях.",
    "start": "Начинать",
    "price": "Цена",
    "redeem_error_notToday_title": "Купон сегодня недействителен.",
    "redeem_error_notToday_message": "Купон можно использовать только в указанные дни.",
    "redeemDays.monday": "Мо",
    "redeemDays.tuesday": "Делать",
    "redeemDays.wednesday": "Мы",
    "redeemDays.thursday": "Че",
    "redeemDays.friday": "РС",
    "redeemDays.saturday": "Суббота",
    "redeemDays.sunday": "Вс",
    "coupon_factory.your_coupon_active": "Ваш купон теперь активен и скоро будет виден вашим клиентам!",
    "coupon_factory.valid_days_header": "Действующие дни",
    "coupon_factory.valid_days_subHeader": "Дни, в которые клиентам разрешено использовать купоны.",
    "error_messages.something_wrong": "Что-то пошло не так. Повторите попытку позже или свяжитесь с нами, если проблема не исчезнет.",
    "store_factory.store_not_found_input_manual": "Магазин не найден? Ручной ввод адреса.",
    "sent_review": "Отправить отзыв.",
    "review_your_visit_at": "Оцените свой визит @",
    "search": "Поиск",
    "faq_title": "FAQ (часто задаваемые вопросы)",
    "faq_title_reverse": "Часто задаваемые вопросы (FAQ)",
    "vendor_faq_question_concept": "Как работает БАСАР?",
    "vendor_faq_answer_concept": "Концепция BASAR проста и эффективна. Как индивидуальный предприниматель, ведущий физический бизнес, вы можете использовать приложение BASAR Business, чтобы представить свой магазин и создавать купоны. Пользователи могут найти эти купоны через пользовательское приложение BASAR и использовать их непосредственно в вашем магазине. Таким образом мы усиливаем местный бизнес в конкуренции с крупными розничными сетями.",
    "vendor_faq_question_costs": "Есть ли какие-либо расходы для меня?",
    "vendor_faq_answer_costs": "Нет, BASAR абсолютно бесплатен для розничных продавцов. Пользователи подписываются, чтобы получить доступ ко всем купонам. Это позволяет нам покрыть затраты на платформу и позволить местному бизнесу быть конкурентоспособным — даже по сравнению с крупными сетями, которые инвестируют в дорогостоящий маркетинг и программы лояльности клиентов.",
    "vendor_faq_question_minium_requirements": "Каким требованиям я должен соответствовать?",
    "vendor_faq_answer_minium_requirements": "Вам нужен физический магазин, где вы обслуживаете клиентов на месте - например. Б. ресторан, парикмахерская, мастерская и т.п. Кроме того, вы или ваши сотрудники должны иметь возможность легко предоставить обещанные скидки на кассе.",
    "vendor_faq_question_interface_cashier": "Нужно ли мне интегрировать кассовый аппарат?",
    "vendor_faq_answer_interface_cashier": "Нет, BASAR очень легко работает по классическому принципу четырех глаз. Клиент показывает вам погашенный купон на своем смартфоне, и вы предоставляете скидку. Никакой дорогой или сложной интеграции POS-системы не требуется. Важно лишь то, чтобы вы или ваши сотрудники могли правильно реализовать скидки.",
    "vendor_faq_question_shop_creation": "Как мне создать свой магазин?",
    "vendor_faq_answer_shop_creation": "Создать свой магазин можно во вкладке «Магазин». Найдите свой бизнес в нашей базе данных или создайте его вручную. Добавьте контактную информацию, время работы, фотографию и соответствующую категорию. Мы проверим информацию и свяжемся с вами, если у вас возникнут вопросы. Проверка обычно занимает один рабочий день — после этого вы можете сразу создавать купоны.",
    "vendor_faq_question_coupons": "Как я могу создавать купоны?",
    "vendor_faq_answer_coupons": "После создания своего магазина вы можете запустить фабрику купонов во вкладке «Купоны». Там вы найдете готовые шаблоны, в которые вы сможете быстро и легко добавить свою информацию. После предварительного просмотра вы можете создать купон, и он начнет действовать в течение короткого времени. Требование: Ваш магазин должен быть подтвержден.",
    "vendor_faq_question_storeinfos_update": "Как обновить информацию о моей компании?",
    "vendor_faq_answer_storeinfos_update": "Зайдите в свой магазин и нажмите на вкладку «Информация». Там вы можете обновить контактную информацию, время работы и изображения, используя соответствующие кнопки редактирования.",
    "vendor_faq_question_abuse": "Как BASAR предотвращает многократное использование купона?",
    "vendor_faq_answer_abuse": "Каждый пользователь регистрируется под своим номером телефона, поэтому его можно четко идентифицировать. При создании купона вы можете указать, как часто купон можно использовать, например. Б. только каждые 90 дней. Таким образом, использование остается справедливым и контролируемым.",
    "vendor_faq_question_help_creation": "Поможете нам создать магазин или купоны?",
    "vendor_faq_answer_help_creation": "Конечно! Мы будем рады помочь вам, если вам нужна помощь в настройке вашего магазина или купонов. Вы можете связаться с нами в любое время через WhatsApp. Номер можно найти в настройках в разделе «Обратная связь и поддержка».",
    "vendor_faq_question_how_to_contact": "Как я могу связаться с вами?",
    "vendor_faq_answer_how_to_contact": "Вы можете связаться с нами по электронной почте basar@basar-digital.com или напрямую через WhatsApp. Номер WhatsApp можно найти в настройках в разделе «Обратная связь и поддержка».",
    "create_store_and_coupons": "Создайте свой магазин и купоны",
    "click_here_for_help": "Нажмите здесь, если вам нужна дополнительная информация.",
    "vendorOnboardingTutorial_title_step1": "Найдите и создайте свой собственный магазин.",
    "vendorOnboardingTutorial_description_step1": "Найдите свой бизнес с помощью функции поиска или просто создайте его самостоятельно. Добавьте всю важную информацию, такую ​​как контакты, время работы, изображения и категории.",
    "vendorOnboardingTutorial_imageCaption_step1": "Ваш магазин, ваш старт: всего несколькими щелчками мыши вы станете видны вашим клиентам - независимо от того, добавляете ли вы существующий магазин или создаете новый.",
    "vendorOnboardingTutorial_title_step2": "Создавайте непреодолимые купоны.",
    "vendorOnboardingTutorial_description_step2": "Используйте нашу простую в использовании фабрику купонов для создания индивидуальных предложений. Порадуйте клиентов эксклюзивными скидками и придайте новый импульс вашему бизнесу.",
    "vendorOnboardingTutorial_imageCaption_step2": "С помощью фабрики купонов вы сможете удивлять клиентов: создавать индивидуальные предложения всего за несколько секунд и укреплять лояльность клиентов.",
    "vendorOnboardingTutorial_title_step3": "Надеемся на восторженных клиентов.",
    "vendorOnboardingTutorial_description_step3": "Своими предложениями вы привлекаете новых клиентов и удивляете своих постоянных клиентов. Покупатели могут найти ваш магазин и купоны непосредственно в пользовательском приложении BASAR. Вы активируете купоны на сайте и можете применить скидку непосредственно при оформлении заказа.",
    "vendorOnboardingTutorial_imageCaption_step3": "Ваш бизнес, их радость: больше клиентов, больше продаж – с пользовательским приложением BASAR ваш магазин станет выбором номер один.",
    "category": "Категория",
    "loadings_favorites": "Избранное Загрузка",
    "feature_so_great": "Эта функция настолько хороша, что она не бесплатна! Зарегистрируйтесь и начните.",
    "login_and_start": "Войдите и начните",
    "oops_something_missing": "Упс, чего-то все еще не хватает!",
    "coupon_factory": "Купонная фабрика",
    "coupon_factory.infoHeader_name_of_product": "Название продукта",
    "coupon_factory.infoBody_name_of_product": "Введите название продукта или услуги, которую вы хотите, чтобы клиент приобрел.",
    "coupon_factory.infoHeader_value_of_product": "Ценность продукта",
    "coupon_factory. infoBody_value_of_product": "Сколько стоит товар в вашем магазине?",
    "coupon_factory.infoHeader_count_of_product": "Количество продуктов",
    "coupon_factory.infoBody_count_of_product": "Сколько этого товара ему следует купить.",
    "coupon_factory.infoHeader_minimum_order_volume": "Минимальная стоимость заказа/минимальная стоимость покупки",
    "coupon_factory.infoBody_minimum_order_volume": "Сколько хотя бы должен потратить клиент, чтобы получить выгоду?",
    "coupon_factory.infoHeader_name_of_benefit_product": "Название преимущества продукта",
    "coupon_factory.infoBody_name_of_benefit_product": "Введите здесь название продукта или услуги, которую получит клиент.",
    "coupon_factory.infoHeader_value_of_benefit_product": "Ценность выгодного продукта",
    "coupon_factory.infoBody_value_of_benefit_product": "Сколько обычно стоит товар без цены предложения?",
    "coupon_factory.infoHeader_discount_amount": "Укажите сумму скидки",
    "coupon_factory.infoBody_discount_amount": "Сколько составляет скидка за штуку - в евро или %.",
    "coupon_factory.infoHeader_order_discount_amount": "Укажите сумму скидки",
    "coupon_factory.infoBody_order_discount_amount": "Сколько составляет скидка на покупку или заказ - в € или %.",
    "delete_coupon": "Удалить купон",
    "generate": "Генерировать",
    "delete_coupon_text": "Вы уверены, что хотите удалить этот купон? Это действие невозможно отменить.",
    "delete_coupon_info": "Внимание: ваш купон активен до конца дня и больше не будет виден пользователям со следующего дня.",
    "validation_all_inputs_required": "Все поля должны быть правильно заполнены.",
    "store_factory.contact_and_opentimes": "Контакты и часы работы",
    "store_factory.always_editable_later": "Вы всегда сможете отредактировать его позже.",
    "choose_category_and_image": "Выберите изображение и категорию.",
    "store_factory.main_categories_limit": "Выберите категорию.",
    "searchdata_openstreetmap": "Данные поиска © OpenStreetMap",
    "success.opentimes_updated": "Время работы успешно обновлено!",
    "success.contactdetails_updated": "Контактные данные успешно обновлены!",
    "editcontact": "Изменить контактные данные",
    "from": "От",
    "until": "До",
    "weekday": "День",
    "open_questionmark": "Открыть?",
    "coupon_factory.contact_if_issue": "Пожалуйста, проверьте всю информацию. Если вам все еще нужна помощь, пожалуйста, свяжитесь с нами здесь.",
    "respawn_time": "Время возрождения",
    "coupon_factory.coupon_in_three_steps": "Создайте купон за 3 шага.",
    "store_factory.your_store_on_map": "Ваш бизнес на карте",
    "coupon_factory.infoHeader_need_help": "Вам нужна поддержка?",
    "coupon_factory.infoBody_need_help_whatsapp": "Свяжитесь с нами прямо здесь через WhatsApp.",
    "coupon_factory.check_preview": "Предварительный просмотр купона.",
    "coupon_factory.preview": "Предварительный просмотр",
    "coupon_factory.check_details": "Вся ли информация верна? Нажмите «Создать», чтобы активировать купон!",
    "coupon_factory.respawn_time_description": "Когда клиент может повторно активировать купон после его погашения. Если вы больше не хотите предлагать предложение, вы можете удалить его самостоятельно.",
    "coupon_factory.respawn_time_short_description": "Как долго клиент должен ждать, прежде чем он сможет снова активировать купон.",
    "coupon_factory.thirty_days_recommendation": "Клиент может снова использовать купон через 30 дней. Идеально подходит для предложений, которые регулярно доступны и привлекательны для постоянных клиентов.",
    "coupon_factory.ninety_days_recommendation": "Купон можно использовать снова через 90 дней. Этот вариант хорошо работает для более длительных предложений, нацеленных на новых клиентов.",
    "coupon_factory.weekly_recommendation": "Купон можно использовать повторно раз в неделю. Идеально подходит для краткосрочных рекламных акций, которые необходимо регулярно обновлять.",
    "coupon_factory.thirty_days_recommendation_short": "Идеально подходит для регулярно доступных предложений.",
    "coupon_factory.ninety_days_recommendation_short": "Идеально подходит для редких предложений новых клиентов.",
    "coupon_factory.weekly_recommendation_short": "Идеально подходит для коротких промо-предложений.",
    "coupon_factory.mainly_for_restaurants": "Обычно относится только к гастрономии.",
    "coupon_factory.default_set": "Установлено по умолчанию.",
    "coupon_factory.redeem_location_onsite_info": "Действительно, если покупатель совершает покупку в магазине.",
    "coupon_factory.redeem_location_togo_info": "Действительно, если клиент покупает что-то на вынос, например. шашлык на вынос.",
    "coupon_factory.choose_coupon_type": "Выберите типы купонов",
    "coupon_factory.choose_coupon_type_details": "Выберите тип купона, который вы хотите создать для своего магазина.",
    "coupon_factory.example_header_one": "кэшбэк 10€",
    "coupon_factory.example_body_one": "Получите скидку 10 евро при минимальной сумме покупки 50 евро.",
    "coupon_factory.example_headertitle_one": "Кэшбэк",
    "coupon_factory.example_subheader_one": "Ваши новые клиенты получат прямую скидку на свою покупку/заказ.",
    "coupon_factory.example_header_two": "1 напиток в ПОДАРОК",
    "coupon_factory.example_body_two": "Вы получаете 1 шоколад бесплатно без минимальной стоимости заказа.",
    "coupon_factory.example_headertitle_two": "ПОДАРОК",
    "coupon_factory.example_subheader_two": "Ваши новые клиенты получат подарок при покупке.",
    "coupon_factory.example_header_three": "2 по цене 1 – картофель фри",
    "coupon_factory.example_body_three": "При покупке 1 картошки фри вы получаете 1 картошку фри в подарок. Применяется к товару той же цены или дешевле.",
    "coupon_factory.example_headertitle_three": "2 по цене 1",
    "coupon_factory.example_subheader_three": "Ваши новые клиенты получают 2 продукта по цене одного.",
    "coupon_factory.example_header_four": "Прямая скидка 50% на куртки",
    "coupon_factory.example_body_four": "Вы получите 1 букет цветов со скидкой 50% на каждый без минимальной стоимости заказа.",
    "coupon_factory.example_headertitle_four": "РАСПРОДАЖА",
    "coupon_factory.example_subheader_four": "Ваши клиенты получают прямую скидку на конкретный продукт.",
    "coupon_factory.example_header_individual": "Индивидуально",
    "coupon_factory.example_body_individual": "Индивидуальная сделка",
    "coupon_factory.example_headertitle_individual": "Индивидуально",
    "coupon_factory.example_subheader_individual": "Создайте полностью индивидуальный купон для своих клиентов.",
    "welcome": "Добро пожаловать",
    "languages.de": "🇩🇪 немецкий",
    "languages.en": "🇺🇸Английский",
    "languages.tr": "🇹🇷 турецкий",
    "languages.ar": "🇸🇦 арабский",
    "languages.fr": "🇫🇷 Французский",
    "languages.es": "🇪🇸Испанский",
    "languages.it": "🇮🇹 Итальянский",
    "languages.pl": "🇵🇱Польский",
    "languages.ru": "🇷🇺Русский",
    "popular_categories": "Популярные категории",
    "no_matching_categories_found": "Соответствующие категории не найдены",
    "hello": "Привет из Германии",
    "with_coupons": "С купонами",
    "cities_with_digital_basar": "Города с цифровым базаром",
    "use_your_location": "Используйте свое местоположение",
    "no_matching_cities_found": "Подходящих городов не найдено",
    "discover": "Обнаружить",
    "favorites": "Избранное",
    "profile": "Профили",
    "delete": "Удалить",
    "openingTimes": "Часы работы",
    "editOpeningTimes": "Изменить часы работы",
    "days.monday": "Понедельник",
    "days.tuesday": "Среда",
    "days.wednesday": "Среда",
    "days.thursday": "Четверг",
    "days.friday": "Пятница",
    "days.saturday": "Суббота",
    "days.sunday": "Воскресенье",
    "command_searchForYourBusiness": "Найдите свой бизнес",
    "placeholder_find_store_google": "Найдите свою компанию в Google",
    "permission_to_camera_roll": "Доступ к фотобиблиотеке обязателен!",
    "businessMustBeOnGoogleMaps": "Ваш бизнес должен быть виден на Картах Google. Если у вас возникли проблемы, пожалуйста, свяжитесь с нами здесь.",
    "closed": "Закрыто",
    "location": "Расположение",
    "coupons": "Купоны",
    "activated_coupons": "Активированные купоны",
    "redeemed_coupons": "Погашенные купоны",
    "activate": "Активировать",
    "redeem_now": "Активировать сейчас",
    "coupon_redeemed_message": "Купон погашен — так держать! Так мы поддерживаем местную торговлю.",
    "coupon_redeemed_message_title": "Купон погашен!",
    "coupon_redeemed_message_bodxy": "Так держать! Так мы поддерживаем местную торговлю.",
    "share_message": "Привет, я в BASAR. Загрузите приложение по этой ссылке.",
    "share_success": "Делитесь успешно!",
    "share_dismissed": "Публикация отменена",
    "share_failed": "Не удалось поделиться:",
    "report_problem": "Сообщить о проблеме",
    "deactivate_coupon": "Деактивировать купон",
    "info": "Информация",
    "more": "Более",
    "Information about this Coupons": "Информация об этом купоне",
    "command_mention_basar_for_redeem": "Перед оплатой сообщите, что у вас есть ваучер BASAR, и активируйте его в присутствии сотрудника. За одно посещение можно использовать только один купон.",
    "Redeem Location": "Место погашения",
    "redeem_location_info": "Предложение на месте или на вынос",
    "Reactivation time": "Время реактивации",
    "reactivation_info": "Когда предложение снова станет для вас активным после того, как вы его активировали.",
    "reviews": "Отзывы",
    "review": "Отзывы",
    "show_all_reviews": "Посмотреть все отзывы",
    "show_all": "Посмотреть все",
    "REVIEW_SENT": "Отзыв отправлен.",
    "REVIEW_THANKS": "Спасибо за ваш отзыв!",
    "REVIEW_WRITE": "Напишите свой отзыв...",
    "loading": "Загрузка",
    "noReviewsAvailable": "Комментариев пока нет",
    "noStoreInformationAvailable": "Пока нет информации",
    "advetorials.vendor.become_vendor_title": "У вас есть местный магазин на территории?",
    "advetorials.vendor.become_vendor_body": "Тогда станьте дилером в нашем приложении прямо сейчас. У нас много пользователей, которые хотели бы поддержать ваш местный бизнес.",
    "advetorials.vendor.download_vendor_app": "Загрузите приложение продавца из App Store.",
    "become_Member": "Стать участником",
    "enter_discount": "Введите код скидки",
    "translated_by_ai": "Переводы могли быть созданы с использованием ИИ и поэтому не всегда идеальны.",
    "closeButton": "Закрывать",
    "edit_profile": "Редактировать профиль",
    "validation_invalid_input": "Неверный ввод",
    "validation_required_input": "Поле должно быть заполнено",
    "success": "Подтвержденный",
    "error": "Ошибка",
    "profile_update_success": "Профиль успешно обновлен!",
    "profile_image_update_success": "Изображение профиля успешно обновлено",
    "profile_image_update_error": "Не удалось обновить изображение профиля.",
    "tutorial": "Учебник",
    "settings": "Настройки",
    "feedbackAndSupport": "Обратная связь и поддержка",
    "changeToVendorMode": "Переключиться в дилерский режим",
    "changeToUserMode": "Перейти в режим клиента",
    "registerAsPartner": "Зарегистрируйтесь как партнер",
    "logout": "Выйти",
    "first_name": "Имя",
    "last_name": "Фамилия",
    "email": "Электронная почта",
    "zipCode": "Почтовый индекс",
    "edit": "Редактировать",
    "delete_account": "Удаление профилей",
    "delete_account_message": "Вы уверены, что хотите удалить свой профиль? Все ваши преимущества и настройки будут безвозвратно потеряны.",
    "delete_account_success": "Профиль успешно удален",
    "delete_account_success_message": "Ваш профиль успешно удален. 👋 Надеемся скоро увидеться снова",
    "save": "Сохранять",
    "back": "Выпекать",
    "continue": "Продолжать",
    "later": "Позже",
    "terms_and_conditions": "Общие условия заключения сделок",
    "agree_to_terms": "Я согласен с <terms>Общими положениями и условиями</terms>.",
    "phonenumber": "Номер телефона",
    "enter_phonenumber": "Введите номер телефона",
    "login_no_password_info": "Легкий вход с помощью SMS-кода. Поэтому вам не нужно запоминать пароль.",
    "enter_code": "Введите код",
    "sent_to": "Отправлено в",
    "search_country_code": "Поиск по коду страны или города",
    "command_agree": "Пожалуйста, согласитесь",
    "command_agree_to_terms": "Вы должны согласиться с условиями, чтобы продолжить.",
    "otp": "Одноразовый пароль",
    "enter_otp": "Введите одноразовый пароль",
    "verify_otp": "Подтвердите одноразовый пароль",
    "otp_expired": "Срок действия одноразового пароля истек",
    "otp_incorrect": "Неверный одноразовый пароль",
    "otp_verification_error": "Введен неверный или просроченный одноразовый пароль.",
    "command_create_profile_to_start": "Создайте свой профиль, чтобы начать!",
    "command_create_company_to_start": "Зарегистрируйте свою компанию, чтобы начать.",
    "info_create_shop_later": "На следующем шаге вы можете предоставить информацию о своем магазине.",
    "your_concern": "Ваш запрос",
    "describe_your_concern": "Сообщите нам свой запрос.",
    "contact_you_asap": "Мы свяжемся с вами как можно быстрее.",
    "contact_via_whatsapp": "Свяжитесь с нами через WhatsApp",
    "change_image": "Изменить изображение",
    "edit_store_profile": "Бизнес-профиль Редактировать",
    "error_loading_store": "Ошибка при загрузке бизнес-данных.",
    "error_messages.no_stores.no_stores_nearby_found_title": "Рядом с вами не найдено ни одного магазина",
    "error_messages.no_stores.no_stores_nearby_found_body": "Видимо, мы еще не быстрые. Но мы доберемся – не волнуйтесь. Измените город с помощью поиска или найдите его с помощью карты.",
    "error_messages.conflict.store_exists_title": "Предприятие по этому адресу уже зарегистрировано.",
    "error_messages.conflict.store_exists_body": "Вы не можете создать уже зарегистрированный магазин. Если вы считаете, что это ошибка, свяжитесь с нами здесь.",
    "funny_error_message": "Упс, что-то пошло не так.",
    "page_not_found": "Страница не может быть загружена.",
    "page_not_found_connect_to_wifi": "Пожалуйста, убедитесь, что вы подключены к Интернету.",
    "page_not_found_help_message": "Если проблема не устранена, пожалуйста, свяжитесь с нами",
    "cancel": "Отмена",
    "partner_login": "Вход для партнеров",
    "partner_register": "Регистрация партнера",
    "command_no_account_register": "Нет аккаунта? Зарегистрируйтесь здесь.",
    "command_have_account_login": "У вас есть учетная запись? Войдите здесь.",
    "login": "Авторизоваться",
    "register": "Регистрация",
    "please_enter_email": "Пожалуйста, укажите адрес электронной почты",
    "email_sent": "Письмо отправлено",
    "password_reset_email_sent": "Письмо отправлено для сброса пароля.",
    "password_reset_failed": "Сбросить пароль не удалось.",
    "forgot_password": "Забыли пароль",
    "password_reset_rate_limit": "Из соображений безопасности вы можете делать этот запрос только один раз каждые 60 секунд. Пожалуйста, подождите и повторите попытку.",
    "back_to_login": "Вернуться к входу",
    "company_profile": "Профиль компании",
    "company_details": "Подробности о компании",
    "company_email": "Электронная почта компании",
    "company_name": "Название компании",
    "company_address": "Адрес компании",
    "company_zipCode": "Почтовый индекс",
    "company_city": "Город",
    "company_country": "Страна",
    "company_phone": "Телефон компании",
    "company_pocName": "Имя, Фамилия (контактное лицо)",
    "company_pocRole": "Выберите роль (контактное лицо)",
    "store_image": "Изображение магазина",
    "vendor_delete_account": "Вы уверены, что хотите удалить свой профиль?",
    "vendor_delete_account_body": "В настоящее время ваш профиль можно удалить, только связавшись с нами через WhatsApp или по электронной почте basar@basar-digital.com.",
    "select_a_category": "Выберите категорию",
    "categories.grocery": "Продукты питания",
    "categories.restaurants": "Ресторан",
    "categories.hairSalon": "Парикмахерская",
    "categories.electronics": "Электроника",
    "categories.beauty_wellness": "Красота и здоровье",
    "categories.other": "Другой",
    "categories.non_food_retail": "Розничная торговля",
    "categories.home_and_garden": "Дом и сад",
    "categories.automotive": "Мобильность",
    "categories.flowers_and_gifts": "Цветы и подарки",
    "categories.activities": "Деятельность",
    "categories.cafe_and_dessert": "Кафе и десерт",
    "categories.farm_shop": "Фермерский магазин",
    "subcategories.convenienceStore": "Мини-маркет",
    "subcategories.eastEuropean": "Восточноевропейский",
    "subcategories.beverageMarket": "Рынок напитков",
    "subcategories.orientalGrocery": "Восточный",
    "subcategories.indianGrocery": "Индийские блюда",
    "subcategories.importedGrocery": "Импортировать",
    "subcategories.bioOrganic": "Органический и органический",
    "subcategories.africanGrocery": "Африканский",
    "subcategories.turkishSupermarket": "Турецкий",
    "subcategories.latinAmericanSupermarket": "Латиноамериканский",
    "subcategories.middleEasternSupermarket": "Средний Восток",
    "subcategories.russianSupermarket": "Русский",
    "subcategories.polishSupermarket": "Польский",
    "subcategories.mediterraneanSupermarket": "Средиземноморье",
    "subcategories.veganVegetarianSupermarket": "Веганский/вегетарианский",
    "subcategories.halalMarket": "Халяль",
    "subcategories.kosherSupermarket": "Кошерный",
    "subcategories.southAsianSupermarket": "Южная Азия",
    "subcategories.germanCuisine": "Немецкий",
    "subcategories.frenchRestaurant": "Французский",
    "subcategories.spanishTapasBar": "Испанский",
    "subcategories.greekTavern": "Греческий",
    "subcategories.mexicanRestaurant": "Мексиканский",
    "subcategories.indianRestaurant": "Индийский",
    "subcategories.sushiBar": "Суши-бар",
    "subcategories.steakhouse": "Стейкхаус",
    "subcategories.vegetarianVeganRestaurant": "Веганский/вегетарианский",
    "subcategories.foodTruck": "Фургон с едой",
    "subcategories.fusionCuisine": "Международный",
    "subcategories.medievalTavern": "Средневековый",
    "subcategories.patisserie": "Кондитерская",
    "subcategories.lowCarb": "Низкоуглеводный",
    "subcategories.brideAndEvent": "Невеста и мероприятие",
    "subcategories.afroBarber": "Афро-парикмахер",
    "subcategories.hairSpecialist": "Специалист по волосам",
    "subcategories.permanentHairRemoval": "Удаление волос навсегда",
    "subcategories.hairReplacement": "Удаление волос",
    "subcategories.barberServices": "Парикмахерские услуги",
    "subcategories.veganCafe": "Веган",
    "subcategories.outdoorActivities": "Активный отдых на свежем воздухе",
    "subcategories.eventDecorators": "Событие Деко",
    "subcategories.indoorPlants": "Комнатные растения",
    "subcategories.outdoorPlants": "Уличные растения",
    "subcategories.ballonAndParty": "Воздушные шары и товары для вечеринок",
    "subcategories.handmade": "Ручной работы",
    "subcategories.candleAndFragrances": "Свечи и ароматы",
    "subcategories.autoDetailing": "Подготовка автомобиля",
    "subcategories.autoBodyAndPaint": "Кузов и покраска",
    "subcategories.carRental": "Прокат автомобилей",
    "subcategories.autoGlass": "Автостекло",
    "subcategories.inspection": "Инспекция",
    "subcategories.oilChange": "Замена масла",
    "subcategories.motorcycle": "Мотоцикл",
    "subcategories.autoAccessories": "Автомобильные аксессуары",
    "subcategories.autoTuning": "Тюнинг мастерская",
    "subcategories.homeDecor": "Украшение дома",
    "subcategories.homeImprovement": "Обустройство дома",
    "subcategories.lightingStore": "Лампы и светильники",
    "subcategories.applianceStore": "Бытовая техника",
    "subcategories.kitchenAndBath": "Кухня и сантехника",
    "subcategories.securityAndSmartHome": "Безопасность и Умный Дом",
    "subcategories.diy": "Сделай сам",
    "subcategories.pharmacy": "Аптека",
    "subcategories.medicalSupply": "Медицинский магазин",
    "subcategories.jewlery": "Ювелир",
    "subcategories.toyStore": "Магазин игрушек",
    "subcategories.musicStore": "Музыка и инструменты",
    "subcategories.secondhandStores": "Подержанный",
    "subcategories.travelAgency": "Туристическое агентство",
    "subcategories.photoStudio": "Фотостудия",
    "subcategories.tailorShop": "Пошив одежды",
    "subcategories.cleaningService": "Уборка",
    "subcategories.shoeRepair": "Ремонт обуви",
    "subcategories.keyCopyService": "Слесарные услуги",
    "subcategories.laundryService": "Услуги прачечной",
    "subcategories.locksmith": "Слесарь",
    "subcategories.mobileRepair": "Ремонт сотового телефона",
    "subcategories.computerRepair": "Ремонт компьютеров",
    "subcategories.packagingService": "Упаковочный сервис",
    "subcategories.printShop": "Типография",
    "subcategories.eventPlanning": "Планирование мероприятий",
    "subcategories.regionalProducts": "Региональные продукты",
    "subcategories.butcherGrocery": "Мясник",
    "subcategories.bakeryGrocery": "Выпечка",
    "subcategories.asianGrocery": "Азиатский",
    "subcategories.eastEuropaen": "Восточноевропейский",
    "subcategories.farmGrocery": "Фермерский магазин",
    "subcategories.halalRestaurant": "Халяль",
    "subcategories.asianRestaurants": "Азиатский",
    "subcategories.seafoodRestaurant": "Морепродукты",
    "subcategories.italianRestaurant": "Итальянский",
    "subcategories.burgerRestaurant": "Бургеры",
    "subcategories.menHairSalon": "Господа",
    "subcategories.kidsHairSalon": "Дети",
    "subcategories.womenHairSalon": "Женщины",
    "subcategories.gadgets": "Гаджеты",
    "subcategories.vegetarianRestaurant": "Вегетарианец",
    "subcategories.mediterraneanRestaurant": "Средиземноморье",
    "subcategories.fishMarket": "Рыбный рынок",
    "subcategories.imker": "Пчеловод",
    "subcategories.makeup": "Составить",
    "subcategories.barbeque": "БАРБЕКЮ",
    "subcategories.bubble_tea": "Пузырьковый чай",
    "subcategories.african": "Африканский",
    "subcategories.hair_treatments": "Уход за волосами",
    "subcategories.hair_removal": "Удаление волос",
    "subcategories.teeth_whitening": "Отбеливание зубов",
    "subcategories.acupuncture": "Иглоукалывание",
    "subcategories.nail_salon": "Маникюрный салон",
    "subcategories.therapies": "Терапия",
    "subcategories.meat": "Мясо",
    "subcategories.buffet": "Шведские столы",
    "subcategories.fried": "Жареная еда",
    "subcategories.fast_food": "Быстрое питание",
    "subcategories.breakfast_brunch": "Завтрак | поздний завтрак",
    "subcategories.bistro_imbiss": "Бистро | закуска",
    "subcategories.manicure_pedicure": "Маникюр и педикюр",
    "subcategories.facial_treatments": "Уход за лицом",
    "subcategories.cosmetic_supply": "Косметическая продукция",
    "subcategories.beverage_market": "Рынок напитков",
    "subcategories.dairyGrocery": "Молочный",
    "subcategories.vegetableGrocery": "Овощи",
    "subcategories.dessertBar": "Десерты",
    "subcategories.crepes": "Блинчики",
    "subcategories.waffles": "Вафли",
    "subcategories.juices": "Соки",
    "subcategories.tea_house": "Чайхана",
    "subcategories.sweet_pastry": "Сладкая выпечка",
    "subcategories.candy_shop": "Кондитерская",
    "subcategories.dairyFarmShop": "Молочный",
    "subcategories.fruit_vegetable": "Фрукты и овощи",
    "subcategories.eggs": "Яйца",
    "subcategories.bio_organic": "Органический и органический",
    "subcategories.scooping": "Банки",
    "subcategories.indian_grocery": "Индийский",
    "subcategories.imported_grocery": "Импортировать",
    "subcategories.spaWellness": "Спа и велнес",
    "subcategories.massageCenter": "Массажи",
    "subcategories.gifts": "Подарки",
    "subcategories.cafe": "Кафе",
    "subcategories.iceCreamParlor": "Кафе-мороженое",
    "subcategories.bakeryCafe": "Выпечка",
    "subcategories.sportsCenter": "Спорт",
    "subcategories.kidsPlayArea": "Детский игровой парк",
    "subcategories.art": "Искусство",
    "subcategories.cinema": "Кино",
    "subcategories.theater": "Театр",
    "subcategories.escape_room": "Квесты",
    "subcategories.climbing": "Взбираться",
    "subcategories.musuem": "Музей",
    "subcategories.flowerShop": "Цветы",
    "subcategories.giftShop": "Подарки",
    "subcategories.carRepair": "Ремонт",
    "subcategories.carWash": "Стирать",
    "subcategories.tireShop": "Шины",
    "subcategories.furnitureStore": "Мебель",
    "subcategories.gardenCenter": "Сад",
    "subcategories.clothingStore": "Магазин одежды",
    "subcategories.electronicsStore": "Электроника",
    "subcategories.petStore": "Товары для домашних животных",
    "subcategories.zooStore": "Товары для зоопарков",
    "subcategories.bookstore": "Книги и журналы",
    "subcategories.cosmetic": "Косметика",
    "keywords.oriental": "Восточный",
    "keywords.middleEastern": "Ближний Восток",
    "keywords.arabic": "Арабский",
    "keywords.turkish": "Турецкий",
    "keywords.moroccan": "Марокканский",
    "keywords.persian": "Персидский",
    "keywords.butcher": "Мясник",
    "keywords.meat": "Мясо",
    "keywords.sausage": "Колбаса",
    "keywords.meatShop": "Мясной магазин",
    "keywords.slaughterhouse": "Мясник",
    "keywords.bakery": "Пекарня",
    "keywords.bread": "Хлеб",
    "keywords.pastry": "Выпечка",
    "keywords.roll": "Булочки",
    "keywords.cake": "Торт",
    "keywords.patisserie": "Кондитерская",
    "keywords.asian": "Азиатский",
    "keywords.chinese": "Китайский",
    "keywords.japanese": "Японский",
    "keywords.thai": "Тайский",
    "keywords.vietnamese": "Вьетнамский",
    "keywords.korean": "Корейский",
    "keywords.indian": "Индийский",
    "keywords.farmShop": "Фермерский магазин",
    "keywords.farmersMarket": "Фермерский рынок",
    "keywords.regional": "Региональный",
    "keywords.organic": "Органический",
    "keywords.fresh": "Свежий",
    "keywords.agricultural": "Сельскохозяйственный",
    "keywords.halal": "Халяль",
    "keywords.halalCertified": "Халяль сертифицирован",
    "keywords.islamic": "Исламский",
    "keywords.muslim": "Мусульманин",
    "keywords.doner": "Донер-кебаб",
    "keywords.sushi": "Суши",
    "keywords.fish": "Рыба",
    "keywords.seafood": "Морепродукты",
    "keywords.salmon": "Лосось",
    "keywords.shrimp": "Креветка",
    "keywords.italian": "Итальянский",
    "keywords.pizza": "Пицца",
    "keywords.pasta": "Макароны",
    "keywords.risotto": "Ризотто",
    "keywords.tiramisu": "Тирамису",
    "keywords.antipasti": "Антипасти",
    "keywords.burger": "Граждане",
    "keywords.hamburger": "Гамбургер",
    "keywords.cheeseburger": "Чизбургеры",
    "keywords.fries": "Фриз",
    "keywords.fastFood": "Быстрое питание",
    "keywords.menHairdresser": "Мужской парикмахер",
    "keywords.menSalon": "Мужской салон",
    "keywords.barber": "Парикмахерская",
    "keywords.shaving": "Бритье",
    "keywords.haircutForMen": "Стрижка мужская",
    "keywords.kidsHairdresser": "Детская парикмахерская",
    "keywords.kidCut": "Детский крой",
    "keywords.kidHaircut": "Детская стрижка",
    "keywords.haircutForKids": "Стрижка для детей",
    "keywords.kidStyling": "Детская укладка",
    "keywords.womenHairdresser": "Женский парикмахер",
    "keywords.womenSalon": "Женский салон",
    "keywords.haircutForWomen": "Стрижка женская",
    "keywords.dyeing": "Крашение",
    "keywords.stylingForWomen": "Стиль для женщин",
    "keywords.technology": "Технология",
    "keywords.electronics": "Электроника",
    "keywords.devices": "Устройства",
    "keywords.accessories": "Аксессуары",
    "units.km": "Км",
    "open_24_hours": "Открыто 24 часа",
    "favorites_and_bookings": "Избранное и бронирования",
    "no_favorites": "Нет избранных!",
    "no_favorites_text": "Вы еще не добавили ни одного избранного. Просматривайте местные магазины и сохраняйте их в избранном, чтобы позже получить к ним доступ!",
    "no_coupons": "Нет доступных купонов!",
    "no_coupons_text": "Вы еще не активировали ни одного купона. Откройте для себя и активируйте купоны, чтобы получить скидки!",
    "onboardingTutorial_title_step1": "Обнаружить",
    "onboardingTutorial_description_step1": "Откройте для себя мир местной коммерции, будь то рестораны, супермаркеты, парикмахерские, салоны красоты, мастерские и многое другое!",
    "onboardingTutorial_imageCaption_step1": "Все есть, все близко.",
    "onboardingTutorial_title_step2": "Выкупать",
    "onboardingTutorial_description_step2": "Наши местные розничные партнеры приглашают вас воспользоваться прямыми скидками и подарками на товары или услуги.",
    "onboardingTutorial_imageCaption_step2": "Поддержать местных и сэкономить деньги невозможно? Но!",
    "onboardingTutorial_title_step3": "Сохранять",
    "onboardingTutorial_description_step3": "Все предложения готовы вручить в вашем кармане. Вы просто показываете свою бронь сотруднику магазина. Вот как мы поддерживаем местную торговлю!",
    "onboardingTutorial_imageCaption_step3": "Быстро и легко для всех.",
    "confirm": "Подтверждать",
    "start_now": "Начни сейчас",
    "next": "Дальше",
    "submit": "Представлять на рассмотрение",
    "company_name_required": "Требуется название компании",
    "company_email_required": "Требуется адрес электронной почты компании",
    "invalid_email": "Неверный адрес электронной почты",
    "invalid_zipCode": "Неверный почтовый индекс",
    "company_address_required": "Требуется адрес компании",
    "company_zipCode_required": "Требуется почтовый индекс",
    "company_city_required": "Требуется город",
    "company_country_required": "Укажите страну",
    "company_phone_required": "Требуется телефон компании",
    "invalid_phone": "Неверный номер телефона",
    "placeholder_company_name": "Введите название компании",
    "placeholder_company_email": "Введите адрес электронной почты компании",
    "placeholder_company_address": "Введите улицу и номер дома",
    "placeholder_company_zipCode": "Введите почтовый индекс",
    "placeholder_company_city": "Введите город",
    "placeholder_company_country": "Введите страну",
    "placeholder_company_phone": "Введите номер телефона",
    "placeholder_company_pocName": "Введите имя и фамилию контактного лица",
    "placeholder_company_pocRole": "Выберите роль контактного лица",
    "no_stores": "Бизнес не создан",
    "no_stores_text": "Вы еще не добавили магазин. Нажмите здесь и в мгновение ока создайте свой магазин, чтобы иметь возможность предлагать купоны.",
    "more_stores": "У вас есть еще один филиал?",
    "more_stores_text": "Нажмите здесь, чтобы добавить еще один магазин, где вы также можете создавать купоны.",
    "stores_of_company": "Филиалы вашей компании",
    "vendor_poc_roles.owner": "Владелец",
    "vendor_poc_roles.employee": "Трудоустроен",
    "vendor_poc_roles.else": "Другой",
    "coupon.ON_SITE": "На месте",
    "coupon.TO_GO": "Идти",
    "coupon.NINETY_DAYS": "90 дней",
    "coupon.THIRTY_DAYS": "30 дней",
    "coupon.WEEKLY": "Еженедельно",
    "coupon.ONE_YEAR": "Ежегодно",
    "coupon.DAILY": "Ежедневно",
    "coupon.THREE_DAYS": "3 дня",
    "coupon.HUNDRED_EIGHTY_DAYS": "180 дней",
    "paywall.price_description_yearly": "Ежегодный платеж",
    "paywall.price_description_monthly": "Ежемесячные платежи",
    "monetization.paywall_title": "Все предложения. Немедленно сохранитесь.",
    "monetization.maybe_later": "Может быть позже",
    "monetization.monthly": "Ежемесячно",
    "monetization.yearly": "Ежегодно",
    "monetization.monthly_marketing.first_benefit": "Обычно всего 1-2 покупки того стоят!",
    "monetization.monthly_marketing.second_benefit": "Возможна отмена ежемесячно!",
    "monetization.monthly_marketing.third_benefit": "Немедленно активируйте все купоны.",
    "monetization.yearly_marketing.first_benefit": "Всего 3-4 покупки в год того стоят!",
    "monetization.yearly_marketing.second_benefit": "Платите один раз, наслаждайтесь в течение года!",
    "monetization.yearly_marketing.third_benefit": "Немедленно активируйте все купоны.",
    "monetization.price_description_effective_per_month": "Эффективность в месяц",
    "monetization.price_description_per_month": "Помесячно",
    "monetization.confirmation_title": "Покупка удачная!",
    "monetization.confirmation_message": "Благодарим вас за покупку. Теперь у вас есть доступ ко всем премиум-функциям.",
    "validation.setProductName": "Должно быть указано название продукта.",
    "for": "Для",
    "coupon_factory.2for1_title": "Купи 2, получи 1 бесплатно",
    "coupon_factory.2for1_description": "Или даже больше. Создайте объемное предложение",
    "coupon_factory.win_customers_and_money": "Привлекайте клиентов и продажи.",
    "coupon_factory.example": "Пример купона",
    "coupon_factory.create_now": "Создать сейчас",
    "coupon_factory.create_coupon_marketing_header": "Получите новых клиентов бесплатно прямо сейчас",
    "coupon_factory.create_coupon_marketing_body": "Создайте купон всего за несколько шагов.",
    "coupon_factory.create_coupon_benefit_header_1": "🎯 Новые клиенты",
    "coupon_factory.create_coupon_benefit_body_1": "Привлекайте новых клиентов прямо через свой смартфон",
    "coupon_factory.create_coupon_benefit_header_2": "🚀 Легко и быстро",
    "coupon_factory.create_coupon_benefit_body_2": "Создавайте сделки проще, чем когда-либо прежде.",
    "coupon_factory.create_coupon_benefit_header_3": "💸 Больше продаж",
    "coupon_factory.create_coupon_benefit_body_3": "Увеличьте свои продажи с помощью купонов для ваших клиентов.",
    "coupon_factory.create_coupon_card": "Создайте купон!",
    "coupon_factory.create_coupon_card_info": "Нажмите здесь, чтобы создать купон за 3 шага.",
    "coupon_factory.create_coupon": "Создайте купон!",
    "coupon_factory.create_coupon_body": "Три шага к идеальной рекламе для новых клиентов!",
    "coupon_factory.condition_for_customer": "Установить условие для клиентов",
    "coupon_factory.choose_condition": "Выберите условие",
    "coupon_factory.choose_condition_info": "Что должен сделать клиент, чтобы получить выгоду",
    "coupon_factory.minimum_order_value": "Минимальная стоимость заказа",
    "coupon_factory.minimum_order_value_info": "Клиент должен потратить определенную сумму",
    "coupon_factory.product_purchase": "Покупка продукта",
    "coupon_factory.product_purchase_info": "Клиент должен купить определенные продукты",
    "coupon_factory.direct_rebate": "Прямая скидка на товар",
    "coupon_factory.no_conditions": "Никаких условий",
    "coupon_factory.no_conditions_set": "Условия не заданы",
    "coupon_factory.go_to_next_step": "Перейти к следующему шагу",
    "coupon_factory.input_mov": "Укажите минимальную стоимость покупки",
    "coupon_factory.count_of_products": "Количество продуктов",
    "coupon_factory.product_name": "Название продукта",
    "coupon_factory.value_of_product_per_piece": "Стоимость товара (за штуку)",
    "coupon_factory.order_discount": "Скидка на покупку",
    "coupon_factory.product_discount": "Скидка на товар",
    "coupon_factory.free_product": "Бесплатный продукт",
    "coupon_factory.discount": "Скидка",
    "coupon_factory.enter_discount_amount": "Введите сумму скидки",
    "coupon_factory.redeem_location_conditons": "Условия скидки",
    "coupon_factory.redeem_location_info": "Где ваши клиенты могут воспользоваться скидкой?",
    "coupon_factory.choose_discount": "Скидка",
    "coupon_factory.choose_discount_info": "Какую скидку получает ваш клиент?",
    "coupon_factory.carousel_header_one": "Выберите купон",
    "coupon_factory.carousel_body_one": "Выберите купон, который хотите создать.",
    "coupon_factory.carousel_header_two": "Установить условие",
    "coupon_factory.carousel_body_two": "Определите, что нужно сделать клиенту.",
    "coupon_factory.carousel_header_three": "Установите преимущества",
    "coupon_factory.carousel_body_three": "Определите, что получит клиент.",
    "coupon_factory.product_quantitiy": "Количество на одного клиента",
    "coupon_factory.product_quantitiy_info": "Скидка распространяется на штуку!",
    "coupon_factory.product_quantitiy_free_product_info": "Сколько штук он получит БЕСПЛАТНО?",
    "coupon_factory.same_product": "Тот же продукт",
    "coupon_factory.same_product_info": "Клиент получает тот же товар.",
    "coupon_factory.other_product": "Другой продукт",
    "coupon_factory.other_product_info": "Пожалуйста, укажите название продукта.",
    "coupon_factory.price_per_unit": "Цена за штуку",
    "coupon_factory.error_invalid_input": "Неверный ввод",
    "coupon_factory.error_rebate_higher_as_order_value": "Скидка не может быть выше суммы покупки/товара",
    "coupon_factory.error_rebate_higher_as_product_value": "Скидка не может быть выше суммы покупки/товара",
    "coupon_factory.max_created_coupon_card": "Достигнуто максимальное количество купонов",
    "coupon_factory.max_created_coupon_card_info": "Вы достигли текущего максимума ваших купонов.",
    "coupon_factory.max_created_stores": "Достигнуто максимальное количество магазинов",
    "coupon_factory.max_created_stores_info": "Вы достигли текущего максимума сделок, которые можно создать. Если вы хотите создать еще один магазин, свяжитесь с нами, используя контактную форму в настройках.",
    "details": "Подробности",
    "enter_details": "Предоставьте подробную информацию",
    "store_factory.store_conflict": "Проблема возникла с магазином",
    "store_factory.store_conflict_info": "Выбранный магазин в Google уже зарегистрирован на Bazaar. Пожалуйста, проверьте один раз. Если у вас все еще есть проблемы, пожалуйста, свяжитесь с нами.",
    "store_factory.wrong_store": "Неправильно выбран бизнес?",
    "store_factory.wrong_store_search_again": "Хотите перезагрузить еще раз? Обратите внимание, что ваша информация в Google должна быть верной.",
    "store_factory.restart": "Перезапуск",
    "store_factory.what_customers_see": "Вот что видят ваши клиенты",
    "store_factory.check_details": "Проверьте, верна ли ваша информация.",
    "store_factory.store_name": "Название филиала",
    "store_factory.store_name_example": "Название вашего бизнеса",
    "store_factory.store_phone": "Телефон для ваших клиентов",
    "store_factory.store_email": "Электронная почта для ваших клиентов",
    "store_factory.choose_category": "Выберите категорию",
    "store_factory.choose_category_info": "Выберите основную категорию, до 3-х подкатегорий и загрузите изображение вашего магазина.",
    "store_factory.main_category": "Основная категория",
    "store_factory.sub_categories": "Подкатегории",
    "store_factory.upload_store_img": "Загрузите изображение магазина",
    "store_factory.store_img": "Фото из магазина",
    "store_factory.max_achieved": "Максимум достигнут",
    "store_factory.sub_categories_limit": "Вы можете выбрать максимум 3 подкатегории.",
    "store_factory.categories_and_image_required": "Категории и изображения необходимы!",
    "store_factory.zip_code": "Почтовый индекс",
    "store_factory.zip_code_placeholder": "Например. 44801",
    "store_factory.claim_store": "Возьмите на себя бизнес",
    "store_factory.house_number": "Номер дома",
    "store_factory.house_number_placeholder": "Например. 33",
    "store_factory.street": "Улица",
    "store_factory.street_placeholder": "Например. Базарная улица",
    "store_factory.city": "Город",
    "store_factory.city_placeholder": "Например. Бохум",
    "store_factory.success": "Магазин успешно создан",
    "store_factory.success_info": "Ваш магазин успешно создан и сейчас проходит проверку.",
    "store_factory.success_verification": "Проверка обычно занимает 1-2 рабочих дня. После завершения проверки ваш магазин откроется и будет виден покупателям.",
    "store_factory.verification_pending": "Ожидается проверка",
    "store_factory.verification_pending_info": "В настоящее время идет процесс проверки. Мы свяжемся с вами, если нам понадобится от вас дополнительная информация. Если у вас есть какие-либо вопросы, пожалуйста, свяжитесь с нами.",
    "store_factory.no_coupons": "Купонов пока нет",
    "store_factory.no_coupons_info": "Вы еще не создали ни одного купона. Нажмите на магазин, чтобы создать купон.",
    "to_dashboard": "На панель управления",
    "error_unexpected": "Произошла непредвиденная ошибка. Попробуйте еще раз. Если проблема не устранена, пожалуйста, свяжитесь с нами.",
    "check_inputs": "Проверить информацию",
    "warning": "Опасность",
    "progress_deleted_info": "Ваш временный статус будет удален, если вы вернетесь сюда. Вы уверены?",
    "notification": "Уведомление",
    "notifications": "Уведомления",
    "request_notification_permission": "Включить push-уведомления?",
    "denied_notification_permission": "Push-уведомления отклонены",
    "denied": "Отклонен",
    "activate_notification_in_settings": "Вы можете включить push-уведомления в настройках.",
    "notifcationsTitle.message1": "🥳 Пришло время местных скидок!",
    "notifcationsBody.message1": "Сэкономьте сейчас с эксклюзивными купонами в местных магазинах! Будь то еда, мода или отдых — откройте для себя отличные скидки и поддержите свое сообщество!"
  },
  "pl": {
    "!add_row_below": "VVV Dodaj linię poniżej VVV",
    "so_easy_for_partners": "Dla partnerów jest to takie proste",
    "so_easy_for_partners.register_store": "Zarejestruj swoją firmę szybko i łatwo, aby dotrzeć do większej liczby klientów.",
    "so_easy_for_partners.create_coupon": "Twórz indywidualne vouchery dla swoich klientów, zwiększaj sprzedaż i zdobywaj nowych klientów – wszystko według Twoich życzeń.",
    "so_easy_for_partners.manage_store": "Zarządzaj swoim sklepem z łatwością - w dowolnym momencie dostosuj godziny otwarcia, obrazy i kupony.",
    "change_language": "Zmień język",
    "english_USA": "🇬🇧 Angielski",
    "german_Germany": "🇩🇪 niemiecki",
    "Imprint": "Odcisk",
    "Datenschutz": "Polityka prywatności",
    "AGB": "Ogólne warunki handlowe",
    "Cookies": "Ciastka",
    "about": "O NAS",
    "our_magazine": "Nasz magazyn",
    "for_companies": "Dla firm",
    "contact": "Kontakt",
    "no_answer_found": "Brak odpowiedzi na pytanie?",
    "jobs": "Praca",
    "become_partner": "Zostań partnerem",
    "shop": "Sklep",
    "experiences": "Wzruszenie religijne",
    "legal": "PRAWNY",
    "social_media": "MEDIA SPOŁECZNOŚCIOWE",
    "frequently_asked_questions": "Często zadawane pytania",
    "vendor_faq.how_work_question": "Jak BASAR działa dla partnerów?",
    "vendor_faq.how_work_answer": "Nawiązaliśmy współpracę z różnymi lokalnymi firmami i wynegocjowaliśmy wyjątkowe oferty, takie jak usługi 2 w cenie 1 lub rabaty na produkty. Aplikacja Bazaar umożliwia przeglądanie, rezerwację i realizację ofert w wielu branżach, takich jak restauracje, fryzjerzy, warsztaty, kwiaciarnie i wiele innych.",
    "vendor_faq.participating_businesses_question": "Które firmy biorą udział?",
    "vendor_faq.participating_businesses_answer": "Obecnie tysiące lokalnych firm uczestniczy w programie i oferuje różnorodne ekskluzywne oferty. Od gastronomii, przez usługi, po produkty – znajdziesz świetne oferty w wielu kategoriach.",
    "vendor_faq.app_free_question": "Czy aplikacja jest obecnie bezpłatna?",
    "vendor_faq.app_free_answer": "Tak, aplikacja BASAR jest obecnie całkowicie darmowa. Partnerzy mogą uczestniczyć i przedstawiać oferty bez żadnych opłat.",
    "vendor_faq.create_store_question": "Jak założyć sklep na BASAR-ie?",
    "vendor_faq.create_store_answer": "Założenie sklepu na BASAR-ie jest łatwe i szybkie. Możesz skonfigurować profil swojej firmy kilkoma kliknięciami bezpośrednio w aplikacji.",
    "vendor_faq.manage_coupons_question": "Czy mogę tworzyć własne kupony i zarządzać nimi?",
    "vendor_faq.manage_coupons_answer": "Tak, masz pełną kontrolę nad tworzeniem i usuwaniem własnych kuponów. Ustaw zniżki, oferty i warunki, które najlepiej odpowiadają Twojej firmie. Żaden menedżer sprzedaży nie przeszkadza w Twoim biznesie i marketingu.",
    "faq.general.category": "OGÓLNY",
    "faq.general.questions[0].question": "Jaka jest koncepcja Bazaru?",
    "faq.general.questions[0].answer": "Nawiązaliśmy współpracę z różnymi lokalnymi firmami i wynegocjowaliśmy ekskluzywne kupony wprowadzające, takie jak: B. Oferty 2 w cenie 1 lub zniżki na produkty. Dzięki aplikacji Bazaar możesz łatwo przeglądać, rezerwować i wykorzystywać oferty w różnych branżach, takich jak restauracje, salony fryzjerskie, warsztaty, kwiaciarnie i nie tylko.",
    "faq.general.questions[1].question": "Które firmy biorą udział?",
    "faq.general.questions[1].answer": "Obecnie tysiące lokalnych firm uczestniczy w programie i oferuje różnorodne ekskluzywne oferty. Od restauracji po usługi i produkty – znajdziesz świetne oferty w wielu kategoriach.",
    "start": "Start",
    "price": "Cena",
    "redeem_error_notToday_title": "Kupon nie podlega dzisiaj realizacji.",
    "redeem_error_notToday_message": "Kupon można wykorzystać wyłącznie w określone dni.",
    "redeemDays.monday": "Pon",
    "redeemDays.tuesday": "Do",
    "redeemDays.wednesday": "My",
    "redeemDays.thursday": "Cz",
    "redeemDays.friday": "SM",
    "redeemDays.saturday": "Sob",
    "redeemDays.sunday": "Su",
    "coupon_factory.your_coupon_active": "Twój kupon jest teraz aktywny i wkrótce będzie widoczny dla Twoich klientów!",
    "coupon_factory.valid_days_header": "Ważne dni",
    "coupon_factory.valid_days_subHeader": "Dni, w które klienci mogą realizować kupony.",
    "error_messages.something_wrong": "Coś poszło nie tak. Spróbuj ponownie później lub skontaktuj się z nami, jeśli problem będzie się powtarzał.",
    "store_factory.store_not_found_input_manual": "Nie znaleziono sklepu? Ręczne wprowadzanie adresu.",
    "sent_review": "Prześlij recenzję.",
    "review_your_visit_at": "Oceń swoją wizytę @",
    "search": "Szukaj",
    "faq_title": "FAQ (często zadawane pytania)",
    "faq_title_reverse": "Często zadawane pytania (FAQ)",
    "vendor_faq_question_concept": "Jak działa BASAR?",
    "vendor_faq_answer_concept": "Koncepcja BASAR jest prosta i skuteczna. Jako osoba samozatrudniona, prowadząca działalność stacjonarną, możesz wykorzystać aplikację BASAR Business do prezentacji swojego sklepu i tworzenia kuponów. Użytkownicy mogą znaleźć te kupony za pośrednictwem aplikacji użytkownika BASAR i zrealizować je bezpośrednio w Twoim sklepie. Wzmacniamy w ten sposób lokalnych przedsiębiorców w konkurencji z dużymi sieciami handlowymi.",
    "vendor_faq_question_costs": "Czy ponoszę jakieś koszty?",
    "vendor_faq_answer_costs": "Nie, BASAR jest całkowicie bezpłatny dla sprzedawców detalicznych. Użytkownicy subskrybują, aby uzyskać dostęp do wszystkich kuponów. Dzięki temu jesteśmy w stanie pokryć koszty platformy i sprawić, że lokalni przedsiębiorcy będą konkurencyjni – nawet wobec dużych sieci, które inwestują w kosztowny marketing i programy lojalnościowe.",
    "vendor_faq_question_minium_requirements": "Jakie wymagania muszę spełnić?",
    "vendor_faq_answer_minium_requirements": "Potrzebujesz sklepu stacjonarnego, w którym będziesz obsługiwać klientów na miejscu – np. sklep stacjonarny. B. restauracja, zakład fryzjerski, warsztat itp. Ponadto Ty lub Twoi pracownicy powinniście mieć możliwość łatwego przyznania obiecanych rabatów przy kasie.",
    "vendor_faq_question_interface_cashier": "Czy muszę zintegrować kasę fiskalną?",
    "vendor_faq_answer_interface_cashier": "Nie, BASAR działa bardzo łatwo na klasycznej zasadzie czterech oczu. Klient pokazuje Ci zrealizowany kupon na swoim smartfonie, a Ty udzielasz mu rabatu. Nie są potrzebne żadne drogie i skomplikowane integracje z systemami POS. Ważne jest tylko to, abyś Ty lub Twoi pracownicy potrafili prawidłowo wdrożyć rabaty.",
    "vendor_faq_question_shop_creation": "Jak stworzyć swój sklep?",
    "vendor_faq_answer_shop_creation": "Swój sklep możesz założyć w zakładce „Sklep”. Wyszukaj swoją firmę w naszej bazie danych lub utwórz ją ręcznie. Dodaj dane kontaktowe, godziny otwarcia, zdjęcie i odpowiednią kategorię. Sprawdzimy informacje i skontaktujemy się z Tobą, jeśli masz jakiekolwiek pytania. Weryfikacja trwa zwykle jeden dzień roboczy – po tym czasie możesz od razu tworzyć kupony.",
    "vendor_faq_question_coupons": "Jak mogę utworzyć kupony?",
    "vendor_faq_answer_coupons": "Po utworzeniu sklepu możesz uruchomić fabrykę kuponów w zakładce „Kupony”. Znajdziesz tam gotowe szablony, do których możesz szybko i łatwo dodać swoje informacje. Po podglądzie możesz utworzyć kupon i zostanie on aktywowany w krótkim czasie. Warunek: Twój sklep musi zostać zweryfikowany.",
    "vendor_faq_question_storeinfos_update": "Jak zaktualizować informacje o mojej firmie?",
    "vendor_faq_answer_storeinfos_update": "Przejdź do swojego sklepu i kliknij zakładkę „Informacje”. Tam możesz zaktualizować dane kontaktowe, godziny otwarcia i zdjęcia za pomocą odpowiednich przycisków edycji.",
    "vendor_faq_question_abuse": "W jaki sposób BASAR zapobiega wielokrotnej realizacji kuponu?",
    "vendor_faq_answer_abuse": "Każdy użytkownik rejestruje się za pomocą swojego numeru telefonu, dzięki czemu można go łatwo zidentyfikować. Tworząc kupon, możesz określić, jak często kupon może być realizowany, np.: B. tylko co 90 dni. W ten sposób użytkowanie pozostaje sprawiedliwe i kontrolowane.",
    "vendor_faq_question_help_creation": "Czy pomożesz nam stworzyć sklep lub kupony?",
    "vendor_faq_answer_help_creation": "Oczywiście! Chętnie Ci pomożemy, jeśli potrzebujesz pomocy w konfiguracji sklepu lub kuponów. W każdej chwili możesz się z nami skontaktować poprzez WhatsApp. Numer znajdziesz w ustawieniach w sekcji „Opinie i wsparcie”.",
    "vendor_faq_question_how_to_contact": "Jak mogę się z Tobą skontaktować?",
    "vendor_faq_answer_how_to_contact": "Możesz skontaktować się z nami e-mailem pod adresem basar@basar-digital.com lub bezpośrednio za pośrednictwem WhatsApp. Numer WhatsApp znajdziesz w ustawieniach w sekcji „Opinie i wsparcie”.",
    "create_store_and_coupons": "Utwórz swój sklep i kupony",
    "click_here_for_help": "Kliknij tutaj, jeśli potrzebujesz więcej informacji.",
    "vendorOnboardingTutorial_title_step1": "Wyszukaj i stwórz własny sklep.",
    "vendorOnboardingTutorial_description_step1": "Znajdź swoją firmę korzystając z wyszukiwarki lub po prostu stwórz ją samodzielnie. Dodaj wszystkie ważne informacje, takie jak kontakt, godziny otwarcia, zdjęcia i kategorie.",
    "vendorOnboardingTutorial_imageCaption_step1": "Twój sklep, Twój start: Już po kilku kliknięciach jesteś widoczny dla swoich klientów – niezależnie od tego, czy dodajesz istniejący sklep, czy tworzysz nowy.",
    "vendorOnboardingTutorial_title_step2": "Twórz nieodparte kupony.",
    "vendorOnboardingTutorial_description_step2": "Skorzystaj z naszej łatwej w obsłudze fabryki kuponów, aby tworzyć spersonalizowane oferty. Zachwyć klientów ekskluzywnymi rabatami i nadaj swojemu biznesowi świeży impuls.",
    "vendorOnboardingTutorial_imageCaption_step2": "Dzięki fabryce kuponów możesz zadziwić klientów: w ciągu kilku sekund stwórz indywidualne oferty i wzmocnij lojalność swoich klientów.",
    "vendorOnboardingTutorial_title_step3": "Czekamy na entuzjastycznych klientów.",
    "vendorOnboardingTutorial_description_step3": "Swoimi ofertami przyciągasz nowych klientów i zaskakujesz swoich stałych klientów. Klienci mogą znaleźć Twój sklep i kupony bezpośrednio w aplikacji użytkownika BASAR. Kupony realizujesz na miejscu, a rabat możesz zastosować bezpośrednio przy kasie.",
    "vendorOnboardingTutorial_imageCaption_step3": "Twój biznes, ich radość: Więcej klientów, większa sprzedaż – dzięki aplikacji użytkownika BASAR Twój sklep będzie pierwszym wyborem.",
    "category": "Kategoria",
    "loadings_favorites": "Ładowanie ulubionych",
    "feature_so_great": "Ta funkcja jest tak wspaniała, że ​​nie jest darmowa! Zarejestruj się i rozpocznij.",
    "login_and_start": "Zaloguj się i rozpocznij",
    "oops_something_missing": "Ups, czegoś jeszcze brakuje!",
    "coupon_factory": "Fabryka kuponów",
    "coupon_factory.infoHeader_name_of_product": "Nazwa produktu",
    "coupon_factory.infoBody_name_of_product": "Wpisz nazwę produktu lub usługi, którą chcesz, aby klient kupił.",
    "coupon_factory.infoHeader_value_of_product": "Wartość produktu",
    "coupon_factory. infoBody_value_of_product": "Ile kosztuje produkt w Waszym sklepie?",
    "coupon_factory.infoHeader_count_of_product": "Liczba produktów",
    "coupon_factory.infoBody_count_of_product": "Ile tego produktu powinien kupić.",
    "coupon_factory.infoHeader_minimum_order_volume": "Minimalna wartość zamówienia / minimalna wartość zakupu",
    "coupon_factory.infoBody_minimum_order_volume": "Ile przynajmniej klient powinien wydać, aby odnieść korzyść?",
    "coupon_factory.infoHeader_name_of_benefit_product": "Nazwa produktu przewagi",
    "coupon_factory.infoBody_name_of_benefit_product": "Wpisz tutaj nazwę produktu lub usługi, którą otrzyma klient.",
    "coupon_factory.infoHeader_value_of_benefit_product": "Wartość produktu przewagi",
    "coupon_factory.infoBody_value_of_benefit_product": "Ile zwykle kosztuje produkt bez ceny promocyjnej?",
    "coupon_factory.infoHeader_discount_amount": "Określ kwotę rabatu",
    "coupon_factory.infoBody_discount_amount": "Ile wynosi rabat na sztukę - w € lub %.",
    "coupon_factory.infoHeader_order_discount_amount": "Określ kwotę rabatu",
    "coupon_factory.infoBody_order_discount_amount": "Ile wynosi rabat na zakup lub zamówienie - w € lub %.",
    "delete_coupon": "Usuń kupon",
    "generate": "Spowodować",
    "delete_coupon_text": "Czy na pewno chcesz usunąć ten kupon? Tej akcji nie można cofnąć.",
    "delete_coupon_info": "Uwaga: Twój kupon jest aktywny do końca dnia i od następnego dnia nie będzie już widoczny dla użytkowników.",
    "validation_all_inputs_required": "Wszystkie pola muszą być wypełnione poprawnie.",
    "store_factory.contact_and_opentimes": "Kontakt i godziny otwarcia",
    "store_factory.always_editable_later": "Zawsze możesz go później edytować.",
    "choose_category_and_image": "Wybierz obraz i kategorię.",
    "store_factory.main_categories_limit": "Wybierz kategorię.",
    "searchdata_openstreetmap": "Wyszukaj dane © OpenStreetMap",
    "success.opentimes_updated": "Godziny otwarcia pomyślnie zaktualizowano!",
    "success.contactdetails_updated": "Dane kontaktowe zostały pomyślnie zaktualizowane!",
    "editcontact": "Edytuj dane kontaktowe",
    "from": "Z",
    "until": "Dopóki",
    "weekday": "Dzień",
    "open_questionmark": "Otwarte?",
    "coupon_factory.contact_if_issue": "Proszę sprawdzić wszystkie informacje. Jeśli nadal potrzebujesz pomocy, skontaktuj się z nami tutaj",
    "respawn_time": "Czas odrodzenia",
    "coupon_factory.coupon_in_three_steps": "Utwórz kupon w 3 krokach.",
    "store_factory.your_store_on_map": "Twoja firma na mapie",
    "coupon_factory.infoHeader_need_help": "Czy potrzebujesz wsparcia?",
    "coupon_factory.infoBody_need_help_whatsapp": "Skontaktuj się z nami bezpośrednio tutaj za pośrednictwem Whatsapp.",
    "coupon_factory.check_preview": "Wyświetl podgląd swojego kuponu.",
    "coupon_factory.preview": "Zapowiedź",
    "coupon_factory.check_details": "Czy wszystkie informacje są prawidłowe? Kliknij Wygeneruj, aby aktywować kupon!",
    "coupon_factory.respawn_time_description": "Kiedy klient może ponownie aktywować kupon po jego zrealizowaniu. Jeśli nie chcesz już oferować oferty, możesz ją usunąć samodzielnie.",
    "coupon_factory.respawn_time_short_description": "Jak długo klient musi czekać, zanim będzie mógł ponownie aktywować kupon.",
    "coupon_factory.thirty_days_recommendation": "Klient może ponownie wykorzystać kupon po 30 dniach. Idealny dla ofert, które są regularnie dostępne i atrakcyjne dla powracających klientów.",
    "coupon_factory.ninety_days_recommendation": "Kupon można wykorzystać ponownie po upływie 90 dni. Opcja ta sprawdza się w przypadku dłuższych ofert skierowanych do nowych klientów.",
    "coupon_factory.weekly_recommendation": "Kupon można wykorzystać ponownie raz w tygodniu. Idealny do krótkoterminowych promocji, które wymagają regularnego odnawiania.",
    "coupon_factory.thirty_days_recommendation_short": "Idealny do regularnie dostępnych ofert.",
    "coupon_factory.ninety_days_recommendation_short": "Idealny dla rzadkich ofert dla nowych klientów.",
    "coupon_factory.weekly_recommendation_short": "Idealny do krótkich ofert promocyjnych.",
    "coupon_factory.mainly_for_restaurants": "Zwykle dotyczy tylko gastronomii.",
    "coupon_factory.default_set": "Ustaw domyślnie.",
    "coupon_factory.redeem_location_onsite_info": "Obowiązuje w przypadku zakupów w sklepie.",
    "coupon_factory.redeem_location_togo_info": "Obowiązuje w przypadku, gdy Klient kupuje coś na wynos, np.: kebab na wynos.",
    "coupon_factory.choose_coupon_type": "Wybierz typy kuponów",
    "coupon_factory.choose_coupon_type_details": "Wybierz typ kuponu, który chcesz utworzyć dla swojego sklepu.",
    "coupon_factory.example_header_one": "Zwrot gotówki w wysokości 10 €",
    "coupon_factory.example_body_one": "Uzyskaj zniżkę w wysokości 10 EUR przy minimalnych wydatkach wynoszących 50 EUR",
    "coupon_factory.example_headertitle_one": "Zwrot gotówki",
    "coupon_factory.example_subheader_one": "Twoi nowi klienci otrzymają bezpośredni rabat na zakup/zamówienie.",
    "coupon_factory.example_header_two": "1x napój PREZENT",
    "coupon_factory.example_body_two": "Otrzymujesz 1x czekoladę gratis bez minimalnej wartości zamówienia",
    "coupon_factory.example_headertitle_two": "PREZENT",
    "coupon_factory.example_subheader_two": "Twoi nowi klienci otrzymają prezent przy zakupie.",
    "coupon_factory.example_header_three": "2 za 1 - frytki",
    "coupon_factory.example_body_three": "Kupując 1x frytki otrzymujesz 1x frytki gratis. Dotyczy produktu w tej samej cenie lub tańszego.",
    "coupon_factory.example_headertitle_three": "2 za 1",
    "coupon_factory.example_subheader_three": "Twoi nowi klienci otrzymują 2 produkty w cenie jednego.",
    "coupon_factory.example_header_four": "50% rabatu bezpośredniego na kurtki",
    "coupon_factory.example_body_four": "Otrzymasz 1 x bukiet kwiatów z rabatem 50% każdy, bez minimalnej wartości zamówienia.",
    "coupon_factory.example_headertitle_four": "SPRZEDAŻ",
    "coupon_factory.example_subheader_four": "Twoi klienci otrzymują bezpośredni rabat na konkretny produkt.",
    "coupon_factory.example_header_individual": "Indywidualnie",
    "coupon_factory.example_body_individual": "Umowa indywidualna",
    "coupon_factory.example_headertitle_individual": "Indywidualnie",
    "coupon_factory.example_subheader_individual": "Stwórz całkowicie indywidualny kupon dla swoich klientów.",
    "welcome": "Powitanie",
    "languages.de": "🇩🇪 niemiecki",
    "languages.en": "🇺🇸angielski",
    "languages.tr": "🇹🇷 Turecki",
    "languages.ar": "🇸🇦 arabski",
    "languages.fr": "🇫🇷 Francuski",
    "languages.es": "🇪🇸hiszpański",
    "languages.it": "🇮🇹 włoski",
    "languages.pl": "🇵🇱polski",
    "languages.ru": "🇷🇺Rosyjski",
    "popular_categories": "Popularne kategorie",
    "no_matching_categories_found": "Nie znaleziono pasujących kategorii",
    "hello": "Witam z Niemiec",
    "with_coupons": "Z kuponami",
    "cities_with_digital_basar": "Miasta z cyfrowym bazarem",
    "use_your_location": "Użyj swojej lokalizacji",
    "no_matching_cities_found": "Nie znaleziono pasujących miast",
    "discover": "Odkryć",
    "favorites": "Ulubione",
    "profile": "Profile",
    "delete": "Usuwać",
    "openingTimes": "Godziny otwarcia",
    "editOpeningTimes": "Edytuj godziny otwarcia",
    "days.monday": "Poniedziałek",
    "days.tuesday": "Środa",
    "days.wednesday": "Środa",
    "days.thursday": "Czwartek",
    "days.friday": "Piątek",
    "days.saturday": "Sobota",
    "days.sunday": "Niedziela",
    "command_searchForYourBusiness": "Wyszukaj swoją firmę",
    "placeholder_find_store_google": "Znajdź swoją firmę w Google",
    "permission_to_camera_roll": "Wymagany jest dostęp do biblioteki zdjęć!",
    "businessMustBeOnGoogleMaps": "Twoja firma musi być widoczna na Mapach Google. Jeśli masz jakiekolwiek problemy, skontaktuj się z nami tutaj.",
    "closed": "Zamknięte",
    "location": "Lokalizacja",
    "coupons": "Kupony",
    "activated_coupons": "Aktywowane kupony",
    "redeemed_coupons": "Zrealizowane kupony",
    "activate": "Aktywować",
    "redeem_now": "Skorzystaj teraz",
    "coupon_redeemed_message": "Kupon zrealizowany – tak trzymaj! W ten sposób wspieramy lokalny handel.",
    "coupon_redeemed_message_title": "Kupon wykorzystany!",
    "coupon_redeemed_message_bodxy": "Tak trzymaj! W ten sposób wspieramy lokalny handel.",
    "share_message": "Hej, jestem w BASAR. Pobierz aplikację, korzystając z tego linku.",
    "share_success": "Udostępnij pomyślnie!",
    "share_dismissed": "Udostępnianie anulowane",
    "share_failed": "Udostępnienie nie powiodło się:",
    "report_problem": "Zgłoś problem",
    "deactivate_coupon": "Dezaktywuj kupon",
    "info": "Informacje",
    "more": "Więcej",
    "Information about this Coupons": "Informacje o tym kuponie",
    "command_mention_basar_for_redeem": "Przed dokonaniem płatności wspomnij, że posiadasz voucher BASAR i zrealizuj go w obecności pracownika. Podczas jednej wizyty można wykorzystać tylko jeden kupon.",
    "Redeem Location": "Miejsce odkupienia",
    "redeem_location_info": "Niezależnie od tego, czy oferta jest na miejscu, czy na wynos",
    "Reactivation time": "Czas reaktywacji",
    "reactivation_info": "Kiedy oferta będzie dla Ciebie ponownie aktywna po jej wykorzystaniu.",
    "reviews": "Recenzje",
    "review": "Recenzje",
    "show_all_reviews": "Zobacz wszystkie recenzje",
    "show_all": "Zobacz wszystkie",
    "REVIEW_SENT": "Recenzja przesłana.",
    "REVIEW_THANKS": "Dziękujemy za opinię!",
    "REVIEW_WRITE": "Napisz swoją recenzję...",
    "loading": "Załadunek",
    "noReviewsAvailable": "Nie ma jeszcze żadnych komentarzy",
    "noStoreInformationAvailable": "Brak informacji",
    "advetorials.vendor.become_vendor_title": "Czy masz lokalny sklep na miejscu?",
    "advetorials.vendor.become_vendor_body": "W takim razie zostań sprzedawcą w naszej aplikacji już teraz. Mamy wielu użytkowników, którzy chcieliby wesprzeć Twój lokalny biznes.",
    "advetorials.vendor.download_vendor_app": "Pobierz aplikację sprzedawcy ze sklepu App Store.",
    "become_Member": "Zostań członkiem",
    "enter_discount": "Wpisz kod rabatowy",
    "translated_by_ai": "Tłumaczenia mogły zostać utworzone przy użyciu sztucznej inteligencji i dlatego nie zawsze są doskonałe.",
    "closeButton": "Zamknąć",
    "edit_profile": "Edytuj profil",
    "validation_invalid_input": "Nieprawidłowe dane wejściowe",
    "validation_required_input": "Pole musi być wypełnione",
    "success": "Potwierdzony",
    "error": "Błąd",
    "profile_update_success": "Profil został pomyślnie zaktualizowany!",
    "profile_image_update_success": "Zdjęcie profilowe zostało pomyślnie zaktualizowane",
    "profile_image_update_error": "Aktualizacja zdjęcia profilowego nie powiodła się",
    "tutorial": "Seminarium",
    "settings": "Ustawienia",
    "feedbackAndSupport": "Informacje zwrotne i wsparcie",
    "changeToVendorMode": "Przełącz na tryb dealera",
    "changeToUserMode": "Przejdź do trybu klienta",
    "registerAsPartner": "Zarejestruj się jako partner",
    "logout": "Wyloguj się",
    "first_name": "Imię",
    "last_name": "Nazwisko",
    "email": "E-mail",
    "zipCode": "Kod pocztowy",
    "edit": "Redagować",
    "delete_account": "Usuń profile",
    "delete_account_message": "Czy na pewno chcesz usunąć swój profil? Wszystkie Twoje zalety i ustawienia zostaną bezpowrotnie utracone.",
    "delete_account_success": "Profil został pomyślnie usunięty",
    "delete_account_success_message": "Twój profil został pomyślnie usunięty. 👋 Mamy nadzieję, że już niedługo spotkamy się ponownie",
    "save": "Ratować",
    "back": "Upiec",
    "continue": "Kontynuować",
    "later": "Później",
    "terms_and_conditions": "Ogólne warunki handlowe",
    "agree_to_terms": "Zgadzam się z <terms>Ogólnymi Warunkami</terms>.",
    "phonenumber": "Numer telefonu",
    "enter_phonenumber": "Wpisz numer telefonu",
    "login_no_password_info": "Łatwe logowanie za pomocą kodu SMS. Nie musisz więc pamiętać hasła.",
    "enter_code": "Wprowadź kod",
    "sent_to": "Wysłano do",
    "search_country_code": "Szukaj według kraju lub numeru kierunkowego",
    "command_agree": "Proszę się zgodzić",
    "command_agree_to_terms": "Aby kontynuować, musisz zaakceptować warunki.",
    "otp": "Hasło jednorazowe",
    "enter_otp": "Wprowadź hasło jednorazowe",
    "verify_otp": "Potwierdź hasło jednorazowe",
    "otp_expired": "Hasło jednorazowe wygasło",
    "otp_incorrect": "Hasło jednorazowe jest nieprawidłowe",
    "otp_verification_error": "Wprowadzono nieprawidłowe lub wygasłe hasło jednorazowe.",
    "command_create_profile_to_start": "Utwórz swój profil, aby rozpocząć!",
    "command_create_company_to_start": "Zarejestruj swoją firmę, aby rozpocząć.",
    "info_create_shop_later": "W kolejnym kroku możesz podać informacje o swoim sklepie.",
    "your_concern": "Twoja prośba",
    "describe_your_concern": "Przedstaw nam swoją prośbę.",
    "contact_you_asap": "Skontaktujemy się z Tobą tak szybko, jak to możliwe.",
    "contact_via_whatsapp": "Skontaktuj się z nami poprzez Whatsapp",
    "change_image": "Zmień obraz",
    "edit_store_profile": "Edycja profilu firmy",
    "error_loading_store": "Błąd podczas ładowania danych biznesowych",
    "error_messages.no_stores.no_stores_nearby_found_title": "Nie znaleziono sklepów w Twojej okolicy",
    "error_messages.no_stores.no_stores_nearby_found_body": "Najwyraźniej nie jesteśmy jeszcze szybcy. Ale damy radę – nie martw się. Zmień miasto za pomocą wyszukiwania lub przewiń do niego za pomocą mapy.",
    "error_messages.conflict.store_exists_title": "Firma pod tym adresem została już zarejestrowana.",
    "error_messages.conflict.store_exists_body": "Nie możesz utworzyć sklepu, który jest już zarejestrowany. Jeśli uważasz, że to błąd, skontaktuj się z nami tutaj.",
    "funny_error_message": "Ups – coś poszło nie tak.",
    "page_not_found": "Nie udało się wczytać strony.",
    "page_not_found_connect_to_wifi": "Upewnij się, że masz połączenie z Internetem.",
    "page_not_found_help_message": "Jeśli problem będzie się powtarzał, skontaktuj się z nami",
    "cancel": "Anulować",
    "partner_login": "Logowanie partnera",
    "partner_register": "Rejestracja partnera",
    "command_no_account_register": "Nie masz konta? Zarejestruj się tutaj.",
    "command_have_account_login": "Czy masz konto? Zaloguj się tutaj.",
    "login": "Login",
    "register": "Rejestracja",
    "please_enter_email": "Podaj e-mail",
    "email_sent": "E-mail wysłany",
    "password_reset_email_sent": "E-mail wysłany w celu zresetowania hasła.",
    "password_reset_failed": "Resetowanie hasła nie powiodło się.",
    "forgot_password": "Zapomniałem hasła",
    "password_reset_rate_limit": "Ze względów bezpieczeństwa możesz wysłać to żądanie tylko raz na 60 sekund. Poczekaj i spróbuj ponownie.",
    "back_to_login": "Powrót do logowania",
    "company_profile": "Profil firmy",
    "company_details": "Dane firmy",
    "company_email": "Adres e-mail firmy",
    "company_name": "Nazwa firmy",
    "company_address": "Adres firmy",
    "company_zipCode": "Kod pocztowy",
    "company_city": "Miasto",
    "company_country": "Kraj",
    "company_phone": "Numer telefonu firmy",
    "company_pocName": "Imię, nazwisko (osoba kontaktowa)",
    "company_pocRole": "Wybierz rolę (osoba kontaktowa)",
    "store_image": "Zdjęcie sklepu",
    "vendor_delete_account": "Czy na pewno chcesz usunąć swój profil?",
    "vendor_delete_account_body": "Obecnie Twój profil można usunąć jedynie kontaktując się z nami za pośrednictwem WhatsApp lub wysyłając e-mail na adres basar@basar-digital.com",
    "select_a_category": "Wybierz kategorię",
    "categories.grocery": "Artykuły spożywcze",
    "categories.restaurants": "Restauracja",
    "categories.hairSalon": "Fryzjer",
    "categories.electronics": "Elektronika",
    "categories.beauty_wellness": "Uroda i dobre samopoczucie",
    "categories.other": "Inny",
    "categories.non_food_retail": "Sprzedaż detaliczna",
    "categories.home_and_garden": "Dom i ogród",
    "categories.automotive": "Ruchliwość",
    "categories.flowers_and_gifts": "Kwiaty i prezenty",
    "categories.activities": "Działania",
    "categories.cafe_and_dessert": "Kawiarnia i deser",
    "categories.farm_shop": "Sklep rolniczy",
    "subcategories.convenienceStore": "Minimarket",
    "subcategories.eastEuropean": "Wschodnioeuropejski",
    "subcategories.beverageMarket": "Rynek napojów",
    "subcategories.orientalGrocery": "Orientalny",
    "subcategories.indianGrocery": "Indyjskie potrawy",
    "subcategories.importedGrocery": "Import",
    "subcategories.bioOrganic": "Organiczne i organiczne",
    "subcategories.africanGrocery": "Afrykanin",
    "subcategories.turkishSupermarket": "Turecki",
    "subcategories.latinAmericanSupermarket": "Ameryka Łacińska",
    "subcategories.middleEasternSupermarket": "Środkowy Wschód",
    "subcategories.russianSupermarket": "Rosyjski",
    "subcategories.polishSupermarket": "Polski",
    "subcategories.mediterraneanSupermarket": "Śródziemnomorski",
    "subcategories.veganVegetarianSupermarket": "Wegańskie/wegetariańskie",
    "subcategories.halalMarket": "Halal",
    "subcategories.kosherSupermarket": "Koszerny",
    "subcategories.southAsianSupermarket": "Południowa Azja",
    "subcategories.germanCuisine": "Niemiecki",
    "subcategories.frenchRestaurant": "Francuski",
    "subcategories.spanishTapasBar": "Hiszpański",
    "subcategories.greekTavern": "Grecki",
    "subcategories.mexicanRestaurant": "Meksykański",
    "subcategories.indianRestaurant": "Indyjski",
    "subcategories.sushiBar": "Bar sushi",
    "subcategories.steakhouse": "Steakhouse",
    "subcategories.vegetarianVeganRestaurant": "Wegańskie/wegetariańskie",
    "subcategories.foodTruck": "Ciężarówka z jedzeniem",
    "subcategories.fusionCuisine": "Międzynarodowy",
    "subcategories.medievalTavern": "Średniowieczny",
    "subcategories.patisserie": "Cukiernia",
    "subcategories.lowCarb": "Niska zawartość węglowodanów",
    "subcategories.brideAndEvent": "Panna młoda i wydarzenie",
    "subcategories.afroBarber": "Afro-fryzjer",
    "subcategories.hairSpecialist": "Specjalista od włosów",
    "subcategories.permanentHairRemoval": "Trwałe usuwanie włosów",
    "subcategories.hairReplacement": "Depilacja",
    "subcategories.barberServices": "Usługi fryzjerskie",
    "subcategories.veganCafe": "Wegańskie",
    "subcategories.outdoorActivities": "Zajęcia na świeżym powietrzu",
    "subcategories.eventDecorators": "Wydarzenie Deco",
    "subcategories.indoorPlants": "Rośliny domowe",
    "subcategories.outdoorPlants": "Rośliny zewnętrzne",
    "subcategories.ballonAndParty": "Balony i artykuły na przyjęcia",
    "subcategories.handmade": "Ręcznie wykonany",
    "subcategories.candleAndFragrances": "Świece i zapachy",
    "subcategories.autoDetailing": "Przygotowanie pojazdu",
    "subcategories.autoBodyAndPaint": "Korpus i farba",
    "subcategories.carRental": "Wynajem samochodów",
    "subcategories.autoGlass": "Szyby samochodowe",
    "subcategories.inspection": "Kontrola",
    "subcategories.oilChange": "Wymiana oleju",
    "subcategories.motorcycle": "Motocykl",
    "subcategories.autoAccessories": "Akcesoria samochodowe",
    "subcategories.autoTuning": "Warsztaty tuningowe",
    "subcategories.homeDecor": "Dekoracja domu",
    "subcategories.homeImprovement": "Ulepszanie domu",
    "subcategories.lightingStore": "Lampy i światła",
    "subcategories.applianceStore": "Sprzęt AGD",
    "subcategories.kitchenAndBath": "Kuchnia i urządzenia sanitarne",
    "subcategories.securityAndSmartHome": "Bezpieczeństwo i inteligentny dom",
    "subcategories.diy": "Zrób to sam",
    "subcategories.pharmacy": "Apteka",
    "subcategories.medicalSupply": "Sklep medyczny",
    "subcategories.jewlery": "Jubiler",
    "subcategories.toyStore": "Sklep z zabawkami",
    "subcategories.musicStore": "Muzyka i instrumenty",
    "subcategories.secondhandStores": "Z drugiej ręki",
    "subcategories.travelAgency": "Biuro podróży",
    "subcategories.photoStudio": "Studio fotograficzne",
    "subcategories.tailorShop": "Krawiectwo",
    "subcategories.cleaningService": "Usługa sprzątania",
    "subcategories.shoeRepair": "Naprawa obuwia",
    "subcategories.keyCopyService": "Usługi ślusarskie",
    "subcategories.laundryService": "Pralnia",
    "subcategories.locksmith": "Ślusarz",
    "subcategories.mobileRepair": "Naprawa telefonu komórkowego",
    "subcategories.computerRepair": "Naprawa komputera",
    "subcategories.packagingService": "Usługa pakowania",
    "subcategories.printShop": "Drukarnia",
    "subcategories.eventPlanning": "Planowanie wydarzeń",
    "subcategories.regionalProducts": "Produkty regionalne",
    "subcategories.butcherGrocery": "Rzeźnik",
    "subcategories.bakeryGrocery": "Wypieki",
    "subcategories.asianGrocery": "Azjatycki",
    "subcategories.eastEuropaen": "Wschodnioeuropejski",
    "subcategories.farmGrocery": "Sklep rolniczy",
    "subcategories.halalRestaurant": "Halal",
    "subcategories.asianRestaurants": "Azjatycki",
    "subcategories.seafoodRestaurant": "Owoce morza",
    "subcategories.italianRestaurant": "Włoski",
    "subcategories.burgerRestaurant": "Hamburgery",
    "subcategories.menHairSalon": "Panowie",
    "subcategories.kidsHairSalon": "Dzieci",
    "subcategories.womenHairSalon": "Kobiety",
    "subcategories.gadgets": "Gadżety",
    "subcategories.vegetarianRestaurant": "Wegetariański",
    "subcategories.mediterraneanRestaurant": "Śródziemnomorski",
    "subcategories.fishMarket": "Targ rybny",
    "subcategories.imker": "Pszczelarz",
    "subcategories.makeup": "Makijaż",
    "subcategories.barbeque": "Grill",
    "subcategories.bubble_tea": "Herbata bąbelkowa",
    "subcategories.african": "Afrykanin",
    "subcategories.hair_treatments": "Zabiegi na włosy",
    "subcategories.hair_removal": "Depilacja",
    "subcategories.teeth_whitening": "Wybielanie zębów",
    "subcategories.acupuncture": "Akupunktura",
    "subcategories.nail_salon": "Salon paznokci",
    "subcategories.therapies": "Terapie",
    "subcategories.meat": "Mięso",
    "subcategories.buffet": "Bufety",
    "subcategories.fried": "Smażone jedzenie",
    "subcategories.fast_food": "Fast food",
    "subcategories.breakfast_brunch": "Śniadanie | przekąska",
    "subcategories.bistro_imbiss": "Bistro | przekąska",
    "subcategories.manicure_pedicure": "Manicure i pedicure",
    "subcategories.facial_treatments": "Zabiegi na twarz",
    "subcategories.cosmetic_supply": "Produkty kosmetyczne",
    "subcategories.beverage_market": "Rynek napojów",
    "subcategories.dairyGrocery": "Mleczarnia",
    "subcategories.vegetableGrocery": "Warzywa",
    "subcategories.dessertBar": "Desery",
    "subcategories.crepes": "Naleśniki",
    "subcategories.waffles": "Gofry",
    "subcategories.juices": "Soki",
    "subcategories.tea_house": "Herbaciarnia",
    "subcategories.sweet_pastry": "Słodkie wypieki",
    "subcategories.candy_shop": "Sklep ze słodyczami",
    "subcategories.dairyFarmShop": "Mleczarnia",
    "subcategories.fruit_vegetable": "Owoce i warzywa",
    "subcategories.eggs": "Jajka",
    "subcategories.bio_organic": "Organiczne i organiczne",
    "subcategories.scooping": "Bańki",
    "subcategories.indian_grocery": "Indyjski",
    "subcategories.imported_grocery": "Import",
    "subcategories.spaWellness": "Spa i dobre samopoczucie",
    "subcategories.massageCenter": "Masaże",
    "subcategories.gifts": "Prezenty",
    "subcategories.cafe": "Kawiarnia",
    "subcategories.iceCreamParlor": "Lodziarnia",
    "subcategories.bakeryCafe": "Wypieki",
    "subcategories.sportsCenter": "Sport",
    "subcategories.kidsPlayArea": "Park zabaw dla dzieci",
    "subcategories.art": "Sztuka",
    "subcategories.cinema": "Kino",
    "subcategories.theater": "Teatr",
    "subcategories.escape_room": "Pokoje ucieczki",
    "subcategories.climbing": "Wspinać się",
    "subcategories.musuem": "Muzeum",
    "subcategories.flowerShop": "Kwiaty",
    "subcategories.giftShop": "Prezenty",
    "subcategories.carRepair": "Naprawa",
    "subcategories.carWash": "Umyć",
    "subcategories.tireShop": "Opony",
    "subcategories.furnitureStore": "Meble",
    "subcategories.gardenCenter": "Ogród",
    "subcategories.clothingStore": "Sklep odzieżowy",
    "subcategories.electronicsStore": "Elektronika",
    "subcategories.petStore": "Artykuły dla zwierząt",
    "subcategories.zooStore": "Zaopatrzenie ogrodu zoologicznego",
    "subcategories.bookstore": "Książki i czasopisma",
    "subcategories.cosmetic": "Kosmetyki",
    "keywords.oriental": "Orientalny",
    "keywords.middleEastern": "Bliskiego Wschodu",
    "keywords.arabic": "Arabski",
    "keywords.turkish": "Turecki",
    "keywords.moroccan": "Marokański",
    "keywords.persian": "Perski",
    "keywords.butcher": "Rzeźnik",
    "keywords.meat": "Mięso",
    "keywords.sausage": "Kiełbasa",
    "keywords.meatShop": "Sklep mięsny",
    "keywords.slaughterhouse": "Rzeźnik",
    "keywords.bakery": "Piekarnia",
    "keywords.bread": "Chleb",
    "keywords.pastry": "Ciasta",
    "keywords.roll": "Bułki",
    "keywords.cake": "Ciasto",
    "keywords.patisserie": "Cukiernia",
    "keywords.asian": "Azjatycki",
    "keywords.chinese": "Chiński",
    "keywords.japanese": "Japoński",
    "keywords.thai": "Tajski",
    "keywords.vietnamese": "Wietnamski",
    "keywords.korean": "Koreański",
    "keywords.indian": "Indyjski",
    "keywords.farmShop": "Sklep rolniczy",
    "keywords.farmersMarket": "Rynek rolników",
    "keywords.regional": "Regionalny",
    "keywords.organic": "Organiczny",
    "keywords.fresh": "Świeży",
    "keywords.agricultural": "Rolniczy",
    "keywords.halal": "Halal",
    "keywords.halalCertified": "Certyfikat halal",
    "keywords.islamic": "Islamski",
    "keywords.muslim": "Muzułmański",
    "keywords.doner": "Kebab Donerowy",
    "keywords.sushi": "Sushi",
    "keywords.fish": "Ryba",
    "keywords.seafood": "Owoce morza",
    "keywords.salmon": "Łosoś",
    "keywords.shrimp": "Krewetka",
    "keywords.italian": "Włoski",
    "keywords.pizza": "Pizza",
    "keywords.pasta": "Makaron",
    "keywords.risotto": "Risotto",
    "keywords.tiramisu": "Tiramisu",
    "keywords.antipasti": "Przystawki",
    "keywords.burger": "Obywatele",
    "keywords.hamburger": "Hamburger",
    "keywords.cheeseburger": "Cheeseburgery",
    "keywords.fries": "Fryz",
    "keywords.fastFood": "Fast food",
    "keywords.menHairdresser": "Fryzjer męski",
    "keywords.menSalon": "Salon męski",
    "keywords.barber": "Fryzjer",
    "keywords.shaving": "Golić się",
    "keywords.haircutForMen": "Strzyżenie dla mężczyzn",
    "keywords.kidsHairdresser": "Fryzjer dziecięcy",
    "keywords.kidCut": "Krój dziecięcy",
    "keywords.kidHaircut": "Strzyżenie dziecięce",
    "keywords.haircutForKids": "Strzyżenie dla dzieci",
    "keywords.kidStyling": "Stylizacje dziecięce",
    "keywords.womenHairdresser": "Fryzjer damski",
    "keywords.womenSalon": "Salon damski",
    "keywords.haircutForWomen": "Strzyżenie dla kobiet",
    "keywords.dyeing": "Barwiący",
    "keywords.stylingForWomen": "Stylizacja dla kobiet",
    "keywords.technology": "Technologia",
    "keywords.electronics": "Elektronika",
    "keywords.devices": "Urządzenia",
    "keywords.accessories": "Akcesoria",
    "units.km": "Km",
    "open_24_hours": "Otwarte 24 godziny",
    "favorites_and_bookings": "Ulubione i rezerwacje",
    "no_favorites": "Brak dostępnych ulubionych!",
    "no_favorites_text": "Nie dodałeś jeszcze żadnych ulubionych. Przeglądaj lokalne sklepy i zapisuj je jako ulubione, aby mieć do nich łatwiejszy dostęp później!",
    "no_coupons": "Brak dostępnych kuponów!",
    "no_coupons_text": "Nie aktywowałeś jeszcze żadnych kuponów. Odkryj i aktywuj kupony, aby cieszyć się zniżkami!",
    "onboardingTutorial_title_step1": "Odkryć",
    "onboardingTutorial_description_step1": "Odkryj świat lokalnego handlu, czy to restauracje, supermarkety, fryzjerzy, salony kosmetyczne, warsztaty i wiele więcej!",
    "onboardingTutorial_imageCaption_step1": "Wszystko tam jest, wszystko jest blisko.",
    "onboardingTutorial_title_step2": "Odkupić",
    "onboardingTutorial_description_step2": "Nasi lokalni partnerzy detaliczni zapraszają do korzystania z bezpośrednich rabatów i prezentów na produkty lub usługi.",
    "onboardingTutorial_imageCaption_step2": "Wspieranie lokalnych i oszczędzanie pieniędzy nie jest możliwe? Ale!",
    "onboardingTutorial_title_step3": "Ratować",
    "onboardingTutorial_description_step3": "Wszystkie oferty gotowe do ręki w kieszeni. Wystarczy pokazać rezerwację pracownikowi w sklepie. Tak wspieramy lokalny handel!",
    "onboardingTutorial_imageCaption_step3": "Szybko i łatwo dla każdego.",
    "confirm": "Potwierdzać",
    "start_now": "Zacznij teraz",
    "next": "Dalej",
    "submit": "Składać",
    "company_name_required": "Nazwa firmy jest wymagana",
    "company_email_required": "Wymagany jest firmowy adres e-mail",
    "invalid_email": "Nieprawidłowy adres e-mail",
    "invalid_zipCode": "Nieprawidłowy kod pocztowy",
    "company_address_required": "Adres firmy jest wymagany",
    "company_zipCode_required": "Kod pocztowy jest wymagany",
    "company_city_required": "Miasto jest wymagane",
    "company_country_required": "Kraj jest wymagany",
    "company_phone_required": "Wymagany jest telefon firmowy",
    "invalid_phone": "Nieprawidłowy numer telefonu",
    "placeholder_company_name": "Wpisz nazwę firmy",
    "placeholder_company_email": "Wpisz firmowy adres e-mail",
    "placeholder_company_address": "Podaj ulicę i numer domu",
    "placeholder_company_zipCode": "Wprowadź kod pocztowy",
    "placeholder_company_city": "Wpisz miasto",
    "placeholder_company_country": "Wpisz kraj",
    "placeholder_company_phone": "Wpisz numer telefonu",
    "placeholder_company_pocName": "Wpisz imię i nazwisko osoby kontaktowej",
    "placeholder_company_pocRole": "Wybierz rolę osoby kontaktowej",
    "no_stores": "Nie utworzono żadnej firmy",
    "no_stores_text": "Nie dodałeś jeszcze sklepu. Kliknij tutaj i błyskawicznie utwórz swój sklep, aby móc oferować kupony.",
    "more_stores": "Czy masz inny oddział?",
    "more_stores_text": "Kliknij tutaj, aby dodać kolejny sklep, w którym możesz również tworzyć kupony.",
    "stores_of_company": "Oddziały Twojej firmy",
    "vendor_poc_roles.owner": "Właściciel",
    "vendor_poc_roles.employee": "Zatrudniony",
    "vendor_poc_roles.else": "Inny",
    "coupon.ON_SITE": "Na miejscu",
    "coupon.TO_GO": "Iść",
    "coupon.NINETY_DAYS": "90 dni",
    "coupon.THIRTY_DAYS": "30 dni",
    "coupon.WEEKLY": "Tygodnik",
    "coupon.ONE_YEAR": "Rocznie",
    "coupon.DAILY": "Codziennie",
    "coupon.THREE_DAYS": "3 dni",
    "coupon.HUNDRED_EIGHTY_DAYS": "180 dni",
    "paywall.price_description_yearly": "Płatność roczna",
    "paywall.price_description_monthly": "Płatności miesięczne",
    "monetization.paywall_title": "Wszystkie oferty. Zapisz natychmiast.",
    "monetization.maybe_later": "Może później",
    "monetization.monthly": "Miesięczny",
    "monetization.yearly": "Rocznie",
    "monetization.monthly_marketing.first_benefit": "Zwykle warto już przy 1-2 zakupach!",
    "monetization.monthly_marketing.second_benefit": "Można anulować co miesiąc!",
    "monetization.monthly_marketing.third_benefit": "Natychmiast wykorzystaj wszystkie kupony.",
    "monetization.yearly_marketing.first_benefit": "Warto już przy 3-4 zakupach rocznie!",
    "monetization.yearly_marketing.second_benefit": "Zapłać raz, ciesz się przez rok!",
    "monetization.yearly_marketing.third_benefit": "Natychmiast wykorzystaj wszystkie kupony.",
    "monetization.price_description_effective_per_month": "Obowiązuje miesięcznie",
    "monetization.price_description_per_month": "Na miesiąc",
    "monetization.confirmation_title": "Zakup udany!",
    "monetization.confirmation_message": "Dziękujemy za zakup. Masz teraz dostęp do wszystkich funkcji premium.",
    "validation.setProductName": "Należy ustawić nazwę produktu.",
    "for": "Dla",
    "coupon_factory.2for1_title": "Kup 2, 1 dostaniesz gratis",
    "coupon_factory.2for1_description": "Albo nawet więcej. Utwórz ofertę ilościową",
    "coupon_factory.win_customers_and_money": "Zdobywaj klientów i sprzedaż.",
    "coupon_factory.example": "Przykładowy kupon",
    "coupon_factory.create_now": "Utwórz teraz",
    "coupon_factory.create_coupon_marketing_header": "Zdobądź nowych klientów za darmo już teraz",
    "coupon_factory.create_coupon_marketing_body": "Utwórz swój kupon w zaledwie kilku krokach.",
    "coupon_factory.create_coupon_benefit_header_1": "🎯 Nowi klienci",
    "coupon_factory.create_coupon_benefit_body_1": "Docieraj do nowych klientów bezpośrednio poprzez swój smartfon",
    "coupon_factory.create_coupon_benefit_header_2": "🚀 Łatwe i szybkie",
    "coupon_factory.create_coupon_benefit_body_2": "Twórz oferty łatwiej niż kiedykolwiek wcześniej.",
    "coupon_factory.create_coupon_benefit_header_3": "💸 Większa sprzedaż",
    "coupon_factory.create_coupon_benefit_body_3": "Zwiększ swoją sprzedaż dzięki kuponom dla swoich klientów.",
    "coupon_factory.create_coupon_card": "Utwórz kupon!",
    "coupon_factory.create_coupon_card_info": "Kliknij tutaj, aby utworzyć kupon w 3 krokach.",
    "coupon_factory.create_coupon": "Utwórz kupon!",
    "coupon_factory.create_coupon_body": "Trzy kroki do doskonałej reklamy dla nowych klientów!",
    "coupon_factory.condition_for_customer": "Ustaw warunek dla klientów",
    "coupon_factory.choose_condition": "Wybierz warunek",
    "coupon_factory.choose_condition_info": "Co powinien zrobić klient, aby odnieść korzyść",
    "coupon_factory.minimum_order_value": "Minimalna wartość zamówienia",
    "coupon_factory.minimum_order_value_info": "Klient powinien wydać określoną kwotę",
    "coupon_factory.product_purchase": "Zakup produktu",
    "coupon_factory.product_purchase_info": "Klient powinien kupić określone produkty",
    "coupon_factory.direct_rebate": "Bezpośredni rabat na produkt",
    "coupon_factory.no_conditions": "Żadnych warunków",
    "coupon_factory.no_conditions_set": "Nie ustawiono żadnych warunków",
    "coupon_factory.go_to_next_step": "Przejdź do następnego kroku",
    "coupon_factory.input_mov": "Określ minimalną wartość zakupu",
    "coupon_factory.count_of_products": "Liczba produktów",
    "coupon_factory.product_name": "Nazwa produktu",
    "coupon_factory.value_of_product_per_piece": "Wartość produktu (za sztukę)",
    "coupon_factory.order_discount": "Rabat na zakup",
    "coupon_factory.product_discount": "Rabat na produkt",
    "coupon_factory.free_product": "Darmowy produkt",
    "coupon_factory.discount": "Rabat",
    "coupon_factory.enter_discount_amount": "Wprowadź kwotę rabatu",
    "coupon_factory.redeem_location_conditons": "Warunki rabatowe",
    "coupon_factory.redeem_location_info": "Gdzie Twoi klienci mogą skorzystać z rabatu?",
    "coupon_factory.choose_discount": "Rabat",
    "coupon_factory.choose_discount_info": "Jaki rodzaj rabatu otrzymuje Twój klient?",
    "coupon_factory.carousel_header_one": "Wybierz kupon",
    "coupon_factory.carousel_body_one": "Wybierz kupon, który chcesz utworzyć.",
    "coupon_factory.carousel_header_two": "Ustaw warunek",
    "coupon_factory.carousel_body_two": "Określ, co klient ma zrobić.",
    "coupon_factory.carousel_header_three": "Ustaw korzyści",
    "coupon_factory.carousel_body_three": "Ustal, co otrzyma klient.",
    "coupon_factory.product_quantitiy": "Ilość na klienta",
    "coupon_factory.product_quantitiy_info": "Rabat obowiązuje za sztukę!",
    "coupon_factory.product_quantitiy_free_product_info": "Ile sztuk dostaje GRATIS?",
    "coupon_factory.same_product": "Ten sam produkt",
    "coupon_factory.same_product_info": "Klient otrzymuje ten sam produkt.",
    "coupon_factory.other_product": "Inny produkt",
    "coupon_factory.other_product_info": "Proszę podać nazwę produktu.",
    "coupon_factory.price_per_unit": "Cena za sztukę",
    "coupon_factory.error_invalid_input": "Nieprawidłowe dane wejściowe",
    "coupon_factory.error_rebate_higher_as_order_value": "Rabat nie może być wyższy niż kwota zakupu/produktu",
    "coupon_factory.error_rebate_higher_as_product_value": "Rabat nie może być wyższy niż kwota zakupu/produktu",
    "coupon_factory.max_created_coupon_card": "Osiągnięto maksymalną liczbę kuponów",
    "coupon_factory.max_created_coupon_card_info": "Osiągnąłeś aktualne maksimum dla swoich kuponów.",
    "coupon_factory.max_created_stores": "Osiągnięto maksymalną liczbę sklepów",
    "coupon_factory.max_created_stores_info": "Osiągnąłeś maksymalną liczbę transakcji, które można utworzyć. Jeśli chcesz założyć kolejny sklep skontaktuj się z nami korzystając z formularza kontaktowego w ustawieniach.",
    "details": "Bliższe dane",
    "enter_details": "Podaj szczegóły",
    "store_factory.store_conflict": "Wystąpił problem ze sklepem",
    "store_factory.store_conflict_info": "Wybrany sklep w Google został już zarejestrowany na Bazarze. Proszę sprawdzić raz. Jeśli nadal masz problemy, skontaktuj się z nami.",
    "store_factory.wrong_store": "Wybrano złą firmę?",
    "store_factory.wrong_store_search_again": "Czy chcesz uruchomić ponownie? Pamiętaj, że Twoje dane w Google muszą być prawidłowe.",
    "store_factory.restart": "Uruchom ponownie",
    "store_factory.what_customers_see": "To właśnie widzą Twoi klienci",
    "store_factory.check_details": "Sprawdź, czy Twoje informacje są prawidłowe.",
    "store_factory.store_name": "Nazwa oddziału",
    "store_factory.store_name_example": "Nazwa Twojej firmy",
    "store_factory.store_phone": "Numer telefonu dla Twoich klientów",
    "store_factory.store_email": "E-mail dla Twoich klientów",
    "store_factory.choose_category": "Wybierz kategorię",
    "store_factory.choose_category_info": "Wybierz kategorię główną, maksymalnie 3 podkategorie i prześlij zdjęcie swojego sklepu.",
    "store_factory.main_category": "Kategoria główna",
    "store_factory.sub_categories": "Podkategorie",
    "store_factory.upload_store_img": "Prześlij obraz sklepu",
    "store_factory.store_img": "Zdjęcie ze sklepu",
    "store_factory.max_achieved": "Osiągnięto maksimum",
    "store_factory.sub_categories_limit": "Możesz wybrać maksymalnie 3 podkategorie.",
    "store_factory.categories_and_image_required": "Kategorie i obrazy są niezbędne!",
    "store_factory.zip_code": "Kod pocztowy",
    "store_factory.zip_code_placeholder": "Np. 44801",
    "store_factory.claim_store": "Przejmij biznes",
    "store_factory.house_number": "Numer domu",
    "store_factory.house_number_placeholder": "Np. 33",
    "store_factory.street": "Ulica",
    "store_factory.street_placeholder": "Np. Ulica Bazarowa",
    "store_factory.city": "Miasto",
    "store_factory.city_placeholder": "Np. Bochum",
    "store_factory.success": "Sklep został utworzony pomyślnie",
    "store_factory.success_info": "Twój sklep został pomyślnie utworzony i jest obecnie weryfikowany.",
    "store_factory.success_verification": "Weryfikacja trwa zwykle 1-2 dni robocze. Po zakończeniu weryfikacji Twój sklep zostanie uruchomiony i będzie widoczny dla klientów.",
    "store_factory.verification_pending": "Weryfikacja w toku",
    "store_factory.verification_pending_info": "Obecnie trwa proces weryfikacji. Skontaktujemy się, jeśli będziemy potrzebować więcej informacji od Ciebie. Jeśli masz jakiekolwiek pytania, skontaktuj się z nami.",
    "store_factory.no_coupons": "Nie ma jeszcze kuponów",
    "store_factory.no_coupons_info": "Nie utworzyłeś jeszcze żadnych kuponów. Kliknij sklep, aby utworzyć kupon.",
    "to_dashboard": "Do deski rozdzielczej",
    "error_unexpected": "Wystąpił nieoczekiwany błąd. Spróbuj ponownie. Jeżeli problem będzie się powtarzał, prosimy o kontakt.",
    "check_inputs": "Sprawdź informacje",
    "warning": "Niebezpieczeństwo",
    "progress_deleted_info": "Jeśli tu wrócisz, Twój status tymczasowy zostanie usunięty. Czy jesteś pewien?",
    "notification": "Powiadomienie",
    "notifications": "Powiadomienia",
    "request_notification_permission": "Włączyć powiadomienia push?",
    "denied_notification_permission": "Powiadomienia push odrzucone",
    "denied": "Zaprzeczony",
    "activate_notification_in_settings": "Możesz włączyć powiadomienia push w Ustawieniach",
    "notifcationsTitle.message1": "🥳 Czas na lokalne rabaty!",
    "notifcationsBody.message1": "Oszczędzaj teraz dzięki ekskluzywnym kuponom do lokalnych sklepów! Niezależnie od tego, czy chodzi o jedzenie, modę czy rozrywkę - odkryj wspaniałe zniżki i wspieraj swoją społeczność!"
  },
  "es": {
    "!add_row_below": "VVV Agregar línea debajo de VVV",
    "so_easy_for_partners": "Así de fácil para los socios",
    "so_easy_for_partners.register_store": "Registre su negocio de forma rápida y sencilla para llegar a más clientes.",
    "so_easy_for_partners.create_coupon": "Cree vales individuales para sus clientes, aumente las ventas y consiga nuevos clientes, todo según sus deseos.",
    "so_easy_for_partners.manage_store": "Administre su tienda fácilmente: ajuste los horarios de apertura, las imágenes y los cupones según sea necesario en cualquier momento.",
    "change_language": "Cambiar idioma",
    "english_USA": "🇬🇧 Inglés",
    "german_Germany": "🇩🇪 alemán",
    "Imprint": "Imprimir",
    "Datenschutz": "Política de privacidad",
    "AGB": "Términos y condiciones generales de negocio.",
    "Cookies": "Galletas",
    "about": "SOBRE NOSOTROS",
    "our_magazine": "Nuestra revista",
    "for_companies": "Para empresas",
    "contact": "Contacto",
    "no_answer_found": "¿Pregunta no respondida?",
    "jobs": "Empleos",
    "become_partner": "Conviértete en socio",
    "shop": "Comercio",
    "experiences": "Experiencias",
    "legal": "LEGAL",
    "social_media": "REDES SOCIALES",
    "frequently_asked_questions": "Preguntas frecuentes",
    "vendor_faq.how_work_question": "¿Cómo funciona BASAR para los socios?",
    "vendor_faq.how_work_answer": "Nos hemos asociado con varias empresas locales y negociamos ofertas únicas, como servicios 2 por 1 o descuentos en productos. La aplicación Bazaar le permite ver, reservar y canjear estas ofertas en numerosos sectores, como restaurantes, peluquerías, talleres, floristerías y muchos otros.",
    "vendor_faq.participating_businesses_question": "¿Qué empresas participan?",
    "vendor_faq.participating_businesses_answer": "Miles de empresas locales participan actualmente con una variedad de ofertas exclusivas. Desde gastronomía hasta servicios y productos, encontrará grandes ofertas en muchas categorías.",
    "vendor_faq.app_free_question": "¿La aplicación es actualmente gratuita?",
    "vendor_faq.app_free_answer": "Sí, la aplicación BASAR actualmente es completamente gratuita. Los socios pueden participar y ofrecer ofertas sin ningún cargo.",
    "vendor_faq.create_store_question": "¿Cómo crear una tienda en BASAR?",
    "vendor_faq.create_store_answer": "Crear tu tienda en BASAR es fácil y rápido. Puede configurar su perfil comercial con solo unos pocos clics directamente en la aplicación.",
    "vendor_faq.manage_coupons_question": "¿Puedo crear y administrar mis propios cupones?",
    "vendor_faq.manage_coupons_answer": "Sí, tienes control total sobre la creación y eliminación de tus propios cupones. Establece descuentos, ofertas y condiciones que mejor se adapten a tu negocio. Ningún gerente de ventas perturbará su negocio, su marketing.",
    "faq.general.category": "GENERAL",
    "faq.general.questions[0].question": "¿Cuál es el concepto de Bazar?",
    "faq.general.questions[0].answer": "Nos hemos asociado con varias empresas locales y negociamos vales de lanzamiento exclusivos como: B. Ofertas o descuentos 2 por 1 en productos. Con la aplicación Bazaar puedes buscar, reservar y canjear fácilmente estas ofertas en una variedad de industrias como restaurantes, peluquerías, talleres, floristerías y más.",
    "faq.general.questions[1].question": "¿Qué empresas participan?",
    "faq.general.questions[1].answer": "Miles de empresas locales participan actualmente con una variedad de ofertas exclusivas. Desde comidas hasta servicios y productos, encontrará grandes ofertas en muchas categorías.",
    "start": "Comenzar",
    "price": "Precio",
    "redeem_error_notToday_title": "Cupón no canjeable hoy.",
    "redeem_error_notToday_message": "El cupón sólo se puede canjear en los días especificados.",
    "redeemDays.monday": "Mes",
    "redeemDays.tuesday": "Hacer",
    "redeemDays.wednesday": "Nosotros",
    "redeemDays.thursday": "Th",
    "redeemDays.friday": "EM",
    "redeemDays.saturday": "Se sentó",
    "redeemDays.sunday": "Su",
    "coupon_factory.your_coupon_active": "¡Tu cupón ya está activo y pronto será visible para tus clientes!",
    "coupon_factory.valid_days_header": "Días válidos",
    "coupon_factory.valid_days_subHeader": "Días en los que los clientes pueden canjear los cupones.",
    "error_messages.something_wrong": "Algo salió mal. Vuelve a intentarlo más tarde o contáctanos si el problema persiste.",
    "store_factory.store_not_found_input_manual": "¿No se encontró la tienda? Entrada manual de dirección.",
    "sent_review": "Enviar reseña.",
    "review_your_visit_at": "Califica tu visita @",
    "search": "Buscar",
    "faq_title": "Preguntas frecuentes (Preguntas frecuentes)",
    "faq_title_reverse": "Preguntas frecuentes (FAQ)",
    "vendor_faq_question_concept": "¿Cómo funciona BASAR?",
    "vendor_faq_answer_concept": "El concepto de BASAR es simple y efectivo. Como trabajador autónomo con un negocio físico, puede utilizar la aplicación BASAR Business para presentar su tienda y crear cupones. Los usuarios pueden encontrar estos cupones a través de la aplicación de usuario BASAR y canjearlos directamente en su tienda. De esta manera, fortalecemos al comercio local en la competencia frente a las grandes cadenas minoristas.",
    "vendor_faq_question_costs": "¿Hay algún costo para mí?",
    "vendor_faq_answer_costs": "No, BASAR es completamente gratuito para los minoristas. Los usuarios se suscriben para obtener acceso a todos los cupones. Esto nos permite cubrir los costos de la plataforma y hacer posible que las empresas locales sean competitivas, incluso frente a las grandes cadenas que invierten en costosos programas de marketing y fidelización de clientes.",
    "vendor_faq_question_minium_requirements": "¿Qué requisitos tengo que cumplir?",
    "vendor_faq_answer_minium_requirements": "Necesita una tienda física donde atienda a los clientes in situ, p. B. un restaurante, una peluquería, un taller o similar. Además, usted o sus empleados deberían poder conceder fácilmente los descuentos prometidos en la caja.",
    "vendor_faq_question_interface_cashier": "¿Tengo que integrar mi caja registradora?",
    "vendor_faq_answer_interface_cashier": "No, BASAR funciona muy fácilmente con el principio clásico de cuatro ojos. El cliente te muestra el cupón canjeado en su smartphone y tú le concedes el descuento. No se necesitan integraciones de sistemas POS costosas o complicadas. Lo único importante es que usted o sus empleados puedan implementar los descuentos correctamente.",
    "vendor_faq_question_shop_creation": "¿Cómo creo mi tienda?",
    "vendor_faq_answer_shop_creation": "Puedes crear tu tienda en la pestaña “Tienda”. Busca tu negocio en nuestra base de datos o créalo manualmente. Añade información de contacto, horarios de apertura, una foto y la categoría adecuada. Comprobaremos la información y nos pondremos en contacto contigo si tienes alguna duda. La verificación suele tardar un día laborable; después podrá crear cupones inmediatamente.",
    "vendor_faq_question_coupons": "¿Cómo puedo crear cupones?",
    "vendor_faq_answer_coupons": "Después de crear tu tienda, puedes iniciar la fábrica de cupones en la pestaña \"Cupones\". Allí encontrará plantillas listas para usar a las que podrá agregar su información rápida y fácilmente. Después de la vista previa, puede crear el cupón y se activará en poco tiempo. Requisito: Tu tienda debe estar verificada.",
    "vendor_faq_question_storeinfos_update": "¿Cómo actualizo la información de mi negocio?",
    "vendor_faq_answer_storeinfos_update": "Vaya a su tienda y haga clic en la pestaña \"Información\". Allí podrás actualizar la información de contacto, horarios de apertura e imágenes utilizando los botones de edición correspondientes.",
    "vendor_faq_question_abuse": "¿Cómo previene BASAR el canje múltiple de un cupón?",
    "vendor_faq_answer_abuse": "Cada usuario se registra con su número de teléfono y por tanto es claramente identificable. Al crear un cupón, puede especificar con qué frecuencia se puede canjear, p. B. sólo cada 90 días. De esta manera el uso sigue siendo justo y controlado.",
    "vendor_faq_question_help_creation": "¿Nos ayudarás a crear la tienda o los cupones?",
    "vendor_faq_answer_help_creation": "¡Por supuesto! Estaremos encantados de ayudarle si necesita ayuda para configurar su tienda o cupones. Puedes contactar con nosotros en cualquier momento a través de WhatsApp. Puede encontrar el número en la configuración en \"Comentarios y soporte\".",
    "vendor_faq_question_how_to_contact": "¿Cómo puedo comunicarme con usted?",
    "vendor_faq_answer_how_to_contact": "Puede comunicarse con nosotros por correo electrónico a basar@basar-digital.com o directamente a través de WhatsApp. Puede encontrar el número de WhatsApp en la configuración en \"Comentarios y soporte\".",
    "create_store_and_coupons": "Crea tu tienda y cupones",
    "click_here_for_help": "Haga clic aquí si necesita más información.",
    "vendorOnboardingTutorial_title_step1": "Busca y crea tu propia tienda.",
    "vendorOnboardingTutorial_description_step1": "Encuentre su negocio usando la función de búsqueda o simplemente créelo usted mismo. Agregue toda la información importante, como contacto, horarios de apertura, imágenes y categorías.",
    "vendorOnboardingTutorial_imageCaption_step1": "Tu tienda, tu comienzo: con solo unos pocos clics serás visible para tus clientes, independientemente de si estás añadiendo una tienda existente o creando una nueva.",
    "vendorOnboardingTutorial_title_step2": "Crea cupones irresistibles.",
    "vendorOnboardingTutorial_description_step2": "Utilice nuestra fábrica de cupones fácil de usar para crear ofertas personalizadas. Deleite a sus clientes con descuentos exclusivos y dé un nuevo impulso a su negocio.",
    "vendorOnboardingTutorial_imageCaption_step2": "Con la fábrica de cupones podrás sorprender a tus clientes: crea ofertas individuales en tan solo unos segundos y fortalece la fidelidad de tus clientes.",
    "vendorOnboardingTutorial_title_step3": "Esperamos clientes entusiastas.",
    "vendorOnboardingTutorial_description_step3": "Con tus ofertas atraes nuevos clientes y sorprendes a tus clientes habituales. Los clientes pueden encontrar su tienda y sus cupones directamente en la aplicación de usuario de BASAR. Usted canjea los cupones en el sitio y puede aplicar el descuento directamente al finalizar la compra.",
    "vendorOnboardingTutorial_imageCaption_step3": "Su negocio, su alegría: más clientes, más ventas: con la aplicación de usuario BASAR su tienda será la primera opción.",
    "category": "Categoría",
    "loadings_favorites": "Cargando favoritos",
    "feature_so_great": "¡Esta característica es tan buena que no es gratuita! Regístrese y comience.",
    "login_and_start": "Inicia sesión y comienza",
    "oops_something_missing": "¡Ups, todavía falta algo!",
    "coupon_factory": "Fábrica de cupones",
    "coupon_factory.infoHeader_name_of_product": "Nombre del producto",
    "coupon_factory.infoBody_name_of_product": "Ingrese el nombre del producto o servicio que desea que compre el cliente.",
    "coupon_factory.infoHeader_value_of_product": "Valor del producto",
    "coupon_factory. infoBody_value_of_product": "¿Cuánto cuesta el producto en su tienda?",
    "coupon_factory.infoHeader_count_of_product": "Número de productos",
    "coupon_factory.infoBody_count_of_product": "¿Cuántos de este producto debería comprar?",
    "coupon_factory.infoHeader_minimum_order_volume": "Valor mínimo de pedido / valor mínimo de compra",
    "coupon_factory.infoBody_minimum_order_volume": "¿Cuánto debería gastar el cliente al menos para beneficiarse?",
    "coupon_factory.infoHeader_name_of_benefit_product": "Nombre del producto de ventaja",
    "coupon_factory.infoBody_name_of_benefit_product": "Ingrese aquí el nombre del producto o servicio que recibirá el cliente.",
    "coupon_factory.infoHeader_value_of_benefit_product": "Valor del producto de ventaja.",
    "coupon_factory.infoBody_value_of_benefit_product": "¿Cuánto cuesta normalmente el producto sin precio de oferta?",
    "coupon_factory.infoHeader_discount_amount": "Especificar monto de descuento",
    "coupon_factory.infoBody_discount_amount": "¿A cuánto asciende el descuento por pieza? En € o %.",
    "coupon_factory.infoHeader_order_discount_amount": "Especificar monto de descuento",
    "coupon_factory.infoBody_order_discount_amount": "¿A cuánto asciende el descuento en la compra o pedido? En € o %.",
    "delete_coupon": "Eliminar cupón",
    "generate": "Generar",
    "delete_coupon_text": "¿Estás seguro de que deseas eliminar este cupón? Esta acción no se puede deshacer.",
    "delete_coupon_info": "Atención: Tu cupón sigue activo hasta el final del día y ya no será visible para los usuarios a partir del día siguiente.",
    "validation_all_inputs_required": "Todos los campos deben completarse correctamente.",
    "store_factory.contact_and_opentimes": "Contacto y horario de apertura",
    "store_factory.always_editable_later": "Siempre podrás editarlo más tarde.",
    "choose_category_and_image": "Seleccione imagen y categoría.",
    "store_factory.main_categories_limit": "Seleccione una categoría.",
    "searchdata_openstreetmap": "Datos de búsqueda © OpenStreetMap",
    "success.opentimes_updated": "¡Horarios de apertura actualizados correctamente!",
    "success.contactdetails_updated": "¡Datos de contacto actualizados exitosamente!",
    "editcontact": "Editar datos de contacto",
    "from": "De",
    "until": "Hasta",
    "weekday": "Día",
    "open_questionmark": "¿Abierto?",
    "coupon_factory.contact_if_issue": "Por favor revisa toda la información. Si aún necesita ayuda, no dude en contactarnos aquí",
    "respawn_time": "Tiempo de reaparición",
    "coupon_factory.coupon_in_three_steps": "Crea un cupón en 3 pasos.",
    "store_factory.your_store_on_map": "Tu negocio en el mapa",
    "coupon_factory.infoHeader_need_help": "¿Necesitas apoyo?",
    "coupon_factory.infoBody_need_help_whatsapp": "Contáctanos directamente aquí vía Whatsapp.",
    "coupon_factory.check_preview": "Obtenga una vista previa de su cupón.",
    "coupon_factory.preview": "Avance",
    "coupon_factory.check_details": "¿Es toda la información correcta? ¡Haga clic en Generar para activar su cupón!",
    "coupon_factory.respawn_time_description": "Cuándo un cliente puede reactivar el cupón después de canjearlo. Si ya no desea ofrecer una oferta, puede eliminarla usted mismo.",
    "coupon_factory.respawn_time_short_description": "Cuánto tiempo tiene que esperar el cliente antes de poder activar el cupón nuevamente.",
    "coupon_factory.thirty_days_recommendation": "El cliente podrá canjear el cupón nuevamente después de 30 días. Ideal para ofertas que están disponibles regularmente y son atractivas para los clientes recurrentes.",
    "coupon_factory.ninety_days_recommendation": "El cupón se puede volver a utilizar después de 90 días. Esta opción funciona bien para ofertas más largas dirigidas a nuevos clientes.",
    "coupon_factory.weekly_recommendation": "El cupón se puede canjear nuevamente una vez por semana. Perfecto para promociones de corta duración que deben renovarse periódicamente.",
    "coupon_factory.thirty_days_recommendation_short": "Ideal para ofertas disponibles regularmente.",
    "coupon_factory.ninety_days_recommendation_short": "Ideal para ofertas poco comunes de nuevos clientes.",
    "coupon_factory.weekly_recommendation_short": "Ideal para ofertas promocionales cortas.",
    "coupon_factory.mainly_for_restaurants": "Generalmente sólo se aplica a la gastronomía.",
    "coupon_factory.default_set": "Establecido por defecto.",
    "coupon_factory.redeem_location_onsite_info": "Válido si el cliente compra en tienda.",
    "coupon_factory.redeem_location_togo_info": "Válido si el cliente compra algo para llevar, p.e. un kebab para llevar.",
    "coupon_factory.choose_coupon_type": "Seleccionar tipos de cupones",
    "coupon_factory.choose_coupon_type_details": "Selecciona el tipo de cupón que deseas crear para tu tienda.",
    "coupon_factory.example_header_one": "10€ de reembolso",
    "coupon_factory.example_body_one": "Consigue 10€ de descuento con un gasto mínimo de 50€",
    "coupon_factory.example_headertitle_one": "Reembolso",
    "coupon_factory.example_subheader_one": "Tus nuevos clientes recibirán un descuento directo en su compra/pedido.",
    "coupon_factory.example_header_two": "1x bebida DE REGALO",
    "coupon_factory.example_body_two": "Obtienes 1x chocolate gratis sin valor mínimo de pedido",
    "coupon_factory.example_headertitle_two": "REGALO",
    "coupon_factory.example_subheader_two": "Tus nuevos clientes recibirán un regalo con su compra.",
    "coupon_factory.example_header_three": "2 por 1 - papas fritas",
    "coupon_factory.example_body_three": "Cuando compras 1 papas fritas, obtienes 1 papas fritas gratis. Aplica para el producto del mismo precio o más barato.",
    "coupon_factory.example_headertitle_three": "2 por 1",
    "coupon_factory.example_subheader_three": "Tus nuevos clientes reciben 2 productos por el precio de uno.",
    "coupon_factory.example_header_four": "50% de descuento directo en chaquetas",
    "coupon_factory.example_body_four": "Recibirás 1 x ramo de flores con un 50% de descuento cada uno sin valor mínimo de pedido.",
    "coupon_factory.example_headertitle_four": "VENTA",
    "coupon_factory.example_subheader_four": "Tus clientes reciben un descuento directo en un producto específico.",
    "coupon_factory.example_header_individual": "Individualmente",
    "coupon_factory.example_body_individual": "trato individual",
    "coupon_factory.example_headertitle_individual": "Individualmente",
    "coupon_factory.example_subheader_individual": "Crea un cupón completamente individual para tus clientes.",
    "welcome": "Bienvenido",
    "languages.de": "🇩🇪 alemán",
    "languages.en": "🇺🇸Inglés",
    "languages.tr": "🇹🇷 turco",
    "languages.ar": "🇸🇦 árabe",
    "languages.fr": "🇫🇷 Francés",
    "languages.es": "🇪🇸Español",
    "languages.it": "🇮🇹 italiano",
    "languages.pl": "🇵🇱polaco",
    "languages.ru": "🇷🇺ruso",
    "popular_categories": "Categorías populares",
    "no_matching_categories_found": "No se encontraron categorías coincidentes",
    "hello": "Hola desde Alemania",
    "with_coupons": "Con cupones",
    "cities_with_digital_basar": "Ciudades con bazar digital",
    "use_your_location": "Usa tu ubicación",
    "no_matching_cities_found": "No se encontraron ciudades coincidentes",
    "discover": "Descubrir",
    "favorites": "Favoritos",
    "profile": "Perfiles",
    "delete": "Borrar",
    "openingTimes": "Horario de apertura",
    "editOpeningTimes": "Editar horario de apertura",
    "days.monday": "Lunes",
    "days.tuesday": "Miércoles",
    "days.wednesday": "Miércoles",
    "days.thursday": "Jueves",
    "days.friday": "Viernes",
    "days.saturday": "Sábado",
    "days.sunday": "Domingo",
    "command_searchForYourBusiness": "Busca tu negocio",
    "placeholder_find_store_google": "Encuentra tu negocio en Google",
    "permission_to_camera_roll": "¡Se requiere acceso a la biblioteca de fotografías!",
    "businessMustBeOnGoogleMaps": "Su empresa debe ser visible en Google Maps. Si tiene algún problema, no dude en contactarnos aquí.",
    "closed": "Cerrado",
    "location": "Ubicación",
    "coupons": "Cupones",
    "activated_coupons": "Cupones activados",
    "redeemed_coupons": "Cupones canjeados",
    "activate": "Activar",
    "redeem_now": "Canjear ahora",
    "coupon_redeemed_message": "Cupón canjeado: ¡sigue así! Así apoyamos el comercio local.",
    "coupon_redeemed_message_title": "¡Cupón canjeado!",
    "coupon_redeemed_message_bodxy": "¡Avanza! Así apoyamos el comercio local.",
    "share_message": "Hola, estoy en BASAR. Descarga la aplicación usando este enlace.",
    "share_success": "¡Comparte con éxito!",
    "share_dismissed": "Compartir cancelado",
    "share_failed": "Compartir falló:",
    "report_problem": "Informar un problema",
    "deactivate_coupon": "Desactivar cupón",
    "info": "Información",
    "more": "Más",
    "Information about this Coupons": "Información sobre este cupón",
    "command_mention_basar_for_redeem": "Antes de pagar, menciona que tienes un bono BASAR y canjéalo frente a un empleado. Sólo se puede canjear un cupón por visita.",
    "Redeem Location": "Ubicación de canje",
    "redeem_location_info": "Ya sea que la oferta sea presencial o para llevar",
    "Reactivation time": "Tiempo de reactivación",
    "reactivation_info": "Cuándo la oferta volverá a estar activa para usted después de haberla canjeado.",
    "reviews": "Reseñas",
    "review": "Reseñas",
    "show_all_reviews": "Ver todas las reseñas",
    "show_all": "Ver todo",
    "REVIEW_SENT": "Revisión enviada.",
    "REVIEW_THANKS": "¡Gracias por tu reseña!",
    "REVIEW_WRITE": "Escribe tu reseña...",
    "loading": "Cargando",
    "noReviewsAvailable": "Aún no hay comentarios",
    "noStoreInformationAvailable": "Aún no hay información",
    "advetorials.vendor.become_vendor_title": "¿Tiene una tienda local en el sitio?",
    "advetorials.vendor.become_vendor_body": "Entonces conviértase en distribuidor de nuestra aplicación ahora. Tenemos muchos usuarios a quienes les gustaría apoyar su negocio local.",
    "advetorials.vendor.download_vendor_app": "Descargue la aplicación minorista desde App Store.",
    "become_Member": "Hazte miembro",
    "enter_discount": "Introduce el código de descuento",
    "translated_by_ai": "Es posible que las traducciones se hayan creado utilizando IA y, por lo tanto, no siempre sean perfectas.",
    "closeButton": "Cerca",
    "edit_profile": "Editar perfil",
    "validation_invalid_input": "Entrada no válida",
    "validation_required_input": "El campo debe ser completado",
    "success": "Confirmado",
    "error": "Error",
    "profile_update_success": "¡Perfil actualizado exitosamente!",
    "profile_image_update_success": "Imagen de perfil actualizada exitosamente",
    "profile_image_update_error": "Error al actualizar la imagen de perfil",
    "tutorial": "Tutorial",
    "settings": "Ajustes",
    "feedbackAndSupport": "Comentarios y soporte",
    "changeToVendorMode": "Cambiar al modo distribuidor",
    "changeToUserMode": "Cambiar al modo cliente",
    "registerAsPartner": "Regístrate como socio",
    "logout": "Finalizar la sesión",
    "first_name": "Nombre de pila",
    "last_name": "Apellido",
    "email": "Correo electrónico",
    "zipCode": "Código postal",
    "edit": "Editar",
    "delete_account": "Eliminar perfiles",
    "delete_account_message": "¿Estás seguro de que quieres eliminar tu perfil? Todas sus ventajas y configuraciones se perderán irremediablemente.",
    "delete_account_success": "Perfil eliminado exitosamente",
    "delete_account_success_message": "Su perfil ha sido eliminado exitosamente. 👋 Esperamos volver a vernos pronto",
    "save": "Ahorrar",
    "back": "Hornear",
    "continue": "Continuar",
    "later": "Más tarde",
    "terms_and_conditions": "Términos y condiciones generales de negocio.",
    "agree_to_terms": "Acepto los <terms>Términos y condiciones generales</terms>.",
    "phonenumber": "Número telefónico",
    "enter_phonenumber": "Introduce el número de teléfono",
    "login_no_password_info": "Inicio de sesión sencillo mediante código SMS. Por lo tanto, no es necesario recordar una contraseña.",
    "enter_code": "Introduce el código",
    "sent_to": "Enviado a",
    "search_country_code": "Buscar por país o código de área",
    "command_agree": "Por favor acepta",
    "command_agree_to_terms": "Debe aceptar los términos para continuar.",
    "otp": "Contraseña de un solo uso",
    "enter_otp": "Ingrese una contraseña de un solo uso",
    "verify_otp": "Confirmar contraseña de un solo uso",
    "otp_expired": "La contraseña de un solo uso expiró",
    "otp_incorrect": "Contraseña de un solo uso incorrecta",
    "otp_verification_error": "Se ingresó una contraseña de un solo uso incorrecta o vencida.",
    "command_create_profile_to_start": "¡Crea tu perfil para comenzar!",
    "command_create_company_to_start": "Registre su empresa para comenzar.",
    "info_create_shop_later": "Puede proporcionar información sobre su tienda en el siguiente paso.",
    "your_concern": "Tu petición",
    "describe_your_concern": "Cuéntanos tu petición.",
    "contact_you_asap": "Nos pondremos en contacto con usted lo más rápido posible.",
    "contact_via_whatsapp": "Contáctanos vía Whatsapp",
    "change_image": "Cambiar imagen",
    "edit_store_profile": "Editar perfil comercial",
    "error_loading_store": "Error al cargar datos comerciales",
    "error_messages.no_stores.no_stores_nearby_found_title": "No se encontraron tiendas cerca de ti",
    "error_messages.no_stores.no_stores_nearby_found_body": "Aparentemente aún no somos rápidos. Pero llegaremos allí, no te preocupes. Cambia la ciudad usando la búsqueda o desplázate hasta ella usando el mapa.",
    "error_messages.conflict.store_exists_title": "El negocio en esta dirección ya ha sido registrado.",
    "error_messages.conflict.store_exists_body": "No puedes crear una tienda que ya esté registrada. Si cree que se trata de un error, contáctenos aquí.",
    "funny_error_message": "Vaya, algo salió mal.",
    "page_not_found": "No se pudo cargar la página.",
    "page_not_found_connect_to_wifi": "Asegúrese de estar conectado a Internet.",
    "page_not_found_help_message": "Si el problema persiste, por favor contáctenos",
    "cancel": "Cancelar",
    "partner_login": "Inicio de sesión de socio",
    "partner_register": "Registro de socios",
    "command_no_account_register": "¿Sin cuenta? Regístrese aquí.",
    "command_have_account_login": "¿Tienes una cuenta? Inicia sesión aquí.",
    "login": "Acceso",
    "register": "Registro",
    "please_enter_email": "Por favor proporcione correo electrónico",
    "email_sent": "Correo electrónico enviado",
    "password_reset_email_sent": "Correo electrónico enviado para restablecer la contraseña.",
    "password_reset_failed": "Error al restablecer la contraseña.",
    "forgot_password": "Has olvidado tu contraseña",
    "password_reset_rate_limit": "Por razones de seguridad, solo puedes realizar esta solicitud una vez cada 60 segundos. Espere y vuelva a intentarlo.",
    "back_to_login": "Volver a iniciar sesión",
    "company_profile": "Perfil de la empresa",
    "company_details": "Detalles de la empresa",
    "company_email": "Correo electrónico de la empresa",
    "company_name": "Nombre de empresa",
    "company_address": "Dirección de la empresa",
    "company_zipCode": "Código Postal",
    "company_city": "Ciudad",
    "company_country": "País",
    "company_phone": "Número de teléfono de la empresa",
    "company_pocName": "Nombre, apellido (persona de contacto)",
    "company_pocRole": "Seleccionar rol (persona de contacto)",
    "store_image": "Foto de la tienda",
    "vendor_delete_account": "¿Estás seguro de que quieres eliminar tu perfil?",
    "vendor_delete_account_body": "Actualmente, tu perfil sólo puede ser eliminado contactando con nosotros vía WhatsApp o por correo electrónico a basar@basar-digital.com",
    "select_a_category": "Selecciona una categoría",
    "categories.grocery": "Comestibles",
    "categories.restaurants": "Restaurante",
    "categories.hairSalon": "Barbero",
    "categories.electronics": "Electrónica",
    "categories.beauty_wellness": "Belleza y bienestar",
    "categories.other": "Otro",
    "categories.non_food_retail": "Minorista",
    "categories.home_and_garden": "Casa y jardin",
    "categories.automotive": "Movilidad",
    "categories.flowers_and_gifts": "Flores y regalos",
    "categories.activities": "Actividades",
    "categories.cafe_and_dessert": "Café y postre",
    "categories.farm_shop": "Tienda de granja",
    "subcategories.convenienceStore": "Minimercado",
    "subcategories.eastEuropean": "Europa del este",
    "subcategories.beverageMarket": "Mercado de bebidas",
    "subcategories.orientalGrocery": "Oriental",
    "subcategories.indianGrocery": "Comidas indias",
    "subcategories.importedGrocery": "Importar",
    "subcategories.bioOrganic": "Orgánico y orgánico",
    "subcategories.africanGrocery": "Africano",
    "subcategories.turkishSupermarket": "Turco",
    "subcategories.latinAmericanSupermarket": "Latinoamericano",
    "subcategories.middleEasternSupermarket": "Oriente Medio",
    "subcategories.russianSupermarket": "Ruso",
    "subcategories.polishSupermarket": "Polaco",
    "subcategories.mediterraneanSupermarket": "Mediterráneo",
    "subcategories.veganVegetarianSupermarket": "Vegano/vegetariano",
    "subcategories.halalMarket": "Halal",
    "subcategories.kosherSupermarket": "Comestible según la ley judía",
    "subcategories.southAsianSupermarket": "Sur de asia",
    "subcategories.germanCuisine": "Alemán",
    "subcategories.frenchRestaurant": "Francés",
    "subcategories.spanishTapasBar": "Español",
    "subcategories.greekTavern": "Griego",
    "subcategories.mexicanRestaurant": "Mexicano",
    "subcategories.indianRestaurant": "Indio",
    "subcategories.sushiBar": "Barra de sushi",
    "subcategories.steakhouse": "Asador",
    "subcategories.vegetarianVeganRestaurant": "Vegano/vegetariano",
    "subcategories.foodTruck": "Camión de comida",
    "subcategories.fusionCuisine": "Internacional",
    "subcategories.medievalTavern": "Medieval",
    "subcategories.patisserie": "Pastelería",
    "subcategories.lowCarb": "Bajo en carbohidratos",
    "subcategories.brideAndEvent": "Novia y evento",
    "subcategories.afroBarber": "Barbero afro",
    "subcategories.hairSpecialist": "Especialista en cabello",
    "subcategories.permanentHairRemoval": "Depilación permanente",
    "subcategories.hairReplacement": "Depilación",
    "subcategories.barberServices": "Servicios para el cabello",
    "subcategories.veganCafe": "Vegano",
    "subcategories.outdoorActivities": "Actividades al aire libre",
    "subcategories.eventDecorators": "Decoración de eventos",
    "subcategories.indoorPlants": "Plantas de interior",
    "subcategories.outdoorPlants": "Plantas de exterior",
    "subcategories.ballonAndParty": "Globos y artículos para fiestas",
    "subcategories.handmade": "Hecho a mano",
    "subcategories.candleAndFragrances": "Velas y fragancias",
    "subcategories.autoDetailing": "Preparación del vehículo",
    "subcategories.autoBodyAndPaint": "Carrocería y pintura",
    "subcategories.carRental": "Alquiler de coches",
    "subcategories.autoGlass": "Autovidrio",
    "subcategories.inspection": "Inspección",
    "subcategories.oilChange": "Cambio de aceite",
    "subcategories.motorcycle": "Motocicleta",
    "subcategories.autoAccessories": "Accesorios de coche",
    "subcategories.autoTuning": "Taller de tuning",
    "subcategories.homeDecor": "Decoración de la casa",
    "subcategories.homeImprovement": "Mejoras para el hogar",
    "subcategories.lightingStore": "Lámparas y luces",
    "subcategories.applianceStore": "Electrodomésticos",
    "subcategories.kitchenAndBath": "Cocina y sanitario",
    "subcategories.securityAndSmartHome": "Seguridad y hogar inteligente",
    "subcategories.diy": "Bricolaje",
    "subcategories.pharmacy": "Farmacia",
    "subcategories.medicalSupply": "Tienda medica",
    "subcategories.jewlery": "Joyero",
    "subcategories.toyStore": "Juguetería",
    "subcategories.musicStore": "Música e instrumentos",
    "subcategories.secondhandStores": "Segunda mano",
    "subcategories.travelAgency": "Agencia de viajes",
    "subcategories.photoStudio": "Estudio fotográfico",
    "subcategories.tailorShop": "Sastrería",
    "subcategories.cleaningService": "Servicio de limpieza",
    "subcategories.shoeRepair": "Reparación de calzado",
    "subcategories.keyCopyService": "Servicio de cerrajería",
    "subcategories.laundryService": "Servicio de lavandería",
    "subcategories.locksmith": "Cerrajero",
    "subcategories.mobileRepair": "Reparacion de celulares",
    "subcategories.computerRepair": "Reparación de computadoras",
    "subcategories.packagingService": "Servicio de embalaje",
    "subcategories.printShop": "Imprenta",
    "subcategories.eventPlanning": "Planificación de eventos",
    "subcategories.regionalProducts": "Productos regionales",
    "subcategories.butcherGrocery": "Carnicero",
    "subcategories.bakeryGrocery": "Productos horneados",
    "subcategories.asianGrocery": "Asiático",
    "subcategories.eastEuropaen": "Europa del este",
    "subcategories.farmGrocery": "Tienda de granja",
    "subcategories.halalRestaurant": "Halal",
    "subcategories.asianRestaurants": "Asiático",
    "subcategories.seafoodRestaurant": "Mariscos",
    "subcategories.italianRestaurant": "Italiano",
    "subcategories.burgerRestaurant": "Hamburguesas",
    "subcategories.menHairSalon": "Caballeros",
    "subcategories.kidsHairSalon": "Niños",
    "subcategories.womenHairSalon": "Mujer",
    "subcategories.gadgets": "Gadgets",
    "subcategories.vegetarianRestaurant": "Vegetariano",
    "subcategories.mediterraneanRestaurant": "Mediterráneo",
    "subcategories.fishMarket": "Mercado de pescado",
    "subcategories.imker": "Apicultor",
    "subcategories.makeup": "Constituir",
    "subcategories.barbeque": "Barbacoa",
    "subcategories.bubble_tea": "Té de burbujas",
    "subcategories.african": "Africano",
    "subcategories.hair_treatments": "Tratamientos capilares",
    "subcategories.hair_removal": "Depilación",
    "subcategories.teeth_whitening": "Blanqueamiento dental",
    "subcategories.acupuncture": "Acupuntura",
    "subcategories.nail_salon": "Salón de uñas",
    "subcategories.therapies": "Terapias",
    "subcategories.meat": "Carne",
    "subcategories.buffet": "Bufés",
    "subcategories.fried": "Comida frita",
    "subcategories.fast_food": "Comida rápida",
    "subcategories.breakfast_brunch": "Desayuno | desalmuerzo",
    "subcategories.bistro_imbiss": "Bistró | bocadillo",
    "subcategories.manicure_pedicure": "Manicuras y Pedicuras",
    "subcategories.facial_treatments": "Tratamientos faciales",
    "subcategories.cosmetic_supply": "Productos cosméticos",
    "subcategories.beverage_market": "Mercado de bebidas",
    "subcategories.dairyGrocery": "Lácteos",
    "subcategories.vegetableGrocery": "Verduras",
    "subcategories.dessertBar": "Postres",
    "subcategories.crepes": "Crepes",
    "subcategories.waffles": "Gofres",
    "subcategories.juices": "Jugos",
    "subcategories.tea_house": "Casa de te",
    "subcategories.sweet_pastry": "Pasteles dulces",
    "subcategories.candy_shop": "Tienda de dulces",
    "subcategories.dairyFarmShop": "Lácteos",
    "subcategories.fruit_vegetable": "Frutas y verduras",
    "subcategories.eggs": "Huevos",
    "subcategories.bio_organic": "Orgánico y orgánico",
    "subcategories.scooping": "Utilización de tazas",
    "subcategories.indian_grocery": "Indio",
    "subcategories.imported_grocery": "Importar",
    "subcategories.spaWellness": "Spa y bienestar",
    "subcategories.massageCenter": "Masajes",
    "subcategories.gifts": "Regalos",
    "subcategories.cafe": "Cafetería",
    "subcategories.iceCreamParlor": "Heladería",
    "subcategories.bakeryCafe": "Productos horneados",
    "subcategories.sportsCenter": "Deporte",
    "subcategories.kidsPlayArea": "Parque de juegos para niños",
    "subcategories.art": "Arte",
    "subcategories.cinema": "Cine",
    "subcategories.theater": "Teatro",
    "subcategories.escape_room": "Salas de escape",
    "subcategories.climbing": "Trepar",
    "subcategories.musuem": "Museo",
    "subcategories.flowerShop": "Flores",
    "subcategories.giftShop": "Regalos",
    "subcategories.carRepair": "Reparar",
    "subcategories.carWash": "Lavar",
    "subcategories.tireShop": "Llantas",
    "subcategories.furnitureStore": "Muebles",
    "subcategories.gardenCenter": "Jardín",
    "subcategories.clothingStore": "Tienda de ropa",
    "subcategories.electronicsStore": "Electrónica",
    "subcategories.petStore": "Suministros para mascotas",
    "subcategories.zooStore": "Suministros del zoológico",
    "subcategories.bookstore": "Libros y revistas",
    "subcategories.cosmetic": "Productos cosméticos",
    "keywords.oriental": "Oriental",
    "keywords.middleEastern": "Medio Oriente",
    "keywords.arabic": "Árabe",
    "keywords.turkish": "Turco",
    "keywords.moroccan": "Marroquí",
    "keywords.persian": "Persa",
    "keywords.butcher": "Carnicero",
    "keywords.meat": "Carne",
    "keywords.sausage": "Embutido",
    "keywords.meatShop": "Carnicería",
    "keywords.slaughterhouse": "Carnicero",
    "keywords.bakery": "Panadería",
    "keywords.bread": "Pan",
    "keywords.pastry": "Pasteles",
    "keywords.roll": "Panecillos",
    "keywords.cake": "Pastel",
    "keywords.patisserie": "Pastelería",
    "keywords.asian": "Asiático",
    "keywords.chinese": "Chino",
    "keywords.japanese": "Japonés",
    "keywords.thai": "Tailandés",
    "keywords.vietnamese": "Vietnamita",
    "keywords.korean": "Coreano",
    "keywords.indian": "Indio",
    "keywords.farmShop": "Tienda de granja",
    "keywords.farmersMarket": "Mercado de agricultores",
    "keywords.regional": "Regional",
    "keywords.organic": "Orgánico",
    "keywords.fresh": "Fresco",
    "keywords.agricultural": "Agrícola",
    "keywords.halal": "Halal",
    "keywords.halalCertified": "Certificado Halal",
    "keywords.islamic": "Islámico",
    "keywords.muslim": "Musulmán",
    "keywords.doner": "Kebab",
    "keywords.sushi": "Sushi",
    "keywords.fish": "Pez",
    "keywords.seafood": "Mariscos",
    "keywords.salmon": "Salmón",
    "keywords.shrimp": "Camarón",
    "keywords.italian": "Italiano",
    "keywords.pizza": "Pizza",
    "keywords.pasta": "Pastas",
    "keywords.risotto": "Risotto",
    "keywords.tiramisu": "Tiramisú",
    "keywords.antipasti": "Antipastos",
    "keywords.burger": "Ciudadanos",
    "keywords.hamburger": "Hamburguesa",
    "keywords.cheeseburger": "Hamburguesas con queso",
    "keywords.fries": "Friso",
    "keywords.fastFood": "Comida rápida",
    "keywords.menHairdresser": "Peluqueria de hombres",
    "keywords.menSalon": "Salón de hombres",
    "keywords.barber": "Barbero",
    "keywords.shaving": "Afeitar",
    "keywords.haircutForMen": "Corte de pelo para hombres",
    "keywords.kidsHairdresser": "Peluqueria infantil",
    "keywords.kidCut": "Corte infantil",
    "keywords.kidHaircut": "Corte de pelo infantil",
    "keywords.haircutForKids": "Corte de pelo para niños",
    "keywords.kidStyling": "Estilismo infantil",
    "keywords.womenHairdresser": "Peluqueria de mujeres",
    "keywords.womenSalon": "Salón de damas",
    "keywords.haircutForWomen": "Corte de pelo para mujeres",
    "keywords.dyeing": "Tintura",
    "keywords.stylingForWomen": "Estilismo para mujeres",
    "keywords.technology": "Tecnología",
    "keywords.electronics": "Electrónica",
    "keywords.devices": "Dispositivos",
    "keywords.accessories": "Accesorios",
    "units.km": "Kilómetros",
    "open_24_hours": "Abierto las 24 horas",
    "favorites_and_bookings": "Favoritos y reservas",
    "no_favorites": "¡No hay favoritos disponibles!",
    "no_favorites_text": "Aún no has agregado ningún favorito. ¡Explore sus tiendas locales y guárdelas como favoritas para acceder más fácilmente más tarde!",
    "no_coupons": "¡No hay cupones disponibles!",
    "no_coupons_text": "Aún no has activado ningún cupón. ¡Descubre y activa cupones para disfrutar de descuentos!",
    "onboardingTutorial_title_step1": "Descubrir",
    "onboardingTutorial_description_step1": "¡Descubre el mundo del comercio local, ya sean restaurantes, supermercados, peluquerías, salones de belleza, talleres y mucho más!",
    "onboardingTutorial_imageCaption_step1": "Todo está ahí, todo está cerca.",
    "onboardingTutorial_title_step2": "Canjear",
    "onboardingTutorial_description_step2": "Nuestros socios minoristas locales lo invitan a beneficiarse de descuentos directos y obsequios para productos o servicios.",
    "onboardingTutorial_imageCaption_step2": "¿Apoyar a lo local y ahorrar dinero no es posible? ¡Pero!",
    "onboardingTutorial_title_step3": "Ahorrar",
    "onboardingTutorial_description_step3": "Todas las ofertas listas para llevar en tu bolsillo. Simplemente muestra tu reserva al empleado de la tienda. ¡Así apoyamos el comercio local!",
    "onboardingTutorial_imageCaption_step3": "Rápido y fácil para todos.",
    "confirm": "Confirmar",
    "start_now": "Empezar ahora",
    "next": "Más",
    "submit": "Entregar",
    "company_name_required": "El nombre de la empresa es obligatorio.",
    "company_email_required": "Se requiere el correo electrónico de la empresa",
    "invalid_email": "Dirección de correo electrónico no válida",
    "invalid_zipCode": "Código postal no válido",
    "company_address_required": "Se requiere la dirección de la empresa",
    "company_zipCode_required": "Se requiere código postal",
    "company_city_required": "Se requiere ciudad",
    "company_country_required": "Se requiere país",
    "company_phone_required": "Se requiere teléfono de la empresa",
    "invalid_phone": "Número de teléfono no válido",
    "placeholder_company_name": "Introduzca el nombre de la empresa",
    "placeholder_company_email": "Introduzca el correo electrónico de la empresa",
    "placeholder_company_address": "Introduzca la calle y el número de casa",
    "placeholder_company_zipCode": "Introduce el código postal",
    "placeholder_company_city": "Introduce la ciudad",
    "placeholder_company_country": "Introduce el país",
    "placeholder_company_phone": "Introduce el número de teléfono",
    "placeholder_company_pocName": "Introduzca el nombre y apellido de la persona de contacto",
    "placeholder_company_pocRole": "Seleccione el rol de la persona de contacto",
    "no_stores": "No se creó ningún negocio",
    "no_stores_text": "Aún no has agregado una tienda. Haga clic aquí y cree su tienda ahora en un instante para poder ofrecer cupones.",
    "more_stores": "¿Tienes otra sucursal?",
    "more_stores_text": "Haz clic aquí para agregar otra tienda donde también puedas crear cupones.",
    "stores_of_company": "Sucursales de su empresa",
    "vendor_poc_roles.owner": "Dueño",
    "vendor_poc_roles.employee": "Empleado",
    "vendor_poc_roles.else": "Otro",
    "coupon.ON_SITE": "En el sitio",
    "coupon.TO_GO": "Ir",
    "coupon.NINETY_DAYS": "90 dias",
    "coupon.THIRTY_DAYS": "30 dias",
    "coupon.WEEKLY": "Semanalmente",
    "coupon.ONE_YEAR": "Anual",
    "coupon.DAILY": "A diario",
    "coupon.THREE_DAYS": "3 dias",
    "coupon.HUNDRED_EIGHTY_DAYS": "180 días",
    "paywall.price_description_yearly": "Pago anual",
    "paywall.price_description_monthly": "Pagos mensuales",
    "monetization.paywall_title": "Todas las ofertas. Ahorre inmediatamente.",
    "monetization.maybe_later": "Tal vez más tarde",
    "monetization.monthly": "Mensual",
    "monetization.yearly": "Anual",
    "monetization.monthly_marketing.first_benefit": "¡Por lo general, vale la pena por solo 1 o 2 compras!",
    "monetization.monthly_marketing.second_benefit": "¡Se puede cancelar mensualmente!",
    "monetization.monthly_marketing.third_benefit": "Canjee todos los cupones inmediatamente.",
    "monetization.yearly_marketing.first_benefit": "¡Vale la pena por sólo 3 o 4 compras al año!",
    "monetization.yearly_marketing.second_benefit": "¡Paga una vez y disfruta durante un año!",
    "monetization.yearly_marketing.third_benefit": "Canjee todos los cupones inmediatamente.",
    "monetization.price_description_effective_per_month": "Efectivo por mes",
    "monetization.price_description_per_month": "Por mes",
    "monetization.confirmation_title": "¡Compra exitosa!",
    "monetization.confirmation_message": "Gracias por tu compra. Ahora tiene acceso a todas las funciones premium.",
    "validation.setProductName": "Se debe establecer el nombre del producto.",
    "for": "Para",
    "coupon_factory.2for1_title": "Compra 2 y llévate 1 gratis",
    "coupon_factory.2for1_description": "O incluso más. Crear una oferta de cantidad",
    "coupon_factory.win_customers_and_money": "Ganar clientes y ventas.",
    "coupon_factory.example": "Cupón de ejemplo",
    "coupon_factory.create_now": "Crear ahora",
    "coupon_factory.create_coupon_marketing_header": "Consigue nuevos clientes gratis ahora",
    "coupon_factory.create_coupon_marketing_body": "Crea tu cupón en tan solo unos pocos pasos.",
    "coupon_factory.create_coupon_benefit_header_1": "🎯 Nuevos clientes",
    "coupon_factory.create_coupon_benefit_body_1": "Llegue a nuevos clientes directamente a través de su teléfono inteligente",
    "coupon_factory.create_coupon_benefit_header_2": "🚀 Fácil y Rápido",
    "coupon_factory.create_coupon_benefit_body_2": "Cree ofertas más fácilmente que nunca.",
    "coupon_factory.create_coupon_benefit_header_3": "💸 Más ventas",
    "coupon_factory.create_coupon_benefit_body_3": "Incrementa tus ventas con cupones para tus clientes.",
    "coupon_factory.create_coupon_card": "¡Crea un cupón!",
    "coupon_factory.create_coupon_card_info": "Haga clic aquí para crear un cupón en 3 pasos.",
    "coupon_factory.create_coupon": "¡Crea un cupón!",
    "coupon_factory.create_coupon_body": "¡Tres pasos para perfeccionar la publicidad para nuevos clientes!",
    "coupon_factory.condition_for_customer": "Establecer condición para los clientes.",
    "coupon_factory.choose_condition": "Seleccionar condición",
    "coupon_factory.choose_condition_info": "Qué debe hacer el cliente para beneficiarse",
    "coupon_factory.minimum_order_value": "Valor mínimo de pedido",
    "coupon_factory.minimum_order_value_info": "El cliente debe gastar una cierta cantidad.",
    "coupon_factory.product_purchase": "Compra de producto",
    "coupon_factory.product_purchase_info": "El cliente debe comprar ciertos productos.",
    "coupon_factory.direct_rebate": "Descuento directo en producto",
    "coupon_factory.no_conditions": "Sin condiciones",
    "coupon_factory.no_conditions_set": "Sin condiciones establecidas",
    "coupon_factory.go_to_next_step": "Ir al siguiente paso",
    "coupon_factory.input_mov": "Especificar valor mínimo de compra",
    "coupon_factory.count_of_products": "Número de productos",
    "coupon_factory.product_name": "Nombre del producto",
    "coupon_factory.value_of_product_per_piece": "Valor del producto (por pieza)",
    "coupon_factory.order_discount": "Descuento en la compra",
    "coupon_factory.product_discount": "Descuento en un producto",
    "coupon_factory.free_product": "Producto gratuito",
    "coupon_factory.discount": "Descuento",
    "coupon_factory.enter_discount_amount": "Ingrese el monto del descuento",
    "coupon_factory.redeem_location_conditons": "Condiciones de descuento",
    "coupon_factory.redeem_location_info": "¿Dónde pueden sus clientes utilizar el descuento?",
    "coupon_factory.choose_discount": "Descuento",
    "coupon_factory.choose_discount_info": "¿Qué tipo de descuento recibe su cliente?",
    "coupon_factory.carousel_header_one": "Seleccionar cupón",
    "coupon_factory.carousel_body_one": "Selecciona el cupón que deseas crear.",
    "coupon_factory.carousel_header_two": "Establecer condición",
    "coupon_factory.carousel_body_two": "Determinar lo que el cliente debe hacer.",
    "coupon_factory.carousel_header_three": "Establecer beneficios",
    "coupon_factory.carousel_body_three": "Determinar lo que recibirá el cliente.",
    "coupon_factory.product_quantitiy": "Cantidad por cliente",
    "coupon_factory.product_quantitiy_info": "¡El descuento aplica por pieza!",
    "coupon_factory.product_quantitiy_free_product_info": "¿Cuántas piezas recibe GRATIS?",
    "coupon_factory.same_product": "Mismo producto",
    "coupon_factory.same_product_info": "El cliente recibe el mismo producto.",
    "coupon_factory.other_product": "Producto diferente",
    "coupon_factory.other_product_info": "Por favor proporcione el nombre del producto.",
    "coupon_factory.price_per_unit": "Precio por pieza",
    "coupon_factory.error_invalid_input": "Entrada no válida",
    "coupon_factory.error_rebate_higher_as_order_value": "El descuento no puede ser superior al importe de la compra/producto.",
    "coupon_factory.error_rebate_higher_as_product_value": "El descuento no puede ser superior al importe de la compra/producto.",
    "coupon_factory.max_created_coupon_card": "Número máximo de cupones alcanzado",
    "coupon_factory.max_created_coupon_card_info": "Ha alcanzado el máximo actual de sus cupones.",
    "coupon_factory.max_created_stores": "Se alcanzó el máximo de tiendas",
    "coupon_factory.max_created_stores_info": "Ha alcanzado el máximo actual de acuerdos que se pueden crear. Si desea crear otra tienda, contáctenos utilizando el formulario de contacto en la configuración.",
    "details": "Detalles",
    "enter_details": "Proporcionar detalles",
    "store_factory.store_conflict": "Ocurrió un problema con la tienda.",
    "store_factory.store_conflict_info": "La tienda seleccionada en Google ya ha sido registrada en Bazaar. Por favor verifique una vez. Si aún tiene problemas, no dude en contactarnos.",
    "store_factory.wrong_store": "¿Eligió el negocio equivocado?",
    "store_factory.wrong_store_search_again": "¿Quieres reiniciar de nuevo? Tenga en cuenta que su información en Google debe ser correcta.",
    "store_factory.restart": "Reanudar",
    "store_factory.what_customers_see": "Esto es lo que ven tus clientes",
    "store_factory.check_details": "Comprueba si tu información es correcta.",
    "store_factory.store_name": "Nombre de la sucursal",
    "store_factory.store_name_example": "Nombre de tu negocio",
    "store_factory.store_phone": "Número de teléfono de tus clientes",
    "store_factory.store_email": "Correo electrónico para sus clientes",
    "store_factory.choose_category": "Seleccionar categoría",
    "store_factory.choose_category_info": "Elige una categoría principal, hasta 3 subcategorías y sube una imagen de tu tienda.",
    "store_factory.main_category": "Categoría principal",
    "store_factory.sub_categories": "Subcategorías",
    "store_factory.upload_store_img": "Subir imagen de la tienda",
    "store_factory.store_img": "Foto de la tienda",
    "store_factory.max_achieved": "Máximo alcanzado",
    "store_factory.sub_categories_limit": "Puede seleccionar un máximo de 3 subcategorías.",
    "store_factory.categories_and_image_required": "¡Las categorías e imágenes son necesarias!",
    "store_factory.zip_code": "Código Postal",
    "store_factory.zip_code_placeholder": "P.ej. 44801",
    "store_factory.claim_store": "Hacerse cargo del negocio",
    "store_factory.house_number": "Número de casa",
    "store_factory.house_number_placeholder": "P.ej. 33",
    "store_factory.street": "Calle",
    "store_factory.street_placeholder": "P.ej. Calle Bazar",
    "store_factory.city": "Ciudad",
    "store_factory.city_placeholder": "P.ej. Bochum",
    "store_factory.success": "Tienda creada con éxito",
    "store_factory.success_info": "Su tienda se ha creado correctamente y ahora se está verificando.",
    "store_factory.success_verification": "La verificación suele tardar entre 1 y 2 días hábiles. Una vez que se complete la verificación, su tienda se activará y será visible para los clientes.",
    "store_factory.verification_pending": "Pendiente de verificación",
    "store_factory.verification_pending_info": "Actualmente se está llevando a cabo un proceso de verificación. Nos pondremos en contacto si necesitamos más información de usted. Si tiene alguna pregunta, no dude en contactarnos.",
    "store_factory.no_coupons": "Aún no hay cupones",
    "store_factory.no_coupons_info": "Aún no has creado ningún cupón. Haga clic en la tienda para crear un cupón.",
    "to_dashboard": "Al tablero",
    "error_unexpected": "Se produjo un error inesperado. Intentar otra vez. Si el problema persiste, no dude en contactarnos.",
    "check_inputs": "Consultar información",
    "warning": "Peligro",
    "progress_deleted_info": "Su estado provisional se eliminará si regresa aquí. ¿Está seguro?",
    "notification": "Notificación",
    "notifications": "Notificaciones",
    "request_notification_permission": "¿Habilitar notificaciones push?",
    "denied_notification_permission": "Notificaciones push rechazadas",
    "denied": "Denegado",
    "activate_notification_in_settings": "Puede habilitar las notificaciones push en Configuración",
    "notifcationsTitle.message1": "🥳 ¡Es hora de descuentos locales!",
    "notifcationsBody.message1": "¡Ahorra ahora con cupones exclusivos para comercios locales! Ya sea comida, moda u ocio, ¡descubra grandes descuentos y apoye a su comunidad!"
  },
  "it": {
    "!add_row_below": "VVV Aggiungi la riga sotto VVV",
    "so_easy_for_partners": "È così facile per i partner",
    "so_easy_for_partners.register_store": "Registra la tua attività in modo semplice e veloce per raggiungere più clienti.",
    "so_easy_for_partners.create_coupon": "Crea buoni individuali per i tuoi clienti, aumenta le vendite e conquista nuovi clienti – tutto secondo i tuoi desideri.",
    "so_easy_for_partners.manage_store": "Gestisci facilmente il tuo negozio: modifica gli orari di apertura, le immagini e i voucher secondo necessità in qualsiasi momento.",
    "change_language": "Cambia lingua",
    "english_USA": "🇬🇧 Inglese",
    "german_Germany": "🇩🇪 Tedesco",
    "Imprint": "Impronta",
    "Datenschutz": "Politica sulla riservatezza",
    "AGB": "Condizioni generali di contratto",
    "Cookies": "Biscotti",
    "about": "CHI SIAMO",
    "our_magazine": "La nostra rivista",
    "for_companies": "Per le aziende",
    "contact": "Contatto",
    "no_answer_found": "Domanda senza risposta?",
    "jobs": "Lavori",
    "become_partner": "Diventa un partner",
    "shop": "Negozio",
    "experiences": "Esperienze",
    "legal": "LEGALE",
    "social_media": "SOCIAL",
    "frequently_asked_questions": "Domande frequenti",
    "vendor_faq.how_work_question": "Come funziona BASAR per i partner?",
    "vendor_faq.how_work_answer": "Collaboriamo con varie aziende locali e negoziamo offerte uniche come servizi 2 al prezzo di 1 o sconti sui prodotti. L'app Bazaar ti consente di visualizzare, prenotare e riscattare queste offerte in numerosi settori come ristoranti, parrucchieri, officine, negozi di fiori e molti altri.",
    "vendor_faq.participating_businesses_question": "Quali aziende partecipano?",
    "vendor_faq.participating_businesses_answer": "Migliaia di aziende locali stanno attualmente partecipando con una varietà di offerte esclusive. Dalla gastronomia ai servizi ai prodotti, troverai fantastiche offerte in molte categorie.",
    "vendor_faq.app_free_question": "L'app è attualmente gratuita?",
    "vendor_faq.app_free_answer": "Sì, l'app BASAR è attualmente completamente gratuita. I partner possono partecipare e fornire offerte senza alcuna commissione.",
    "vendor_faq.create_store_question": "Come creare un negozio su BASAR?",
    "vendor_faq.create_store_answer": "Creare il tuo negozio su BASAR è facile e veloce. Puoi configurare il tuo profilo aziendale in pochi clic direttamente nell'app.",
    "vendor_faq.manage_coupons_question": "Posso creare e gestire i miei coupon?",
    "vendor_faq.manage_coupons_answer": "Sì, hai il controllo completo sulla creazione e sull'eliminazione dei tuoi coupon. Imposta sconti, offerte e condizioni più adatte alla tua attività. Nessun responsabile delle vendite disturba la tua attività, il tuo marketing.",
    "faq.general.category": "GENERALE",
    "faq.general.questions[0].question": "Qual è il concetto di Bazar?",
    "faq.general.questions[0].answer": "Abbiamo collaborato con varie aziende locali e negoziato voucher introduttivi esclusivi come: B. Offerte 2 per 1 o sconti sui prodotti. Con l'app Bazaar puoi facilmente sfogliare, prenotare e riscattare queste offerte in una varietà di settori come ristoranti, parrucchieri, officine, negozi di fiori e altro ancora.",
    "faq.general.questions[1].question": "Quali aziende partecipano?",
    "faq.general.questions[1].answer": "Migliaia di aziende locali stanno attualmente partecipando con una varietà di offerte esclusive. Dalla ristorazione ai servizi e ai prodotti, troverai ottimi affari in molte categorie.",
    "start": "Inizio",
    "price": "Prezzo",
    "redeem_error_notToday_title": "Coupon non rimborsabile oggi.",
    "redeem_error_notToday_message": "Il coupon può essere riscattato solo nei giorni specificati.",
    "redeemDays.monday": "Mo",
    "redeemDays.tuesday": "Fare",
    "redeemDays.wednesday": "Noi",
    "redeemDays.thursday": "Gi",
    "redeemDays.friday": "SM",
    "redeemDays.saturday": "Sab",
    "redeemDays.sunday": "Su",
    "coupon_factory.your_coupon_active": "Il tuo coupon è ora attivo e presto sarà visibile ai tuoi clienti!",
    "coupon_factory.valid_days_header": "Giorni validi",
    "coupon_factory.valid_days_subHeader": "Giorni in cui i clienti possono riscattare i coupon.",
    "error_messages.something_wrong": "Qualcosa è andato storto: riprova più tardi o contattaci se il problema persiste.",
    "store_factory.store_not_found_input_manual": "Negozio non trovato? Inserimento manuale dell'indirizzo.",
    "sent_review": "Invia recensione.",
    "review_your_visit_at": "Valuta la tua visita @",
    "search": "Ricerca",
    "faq_title": "FAQ (domande frequenti)",
    "faq_title_reverse": "Domande frequenti (FAQ)",
    "vendor_faq_question_concept": "Come funziona BASAR?",
    "vendor_faq_answer_concept": "Il concetto di BASAR è semplice ed efficace. In qualità di lavoratore autonomo con un'attività fisica, puoi utilizzare l'app BASAR Business per presentare il tuo negozio e creare coupon. Gli utenti possono trovare questi coupon tramite l'app utente BASAR e riscattarli direttamente nel tuo negozio. In questo modo rafforziamo le imprese locali nella competizione con le grandi catene di distribuzione organizzata.",
    "vendor_faq_question_costs": "Ci sono dei costi per me?",
    "vendor_faq_answer_costs": "No, BASAR è completamente gratuito per i rivenditori. Gli utenti si iscrivono per avere accesso a tutti i coupon. Questo ci permette di coprire i costi della piattaforma e di consentire alle imprese locali di essere competitive, anche contro le grandi catene che investono in costosi programmi di marketing e fidelizzazione della clientela.",
    "vendor_faq_question_minium_requirements": "Quali requisiti devo soddisfare?",
    "vendor_faq_answer_minium_requirements": "Hai bisogno di un negozio fisico in cui servi i clienti in loco, ad es. B. un ristorante, un parrucchiere, un laboratorio o simili. Inoltre, tu o i tuoi dipendenti dovreste essere in grado di concedere facilmente gli sconti promessi alla cassa.",
    "vendor_faq_question_interface_cashier": "Devo integrare il mio registratore di cassa?",
    "vendor_faq_answer_interface_cashier": "No, BASAR funziona molto facilmente con il classico principio dei quattro occhi. Il cliente ti mostra il coupon utilizzato sul suo smartphone e tu gli concedi lo sconto. Non sono necessarie integrazioni di sistemi POS costose o complicate. L'unica cosa importante è che tu o i tuoi dipendenti possiate applicare correttamente gli sconti.",
    "vendor_faq_question_shop_creation": "Come creo il mio negozio?",
    "vendor_faq_answer_shop_creation": "Puoi creare il tuo negozio nella scheda \"Negozio\". Cerca la tua attività nel nostro database o creala manualmente. Aggiungi le informazioni di contatto, gli orari di apertura, una foto e la categoria appropriata. Controlleremo le informazioni e ti contatteremo in caso di domande. La verifica richiede solitamente un giorno lavorativo, dopodiché puoi creare immediatamente i coupon.",
    "vendor_faq_question_coupons": "Come posso creare coupon?",
    "vendor_faq_answer_coupons": "Dopo aver creato il tuo negozio, puoi avviare la fabbrica di coupon nella scheda \"Coupon\". Lì troverai modelli già pronti a cui potrai aggiungere rapidamente e facilmente le tue informazioni. Dopo l'anteprima, puoi creare il coupon e sarà attivo in breve tempo. Requisito: il tuo negozio deve essere verificato.",
    "vendor_faq_question_storeinfos_update": "Come posso aggiornare le informazioni sulla mia attività?",
    "vendor_faq_answer_storeinfos_update": "Vai al tuo negozio e fai clic sulla scheda \"Informazioni\". Lì puoi aggiornare le informazioni di contatto, gli orari di apertura e le immagini utilizzando i pulsanti di modifica corrispondenti.",
    "vendor_faq_question_abuse": "In che modo BASAR impedisce il riscatto multiplo di un coupon?",
    "vendor_faq_answer_abuse": "Ogni utente si registra con il proprio numero di telefono ed è quindi chiaramente identificabile. Quando crei un coupon, puoi specificare la frequenza con cui un coupon può essere riscattato, ad es. B. solo ogni 90 giorni. In questo modo l'utilizzo rimane equo e controllato.",
    "vendor_faq_question_help_creation": "Ci aiuterai a creare il negozio o i coupon?",
    "vendor_faq_answer_help_creation": "Ovviamente! Saremo felici di aiutarti se hai bisogno di assistenza per la configurazione del tuo negozio o di coupon. Puoi contattarci in qualsiasi momento tramite WhatsApp. Puoi trovare il numero nelle impostazioni sotto \"Feedback e supporto\".",
    "vendor_faq_question_how_to_contact": "Come posso raggiungerti?",
    "vendor_faq_answer_how_to_contact": "Puoi contattarci via e-mail all'indirizzo basar@basar-digital.com o direttamente tramite WhatsApp. Puoi trovare il numero WhatsApp nelle impostazioni sotto “Feedback e supporto”.",
    "create_store_and_coupons": "Crea il tuo negozio e coupon",
    "click_here_for_help": "Clicca qui se hai bisogno di maggiori informazioni.",
    "vendorOnboardingTutorial_title_step1": "Cerca e crea il tuo negozio.",
    "vendorOnboardingTutorial_description_step1": "Trova la tua attività utilizzando la funzione di ricerca o creala semplicemente tu stesso. Aggiungi tutte le informazioni importanti come contatti, orari di apertura, immagini e categorie.",
    "vendorOnboardingTutorial_imageCaption_step1": "Il tuo negozio, il tuo inizio: con pochi clic sei visibile ai tuoi clienti, indipendentemente dal fatto che tu stia aggiungendo un negozio esistente o creandone uno nuovo.",
    "vendorOnboardingTutorial_title_step2": "Crea coupon irresistibili.",
    "vendorOnboardingTutorial_description_step2": "Utilizza la nostra fabbrica di coupon facile da usare per creare offerte personalizzate. Delizia i clienti con sconti esclusivi e dai nuovo slancio alla tua attività.",
    "vendorOnboardingTutorial_imageCaption_step2": "Con la fabbrica di coupon puoi stupire i clienti: crea offerte personalizzate in pochi secondi e rafforza la fidelizzazione dei tuoi clienti.",
    "vendorOnboardingTutorial_title_step3": "Attendo con ansia i clienti entusiasti.",
    "vendorOnboardingTutorial_description_step3": "Con le tue offerte attiri nuovi clienti e sorprendi i tuoi clienti abituali. I clienti possono trovare il tuo negozio e i tuoi coupon direttamente nell'app utente BASAR. Riscatta i coupon sul posto e puoi applicare lo sconto direttamente alla cassa.",
    "vendorOnboardingTutorial_imageCaption_step3": "La vostra attività, la loro gioia: più clienti, più vendite – con l'app utente BASAR il vostro negozio sarà la prima scelta.",
    "category": "Categoria",
    "loadings_favorites": "Caricamento dei preferiti",
    "feature_so_great": "Questa funzionalità è così eccezionale che non è gratuita! Iscriviti e inizia.",
    "login_and_start": "Accedi e inizia",
    "oops_something_missing": "Ops, manca ancora qualcosa!",
    "coupon_factory": "Fabbrica di buoni sconto",
    "coupon_factory.infoHeader_name_of_product": "Nome del prodotto",
    "coupon_factory.infoBody_name_of_product": "Inserisci il nome del prodotto o del servizio che desideri che il cliente acquisti.",
    "coupon_factory.infoHeader_value_of_product": "Valore del prodotto",
    "coupon_factory. infoBody_value_of_product": "Quanto costa il prodotto nel tuo negozio?",
    "coupon_factory.infoHeader_count_of_product": "Numero di prodotti",
    "coupon_factory.infoBody_count_of_product": "Quanti di questo prodotto dovrebbe comprare.",
    "coupon_factory.infoHeader_minimum_order_volume": "Valore minimo dell'ordine/valore minimo di acquisto",
    "coupon_factory.infoBody_minimum_order_volume": "Quanto dovrebbe spendere almeno il cliente per trarne vantaggio?",
    "coupon_factory.infoHeader_name_of_benefit_product": "Nome del prodotto vantaggioso",
    "coupon_factory.infoBody_name_of_benefit_product": "Inserisci qui il nome del prodotto o del servizio che il cliente riceverà.",
    "coupon_factory.infoHeader_value_of_benefit_product": "Valore del prodotto vantaggioso",
    "coupon_factory.infoBody_value_of_benefit_product": "Quanto costa normalmente il prodotto senza prezzo di offerta?",
    "coupon_factory.infoHeader_discount_amount": "Specificare l'importo dello sconto",
    "coupon_factory.infoBody_discount_amount": "Qual è lo sconto per pezzo - in € o %.",
    "coupon_factory.infoHeader_order_discount_amount": "Specificare l'importo dello sconto",
    "coupon_factory.infoBody_order_discount_amount": "A quanto ammonta lo sconto sull'acquisto o sull'ordine - in € o %.",
    "delete_coupon": "Elimina buono",
    "generate": "Generare",
    "delete_coupon_text": "Sei sicuro di voler eliminare questo coupon? Questa azione non può essere annullata.",
    "delete_coupon_info": "Attenzione: Il tuo coupon è ancora attivo fino a fine giornata e non sarà più visibile agli utenti dal giorno successivo.",
    "validation_all_inputs_required": "Tutti i campi devono essere compilati correttamente.",
    "store_factory.contact_and_opentimes": "Contatti e orari di apertura",
    "store_factory.always_editable_later": "Puoi sempre modificarlo in seguito.",
    "choose_category_and_image": "Seleziona immagine e categoria.",
    "store_factory.main_categories_limit": "Seleziona una categoria.",
    "searchdata_openstreetmap": "Dati di ricerca © OpenStreetMap",
    "success.opentimes_updated": "Orari di apertura aggiornati con successo!",
    "success.contactdetails_updated": "Dettagli di contatto aggiornati con successo!",
    "editcontact": "Modifica i dettagli di contatto",
    "from": "Da",
    "until": "Fino a",
    "weekday": "Giorno",
    "open_questionmark": "Aprire?",
    "coupon_factory.contact_if_issue": "Si prega di controllare tutte le informazioni. Se hai ancora bisogno di aiuto, non esitare a contattarci qui",
    "respawn_time": "Tempo di rigenerazione",
    "coupon_factory.coupon_in_three_steps": "Crea un coupon in 3 passaggi.",
    "store_factory.your_store_on_map": "La tua attività sulla mappa",
    "coupon_factory.infoHeader_need_help": "Hai bisogno di supporto?",
    "coupon_factory.infoBody_need_help_whatsapp": "Contattaci direttamente qui tramite Whatsapp.",
    "coupon_factory.check_preview": "Visualizza l'anteprima del tuo coupon.",
    "coupon_factory.preview": "Anteprima",
    "coupon_factory.check_details": "Tutte le informazioni sono corrette? Fai clic su Genera per rendere attivo il tuo coupon!",
    "coupon_factory.respawn_time_description": "Quando un cliente può riattivare il coupon dopo averlo riscattato. Se non desideri più offrire un'offerta, puoi eliminarla tu stesso.",
    "coupon_factory.respawn_time_short_description": "Quanto tempo deve attendere il cliente prima di poter attivare nuovamente il coupon.",
    "coupon_factory.thirty_days_recommendation": "Il cliente potrà riscattare nuovamente il coupon dopo 30 giorni. Ideale per offerte regolarmente disponibili e interessanti per i clienti abituali.",
    "coupon_factory.ninety_days_recommendation": "Il coupon potrà essere riutilizzato dopo 90 giorni. Questa opzione funziona bene per offerte più lunghe rivolte a nuovi clienti.",
    "coupon_factory.weekly_recommendation": "Il coupon può essere riscattato nuovamente una volta alla settimana. Perfetto per promozioni a breve termine che devono essere rinnovate regolarmente.",
    "coupon_factory.thirty_days_recommendation_short": "Ideale per le offerte regolarmente disponibili.",
    "coupon_factory.ninety_days_recommendation_short": "Ideale per rare offerte per nuovi clienti.",
    "coupon_factory.weekly_recommendation_short": "Ideale per offerte promozionali brevi.",
    "coupon_factory.mainly_for_restaurants": "Di solito si applica solo alla gastronomia.",
    "coupon_factory.default_set": "Impostato per impostazione predefinita.",
    "coupon_factory.redeem_location_onsite_info": "Valido se il cliente acquista in negozio.",
    "coupon_factory.redeem_location_togo_info": "Valido se il cliente acquista qualcosa da portare via, ad es. un kebab da asporto.",
    "coupon_factory.choose_coupon_type": "Seleziona i tipi di coupon",
    "coupon_factory.choose_coupon_type_details": "Seleziona il tipo di coupon che desideri creare per il tuo negozio.",
    "coupon_factory.example_header_one": "Rimborso di 10€",
    "coupon_factory.example_body_one": "Ottieni uno sconto di 10€ con una spesa minima di 50€",
    "coupon_factory.example_headertitle_one": "Rimborso",
    "coupon_factory.example_subheader_one": "I tuoi nuovi clienti riceveranno uno sconto diretto sul loro acquisto/ordine.",
    "coupon_factory.example_header_two": "1x bevanda in REGALO",
    "coupon_factory.example_body_two": "Ricevi 1 cioccolata gratis senza alcun valore minimo dell'ordine",
    "coupon_factory.example_headertitle_two": "REGALO",
    "coupon_factory.example_subheader_two": "I tuoi nuovi clienti riceveranno un regalo con il loro acquisto.",
    "coupon_factory.example_header_three": "2 per 1 - patatine fritte",
    "coupon_factory.example_body_three": "Quando acquisti 1 patatine fritte, riceverai 1 patatine fritte in omaggio. Si applica al prodotto dello stesso prezzo o più economico.",
    "coupon_factory.example_headertitle_three": "2 per 1",
    "coupon_factory.example_subheader_three": "I tuoi nuovi clienti ricevono 2 prodotti al prezzo di uno.",
    "coupon_factory.example_header_four": "Sconto diretto del 50% sulle giacche",
    "coupon_factory.example_body_four": "Riceverai 1 mazzo di fiori con uno sconto del 50% ciascuno senza valore minimo dell'ordine.",
    "coupon_factory.example_headertitle_four": "VENDITA",
    "coupon_factory.example_subheader_four": "I tuoi clienti ricevono uno sconto diretto su un prodotto specifico.",
    "coupon_factory.example_header_individual": "Individualmente",
    "coupon_factory.example_body_individual": "Trattativa individuale",
    "coupon_factory.example_headertitle_individual": "Individualmente",
    "coupon_factory.example_subheader_individual": "Crea un coupon completamente personalizzato per i tuoi clienti.",
    "welcome": "Benvenuto",
    "languages.de": "🇩🇪 Tedesco",
    "languages.en": "🇺🇸Inglese",
    "languages.tr": "🇹🇷 Turco",
    "languages.ar": "🇸🇦 Arabo",
    "languages.fr": "🇫🇷 Francese",
    "languages.es": "🇪🇸Spagnolo",
    "languages.it": "🇮🇹Italiano",
    "languages.pl": "🇵🇱Polacco",
    "languages.ru": "🇷🇺Russo",
    "popular_categories": "Categorie popolari",
    "no_matching_categories_found": "Nessuna categoria corrispondente trovata",
    "hello": "Ciao dalla Germania",
    "with_coupons": "Con coupon",
    "cities_with_digital_basar": "Città con un bazar digitale",
    "use_your_location": "Usa la tua posizione",
    "no_matching_cities_found": "Nessuna città corrispondente trovata",
    "discover": "Scoprire",
    "favorites": "Preferiti",
    "profile": "Profili",
    "delete": "Eliminare",
    "openingTimes": "Orari di apertura",
    "editOpeningTimes": "Modifica gli orari di apertura",
    "days.monday": "Lunedi",
    "days.tuesday": "Mercoledì",
    "days.wednesday": "Mercoledì",
    "days.thursday": "Giovedì",
    "days.friday": "Venerdì",
    "days.saturday": "Sabato",
    "days.sunday": "Domenica",
    "command_searchForYourBusiness": "Cerca la tua attività",
    "placeholder_find_store_google": "Trova la tua attività su Google",
    "permission_to_camera_roll": "È richiesto l'accesso alla libreria fotografica!",
    "businessMustBeOnGoogleMaps": "La tua attività deve essere rilevabile su Google Maps. In caso di problemi, non esitate a contattarci qui.",
    "closed": "Chiuso",
    "location": "Posizione",
    "coupons": "Buoni",
    "activated_coupons": "Coupon attivati",
    "redeemed_coupons": "Coupon riscattati",
    "activate": "Attivare",
    "redeem_now": "Riscatta ora",
    "coupon_redeemed_message": "Coupon riscattato: continua così! Ecco come sosteniamo il commercio locale.",
    "coupon_redeemed_message_title": "Coupon riscattato!",
    "coupon_redeemed_message_bodxy": "Continua così! Ecco come sosteniamo il commercio locale.",
    "share_message": "Ehi, sono al BASAR. Scarica l'app utilizzando questo collegamento.",
    "share_success": "Condividi con successo!",
    "share_dismissed": "Condivisione annullata",
    "share_failed": "Condivisione non riuscita:",
    "report_problem": "Segnala un problema",
    "deactivate_coupon": "Disattiva il buono",
    "info": "Informazioni",
    "more": "Di più",
    "Information about this Coupons": "Informazioni su questo coupon",
    "command_mention_basar_for_redeem": "Prima di pagare, menziona che possiedi un buono BASAR e riscattalo davanti a un dipendente. È possibile riscattare un solo coupon per visita.",
    "Redeem Location": "Luogo di riscatto",
    "redeem_location_info": "Sia che l'offerta sia sul posto o da asporto",
    "Reactivation time": "Tempo di riattivazione",
    "reactivation_info": "Quando l'offerta sarà di nuovo attiva per te dopo averla riscattata.",
    "reviews": "Recensioni",
    "review": "Recensioni",
    "show_all_reviews": "Visualizza tutte le recensioni",
    "show_all": "Visualizza tutto",
    "REVIEW_SENT": "Recensione inviata.",
    "REVIEW_THANKS": "Grazie per la tua recensione!",
    "REVIEW_WRITE": "Scrivi la tua recensione...",
    "loading": "Caricamento",
    "noReviewsAvailable": "Nessun commento ancora",
    "noStoreInformationAvailable": "Nessuna informazione ancora",
    "advetorials.vendor.become_vendor_title": "Hai un negozio locale in loco?",
    "advetorials.vendor.become_vendor_body": "Allora diventa subito un rivenditore sulla nostra app. Abbiamo molti utenti che vorrebbero supportare la tua attività locale.",
    "advetorials.vendor.download_vendor_app": "Scarica l'app del rivenditore dall'App Store.",
    "become_Member": "Diventa un membro",
    "enter_discount": "Inserisci il codice sconto",
    "translated_by_ai": "Le traduzioni potrebbero essere state create utilizzando l'intelligenza artificiale e quindi non sono sempre perfette.",
    "closeButton": "Vicino",
    "edit_profile": "Modifica profilo",
    "validation_invalid_input": "Immissione non valida",
    "validation_required_input": "Il campo deve essere compilato",
    "success": "Confermato",
    "error": "Errore",
    "profile_update_success": "Profilo aggiornato con successo!",
    "profile_image_update_success": "Immagine del profilo aggiornata correttamente",
    "profile_image_update_error": "Aggiornamento dell'immagine del profilo non riuscito",
    "tutorial": "Esercitazione",
    "settings": "Impostazioni",
    "feedbackAndSupport": "Feedback e supporto",
    "changeToVendorMode": "Passa alla modalità rivenditore",
    "changeToUserMode": "Passa alla modalità cliente",
    "registerAsPartner": "Registrati come partner",
    "logout": "Esci",
    "first_name": "Nome di battesimo",
    "last_name": "Cognome",
    "email": "E-mail",
    "zipCode": "Cap",
    "edit": "Modificare",
    "delete_account": "Elimina profili",
    "delete_account_message": "Sei sicuro di voler eliminare il tuo profilo? Tutti i tuoi vantaggi e le tue impostazioni andranno irrimediabilmente persi.",
    "delete_account_success": "Profilo eliminato con successo",
    "delete_account_success_message": "Il tuo profilo è stato eliminato con successo. 👋 Speriamo di rivederci presto",
    "save": "Salva",
    "back": "Cottura al forno",
    "continue": "Continuare",
    "later": "Dopo",
    "terms_and_conditions": "Condizioni generali di contratto",
    "agree_to_terms": "Accetto i <terms>Termini e condizioni generali</terms>.",
    "phonenumber": "Numero di telefono",
    "enter_phonenumber": "Inserisci il numero di telefono",
    "login_no_password_info": "Accesso facile tramite codice SMS. Quindi non è necessario ricordare una password.",
    "enter_code": "Inserisci il codice",
    "sent_to": "Inviato a",
    "search_country_code": "Cerca per paese o prefisso",
    "command_agree": "Per favore accetta",
    "command_agree_to_terms": "È necessario accettare i termini per continuare.",
    "otp": "Password una tantum",
    "enter_otp": "Inserisci la password monouso",
    "verify_otp": "Conferma la password monouso",
    "otp_expired": "La password monouso è scaduta",
    "otp_incorrect": "Password monouso errata",
    "otp_verification_error": "È stata inserita una password monouso errata o scaduta.",
    "command_create_profile_to_start": "Crea il tuo profilo per iniziare!",
    "command_create_company_to_start": "Registra la tua azienda per iniziare.",
    "info_create_shop_later": "Puoi fornire informazioni sul tuo negozio nel passaggio successivo.",
    "your_concern": "La tua richiesta",
    "describe_your_concern": "Raccontaci la tua richiesta.",
    "contact_you_asap": "Ti contatteremo il più rapidamente possibile.",
    "contact_via_whatsapp": "Contattaci tramite Whatsapp",
    "change_image": "Cambia immagine",
    "edit_store_profile": "Modifica profilo aziendale",
    "error_loading_store": "Errore durante il caricamento dei dati aziendali",
    "error_messages.no_stores.no_stores_nearby_found_title": "Nessun negozio trovato vicino a te",
    "error_messages.no_stores.no_stores_nearby_found_body": "A quanto pare non siamo ancora veloci. Ma ci arriveremo, non preoccuparti. Cambia la città utilizzando la ricerca oppure scorrila utilizzando la mappa.",
    "error_messages.conflict.store_exists_title": "L'attività a questo indirizzo è già stata registrata.",
    "error_messages.conflict.store_exists_body": "Non è possibile creare un negozio già registrato. Se ritieni che si tratti di un errore, contattaci qui.",
    "funny_error_message": "Spiacenti, qualcosa è andato storto.",
    "page_not_found": "Impossibile caricare la pagina.",
    "page_not_found_connect_to_wifi": "Assicurati di essere connesso a Internet.",
    "page_not_found_help_message": "Se il problema persiste, contattaci",
    "cancel": "Cancellare",
    "partner_login": "Accesso partner",
    "partner_register": "Registrazione del partner",
    "command_no_account_register": "Nessun conto? Registrati qui.",
    "command_have_account_login": "Hai un conto? Accedi qui.",
    "login": "Login",
    "register": "Registrazione",
    "please_enter_email": "Si prega di fornire un'e-mail",
    "email_sent": "E-mail inviata",
    "password_reset_email_sent": "Email inviata per reimpostare la password.",
    "password_reset_failed": "Reimpostazione della password non riuscita.",
    "forgot_password": "Ha dimenticato la password",
    "password_reset_rate_limit": "Per motivi di sicurezza, puoi effettuare questa richiesta solo una volta ogni 60 secondi. Attendi e riprova.",
    "back_to_login": "Torna al login",
    "company_profile": "Profilo Aziendale",
    "company_details": "Dettagli dell'azienda",
    "company_email": "E-mail aziendale",
    "company_name": "Nome dell'azienda",
    "company_address": "Indirizzo dell'azienda",
    "company_zipCode": "Codice Postale",
    "company_city": "Città",
    "company_country": "Paese",
    "company_phone": "Numero di telefono dell'azienda",
    "company_pocName": "Nome, cognome (persona da contattare)",
    "company_pocRole": "Seleziona ruolo (persona da contattare)",
    "store_image": "Immagine del negozio",
    "vendor_delete_account": "Sei sicuro di voler eliminare il tuo profilo?",
    "vendor_delete_account_body": "Attualmente, il tuo profilo può essere eliminato solo contattandoci tramite WhatsApp o via e-mail a basar@basar-digital.com",
    "select_a_category": "Seleziona una categoria",
    "categories.grocery": "Generi alimentari",
    "categories.restaurants": "Ristorante",
    "categories.hairSalon": "Barbiere",
    "categories.electronics": "Elettronica",
    "categories.beauty_wellness": "Bellezza e benessere",
    "categories.other": "Altro",
    "categories.non_food_retail": "Vedere al dettaglio",
    "categories.home_and_garden": "Casa e giardino",
    "categories.automotive": "Mobilità",
    "categories.flowers_and_gifts": "Fiori e regali",
    "categories.activities": "Attività",
    "categories.cafe_and_dessert": "Caffetteria e dessert",
    "categories.farm_shop": "Negozio agricolo",
    "subcategories.convenienceStore": "Minimercato",
    "subcategories.eastEuropean": "Est europeo",
    "subcategories.beverageMarket": "Mercato delle bevande",
    "subcategories.orientalGrocery": "Orientale",
    "subcategories.indianGrocery": "Cibi indiani",
    "subcategories.importedGrocery": "Importare",
    "subcategories.bioOrganic": "Biologico e biologico",
    "subcategories.africanGrocery": "Africano",
    "subcategories.turkishSupermarket": "Turco",
    "subcategories.latinAmericanSupermarket": "Latinoamericano",
    "subcategories.middleEasternSupermarket": "Medio Oriente",
    "subcategories.russianSupermarket": "Russo",
    "subcategories.polishSupermarket": "Polacco",
    "subcategories.mediterraneanSupermarket": "Mediterraneo",
    "subcategories.veganVegetarianSupermarket": "Vegano/vegetariano",
    "subcategories.halalMarket": "Halal",
    "subcategories.kosherSupermarket": "Kosher",
    "subcategories.southAsianSupermarket": "Sud asiatico",
    "subcategories.germanCuisine": "Tedesco",
    "subcategories.frenchRestaurant": "Francese",
    "subcategories.spanishTapasBar": "Spagnolo",
    "subcategories.greekTavern": "Greco",
    "subcategories.mexicanRestaurant": "Messicano",
    "subcategories.indianRestaurant": "Indiano",
    "subcategories.sushiBar": "Barra dei sushi",
    "subcategories.steakhouse": "Bisteccheria",
    "subcategories.vegetarianVeganRestaurant": "Vegano/vegetariano",
    "subcategories.foodTruck": "Camion di cibo",
    "subcategories.fusionCuisine": "Internazionale",
    "subcategories.medievalTavern": "Medievale",
    "subcategories.patisserie": "Pasticceria",
    "subcategories.lowCarb": "Basso contenuto di carboidrati",
    "subcategories.brideAndEvent": "Sposa ed evento",
    "subcategories.afroBarber": "Barbiere Afro",
    "subcategories.hairSpecialist": "Specialista dei capelli",
    "subcategories.permanentHairRemoval": "Depilazione permanente",
    "subcategories.hairReplacement": "Depilazione",
    "subcategories.barberServices": "Servizi per capelli",
    "subcategories.veganCafe": "Vegano",
    "subcategories.outdoorActivities": "Attività all'aperto",
    "subcategories.eventDecorators": "Decorazione dell'evento",
    "subcategories.indoorPlants": "Piante da interno",
    "subcategories.outdoorPlants": "Piante da esterno",
    "subcategories.ballonAndParty": "Palloncini e articoli per feste",
    "subcategories.handmade": "Fatto a mano",
    "subcategories.candleAndFragrances": "Candele e fragranze",
    "subcategories.autoDetailing": "Preparazione del veicolo",
    "subcategories.autoBodyAndPaint": "Carrozzeria e vernice",
    "subcategories.carRental": "Noleggio auto",
    "subcategories.autoGlass": "Vetro per auto",
    "subcategories.inspection": "Ispezione",
    "subcategories.oilChange": "Cambio olio",
    "subcategories.motorcycle": "Motociclo",
    "subcategories.autoAccessories": "Accessori per auto",
    "subcategories.autoTuning": "Laboratorio di accordatura",
    "subcategories.homeDecor": "Decorazione della casa",
    "subcategories.homeImprovement": "Miglioramento della casa",
    "subcategories.lightingStore": "Lampade e luci",
    "subcategories.applianceStore": "Elettrodomestici",
    "subcategories.kitchenAndBath": "Cucina e sanitari",
    "subcategories.securityAndSmartHome": "Sicurezza e casa intelligente",
    "subcategories.diy": "Fai da te",
    "subcategories.pharmacy": "Farmacia",
    "subcategories.medicalSupply": "Negozio di medicinali",
    "subcategories.jewlery": "Gioielliere",
    "subcategories.toyStore": "Negozio di giocattoli",
    "subcategories.musicStore": "Musica e strumenti",
    "subcategories.secondhandStores": "Seconda mano",
    "subcategories.travelAgency": "Agenzia di viaggi",
    "subcategories.photoStudio": "Studio fotografico",
    "subcategories.tailorShop": "Sartoria",
    "subcategories.cleaningService": "Servizio di pulizia",
    "subcategories.shoeRepair": "Riparazione di scarpe",
    "subcategories.keyCopyService": "Servizio fabbro",
    "subcategories.laundryService": "Servizio lavanderia",
    "subcategories.locksmith": "Fabbro",
    "subcategories.mobileRepair": "Riparazione cellulari",
    "subcategories.computerRepair": "Riparazione computer",
    "subcategories.packagingService": "Servizio di imballaggio",
    "subcategories.printShop": "Negozio di stampa",
    "subcategories.eventPlanning": "Pianificazione di eventi",
    "subcategories.regionalProducts": "Prodotti regionali",
    "subcategories.butcherGrocery": "Macellaio",
    "subcategories.bakeryGrocery": "Prodotti da forno",
    "subcategories.asianGrocery": "Asiatico",
    "subcategories.eastEuropaen": "Est europeo",
    "subcategories.farmGrocery": "Negozio agricolo",
    "subcategories.halalRestaurant": "Halal",
    "subcategories.asianRestaurants": "Asiatico",
    "subcategories.seafoodRestaurant": "Frutti di mare",
    "subcategories.italianRestaurant": "Italiano",
    "subcategories.burgerRestaurant": "Hamburger",
    "subcategories.menHairSalon": "Signori",
    "subcategories.kidsHairSalon": "Bambini",
    "subcategories.womenHairSalon": "Donne",
    "subcategories.gadgets": "Gadget",
    "subcategories.vegetarianRestaurant": "Vegetariano",
    "subcategories.mediterraneanRestaurant": "Mediterraneo",
    "subcategories.fishMarket": "Mercato del pesce",
    "subcategories.imker": "Apicoltore",
    "subcategories.makeup": "Trucco",
    "subcategories.barbeque": "Barbecue",
    "subcategories.bubble_tea": "Tè alle bolle",
    "subcategories.african": "Africano",
    "subcategories.hair_treatments": "Trattamenti per capelli",
    "subcategories.hair_removal": "Depilazione",
    "subcategories.teeth_whitening": "Sbiancamento dei denti",
    "subcategories.acupuncture": "Agopuntura",
    "subcategories.nail_salon": "Salone per unghie",
    "subcategories.therapies": "Terapie",
    "subcategories.meat": "Carne",
    "subcategories.buffet": "Buffet",
    "subcategories.fried": "Cibo fritto",
    "subcategories.fast_food": "Fast food",
    "subcategories.breakfast_brunch": "Colazione | brunch",
    "subcategories.bistro_imbiss": "Bistrot | spuntino",
    "subcategories.manicure_pedicure": "Manicure e pedicure",
    "subcategories.facial_treatments": "Trattamenti per il viso",
    "subcategories.cosmetic_supply": "Prodotti cosmetici",
    "subcategories.beverage_market": "Mercato delle bevande",
    "subcategories.dairyGrocery": "Latticini",
    "subcategories.vegetableGrocery": "Verdure",
    "subcategories.dessertBar": "Dessert",
    "subcategories.crepes": "Crepes",
    "subcategories.waffles": "Cialde",
    "subcategories.juices": "Succhi",
    "subcategories.tea_house": "Casa da tè",
    "subcategories.sweet_pastry": "Dolci",
    "subcategories.candy_shop": "Negozio di caramelle",
    "subcategories.dairyFarmShop": "Latticini",
    "subcategories.fruit_vegetable": "Frutta e verdura",
    "subcategories.eggs": "Uova",
    "subcategories.bio_organic": "Biologico e biologico",
    "subcategories.scooping": "Coppettazione",
    "subcategories.indian_grocery": "Indiano",
    "subcategories.imported_grocery": "Importare",
    "subcategories.spaWellness": "Terme e benessere",
    "subcategories.massageCenter": "Massaggi",
    "subcategories.gifts": "Regali",
    "subcategories.cafe": "Caffè",
    "subcategories.iceCreamParlor": "Gelateria",
    "subcategories.bakeryCafe": "Prodotti da forno",
    "subcategories.sportsCenter": "Sport",
    "subcategories.kidsPlayArea": "Parco giochi per bambini",
    "subcategories.art": "Arte",
    "subcategories.cinema": "Cinema",
    "subcategories.theater": "Teatro",
    "subcategories.escape_room": "Camere di fuga",
    "subcategories.climbing": "Scalata",
    "subcategories.musuem": "Museo",
    "subcategories.flowerShop": "Fiori",
    "subcategories.giftShop": "Regali",
    "subcategories.carRepair": "Riparazione",
    "subcategories.carWash": "Lavare",
    "subcategories.tireShop": "Pneumatici",
    "subcategories.furnitureStore": "Mobilia",
    "subcategories.gardenCenter": "Giardino",
    "subcategories.clothingStore": "Negozio di abbigliamento",
    "subcategories.electronicsStore": "Elettronica",
    "subcategories.petStore": "Forniture per animali domestici",
    "subcategories.zooStore": "Forniture per lo zoo",
    "subcategories.bookstore": "Libri e riviste",
    "subcategories.cosmetic": "Cosmetici",
    "keywords.oriental": "Orientale",
    "keywords.middleEastern": "Medio Orientale",
    "keywords.arabic": "Arabo",
    "keywords.turkish": "Turco",
    "keywords.moroccan": "Marocchino",
    "keywords.persian": "Persiano",
    "keywords.butcher": "Macellaio",
    "keywords.meat": "Carne",
    "keywords.sausage": "Salsiccia",
    "keywords.meatShop": "Macelleria",
    "keywords.slaughterhouse": "Macellaio",
    "keywords.bakery": "Forno",
    "keywords.bread": "Pane",
    "keywords.pastry": "Dolci",
    "keywords.roll": "Panini",
    "keywords.cake": "Torta",
    "keywords.patisserie": "Pasticceria",
    "keywords.asian": "Asiatico",
    "keywords.chinese": "Cinese",
    "keywords.japanese": "Giapponese",
    "keywords.thai": "Tailandese",
    "keywords.vietnamese": "Vietnamita",
    "keywords.korean": "Coreano",
    "keywords.indian": "Indiano",
    "keywords.farmShop": "Negozio agricolo",
    "keywords.farmersMarket": "Mercato degli agricoltori",
    "keywords.regional": "Regionale",
    "keywords.organic": "Organico",
    "keywords.fresh": "Fresco",
    "keywords.agricultural": "Agricolo",
    "keywords.halal": "Halal",
    "keywords.halalCertified": "Certificato Halal",
    "keywords.islamic": "Islamico",
    "keywords.muslim": "Musulmano",
    "keywords.doner": "Döner Kebab",
    "keywords.sushi": "Sushi",
    "keywords.fish": "Pescare",
    "keywords.seafood": "Frutti di mare",
    "keywords.salmon": "Salmone",
    "keywords.shrimp": "Gamberetto",
    "keywords.italian": "Italiano",
    "keywords.pizza": "Pizza",
    "keywords.pasta": "Pasta",
    "keywords.risotto": "Risotto",
    "keywords.tiramisu": "Tiramisù",
    "keywords.antipasti": "Antipasti",
    "keywords.burger": "Cittadini",
    "keywords.hamburger": "Hamburger",
    "keywords.cheeseburger": "Cheeseburger",
    "keywords.fries": "Fregio",
    "keywords.fastFood": "Fast food",
    "keywords.menHairdresser": "Parrucchiere da uomo",
    "keywords.menSalon": "Salone maschile",
    "keywords.barber": "Barbiere",
    "keywords.shaving": "Farsi la barba",
    "keywords.haircutForMen": "Taglio di capelli per uomo",
    "keywords.kidsHairdresser": "Parrucchiere per bambini",
    "keywords.kidCut": "Taglio per bambini",
    "keywords.kidHaircut": "Taglio di capelli per bambini",
    "keywords.haircutForKids": "Taglio di capelli per bambini",
    "keywords.kidStyling": "Lo stile dei bambini",
    "keywords.womenHairdresser": "Parrucchiere per donna",
    "keywords.womenSalon": "Salone per signore",
    "keywords.haircutForWomen": "Taglio di capelli per le donne",
    "keywords.dyeing": "Tintura",
    "keywords.stylingForWomen": "Stile per le donne",
    "keywords.technology": "Tecnologia",
    "keywords.electronics": "Elettronica",
    "keywords.devices": "Dispositivi",
    "keywords.accessories": "Accessori",
    "units.km": "Km",
    "open_24_hours": "Aperto 24 ore",
    "favorites_and_bookings": "Preferiti e prenotazioni",
    "no_favorites": "Nessun preferito disponibile!",
    "no_favorites_text": "Non hai ancora aggiunto nessun preferito. Sfoglia i tuoi negozi locali e salvali come preferiti per accedervi più facilmente in seguito!",
    "no_coupons": "Nessun coupon disponibile!",
    "no_coupons_text": "Non hai ancora attivato alcun coupon. Scopri e attiva i coupon per usufruire degli sconti!",
    "onboardingTutorial_title_step1": "Scoprire",
    "onboardingTutorial_description_step1": "Scopri il mondo del commercio locale, che si tratti di ristoranti, supermercati, parrucchieri, saloni di bellezza, laboratori e molto altro!",
    "onboardingTutorial_imageCaption_step1": "Tutto è lì, tutto è vicino.",
    "onboardingTutorial_title_step2": "Riscattare",
    "onboardingTutorial_description_step2": "I nostri partner di vendita al dettaglio locali ti invitano a beneficiare di sconti diretti e regali per prodotti o servizi.",
    "onboardingTutorial_imageCaption_step2": "Sostenere il locale e risparmiare non è possibile? Ma!",
    "onboardingTutorial_title_step3": "Salva",
    "onboardingTutorial_description_step3": "Tutte le offerte a portata di mano. Devi semplicemente mostrare la tua prenotazione al dipendente nel negozio. Ecco come sosteniamo il commercio locale!",
    "onboardingTutorial_imageCaption_step3": "Facile e veloce per tutti.",
    "confirm": "Confermare",
    "start_now": "Inizia ora",
    "next": "Ulteriore",
    "submit": "Invia",
    "company_name_required": "Il nome dell'azienda è obbligatorio",
    "company_email_required": "L'e-mail aziendale è obbligatoria",
    "invalid_email": "Indirizzo e-mail non valido",
    "invalid_zipCode": "Codice postale non valido",
    "company_address_required": "L'indirizzo dell'azienda è obbligatorio",
    "company_zipCode_required": "Il codice postale è obbligatorio",
    "company_city_required": "La città è obbligatoria",
    "company_country_required": "Il paese è obbligatorio",
    "company_phone_required": "È richiesto il telefono aziendale",
    "invalid_phone": "Numero di telefono non valido",
    "placeholder_company_name": "Inserisci il nome dell'azienda",
    "placeholder_company_email": "Inserisci l'e-mail aziendale",
    "placeholder_company_address": "Inserisci via e numero civico",
    "placeholder_company_zipCode": "Inserisci il codice postale",
    "placeholder_company_city": "Inserisci la città",
    "placeholder_company_country": "Inserisci il paese",
    "placeholder_company_phone": "Inserisci il numero di telefono",
    "placeholder_company_pocName": "Immettere il nome e il cognome della persona da contattare",
    "placeholder_company_pocRole": "Seleziona il ruolo della persona da contattare",
    "no_stores": "Nessuna attività creata",
    "no_stores_text": "Non hai ancora aggiunto un negozio. Clicca qui e crea subito il tuo negozio in un attimo per poter offrire coupon.",
    "more_stores": "Hai un'altra filiale?",
    "more_stores_text": "Fai clic qui per aggiungere un altro negozio in cui puoi anche creare coupon.",
    "stores_of_company": "Filiali della tua azienda",
    "vendor_poc_roles.owner": "Proprietario",
    "vendor_poc_roles.employee": "Impiegato",
    "vendor_poc_roles.else": "Altro",
    "coupon.ON_SITE": "Sul posto",
    "coupon.TO_GO": "Andare",
    "coupon.NINETY_DAYS": "90 giorni",
    "coupon.THIRTY_DAYS": "30 giorni",
    "coupon.WEEKLY": "Settimanale",
    "coupon.ONE_YEAR": "Annuale",
    "coupon.DAILY": "Quotidiano",
    "coupon.THREE_DAYS": "3 giorni",
    "coupon.HUNDRED_EIGHTY_DAYS": "180 giorni",
    "paywall.price_description_yearly": "Pagamento annuale",
    "paywall.price_description_monthly": "Pagamenti mensili",
    "monetization.paywall_title": "Tutte le offerte. Risparmia subito.",
    "monetization.maybe_later": "Forse più tardi",
    "monetization.monthly": "Mensile",
    "monetization.yearly": "Annuale",
    "monetization.monthly_marketing.first_benefit": "Di solito ne vale la pena solo per 1-2 acquisti!",
    "monetization.monthly_marketing.second_benefit": "Può essere cancellato mensilmente!",
    "monetization.monthly_marketing.third_benefit": "Riscatta immediatamente tutti i coupon.",
    "monetization.yearly_marketing.first_benefit": "Ne vale la pena per soli 3-4 acquisti all'anno!",
    "monetization.yearly_marketing.second_benefit": "Paga una volta, divertiti per un anno!",
    "monetization.yearly_marketing.third_benefit": "Riscatta immediatamente tutti i coupon.",
    "monetization.price_description_effective_per_month": "Efficace al mese",
    "monetization.price_description_per_month": "Al mese",
    "monetization.confirmation_title": "Acquisto riuscito!",
    "monetization.confirmation_message": "Grazie per il tuo acquisto Ora hai accesso a tutte le funzionalità premium.",
    "validation.setProductName": "È necessario impostare il nome del prodotto.",
    "for": "Per",
    "coupon_factory.2for1_title": "Acquistane 2, ricevi 1 gratis",
    "coupon_factory.2for1_description": "O anche di più. Crea un'offerta di quantità",
    "coupon_factory.win_customers_and_money": "Guadagna clienti e vendite.",
    "coupon_factory.example": "Coupon di esempio",
    "coupon_factory.create_now": "Crea ora",
    "coupon_factory.create_coupon_marketing_header": "Ottieni nuovi clienti gratuitamente adesso",
    "coupon_factory.create_coupon_marketing_body": "Crea il tuo coupon in pochi passaggi.",
    "coupon_factory.create_coupon_benefit_header_1": "🎯 Nuovi clienti",
    "coupon_factory.create_coupon_benefit_body_1": "Raggiungi nuovi clienti direttamente tramite il tuo smartphone",
    "coupon_factory.create_coupon_benefit_header_2": "🚀 Facile e veloce",
    "coupon_factory.create_coupon_benefit_body_2": "Crea affari più facilmente che mai.",
    "coupon_factory.create_coupon_benefit_header_3": "💸 Più vendite",
    "coupon_factory.create_coupon_benefit_body_3": "Aumenta le tue vendite con i coupon per i tuoi clienti.",
    "coupon_factory.create_coupon_card": "Crea un buono!",
    "coupon_factory.create_coupon_card_info": "Clicca qui per creare un coupon in 3 passaggi.",
    "coupon_factory.create_coupon": "Crea un buono!",
    "coupon_factory.create_coupon_body": "Tre passaggi per perfezionare la pubblicità per i nuovi clienti!",
    "coupon_factory.condition_for_customer": "Imposta le condizioni per i clienti",
    "coupon_factory.choose_condition": "Seleziona condizione",
    "coupon_factory.choose_condition_info": "Cosa dovrebbe fare il cliente per trarne vantaggio",
    "coupon_factory.minimum_order_value": "Valore minimo dell'ordine",
    "coupon_factory.minimum_order_value_info": "Il cliente dovrebbe spendere un certo importo",
    "coupon_factory.product_purchase": "Acquisto del prodotto",
    "coupon_factory.product_purchase_info": "Il cliente dovrebbe acquistare determinati prodotti",
    "coupon_factory.direct_rebate": "Sconto diretto sul prodotto",
    "coupon_factory.no_conditions": "Nessuna condizione",
    "coupon_factory.no_conditions_set": "Nessuna condizione impostata",
    "coupon_factory.go_to_next_step": "Vai al passaggio successivo",
    "coupon_factory.input_mov": "Specificare il valore minimo di acquisto",
    "coupon_factory.count_of_products": "Numero di prodotti",
    "coupon_factory.product_name": "Nome del prodotto",
    "coupon_factory.value_of_product_per_piece": "Valore del prodotto (per pezzo)",
    "coupon_factory.order_discount": "Sconto sull'acquisto",
    "coupon_factory.product_discount": "Sconto su un prodotto",
    "coupon_factory.free_product": "Prodotto gratuito",
    "coupon_factory.discount": "Sconto",
    "coupon_factory.enter_discount_amount": "Inserisci l'importo dello sconto",
    "coupon_factory.redeem_location_conditons": "Condizioni di sconto",
    "coupon_factory.redeem_location_info": "Dove possono utilizzare lo sconto i tuoi clienti?",
    "coupon_factory.choose_discount": "Sconto",
    "coupon_factory.choose_discount_info": "Che tipo di sconto riceve il tuo cliente?",
    "coupon_factory.carousel_header_one": "Seleziona buono",
    "coupon_factory.carousel_body_one": "Seleziona il coupon che desideri creare.",
    "coupon_factory.carousel_header_two": "Imposta condizione",
    "coupon_factory.carousel_body_two": "Determina cosa deve fare il cliente.",
    "coupon_factory.carousel_header_three": "Imposta i vantaggi",
    "coupon_factory.carousel_body_three": "Determina cosa riceverà il cliente.",
    "coupon_factory.product_quantitiy": "Quantità per cliente",
    "coupon_factory.product_quantitiy_info": "Lo sconto si applica al pezzo!",
    "coupon_factory.product_quantitiy_free_product_info": "Quanti pezzi riceve GRATIS?",
    "coupon_factory.same_product": "Stesso prodotto",
    "coupon_factory.same_product_info": "Il cliente riceve lo stesso prodotto.",
    "coupon_factory.other_product": "Prodotto diverso",
    "coupon_factory.other_product_info": "Si prega di fornire il nome del prodotto.",
    "coupon_factory.price_per_unit": "Prezzo per pezzo",
    "coupon_factory.error_invalid_input": "Immissione non valida",
    "coupon_factory.error_rebate_higher_as_order_value": "Lo sconto non può essere superiore all'importo dell'acquisto/prodotto",
    "coupon_factory.error_rebate_higher_as_product_value": "Lo sconto non può essere superiore all'importo dell'acquisto/prodotto",
    "coupon_factory.max_created_coupon_card": "È stato raggiunto il numero massimo di coupon",
    "coupon_factory.max_created_coupon_card_info": "Hai raggiunto il limite massimo attuale per i tuoi coupon.",
    "coupon_factory.max_created_stores": "Raggiunto il numero massimo di negozi",
    "coupon_factory.max_created_stores_info": "Hai raggiunto il numero massimo attuale di offerte che possono essere create. Se desideri creare un altro negozio, contattaci utilizzando il modulo di contatto nelle impostazioni.",
    "details": "Dettagli",
    "enter_details": "Fornire dettagli",
    "store_factory.store_conflict": "Si è verificato un problema con il negozio",
    "store_factory.store_conflict_info": "Il negozio selezionato su Google è già stato registrato su Bazaar. Per favore controlla una volta. Se i problemi persistono, non esitate a contattarci.",
    "store_factory.wrong_store": "Scelta dell'attività sbagliata?",
    "store_factory.wrong_store_search_again": "Vuoi ricominciare di nuovo? Tieni presente che le tue informazioni su Google devono essere corrette.",
    "store_factory.restart": "Ricomincia",
    "store_factory.what_customers_see": "Questo è ciò che vedono i tuoi clienti",
    "store_factory.check_details": "Controlla se le tue informazioni sono corrette.",
    "store_factory.store_name": "Nome della filiale",
    "store_factory.store_name_example": "Nome della tua attività",
    "store_factory.store_phone": "Numero di telefono per i tuoi clienti",
    "store_factory.store_email": "E-mail per i tuoi clienti",
    "store_factory.choose_category": "Seleziona categoria",
    "store_factory.choose_category_info": "Scegli una categoria principale, fino a 3 sottocategorie e carica un'immagine del tuo negozio.",
    "store_factory.main_category": "Categoria principale",
    "store_factory.sub_categories": "Sottocategorie",
    "store_factory.upload_store_img": "Carica l'immagine del negozio",
    "store_factory.store_img": "Foto dal negozio",
    "store_factory.max_achieved": "Massimo raggiunto",
    "store_factory.sub_categories_limit": "Puoi selezionare un massimo di 3 sottocategorie.",
    "store_factory.categories_and_image_required": "Le categorie e le immagini sono necessarie!",
    "store_factory.zip_code": "Codice Postale",
    "store_factory.zip_code_placeholder": "Per esempio. 44801",
    "store_factory.claim_store": "Prendi il controllo degli affari",
    "store_factory.house_number": "Numero civico",
    "store_factory.house_number_placeholder": "Per esempio. 33",
    "store_factory.street": "Strada",
    "store_factory.street_placeholder": "Per esempio. Via del Bazar",
    "store_factory.city": "Città",
    "store_factory.city_placeholder": "Per esempio. Bochum",
    "store_factory.success": "Negozio creato con successo",
    "store_factory.success_info": "Il tuo negozio è stato creato con successo ed è ora in fase di verifica.",
    "store_factory.success_verification": "La verifica richiede solitamente 1-2 giorni lavorativi. Una volta completata la verifica, il tuo negozio diventerà attivo e sarà visibile ai clienti.",
    "store_factory.verification_pending": "Verifica in sospeso",
    "store_factory.verification_pending_info": "Attualmente è in corso un processo di verifica. Ci metteremo in contatto se avremo bisogno di maggiori informazioni da te. Se avete domande, non esitate a contattarci.",
    "store_factory.no_coupons": "Nessun coupon ancora",
    "store_factory.no_coupons_info": "Non hai ancora creato alcun coupon. Fare clic sul negozio per creare un coupon.",
    "to_dashboard": "Al cruscotto",
    "error_unexpected": "Si è verificato un errore imprevisto. Riprova. Se il problema persiste, non esitate a contattarci.",
    "check_inputs": "Controlla le informazioni",
    "warning": "Pericolo",
    "progress_deleted_info": "Il tuo stato provvisorio verrà cancellato se torni qui. Sei sicuro?",
    "notification": "Notifica",
    "notifications": "Notifiche",
    "request_notification_permission": "Abilitare le notifiche push?",
    "denied_notification_permission": "Notifiche push rifiutate",
    "denied": "Negato",
    "activate_notification_in_settings": "Puoi abilitare le notifiche push nelle Impostazioni",
    "notifcationsTitle.message1": "🥳 È tempo di sconti locali!",
    "notifcationsBody.message1": "Risparmia ora con coupon esclusivi per i negozi locali! Che si tratti di cibo, moda o tempo libero, scopri fantastici sconti e sostieni la tua comunità!"
  }
};
