import React from "react";
import PartnerSearch from "../components/partner/PartnerSearch";
import "./PartnerPage.css";
import BenefitSection from "../components/partner/BenefitSection";
import Accordion from "../components/Accordion";
import googlePlayBadge from "../assets/images/badges/google-play-badge.png"; // Google Play Store badge for Partner App
import appStoreBadge from "../assets/images/badges/app-store-badge.png"; // Apple App Store badge for Partner App
import HeaderTextComponent from "../styles/HeaderTextComponent";
import NormalTextComponent from "../styles/NormalTextComponent";
import mockupImage from "../assets/images/mockup2PhonesApp.png"; // Bildimport für das Mockup
import useTypedTranslation from "../i18n/useTypedTranslation";
import dS from "../designSystem/designSystem";
import HowItsWorksPartner from "../components/partner/HowItWorksPartner";
import VENDORS from "../assets/images/local_vendors.png"

import {
  BASAR_PARTNER_ANDROID_APP_URL,
  BASAR_PARTNER_IOS_APP_URL,
} from "../components/links/AppStoreLinks";

const PartnerPage: React.FC = () => {
  const { t } = useTypedTranslation();
  const faqData = [
    {
      category: t("frequently_asked_questions"),
      questions: [
        {
          question: t("vendor_faq.how_work_question"),
          answer: t("vendor_faq.how_work_answer"),
        },
        {
          question: t("vendor_faq.participating_businesses_question"),
          answer: t("vendor_faq.participating_businesses_answer"),
        },
        {
          question: t("vendor_faq.app_free_question"),
          answer: t("vendor_faq.app_free_answer"),
        },
        {
          question: t("vendor_faq.create_store_question"),
          answer: t("vendor_faq.create_store_answer"),
        },
        {
          question: t("vendor_faq.manage_coupons_question"),
          answer: t("vendor_faq.manage_coupons_answer"),
        },
      ],
    },
  ];

  return (
    <div className="partner-page-container">
      <div className="two-grid-section-container">
        <div className="left-grid-container">
          <h1>Gratis neue Kunden erreichen.</h1>
          <p>
            Finde dein Geschäft & erhalte einen Vorgeschmack davon, wie dein
            Geschäft auf der App aussehen könnte.
          </p>
          <PartnerSearch />
          {/* App Store download buttons for Partner App */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "20px",
              marginTop: "20px",
            }}
          >
            <a
              href={BASAR_PARTNER_ANDROID_APP_URL}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={googlePlayBadge}
                alt="Download on Google Play"
                style={{ width: "150px" }}
              />
            </a>
            <a
              href={BASAR_PARTNER_IOS_APP_URL}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={appStoreBadge}
                alt="Download on the App Store"
                style={{ width: "150px" }}
              />
            </a>
          </div>
        </div>

        <div className="right-grid-container">
          <img
            src={VENDORS}
            alt="Your Alt Text"
            className="right-grid-image"
          />
        </div>
      </div>

      {/* How It Works */}
      <HowItsWorksPartner />

      {/* Benefits */}

      <div className="section-container">
        <div className="section-header">
          <h1>Vorteile</h1>
        </div>
        <BenefitSection />å
      </div>

      {/* App Mockup */}
      <div className="section-container">
        <div className="section-header">
          <h1>Direkt loslegen. Komplett kostenlos.</h1>
        </div>
        <div className="two-column-section">
          <div className="left-column">
            <img
              src={mockupImage}
              alt="Mockup der App auf zwei Telefonen"
              className="section-image"
            />
          </div>
          <div className="right-column">
            <HeaderTextComponent
              level={1}
              title="Lokale Schätze entdecken - BASAR"
              seoDescription="Entdecke lokale Vielfalt und spare mit BASAR"
              style={{ marginBottom: 1 }}
            >
              <span style={{ color: dS.colors.basarColorDarkBlue }}>
                Gewinne
              </span>{" "}
              neue Kunden.
            </HeaderTextComponent>
            <NormalTextComponent
              size="L"
              title="Lokale Vielfalt entdecken - BASAR"
              seoDescription="Entdecke die Vielfalt des lokalen Handels mit BASAR"
            >
              - Schnell und unkompliziert loslegen.
            </NormalTextComponent>
            {/* App store download buttons */}
            <div
              style={{
                display: "flex",
                gap: "10px",
                marginTop: "20px",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <a
                href={BASAR_PARTNER_ANDROID_APP_URL}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={googlePlayBadge}
                  alt="Download on Google Play"
                  style={{ width: "150px" }}
                />
              </a>
              <a
                href={BASAR_PARTNER_IOS_APP_URL}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={appStoreBadge}
                  alt="Download on the App Store"
                  style={{ width: "150px" }}
                />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="section-container">
        <div className="section-header">
          <h1>Wie sieht dein Geschäft auf der App aus?</h1>
          <p>
            Finde dein Geschäft & erhalte einen Vorgeschmack davon, wie dein
            Geschäft auf der App aussehen könnte.
          </p>
        </div>
        <div className="partner-search-container">
          <PartnerSearch />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "20px",
            marginTop: "20px",
          }}
        >
          <a
            href={BASAR_PARTNER_ANDROID_APP_URL}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={googlePlayBadge}
              alt="Download on Google Play"
              style={{ width: "150px" }}
            />
          </a>
          <a
            href={BASAR_PARTNER_IOS_APP_URL}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={appStoreBadge}
              alt="Download on the App Store"
              style={{ width: "150px" }}
            />
          </a>
        </div>
        <div className="partner-search-container">
          {faqData.map((category, index) => (
            <div key={index} className="faq-category">
              <h2 style={{ color: dS.colors.basarColorDarkBlue }}>
                {category.category}
              </h2>
              {category.questions.map((faq, i) => (
                <Accordion
                  key={i}
                  question={faq.question}
                  answer={faq.answer}
                />
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PartnerPage;
