import React, { useState, useEffect } from "react";
import "./CallToActionApp.css";
import {
  BASAR_PARTNER_ANDROID_APP_URL,
  BASAR_PARTNER_IOS_APP_URL,
  BASAR_USER_ANDROID_APP_URL,
  BASAR_USER_IOS_APP_URL,
} from "./links/AppStoreLinks";

const useDeviceDetect = () => {
  const [isAndroid, setIsAndroid] = useState(false);

  useEffect(() => {
    const userAgent =
      navigator.userAgent || navigator.vendor || (window as any).opera;
    setIsAndroid(/android/i.test(userAgent));
  }, []);

  return { isAndroid };
};

const CallToActionApp: React.FC = () => {
  const { isAndroid } = useDeviceDetect();

  const getGooglePlayLinkForUser = (): string => {
    return isAndroid ? BASAR_USER_ANDROID_APP_URL : BASAR_USER_IOS_APP_URL;
  };

  const getGooglePlayLinkForBusiness = (): string => {
    return isAndroid
      ? BASAR_PARTNER_ANDROID_APP_URL
      : BASAR_PARTNER_IOS_APP_URL;
  };

  return (
    <div className="blog-advertisement">
      <div className="column">
        <h2>Bist du schon auf BASAR?</h2>
        <p>
          BASAR ist die Couponplattform für den lokalen Handel. Lade dir jetzt
          die App herunter und entdecke die besten Angebote in deiner Nähe!
        </p>
        <button
          onClick={() => window.open(getGooglePlayLinkForUser(), "_blank")}
        >
          Zur App
        </button>
      </div>
      <div className="column">
        <h2>Bist du Händler?</h2>
        <p>
          Vermarkte gratis dein Geschäft auf BASAR und erreiche tausende Kunden
          in deiner Region! Lade dir jetzt die App herunter, um dein Geschäft
          nach vorne zu bringen!
        </p>
        <button
          onClick={() => window.open(getGooglePlayLinkForBusiness(), "_blank")}
        >
          Als Händler registrieren
        </button>
      </div>
    </div>
  );
};

export default CallToActionApp;
