import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { Locales } from './locales';
import { translations } from './translations';

// Browsersprache abrufen
const languageTag = navigator.language.split('-')[0] as Locales || 'en'; // Nur Sprachcode, Region ignorieren

// i18n konfigurieren
i18n
  .use(initReactI18next)
  .init({
    resources: {
      "de": { translation: translations["de"] },
      "en": { translation: translations["en"] },
    },
    lng: languageTag,
    fallbackLng: 'de',
    compatibilityJSON: 'v3',
    interpolation: {
      escapeValue: false,
    },
  }, (err, t) => {
    if (err) console.error("i18n init error:", err);
    console.log("i18n initialized:", t("welcome")); // Teste die Übersetzungen hier
  });
